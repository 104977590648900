import React, { useState, useRef, useCallback, useMemo, useEffect, useContext } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import MusicModal from '../MusicModal';
import VolumeUpIcon from '@mui/icons-material/VolumeUp'; // Icon for volume
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'; // Importing play icon
import MusicLibrary from '../../utils/music-library.json'
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useVideo from '../../hooks/useVideo';
import { mergeAudio } from '../../redux/slices/video.slice';
import { RootState } from '../../redux/store';
import { VideoContext } from '../../context/video.context';
import { addNewMusicBlockBasedOnMarker, adjustScaleLength, getMusicDuration } from '../../utils/functions';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

function MusicCard(disabled: any) {
    const dispatch = useAppDispatch();

    const contextValue = useContext(VideoContext);

    if (!contextValue) {
        // handle the case where the context value is not provided
        throw new Error("VideoContext value is not provided");
    }

    const { musicTracks, setMusicTracks, videoFile, voiceOverTracks, setVoiceOverTracks, setScale, setScaleLength,
        markerXPosition, setMarkerXPosition, selectedMusicBlockId, setSelectedMusicBlockId
    } = contextValue;


    const [volume, setVolume] = useState(50); // Default volume set to 50%
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(new Audio(''));
    const [selectMusicModal, setSelectMusicModal] = useState(false)
    const { videoId, user } = useVideo()
    const fileInputRef = useRef<HTMLInputElement>(null);
    // const musicId = useAppSelector((state: RootState) => state.video.musicId)

    const getMusicById = useMemo(
        () => {
            return musicTracks.find(({ id }: any) => id == selectedMusicBlockId) || { file: { name: "Add Music", }, thumbnail_url: '/addmusic.png' }
        },
        [selectedMusicBlockId],
    )

    const playMusic = () => {
        if (!isPlaying) {
            audioRef.current.play();
            setIsPlaying(true);
        } else {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleMusicSelection = async () => {
        try {
            // Call getMusicById function to fetch music file data

            // Pause the audio before setting a new source
            audioRef.current.pause();
            const musicURL = URL.createObjectURL(getMusicById.file);
            audioRef.current.src = musicURL;

            // Listen for the canplaythrough event before playing the audio
            audioRef.current.addEventListener('canplaythrough', () => {
                // Play the music once it's ready
                playMusic();
            });
        } catch (error) {
            console.error('Error fetching music track:', error);
        }
    };


    useEffect(() => {
        audioRef.current.volume = volume / 100;
    }, [volume])



    const selectMusic = (url: string) => {
        audioRef.current.src = url;
        setSelectMusicModal(false)
        // get music_id
        let music = MusicLibrary.find(({ audio_url }) => audio_url == url)
        if (music) {
            let data = new FormData()
            data.append('video_id', videoId)
            data.append('user_id', user?.id || '')
            data.append('music_id', music.id.toString())
            data.append('music_url', url)
            data.append("start_point", '0')
            data.append("volume_level_music", (volume / 100).toString())
            dispatch({ type: 'video/addMusicId', payload: music })
        }

    }

    const handleMusicUpload = async (event: any) => {
        const files = Array.from(event.target.files);
        const MAX_DURATION_ALLOWED = 5400;

        try {
            const newTracks = await Promise.all(files.map(async (file: any, index) => {
                const duration = await getMusicDuration(file); // This function gets the duration of the music file

                // Check if the music duration exceeds the maximum allowed duration
                if (typeof duration === 'number' && duration > MAX_DURATION_ALLOWED) {
                    console.log(`Maximum allowed music duration is 90 minutes. The uploaded file exceeds this limit.`);
                    return null;
                }

                // Calculate total duration of all existing plus new music blocks
                const totalDurationWithNewBlock = musicTracks.reduce((total: number, track: any) => total + track.modifiedDuration, duration);

                if (totalDurationWithNewBlock > MAX_DURATION_ALLOWED) {
                    console.log("Exceeds maximum allowed duration when combined with existing blocks.");
                    return null; // Skip adding this block
                }

                if (totalDurationWithNewBlock > file) {
                    // Adjust the scale length based on the total duration
                    adjustScaleLength(totalDurationWithNewBlock);
                }

                const markerTimestamp = markerXPosition; // The current position of the marker on the scale
                const newBlock = addNewMusicBlockBasedOnMarker(markerTimestamp, duration as number, musicTracks);

                console.log("newBlock is: ", newBlock)
                if (newBlock) { // Only proceed if there is space for the new block
                   
                    let newID = `track-${Date.now()}-${index}`;
                    setSelectedMusicBlockId(newID)

                    return {
                        id: newID,
                        file,
                        originalStartTime: newBlock.originalStartTime,
                        originalDuration: duration,
                        originalEndTime: newBlock.originalEndTime,
                        modifiedStartTime: newBlock.modifiedStartTime,
                        modifiedDuration: duration,
                        modifiedEndTime: newBlock.modifiedEndTime,
                        preTrimStartTime: newBlock.originalStartTime,
                        preTrimEndTime: newBlock.originalEndTime,
                        musicTrimDuration: duration,
                        trimDurationAmount_L: 0,
                        trimDurationAmount_R: 0,
                        volume: 50,
                    };
                } else {
                    return null;
                }
            }));

            // Filter out any null values and add new tracks to the state
            const updatedTracks = [...musicTracks, ...newTracks.filter(track => track !== null)];
            // Sort the updated tracks array by start time
            updatedTracks.sort((a, b) => a.modifiedStartTime - b.modifiedStartTime);
            setMusicTracks(updatedTracks);

            ////// Calculate the longest end time among music, voice-over, and video
            const longestMusicEndTime = Math.max(...updatedTracks.map(track => track.modifiedEndTime));
            const longestVoiceOverEndTime = Math.max(...voiceOverTracks.map((track: any) => track.modifiedEndTime));
            const calculatedLongestDuration = Math.max(videoFile.duration, longestMusicEndTime, longestVoiceOverEndTime);
            // Adjust the scale length if necessary
            if (calculatedLongestDuration > videoFile.duration) {
                let setting = await adjustScaleLength(calculatedLongestDuration);
                if (setting == null) return
                setScale(setting?.scale);
                setScaleLength(setting?.scaleLength);
            }

        } catch (error) {
            console.error('An error occurred while uploading files:', error);
            // Handle the error appropriately in the UI
        }
    };


    function updateVolumneMusic(vol: any) {
        let temp = [...musicTracks]
        let indxxx = temp.findIndex(({ id }) => id === selectedMusicBlockId)
        temp[indxxx].volume = vol
        setMusicTracks(temp)
    }

    function removeMusic() {
        let temp = [...musicTracks]
        temp = temp.filter(({ id }) => id !== selectedMusicBlockId)
        setMusicTracks(temp)
        setSelectedMusicBlockId(null)
    }


    return (
        <>
            <div className='box-shadoww' style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: selectedMusicBlockId ? '#725578' : '#EAEAEA', borderRadius: '8px', marginTop: '10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>

                {/* Music Thumbnail */}
                <div style={{ flexShrink: 0, backgroundColor: 'white', width: '80px', height: '80px', borderRadius: '8px', overflow: 'hidden', position: 'relative', margin: 10 }}>
                    <img src={getMusicById?.thumbnail_url || "/music-track.jpg"} alt="music-thumbnail" style={{ width: '100%', height: '100%', objectFit: 'cover', }} />

                    {/* Play Icon */}
                    {getMusicById.id && <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }} className="play-icon" onClick={handleMusicSelection}>
                        {isPlaying ? <PauseCircleOutlineIcon style={{ fontSize: '40px', color: '#ffffff' }} /> : <PlayCircleOutlineIcon style={{ fontSize: '40px', color: '#ffffff' }} />}
                    </div>}
                </div>

                {/* Music Info, Controls, and Volume */}
                <div style={{ flex: 1, marginLeft: '10px' }}>
                    <p className='music-title' style={{ margin: 0, fontWeight: 'bold', fontSize: '16px', color: '#ffffff' }}>{getMusicById?.file?.name}</p>
                    <p className='music-subtitle' style={{ margin: '5px 0', color: '#c0c0c0', fontSize: '14px' }}>{getMusicById?.artist_name}</p>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                        {/* Volume Controller */}
                        {selectedMusicBlockId && <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <VolumeUpIcon style={{ color: '#c0c0c0' }} />
                            <input type="range" min="0" max="100" value={getMusicById?.volume || 50} onChange={(e: any) => {
                                updateVolumneMusic(e.target.value)
                            }} style={{ width: '100px', cursor: 'pointer' }} />
                        </div>}

                        {/* Delete and Change Song Buttons */}
                        <div style={{ display: 'flex', justifyContent: 'f lex-end', gap: '10px' }}>
                            {selectedMusicBlockId ? <div onClick={removeMusic} style={{ backgroundColor: 'white', padding: '5px', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <DeleteIcon style={{ color: '#725578' }} />
                            </div> :
                                <div onClick={() => !disabled && fileInputRef?.current?.click()} style={{ backgroundColor: 'white', padding: '5px', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                    <AddIcon style={{ color: '#725578' }} />
                                </div>
                            }
                            {selectedMusicBlockId && <div style={{ backgroundColor: 'white', padding: '5px 5px', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <p className='music-change-song' style={{ margin: 0, fontSize: '14px', fontWeight: '500', color: '#725578' }}>Change Song</p>
                            </div>}
                            <input
                                ref={fileInputRef}
                                type="file"
                                accept=".mp3, .wav"
                                multiple
                                onChange={handleMusicUpload}
                                style={{ display: 'none' }} // Hide the input
                            />
                        </div>
                    </div>
                </div>
            </div>
            <MusicModal selectMusic={selectMusic} open={selectMusicModal} />

        </>
    )
}


export default MusicCard;