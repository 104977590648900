import './style.css'
import { Modal, Box, useMediaQuery, useTheme, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate } from 'react-router-dom';

const LanguageCaptionModal = ({ open, close, updateLanguageOption }: any) => {
    const navigate = useNavigate()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : "65%",
        maxWidth: '90%',
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: 500
    };

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ position: 'absolute', left: 20, top: 15 }}>
                    <ArrowBackIcon  className="close-icon" onClick={close} />
                </div>
                <Grid display={'flex'} justifyContent={'center'} alignItems={'space-around'} minHeight={500} container>
                    <Grid display={'flex'} justifyContent={'center'} alignItems={'center'} md={12}>
                        <p className='head-title'>
                            Choose language to <span>add captions</span> in
                        </p>
                    </Grid>
                    <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                        <Grid item md={4} sm={12} xs={12} display='flex' flexDirection='column' alignItems='center'>
                            <div onClick={() => updateLanguageOption(1)} className='main-lang-div'>
                                <p className="title-lang one">
                                    English
                                </p>
                                <p className='desc-lang'>
                                    Video already in English
                                </p>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} display='flex' flexDirection='column' alignItems='center'>
                            <div onClick={() => updateLanguageOption(2)} className='main-lang-div'>
                                <p className="title-lang two">
                                    Original Language
                                </p>
                                <p className='desc-lang'>
                                    For Non-English Videos
                                </p>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} display='flex' flexDirection='column' alignItems='center'>
                            <div onClick={() => updateLanguageOption(3)} className='main-lang-div'>
                                <p className="title-lang three">
                                    Translate to English
                                </p>
                                <p className='desc-lang'>
                                    For Non-English Videos
                                </p>
                            </div>
                        </Grid>

                    </Grid>
                    <Grid display={'flex'} justifyContent={'center'} alignItems={'center'} md={12}>
                        <p className='last-desc'>
                            You cannot reverse this step!
                        </p>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default LanguageCaptionModal;
