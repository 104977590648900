import "./style.css"
import { Fragment } from 'react'
import Header from '../Header';
import { Container, Grid } from '@mui/material';
import logs from '../../utils/product_update.json'


const ProductUpdate = () => {
    return (
        <div style={{ backgroundColor: 'white' }}>
            <Fragment>
                <Header></Header>
                <Container sx={{ backgroundColor: 'white', height: '100vh' }} maxWidth="xl">
                    <Grid spacing={2} container>
                        <Grid item sx={{ height: 150, borderBottomWidth: 1 }} md={12} sm={12} xs={12} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={'center'}>
                            <p className="prod-head">
                                Product Logs
                            </p>
                            <p className="prod-desc">
                                We are continuously enhancing, perfecting, and optimizing ViralMe, adding new features and constantly monitoring for improvements.
                            </p>
                        </Grid>
                        <Grid item container spacing={{ xs: 2, md: 8, sm: 2 }} md={12} sm={12} xs={12}>
                            <Grid item md={3}>
                                <p className="archive-txt">Archive</p>
                                <div style={{ marginTop: 20 }}>
                                    {logs.map(({ date }: any) =>
                                        <p> {date}</p>
                                    )}</div>
                            </Grid>
                            <Grid item md={9}>
                                <p className="wel-txt">Welcome to the ViralMe Product Updates page. Here you can find the latest news about changes to the ViralMe platform.</p>
                                <div style={{ marginTop: 20 }}>
                                    {logs.map(({ update, date }: any) => <>
                                        <p className="archive-txt"> {date}</p>
                                        <ul>
                                            {update.map((d: any) =>
                                                <li className="wel-txt">{d}</li>)}
                                        </ul>
                                    </>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Fragment>
        </div>
    )
}

export default ProductUpdate;