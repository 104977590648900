/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
/* eslint-disable capitalized-comments */

import {useRef} from 'react'
// import { useEffect, useState } from 'react';
import { Gif } from '@remotion/gif';
import { Img } from 'remotion';
import { useVideoConfig} from 'remotion';


export const Emoji = ({ src }: { src: string }) => {
    const { width, height } = useVideoConfig();
    const ref = useRef<HTMLCanvasElement>(null);

    const style = {
        display: 'inline-block', // This makes the div wrap tightly around the GIF
        // filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 245, 235, 1.0))',   // Apply the drop-shadow effect, not the box shadow.
        // width: width / 2, // Adjust size as needed
        // height: height / 2,
        // filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 245, 235, 1.0)) blur(0.05rem)',
        filter: 'drop-shadow(0.85rem 0.35rem 0.4rem rgba(255, 245, 235, 1.0))',
    };

    // Extract the file extension from the URL before any query parameters
    const extension = src ? new URL(src).pathname.split('.').pop()?.toLowerCase() : '';
    // const extension = new URL(src).pathname.split('.').pop().toLowerCase();
    // Check if the extracted extension is 'gif'
    const isGif = extension === 'gif';

    return (
        <div style={style}> {/* Apply the shadow style here */}
            {isGif ? (
                // Render as GIF if source ends with .gif
                <Gif
                    ref={ref}
                    src={src}
                    width={width / 6} // Adjust size as needed
                    height={height / 7}
                    fit="contain"
                    playbackRate={1}
                    loopBehavior="loop"
                />
            ) : (
                // Render as image if source is not a GIF
                <Img
                    src={src}
                    style={{
                        width: width / 6,
                        height: height / 7,
                        objectFit: 'contain',
                    }}
                />
            )}
        </div>
    );
};
