import React, { Fragment, useEffect, useState } from 'react';
import PlanCard from './PlanCard';
import './style.css'
import './ProfileCard.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';
import { updateProfile, uploadProfilePic } from '../../redux/slices/profile.slice';
import { APP_URL, BASE_URL } from '../../utils/url';
import { Button, Card, Container, Grid, IconButton, Snackbar, Typography } from '@mui/material';
import { checkSubsPlan } from '../../utils/functions';
import {  useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../Header';
import ChangePassword from './ChangePassword';
import InputWithoutIcon from '../../components/InputBox/inputWithoutIcon';
import RewardTable from './rewardTable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Profile = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  const user = useAppSelector((state: RootState) => state.auth.user)

  const [open, setOpen] = React.useState(false);
  const [image, setImage] = useState('/default-profile.png'); // Default image path
  const [plan, setPlan] = useState('Starter'); // Change this to test different plans
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState('');
  const [tab, setTab] = useState("profile")
  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e: any) => {
    setName(e.target.value);
  };

  const handleInputBlur = () => {
    console.log("blur")
    profileupdate()
  };

  useEffect(() => {
    setName(user?.fullName || "")
    if (user?.subscriptionPlan) {
      let pkg = checkSubsPlan(user)
      setPlan(pkg?.name)
    } else {
      setPlan("Free")
    }
  }, [user])




  const profileupdate = () => {
    if (name !== user?.fullName && user) {
      let data = {
        id: user.id, fullName: name
      }
      dispatch(updateProfile(data))
    }
  }

  useEffect(() => {
    const currentTab: any = searchParams.get('tab');
    if (currentTab) {
      setTab(currentTab)
      // setSelectedOption(currentTab);
    }
  }, [searchParams]);

  const handleCopyClick = () => {
    const textToCopy = `${APP_URL}signup?referral=${user?.referral}`;

    navigator.clipboard.writeText(textToCopy).then(() => {
      setOpen(true);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };


  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const inputStyle = {
    fontSize: '2em', // Match h2 font size
    fontWeight: 'bold', // Match h2 font weight
    border: 'none',
    outline: 'none',
    width: '100%',
    backgroundColor: 'transparent',
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file && user) {

      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);

      let formData = new FormData();
      formData.append("userId", user?.id)
      formData.append("filePath", file)
      dispatch(uploadProfilePic(formData))

    }
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    const form = event.target.closest('form');
    form.submit();
  };

  const rewardContent = (
    <Grid sx={{ marginTop: 5 }} container spacing={1}>
      <Grid md={6}>
        <Card sx={{ padding: 2 }} className="display-flex">
          <div>
            <img className="img-trophy" src="/reward-point.png"></img>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Typography className="input-field ">Reward Point</Typography>
            <Typography className="input-field color-gold">{user?.reward}</Typography>
          </div>
        </Card>
      </Grid>
      <Grid className='' sx={{ padding: 2 }} md={6}>
        <Grid className='centeralize' sx={{ justifyContent: 'flex-start' }} item md={12} sm={12}>
          <Typography className='referal-earn'>
            Refer & Earn &nbsp;<span>Rewards</span>&nbsp;
          </Typography>
        </Grid>
        <Grid className='centeralize' item md={12} sm={12}>
          <InputWithoutIcon disabled={true} onChange={() => { }} placeholder='' value={`${APP_URL}signup?referral=${user?.referral}`} />
          <IconButton color="primary" onClick={handleCopyClick}>  <ContentCopyIcon sx={{ color: "rgba(0,0,0,0.6)" }} /></IconButton>
        </Grid>
      </Grid>
      <Grid md={12}>
        <RewardTable></RewardTable>
      </Grid>
    </Grid>
  )

  return (
    <Fragment>
      <Header></Header>
      <Container>
        <Grid container sx={{ flex: 'display', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Grid item sx={{ marginTop: 2, width: '100%' }} xs={12}>
            <div className="display-flex text-decoration" onClick={() => {
              if (tab == "profile") { navigate("/") }
              else {
                setTab("profile")
              }
            }}><KeyboardBackspaceIcon htmlColor='rgba(0, 0, 0, 0.54)' /> <Typography className="profile-nav">Profile</Typography></div>
          </Grid>
          {tab == "profile" ? <div className='profile-conatiner'>
            <div className="profile-card-container">
              <div className="profile-image-container">
                <input
                  type="file"
                  id="file-input"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />
                <label htmlFor="file-input">
                  <div className="profile-image" style={{ backgroundImage: `url(${user?.pictureUrl || image})` }}></div>
                </label>
              </div>
              <div className="profile-details">
                <input
                  type="name"
                  value={name}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  autoFocus
                  style={inputStyle}
                  placeholder='Enter Your Full Name'
                  className="profile-details-input"
                />
                <p>{user?.email}</p>
                <button onClick={() => setTab("ChangePassword")} className="change-password-button">Change Password</button>
                <div className="plan-cards-container">
                  <div className="plan-card-container">
                    <form action={`${BASE_URL}/pay/create-portal-session`} method="POST">
                      <input
                        type="hidden"
                        id="customer"
                        name="customer"
                        value={user?.stripeCustomerId}

                      />
                      <PlanCard onClick={handleFormSubmit} plan="Manage Billing" />
                    </form>
                  </div>
                  <div className="plan-card-container">
                    <PlanCard onClick={() => navigate("/pricing")} plan={plan || "Free"} />

                  </div>
                  <div className="plan-card-container">
                    <PlanCard onClick={() =>
                      setTab("reward")
                    } plan="Reward" />
                  </div>
                </div>
                <button className="delete-account">
                  Delete Account
                  <span className="tooltip">
                    Deleting account will immediately delete all files, data & payment records associated with your account
                  </span>
                </button>
              </div>
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={open}
              autoHideDuration={2000}
              onClose={handleClose}
              message="Text copied to clipboard!"
              action={
                <Button color="secondary" size="small" onClick={handleClose}>
                  Close
                </Button>
              }
            />
          </div> : tab == "reward" ? rewardContent : <ChangePassword setTab={setTab} />}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default Profile;
