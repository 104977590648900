// api.ts
import { store } from '../redux/store'; // Import your Redux store
import axios from 'axios';
import { BASE_URL } from './url';

const api = axios.create({
  baseURL: BASE_URL

});

api.interceptors.request.use(
  (config) => {
    const storeToken = store.getState().auth.token;
    const token = storeToken || localStorage.getItem('token');


if (token) {
  config.headers.Authorization = `Bearer ${token}`;
}

return config;
  },
(error) => Promise.reject(error)
);

// Response Interceptor to handle Token Expiry and Refresh
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Assuming 401 is returned when token is expired. Adjust as per your API's contract.
    if (error.response.status === 401 && !originalRequest._retry) {
      const token = localStorage.getItem('token'); // Get token from localStorage

      originalRequest._retry = true;

      if (token && token !== null) return api(originalRequest);

      // Assuming you have an API endpoint to refresh tokens
      // const refreshTokenResponse = await api.post('/refresh-token-endpoint');

      // // Extract new token, save it in your store and also in localStorage
      // const newToken = refreshTokenResponse.data.token;
      // localStorage.setItem('token', newToken); // Save token to localStorage
      // store.dispatch({ type: 'UPDATE_TOKEN', payload: newToken }); // Dispatch an action to save this token in your store

      // originalRequest.headers.Authorization = `Bearer ${newToken}`;
      return api(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default api;
