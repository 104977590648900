import './style.css'
import { Player, PlayerRef, RenderLoading } from '@remotion/player';
import { CaptionsOverlay } from './Composition_Captions_3';
import jsonCaptions from './components/json_captions';
import emojiData from './components/json_data_emoji';
import jsonGifsData from './components/json_gifs_data';
import { AbsoluteFill, CalculateMetadataFunction, staticFile } from 'remotion';
import useVideo from '../../hooks/useVideo';
import { getVideoOrientationHeight, rgbaToHex } from '../../utils/functions';
import { useAppSelector } from '../../hooks/redux';
import { useCallback, useContext, useEffect, useState } from 'react';
import { VideoContext } from '../../context/video.context';
import axios from 'axios';
import { useMediaQuery, useTheme } from '@mui/material';

export const MyRemotionPlayer: React.FC = () => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const videoFile = useAppSelector((state) => state.video.videoFile);

  const [subtitleGif, setSubtitleGif] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const contextValue = useContext(VideoContext);

  if (!contextValue) {
    // handle the case where the context value is not provided
    throw new Error("VideoContext value is not provided");
  }

  const { subtitleJSON, setSubtitleJSON, mediaPlayerRef, subtitleEmoji, setSubtitleEmoji } = contextValue;

  const {
    preset,
    font,
    fontSizeCaption,
    captionColor,
    maxWords,
    visuals,
    captionPosition
  } = useVideo();



  // Initialize state
  const [videoProps, setVideoProps] = useState({
    jsonData: subtitleJSON,
    jsonCaptions: subtitleJSON,
    jsonGifsData: subtitleGif,
    jsonEmojiData: subtitleEmoji,
    videoLink: videoFile?.urls.video_original_file,
    template: preset,
    config: {
      font_name: font,
      font_size: fontSizeCaption,
      colors: captionColor,
      position: captionPosition,
      maxWords,
    },
    gifs_on: visuals === 'GIFs' ? 1 : 0,
    emojis_on: visuals === 'emojis' ? 1 : 0,
  });

  // Update state with useEffect
  useEffect(() => {
    setVideoProps({
      jsonData: subtitleJSON,
      jsonCaptions: subtitleJSON,
      jsonGifsData: subtitleGif,
      jsonEmojiData: subtitleEmoji,
      videoLink: videoFile?.urls.video_original_file,
      template: preset,
      config: {
        font_name: font,
        font_size: fontSizeCaption,
        colors: captionColor,
        position: captionPosition,
        maxWords,
      },
      gifs_on: visuals === 'GIFs' ? 1 : 0,
      emojis_on: visuals === 'emojis' ? 1 : 0,
    });
  }, [
    subtitleJSON,
    subtitleGif,
    subtitleEmoji,
    videoFile,
    preset,
    font,
    fontSizeCaption,
    captionColor,
    captionPosition,
    maxWords,
    visuals,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (subtitleJSON == null) {
          if (videoFile?.urls?.updated_transcriptionJson_file) {
            const response = await fetch(videoFile?.urls?.updated_transcriptionJson_file);
            const data = await response.json();
            setSubtitleJSON(data);
          }
        }
        if (videoFile?.urls?.emoji_predicted_file) {
          const { data } = await axios.get(videoFile?.urls?.emoji_predicted_file);
          setSubtitleEmoji(data);
        }
        if (videoFile?.urls?.gif_predicted_file) {
          const { data } = await axios.get(videoFile?.urls?.gif_predicted_file);
          setSubtitleGif(data);
        }
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, [subtitleJSON, videoFile, setSubtitleJSON]);

  const calculateFrameDuration = () => {
    if (videoFile) {
      let val = Math.round((videoFile?.video_FPS || 30) * videoFile?.duration)
      return val == 0 ? 1020 : val
    }
    else {
      return 0
    }
  }


  const Spinner = () => (
    <div className="spinner"></div>
  );

  const renderLoading = useCallback(({ height, width }: any) => {
    return (
      <AbsoluteFill style={{ backgroundColor: 'gray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <Spinner />
          <div>Loading player ({height}x{width})</div>
        </div>
      </AbsoluteFill>
    );
  }, []);

  if (loading || !subtitleEmoji || !subtitleGif || !videoFile) {
    return <div>Loading...</div>;
  }
  return (<div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: isXs ? 200 : '100%', backgroundColor: "black" }}>
    <Player
      component={CaptionsOverlay}
      ref={mediaPlayerRef}
      compositionWidth={videoFile.resolution[0]}
      compositionHeight={videoFile.resolution[1]}
      durationInFrames={calculateFrameDuration()}
      fps={videoFile?.video_FPS || 30}
      // controls
      inputProps={videoProps}
      style={{
        width: "100%",
        height: "auto",
        maxWidth: "720px",
        aspectRatio: "9/16",
      }}
      // loop
      renderLoading={renderLoading}
    />
  </div>
  );
};
