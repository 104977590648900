import React, { Fragment } from 'react';
import './style.css'
import { Box, Typography } from '@mui/material';

const Working = () => {
    return (
        <Fragment>
            <Box
                sx={{
                    my: [4, 6, 8],
                    mx: [2, 3, 4],
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: 'inherit',
                    width: "90%",
                    '@media (max-width: 600px)': {
                        width: '100%',  // Set width to 80% on mobile
                    }
                }}
            >
                <img src='/logo.png' style={{ maxWidth: '100%', height: 'auto', marginBottom: 40 }} />

                <Typography className="typo1 display-flex">
                    <Typography className='redcolor'>Oops!</Typography>
                    <Typography className="">&nbsp;We are working to deliver you a better&nbsp;</Typography>
                    <Typography className="gradient-color">User Experience</Typography>
                </Typography>

                <img src="/output-onlinegiftools.gif" />
                <Typography className='typo2 graycolor'>
                    As per our commitments, our team is constantly pushing updates to the server; adding new features, improving bugs, enhancing experience as per our user’s requests.
                </Typography>
                <Typography className='typo2 bluecolor'>
                    We will be live soon!
                </Typography>
                <Typography className="p-12">
                    Want to request a feature or report a bug? <a href="https://discord.gg/zZyjmEMNxx" target="_blank" rel="noopener noreferrer">Join Community.</a> It’s open for all!
                </Typography>
            </Box>
        </Fragment>
    )
}

export default Working;