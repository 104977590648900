import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';
import { PlayerRef } from '@remotion/player';

export interface VideoContextType {
    mediaPlayerRef: React.RefObject<any>;
    audioRef: React.RefObject<any>;
    canvasRef: any;
    currentTime: number;
    setCurrentTime: any;
    subtitleJSON: any;
    setSubtitleJSON: any;
    currentCaption: any;
    setCurrentCaption: any;
    shapes: any;
    setShapes: any;
    videoFile: any;
    musicTracks: any;
    setMusicTracks: any;
    voiceOverTracks: any;
    setVoiceOverTracks: any;
    scale: number;
    setScale: any;
    scaleLength: number;
    setScaleLength: any;
    markerXPosition: number;
    setMarkerXPosition: any;
    videoRef: any;
    setVideoRef: any
    ffmpegRef: React.RefObject<FFmpeg>;
    ffmpegLoaded: boolean; // New state to indicate FFmpeg loading state
    shapeStageRef: any;
    selectedShapeId: any;
    setSelectedShapeId: any;
    selectedBlockId: any;
    setSelectedBlockId: any;
    selectedMusicBlockId: any;
    setSelectedMusicBlockId: any;
    adjustScaleLength: any;
    subtitleEditable: any;
    setSubtitleEditable: any;
    premimumModal: any; setPremiumModal: any
    subtitleEmoji: any, setSubtitleEmoji: any
    jsonAIStudio: any, setJsonAIStudio: any
}

export interface shapeItem {
    type: string;
    itemType: string;
    width: number | string;
    xPosition: number;
    selector: string;
    color: string;
    opacity: number;
    keepRatio: boolean;

    start: number,
    end: number,
    videoPosition: {
        x: number;
        y: number;
    }
    textOptions: {
        textAlign: string;
        fontSize: string;
        justifyContent: string;
        fontFamily: string;
        text: string;
        textColor: string;
        textPosition: {
            x: number;
            y: number;
        } | null;
    } | null;
    imageSrc: string | null;
}

export const VideoContext = React.createContext<VideoContextType | undefined>(undefined);


const VideoContextProvider = ({ children }: any) => {
    const mediaPlayerRef = React.useRef<PlayerRef>(null);
    const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);
    const audioRef = React.useRef<HTMLAudioElement | null>(null);
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const ffmpegRef = useRef<FFmpeg>(new FFmpeg());
    const shapeStageRef = useRef(null);

    const videoFile = useSelector((state: RootState) => state.video.videoFile)

    const [shapes, setShapes] = useState<shapeItem[]>([])
    const [currentTime, setCurrentTime] = useState<number>(0)
    const [subtitleJSON, setSubtitleJSON] = useState<any | null>(null)
    const [subtitleEmoji, setSubtitleEmoji] = useState<any | null>(null);
    const [jsonAIStudio, setJsonAIStudio] = useState<any | null>(null)
    const [currentCaption, setCurrentCaption] = useState<any | null>(null);
    const [selectedShapeId, setSelectedShapeId] = useState<string | null>(null);

    const [musicTracks, setMusicTracks] = useState([]);
    const [voiceOverTracks, setVoiceOverTracks] = useState([]);
    const [scale, setScale] = useState(20); // Initial scale is 20 pixels per second
    const [scaleLength, setScaleLength] = useState(120); // Default scale length (e.g., 30 mins)
    const initialMarkerPositionOnScale = 0;
    const [markerXPosition, setMarkerXPosition] = useState(initialMarkerPositionOnScale);  // Initial position
    const [ffmpegLoaded, setFFmpegLoaded] = useState(false); // State to track FFmpeg loading

    const [selectedBlockId, setSelectedBlockId] = useState(null); // Initial selected block ID
    const [selectedMusicBlockId, setSelectedMusicBlockId] = useState(null);

    const [subtitleEditable, setSubtitleEditable] = useState({})
    const [premimumModal, setPremiumModal] = useState(false)

    const load = async () => {
        try {
            const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
            const ffmpeg = ffmpegRef.current;

            ffmpeg.on('log', ({ message }) => {
            });

            const coreURL = await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript');
            const wasmURL = await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm');

            await ffmpeg.load({ coreURL, wasmURL });
            setFFmpegLoaded(true); // Update sta˜te to indicate successful loading
        } catch (error) {
            console.error('Error loading FFmpeg:', error);
        }
    };

    const adjustScaleLength = (totalDuration: any) => {
        const scaleSettings = [
            { maxDuration: 120, scale: 14, scaleLength: 120 },
            { maxDuration: 180, scale: 10, scaleLength: 180 },
            { maxDuration: 360, scale: 4, scaleLength: 360 },
            { maxDuration: 600, scale: 2.7, scaleLength: 600 },
            { maxDuration: 900, scale: 1.8, scaleLength: 900 },
            { maxDuration: 1200, scale: 1.4, scaleLength: 1200 },
            { maxDuration: 1500, scale: 1.3, scaleLength: 1500 },
            { maxDuration: 1800, scale: 0.9, scaleLength: 1800 },
            { maxDuration: 2100, scale: 0.8, scaleLength: 2100 },
            { maxDuration: 2400, scale: 0.8, scaleLength: 2400 },
            { maxDuration: 2700, scale: 0.7, scaleLength: 2700 },
            { maxDuration: 3000, scale: 0.6, scaleLength: 3000 },
            { maxDuration: 3300, scale: 0.5, scaleLength: 3300 },
            { maxDuration: 3600, scale: 0.5, scaleLength: 3600 },
            { maxDuration: 3900, scale: 0.4, scaleLength: 3900 },
            { maxDuration: 4200, scale: 0.4, scaleLength: 4200 },
            { maxDuration: 4500, scale: 0.3, scaleLength: 4500 },
            { maxDuration: 4800, scale: 0.3, scaleLength: 4800 },
            { maxDuration: 5400, scale: 0.3, scaleLength: 5400 },
            { maxDuration: Infinity, scale: 18, scaleLength: 90 } // Default case
            // The last object in scaleSettings serves as the default case, applying if none of the other conditions are met.
        ];

        const setting = scaleSettings.find(s => totalDuration <= s.maxDuration);
        if (setting) {
            setScale(setting.scale);
            setScaleLength(setting.scaleLength);
        }
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <VideoContext.Provider value={{
            mediaPlayerRef, currentTime, setCurrentTime, subtitleJSON, setSubtitleJSON, currentCaption, setCurrentCaption,
            audioRef, canvasRef, ffmpegRef, shapes, setShapes, videoFile, musicTracks, setMusicTracks, voiceOverTracks, setVoiceOverTracks,
            scale, setScale, scaleLength, setScaleLength, markerXPosition, setMarkerXPosition, videoRef, setVideoRef, ffmpegLoaded, shapeStageRef, selectedShapeId, setSelectedShapeId,
            selectedBlockId, setSelectedBlockId, selectedMusicBlockId, setSelectedMusicBlockId, adjustScaleLength, subtitleEditable, setSubtitleEditable, premimumModal, setPremiumModal,
            subtitleEmoji, setSubtitleEmoji, jsonAIStudio, setJsonAIStudio
        }}>{children}</VideoContext.Provider>
    )
}

export default VideoContextProvider;