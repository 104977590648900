

import React from 'react';
import { AbsoluteFill, OffthreadVideo, Sequence, useCurrentFrame, useVideoConfig } from 'remotion';

export const CompositionPodcast = ({
    videoLink,
    topFace,
    bottomFace,
    totalVideoWidth,
    overlayDelay
}: {
    videoLink: string;
    topFace: { x: number, y: number, width: number, height: number };
    bottomFace: { x: number, y: number, width: number, height: number };
    totalVideoWidth: number;
    overlayDelay: any;
}) => {
    const { width, height } = useVideoConfig();

    if (topFace.x > bottomFace.x) {
        // Swap the faces if the top face is on the right
        const temp = topFace;
        topFace = bottomFace;
        bottomFace = temp;
    }
    const frame = useCurrentFrame();

    const overlapHeight = height * 0.06; // Adjust the overlap height as needed

    // Calculate the center position and object-position percentage for the top face
    const topFaceCenter = topFace.x + (topFace.width / 2);
    const topObjectPosition = (topFaceCenter / totalVideoWidth) * 100;

    // Calculate the center position and object-position percentage for the bottom face
    const bottomFaceCenter = bottomFace.x + (bottomFace.width / 2);
    const bottomObjectPosition = Math.min(100,(bottomFaceCenter / totalVideoWidth) * 100);

    console.log('topObjectPosition', topObjectPosition);
    console.log('bottomObjectPosition', bottomObjectPosition);


    return (
        <AbsoluteFill>
            <Sequence layout="none">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    {/* Top Half */}
                    <AbsoluteFill
                        style={{
                            height: height / 2 + overlapHeight,
                            width: width,
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        <OffthreadVideo
                            src={videoLink}
                            style={{
                                width: '200%',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: `${topObjectPosition}% left`,
                                maskImage: 'linear-gradient(to top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%)',
                                WebkitMaskImage: 'linear-gradient(to top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%)',
                            }}
                            volume={frame >= overlayDelay ? 1 : 0}
                        />
                    </AbsoluteFill>

                    {/* Bottom Half */}
                    <AbsoluteFill
                        style={{
                            height: height / 2 + overlapHeight,
                            width: width,
                            overflow: 'hidden',
                            position: 'absolute',
                            bottom: 0,
                            top: 'auto', // Ensure the bottom half is aligned to the bottom
                        }}
                    >
                        <OffthreadVideo
                            src={videoLink}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: `${bottomObjectPosition}%`,
                                maskImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%)',
                                WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%)',
                            }}
                            volume={0}
                        />
                    </AbsoluteFill>
                </div>
            </Sequence>
        </AbsoluteFill>
    );
};

export default CompositionPodcast;
