import React, { useState, useEffect, ChangeEvent } from 'react';
import './style.css'

interface TimeInputProps {
    id: string;
    label?: string;
    value: number;
    onChange: (value: number, check: boolean) => void;
    error?: string
}

const TimeInputComponent: React.FC<TimeInputProps> = ({ id, label, value, onChange, error }) => {
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [seconds, setSeconds] = useState<number>(0);
    const [milliseconds, setMilliseconds] = useState<number>(0);

    useEffect(() => {
        const totalSeconds = Math.floor(value);
        const remainingMilliseconds = Math.round((value - totalSeconds) * 100);  // Convert fraction of a second to 2-digit milliseconds

        const hours = Math.floor(totalSeconds / 3600);
        const remainingSecondsAfterHours = totalSeconds - (hours * 3600);
        const minutes = Math.floor(remainingSecondsAfterHours / 60);
        const seconds = remainingSecondsAfterHours % 60;

        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
        setMilliseconds(remainingMilliseconds);
    }, [value]);

    const handleTimeChange = (obj: any) => {
        const hours1 = obj.hours !== undefined ? obj.hours : hours;
        const minutes1 = obj.minutes !== undefined ? obj.minutes : minutes;
        const seconds1 = obj.seconds !== undefined ? obj.seconds : seconds;
        const milliseconds1 = obj.milliseconds !== undefined ? obj.milliseconds : milliseconds;

        const compiledHours = Math.max(0, Math.min(23, Number(hours1)));
        const compiledMinutes = Math.max(0, Math.min(59, Number(minutes1)));
        const compiledSeconds = Math.max(0, Math.min(59, Number(seconds1)));
        const compiledMilliseconds = Math.max(0, Math.min(99, Number(milliseconds1)));

        const compiledValue = compiledHours * 3600 + compiledMinutes * 60 + compiledSeconds + (compiledMilliseconds / 100);
        onChange(compiledValue, false);
    };
    return (
        <div className="time-container" id={id}>
            <input
                type="number"
                value={hours}
                min="0"
                max="23"
                style={error ? { borderColor: 'red' } : {}}
                onChange={(e) => { setHours(Number(e.target.value)); handleTimeChange({ hours: e.target.value }); }}
            />
            <span>:</span>
            <input
                type="number"
                value={minutes}
                min="0"
                max="59"
                style={error ? { borderColor: 'red' } : {}}
                onChange={(e) => { setMinutes(Number(e.target.value)); handleTimeChange({ minutes: e.target.value }); }}
            />
            <span>:</span>
            <input
                type="number"
                value={seconds}
                min="0"
                max="59"
                style={error ? { borderColor: 'red' } : {}}
                onChange={(e) => {
                    setSeconds(Number(e.target.value));
                    handleTimeChange({ seconds: e.target.value });
                }}
            />
            <span>.</span>
            <input
                type="number"
                value={milliseconds}
                min="0"
                max="99"
                style={error ? { borderColor: 'red' } : {}}
                onChange={(e) => { setMilliseconds(Number(e.target.value)); handleTimeChange({ milliseconds: e.target.value }); }}
            />
        </div>
    );
}

export default TimeInputComponent;
