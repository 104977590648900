/* eslint-disable @remotion/staticfile-no-relative */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable capitalized-comments */
import { CompositionAIStudio } from "./Composition_AIStudio";
import { useAppSelector } from "../../hooks/redux";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { VideoContext } from "../../context/video.context";
import useVideo from "../../hooks/useVideo";
import { Player } from "@remotion/player";
import toast from "react-hot-toast";
import { useMediaQuery, useTheme } from "@mui/material";


export const AIStudioRemotionPlayer: React.FC = () => {
  const videoFile = useAppSelector(store => store.video.videoFile)
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const [jsonAIStudio, setJsonAIStudio] = useState(null)
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState(0); // Used to force re-render the player
  const [toastShown, setToastShown] = useState(false);


  const contextValue = useContext(VideoContext);

  if (!contextValue) {
    // handle the case where the context value is not provided
    throw new Error("VideoContext value is not provided");
  }

  const { mediaPlayerRef, subtitleJSON, setSubtitleJSON } = contextValue;

  const {
    preset,
    font,
    fontSizeCaption,
    captionColor,
    maxWords,
    visuals,
    captionPosition,
    effects,
    PKG
  } = useVideo();

  // Initialize state
  const [videoProps, setVideoProps] = useState({
    jsonData: jsonAIStudio,
    jsonCaptions: subtitleJSON,
    videoLink: videoFile?.urls.video_original_file,
    template: preset,
    config: {
      font_name: font,
      font_size: fontSizeCaption,
      colors: captionColor,
      position: captionPosition,
      maxWords,
    },
    gifs_on: visuals === 'GIFs' ? 1 : 0,
    emojis_on: visuals === 'emojis' ? 1 : 0,
    broll: effects?.broll ? 1 : 0,
    overlay: effects?.overlays ? 1 : 0,
    motion_bg: effects?.motionbg_on ? 1 : 0,
    soundSFX: effects?.keyword_soundEffects_on ? 1 : 0,
    bgmusic: effects?.bg_music_on ? 1 : 0,
    zoom: effects?.zoom_on ? 1 : 0,
    filters: effects?.filters_on ? 1 : 0,
    scene_transitions: effects?.scene_transitions_on ? 1 : 0,
    logo_on: 0,
    segmented_video_uris: videoFile?.urls?.segmented_video_uris,
  });

  // Update state with useEffect
  useEffect(() => {
    setVideoProps({
      jsonData: jsonAIStudio,
      jsonCaptions: subtitleJSON,
      videoLink: videoFile?.urls.video_original_file,
      template: preset,
      config: {
        font_name: font,
        font_size: fontSizeCaption,
        colors: captionColor,
        position: captionPosition,
        maxWords,
      },
      gifs_on: visuals === 'GIFs' ? 1 : 0,
      emojis_on: visuals === 'emojis' ? 1 : 0,
      broll: effects?.broll ? 1 : 0,
      overlay: effects?.overlays ? 1 : 0,
      motion_bg: effects?.motionbg_on ? 1 : 0,
      soundSFX: effects?.keyword_soundEffects_on ? 1 : 0,
      bgmusic: effects?.bg_music_on ? 1 : 0,
      zoom: effects?.zoom_on ? 1 : 0,
      filters: effects?.filters_on ? 1 : 0,
      scene_transitions: effects?.scene_transitions_on ? 1 : 0,
      logo_on: 0,
      segmented_video_uris: videoFile?.urls?.segmented_video_uris,
    });
  }, [
    jsonAIStudio,
    subtitleJSON,
    videoFile,
    preset,
    font,
    fontSizeCaption,
    captionColor,
    captionPosition,
    maxWords,
    visuals,
    effects
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (videoFile?.urls?.edited_json_uri && jsonAIStudio == null) {
          const { data } = await axios.get(videoFile?.urls?.edited_json_uri);
          setJsonAIStudio(data);
        }
        if (videoFile?.urls?.caption_orig_json_uri && subtitleJSON == null) {
          const { data } = await axios.get(videoFile?.urls?.caption_orig_json_uri);
          setSubtitleJSON(data);
        }
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    console.log("me updateinngggg")

    fetchData();
  }, [subtitleJSON, videoFile, jsonAIStudio]);


  const calculateFrameDuration = () => {
    if (videoFile) {
      let val = Math.round((videoFile?.video_FPS || 30) * videoFile?.duration)
      return val == 0 ? 1020 : val
    }
    else {
      return 0
    }
  }

  if (loading || !jsonAIStudio || !subtitleJSON || !videoFile) {
    return <div>Loading...</div>;
  }

  // const renderLoading = useCallback(({ height, width }: any) => {
  //   return (
  //     <AbsoluteFill style={{ backgroundColor: 'gray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //       <div style={{ textAlign: 'center' }}>
  //         <Spinner />
  //         <div>Loading player ({height}x{width})</div>
  //       </div>
  //     </AbsoluteFill>
  //   );
  // }, []);


  const handleError = ({ error }: any) => {
    console.error('Error occurred in video', error);
    
    if (!toastShown) {
      toast.error('An error occurred while trying to play the video. Attempting to reload.',{
        id: "videoError",
      });
      setToastShown(true);
    }
    
    setKey((prevKey) => prevKey + 1); // Force re-render the player
    return false;
  };


  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: isXs ? 200 : '100%', backgroundColor: "white" }}>
      <Player
        component={CompositionAIStudio}
        ref={mediaPlayerRef}
        compositionWidth={videoFile.resolution[0]}
        compositionHeight={videoFile.resolution[1]}
        durationInFrames={calculateFrameDuration()}
        fps={videoFile?.video_FPS || 30}
        // controls
        errorFallback={handleError}
        inputProps={videoProps}
        style={{
          width: "100%",
          height: "auto",
          maxWidth: "720px",
          aspectRatio: "9/16",
        }}
        controls
        showVolumeControls
        clickToPlay
        spaceKeyToPlayOrPause
        moveToBeginningWhenEnded
      // loop
      // renderLoading={renderLoading}
      />
    </div>

  );
};
