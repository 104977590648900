import React from 'react';
import { AbsoluteFill, OffthreadVideo, Sequence, Freeze, useVideoConfig } from 'remotion';

export const LaptopOverlayTemplate = ({
    backgroundVideoLink,
    overlayVideoLink,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight
}: {
    backgroundVideoLink: string;
    overlayVideoLink: string;
    topLeft: { x: number, y: number };
    topRight: { x: number, y: number };
    bottomLeft: { x: number, y: number };
    bottomRight: { x: number, y: number };
}) => {
    const { width, height } = useVideoConfig();
    const firstFrameDuration = 10 ; // 3 frames for 0.1 second at 30 fps

    const computeClipPath = (tl:any, tr:any, bl:any, br:any) => {
        const w = width;
        const h = height;
 
        const toPercent = (x:any, y:any) => `${(x / w) * 100}% ${(y / h) * 100}%`;

        return `polygon(
            ${toPercent(tl.x, tl.y)},
            ${toPercent(tr.x, tr.y)},
            ${toPercent(br.x, br.y)},
            ${toPercent(bl.x, bl.y)}
        )`;
    };

    const clipPath = computeClipPath(topLeft, topRight, bottomLeft, bottomRight);

    const overlayWidth = Math.max(topRight.x - topLeft.x, bottomRight.x - bottomLeft.x);
    const overlayHeight = Math.max(bottomLeft.y - topLeft.y, bottomRight.y - topRight.y);

    return (
        <AbsoluteFill>
            <Sequence layout="none">
                {/* Background Video */}
                <OffthreadVideo
                    src={backgroundVideoLink}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                    volume={0.2} // Background video volume
                />

                {/* Sequence to show the first frame */}
                <Sequence from={0} durationInFrames={firstFrameDuration}>
                    <Freeze frame={0}>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                clipPath: clipPath,
                                WebkitClipPath: clipPath,
                            }}
                        >
                            <OffthreadVideo
                                src={overlayVideoLink}
                                style={{
                                    position: 'absolute',
                                    top: `${topLeft.y}px`,
                                    left: `${topLeft.x}px`,
                                    width: `${overlayWidth}px`,
                                    height: `${overlayHeight}px`,
                                    objectFit: 'fill',
                                }}
                                volume={0}
                            />
                        </div>
                    </Freeze>
                </Sequence>

                {/* Sequence to play the video after the first frame */}
                <Sequence from={firstFrameDuration}>
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            clipPath: clipPath,
                            WebkitClipPath: clipPath,
                        }}
                    >
                        <OffthreadVideo
                            src={overlayVideoLink}
                            style={{
                                position: 'absolute',
                                top: `${topLeft.y}px`,
                                left: `${topLeft.x}px`,
                                width: `${overlayWidth}px`,
                                height: `${overlayHeight}px`,
                                objectFit: 'fill',
                            }}
                            volume={0}
                            startFrom={0} // Start from the beginning of the video
                        />
                    </div>
                </Sequence>
            </Sequence>
        </AbsoluteFill>
    );
};

export default LaptopOverlayTemplate;