import React, { useState } from 'react';
import { SketchPicker, ColorResult } from 'react-color';

const SketchColor: React.FC<{ name:string, disabled?: boolean,onChange?:any,color:any }> = ({ name, disabled,onChange,color }) => {

    const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };


    const handleChange = (newColor: ColorResult) => {
        onChange(newColor.hex)
    };

    const styles = {
        color: {
            borderRadius: '10px',
            width: "30px",
            height: "30px",
            border: "0.846px solid #D9D9D9",
            background:  color
        },
        swatch: {
            // padding: '5px',
            width: "30px",
            height: "30px",
            background: '#fff',
            borderRadius: '10px',
            // boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        popover: {
            position: 'absolute' as 'absolute', // Fix for the position type
            zIndex: 2
        },
        cover: {
            position: 'fixed' as 'fixed', // Fix for the position type
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
    };

    return (
        <div>
                <div style={styles.color} className='box-shadoww cursor-pointer' onClick={handleClick}/>
            {/* </div> */}
            {(displayColorPicker && !disabled) && (
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <SketchPicker color={color} onChange={handleChange} />
                </div>
            )}
        </div>
    );
}

export default SketchColor;
