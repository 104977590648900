import React from 'react';
import { Modal, Box, Typography, useMediaQuery, useTheme, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';

const AlertModal = ({ open }: any) => {
    const navigate = useNavigate()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : 600,
        maxWidth: '90%',
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)", // This is the CSS property that blurs the background
                }
            }}
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                    color: "#4A4A4A",
                    textAlign: "center",
                    fontFamily: "Nunito",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                }}>
                    <p>  🚀 Join Our Beta Release - It's FREE Until August 31, 2023! 🚀 </p>
                    <br />
                    <p>  We're working hard to understand your needs and keep costs minimal. Enjoy full access now, and share your feedback to help us grow!</p>
                    <br />
                    <Button variant="outlined"
                        sx={{
                            marginTop: 2,
                            backgroundColor: 'white',
                            borderColor: '#4623E9',
                            color: '#4623E9',
                            '&:hover': {
                                borderColor: '#4623E9' // Light blue on hover
                            }
                        }}
                        onClick={() => navigate("/")}>
                        Try It Today!
                    </Button>
                </Typography>
            </Box>
        </Modal>
    );
}

export default AlertModal;
