import { TourProvider, useTour } from '@reactour/tour'
import { steps } from '../../utils/titties-steps'
import TittiesParent from './index'

function Titties() {

    return (
        <TourProvider onClickMask={() => { }} styles={{
            close: (base) => ({ ...base, width: 15 }),
            arrow: (base: any) => ({
                ...base,
                width: 25,
                height: 25,
            }),
        }} steps={steps}>
            <TittiesParent />
        </TourProvider>
    )
}

export default Titties