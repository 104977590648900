import './style.css'
import { useEffect } from 'react';
import { Modal, Box, Typography, useMediaQuery, useTheme, } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';


const ExperimentalModal = ({ open, setOpen }: any) => {
    const theme = useTheme();
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : 600,
        maxWidth: '90%',
        bgcolor: 'background.paper',
        // border: '2px solid white',
        borderRadius: 8,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };


    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)", // This is the CSS property that blurs the background
                }
            }}
        >
            <Box sx={style}>
                <div style={{ position: 'absolute', right: 20, top: 15 }}>
                    <CloseIcon className="close-icon" onClick={() => setOpen(false)} />
                </div>
                <p className='ex-head'>
                    Experimantal Launch
                </p>
                <p className='ex-version'>v0.3.22 Jul 19, 2024</p>
                <p className='ex-txt-1'>You may experience imperfect results, lack of functionality in user interface or anything weirder.</p>
                <p className='ex-txt-1'>But <span>we iterate very fast,</span> so feel free to let us know if you run into any problem or have any feature ideas.</p>
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <div
                        onClick={() => navigate("/request-feedback")}
                        className='ex-button'>
                        <p>Request Feature</p>
                    </div>
                    <div
                        onClick={() => window.open('https://discord.gg/zZyjmEMNxx', '_blank')}
                        className='ex-button ex-button-2'>
                        <p> Talk to Founders 24/7</p>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default ExperimentalModal;

