import { TourProvider, useTour } from '@reactour/tour'
import { steps } from '../../utils/steps'
import VideoParent from './index'

function Video() {

    return (
        <TourProvider onClickMask={() => { }} styles={{
            close: (base) => ({ ...base, width: 15 }),
            arrow: (base: any) => ({
                ...base,
                width: 25,
                height: 25,
            }),
        }} steps={steps}>
            <VideoParent />
        </TourProvider>
    )
}

export default Video