import React, { useState, useEffect, useMemo } from 'react'
import { debounce } from 'lodash';
import { useAppDispatch, useAppSelector } from './redux';
import { RootState } from '../redux/store';
import { IMediaPlayer, IVideoEffect, mediaPlayerAction } from '../redux/slices/mediaPlayer';
import { rgbaToHex } from '../utils/functions';
import { updateAddCaptions, updateAIShortsCaptions, updateAIShortsWord, updateAIStudioCaptions, updateAIStudioWord, updateAssFile } from '../redux/slices/video.slice';
import packageData from '../utils/packageData';
import { discordWebhook } from '../redux/slices/feedbacks.slice';

const useVideo = () => {

    const dispatch = useAppDispatch()

    const videoFile = useAppSelector((state: RootState) => state.video?.videoFile) as any
    const videoId = useAppSelector((state: RootState) => state.video.videoFile?.video_id) || ""

    const user = useAppSelector((state: RootState) => state.auth.user)
    const guestId = useAppSelector((state: RootState) => state.auth.guestId)
    const font = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.font)
    const variant = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.variant)
    const captionColor = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.captionColor)
    const captionPosition = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.captionPosition)
    const customCaption = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.customCaption)
    const fontSizeCaption = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.fontSize)
    const preset = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.preset)
    const videoToggle = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.videoToggle)
    const language = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.language)
    const italic = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.italic)
    const bold = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.bold)
    const underline = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.underline)
    const toModified = useAppSelector((state: RootState) => state.video.toModified)
    const maxWords = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.maxWords)
    const visuals = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.visuals)
    const effects = useAppSelector((state: RootState) => state.mediaPlayer[videoId]?.effects)

    useEffect(() => {
        dispatch({ type: "mediaPlayer/setDefault", payload: videoId })

    }, [])


    const PKG = useMemo(() => {
        if (user?.subscriptionPlan == null) return null
        return packageData.find(data => {
            if (data.name == "Free") return false
            return data.cycle.find(cy => cy.subscriptionId == user?.subscriptionPlan.id);
        }) // filters out undefined or null values
    }, [user?.subscriptionPlan]);

    const UpdateAssFile = (updatedData: any) => {
        if (!user && !guestId && !videoFile) return
        if (font == undefined) return
        // let data = new FormData()
        // let capColor = { ...captionColor, ...updatedData.captionColor } || captionColor
        // let wordCount = updatedData.maxWords || maxWords
        // let position = updatedData.captionPosition && updatedData.captionPosition == 0 ? 0 : updatedData.captionPosition || captionPosition
        // let pri = rgbaToHex(
        //     capColor.primary.r,
        //     capColor.primary.g,
        //     capColor.primary.b,
        //     capColor.primary.a,
        // ).toUpperCase()
        // let sec = rgbaToHex(
        //     capColor.secondary.r,
        //     capColor.secondary.g,
        //     capColor.secondary.b,
        //     capColor.secondary.a,
        // ).toUpperCase()

        // // let fontSizeMul = orientation == 'vertical' ? 0.9 : isMobile ? 0.9 : isDesktop ? 0.9 : 1

        // data.append('video_id', videoId)
        // if (user?.id) data.append('user_id', user?.id)
        // else if (guestId) data.append('user_id', guestId)
        // data.append('primary', pri)
        // data.append('secondary', sec)
        // // data.append('is_underline', updatedData.underline || underline ? '1' : '0')
        // // data.append('is_italic', updatedData.italic || italic ? '1' : '0')
        // // data.append('is_bold', updatedData.bold|| bold ? '1' : '0')
        // data.append('font_name', updatedData.font || font)
        // data.append(
        //     'font_size',
        //     Math.round(updatedData.fontSize || fontSizeCaption * 1).toString(),
        // )
        // data.append('lang', 'en')
        // data.append(
        //     'position',
        //     position == 0 ? '8' : position == 50 ? '5' : '2',
        // )
        // data.append('max_words_per_screen', maxWords ? wordCount.toString() : '5')
        // data.append('styles', updatedData.preset || preset)
        dispatch(updateAssFile({ videoId, user, guestId }));
    }

    const addCaptions = () => {
        console.log("addCaptions")
        if (!user && !guestId && !videoFile) return

        if (font == undefined) return
        let data = new FormData()


        // let fontSizeMul = orientation == 'vertical' ? 0.9 : isMobile ? 0.9 : isDesktop ? 0.9 : 1

        data.append('video_id', videoId.split("-")?.[0])
        if (user?.id) data.append('user_id', user?.id)

        if (captionColor && captionColor.length > 0) {
            captionColor?.map((val) => {
                data.append('colors', val)
            })
        }
        else {
            data.append('colors', "#FFF")
        }
        // data.append('is_underline', underline ? '1' : '0')
        // data.append('is_italic', italic ? '1' : '0')
        // data.append('is_bold', bold ? '1' : '0')
        data.append('font_name', font)
        data.append(
            'font_size',
            Math.round(fontSizeCaption).toString(),
        )
        data.append('lang', 'en')
        data.append(
            'position',
            captionPosition.toString(),
        )
        data.append('max_words_per_screen', maxWords ? maxWords.toString() : '5')
        data.append('template', preset)
        data.append("emojis_on", visuals == "emojis" ? '1' : '0')
        if (videoFile?.active_service[0] == "aistudio") {
            if (effects) {
                (Object.keys(effects) as (keyof IVideoEffect)[]).forEach((key) => {
                    data.append(key, effects[key] ? '1' : '0');
                });
            }
            data.append('logo_on', PKG && PKG?.name ? '0' : '1')

            data.append("video_url", videoFile.urls.video_original_file)
            data.append("json_file_url", videoFile.urls.json_original_file)
            data.append("gifs_on", visuals == "GIFs" ? '1' : '0')

        }
        // if () {
        if (videoFile?.active_service[0] == "transcribe" || videoFile?.active_service[0] == "aishorts") {
            if (effects) {
                (Object.keys(effects) as (keyof IVideoEffect)[]).forEach((key) => {
                    let val = key as any
                    if (val == "broll") { val = "broll_on" }
                    else if (val == "overlays") { val = "overlays_on" }
                    data.append(val, effects[key] ? '1' : '0');
                });
            }
            data.append('logo_on', PKG && PKG?.name ? '0' : '1')
            data.append('video_index_number', videoId.split("-")?.[1])
            if (videoFile?.active_service[0] == "aishorts") {
                dispatch(updateAIShortsCaptions({ data, userId: user?.id || guestId, videoId }));
            }
            data.append("gifs_on", visuals == "GIFs" ? '1' : '0')
            dispatch(updateAddCaptions({ data, userId: user?.id || guestId, videoId }));
            return
        }
        dispatch(updateAIStudioCaptions({ data, userId: user?.id || guestId, videoId }));
        // data.append("only_emoji_gifs", "0")
    }


    const updateFont = React.useCallback((font: string) => {
        dispatch(mediaPlayerAction.setFont({ font, videoId }))
        // setTimeout(() => UpdateAssFile({ font }), 1000)
    }, [videoId])

    const updateVariant = React.useCallback((variant: string) => {
        dispatch(mediaPlayerAction.setVariant({ variant, videoId }))
    }, [videoId])

    const updateCaptionColor = React.useCallback((captionColor: object) => {
        dispatch(mediaPlayerAction.setCaptionColor({ captionColor, videoId }))
        // setTimeout(() => UpdateAssFile({ captionColor }), 1000)
    }, [videoId])

    const updateCaptionPosition = React.useCallback((captionPosition: number) => {
        dispatch(mediaPlayerAction.setCaptionPosition({ captionPosition, videoId }))
        // setTimeout(() => UpdateAssFile({ captionPosition }), 1000)

    }, [videoId])

    const updateCustomCaption = React.useCallback((customCaption: boolean) => {
        dispatch(mediaPlayerAction.setCustomCaption({ customCaption, videoId }))
    }, [videoId])

    const clearCaption = React.useCallback(() => {
        dispatch(mediaPlayerAction.clearCaption({ videoId }))
    }, [videoId])

    const updatePresets = React.useCallback((preset: IMediaPlayer) => {
        dispatch(mediaPlayerAction.setPresets({ ...preset, videoId }))

        // setTimeout(() => UpdateAssFile(preset), 1000)

    }, [videoId])

    const updatePresetsCustom = React.useCallback(() => {
        dispatch(mediaPlayerAction.setPresetsCustom({ videoId }))
    }, [videoId])


    const updateFontSize = React.useCallback((fontSize: number) => {
        console.log("passing", { videoId })

        dispatch(mediaPlayerAction.setFontSize({ fontSize, videoId }))
        // setTimeout(() => UpdateAssFile({ fontSize }), 1000)

    }, [videoId])

    const updateVideoToggle = React.useCallback((videoToggle: boolean) => {
        dispatch(mediaPlayerAction.setVideoToggle({ videoToggle, videoId }))
    }, [videoId])

    const updateLanguage = React.useCallback((language: string) => {
        dispatch(mediaPlayerAction.setLanguage({ language, videoId }))
    }, [videoId])

    const updateItalic = React.useCallback((italic: boolean) => {
        dispatch(mediaPlayerAction.setItalic({ italic, videoId }))
        // setTimeout(() => UpdateAssFile({ italic }), 1000)

    }, [videoId])

    const updateBold = React.useCallback((bold: boolean) => {
        dispatch(mediaPlayerAction.setBold({ bold, videoId }))
        // setTimeout(() => UpdateAssFile({ bold }), 1000)

    }, [videoId])

    const updateUnderline = React.useCallback((underline: boolean) => {
        dispatch(mediaPlayerAction.setUnderLine({ underline, videoId }))
        // setTimeout(() => UpdateAssFile({ underline }), 1000)

    }, [videoId])

    const updateMaxWords = React.useCallback((maxWords: number) => {
        dispatch(mediaPlayerAction.setMaxWords({ maxWords, videoId }))
        if (videoFile.active_service[0] == "aistudio") {
            dispatch(updateAIStudioWord({ videoId: videoId.split("-")?.[0], user, maxWords }))
        }
        else if (videoFile.active_service[0] == "aishorts") {
            dispatch(updateAIShortsWord({ videoId: videoId.split("-")?.[0], user, maxWords, index: videoId.split("-")?.[1] }))
        } else setTimeout(() => UpdateAssFile({ maxWords }), 1000)

    }, [videoId])

    const updateVisuals = React.useCallback((visuals: string) => {
        dispatch(mediaPlayerAction.setVisuals({ visuals, videoId }))
    }, [videoId])

    const updateEffects = React.useCallback((effects: IVideoEffect) => {
        dispatch(mediaPlayerAction.setEffects({ effects, videoId }))
    }, [videoId])

    return {
        font,
        updateFont,
        variant,
        preset,
        updateVariant,
        updateCaptionColor,
        captionColor,
        captionPosition,
        updateCaptionPosition,
        customCaption,
        updateCustomCaption,
        clearCaption,
        updatePresets,
        updateFontSize,
        fontSizeCaption,
        videoId,
        user,
        updateVideoToggle,
        videoToggle,
        updateLanguage,
        language,
        updateItalic,
        italic,
        updateBold,
        bold,
        toModified,
        updatePresetsCustom,
        updateUnderline,
        underline,
        updateMaxWords,
        maxWords,
        updateVisuals,
        visuals,
        effects,
        updateEffects,
        addCaptions, PKG
    }
}

export default useVideo;