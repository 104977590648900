/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
/* eslint-disable capitalized-comments */

import {useRef} from 'react'
// import { useEffect, useState } from 'react';
import { Gif } from '@remotion/gif';
import { useVideoConfig , Img } from 'remotion';


export const MotionbgGIF = ({ src }: { src: string }) => {
    const { width, height } = useVideoConfig();
    const ref = useRef<HTMLCanvasElement>(null);

    const gifStyle = {
        display: 'inline-block', // This makes the div wrap tightly around the GIF
        position: 'absolute', // This ensures it participates in the stacking context correctly
        zIndex: 20, // Ensure the GIF is on top of the video
        width: width / 1.0, // Adjust size as needed
        // height: height / 5,
        height: '30%',
        top: '65%',
        // width: '100%', 
        // height: '100%',
        filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 245, 235, 1.0)) blur(0.05rem) opacity(0.4)',
    };
    
    // Extract the file extension from the URL before any query parameters
    const extension = src ? new URL(src).pathname.split('.').pop()?.toLowerCase() : '';
    // Check if the extracted extension is 'gif'
    const isGif = extension === 'gif';

    console.log('Rendering GIF:', src, 'isGif:', isGif);

    return (
        <div style={gifStyle as React.CSSProperties}> {/* Apply the shadow style here */}
            {isGif ? (
                // Render as GIF if source ends with .gif
                <Gif
                    ref={ref}
                    src={src}
                    style={{ width: '100%', height: '100%' }}
                    fit="contain"
                    playbackRate={1}
                    loopBehavior="loop"
                />
            ) : (
                // Render as image if source is not a GIF
                <Img
                    src={src}
                    // width={width / 3} // Use position width or default
                    // height={height / 4} // Use position height or default
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // cover or contain
                    }}
                />
            )}
        </div>
    );
};
