import React, { Fragment, useEffect, useMemo } from 'react';
import Header from '../Header';
import { Container, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import VideoPublishModal from '../../components/VideoPublishModal';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import ReplyIcon from '@mui/icons-material/Reply';

import { RootState } from '../../redux/store';
import VideoProgress from '../../components/ProgressVideo';
import { loadFont } from '../../utils/fontLoader';
import './style.css'
import { useNavigate } from 'react-router-dom';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getVideoFiles } from '../../redux/slices/video.slice';
import httpApi from '../../utils/httpApi';
import { SEVER_URL } from '../../utils/url';
import { getToken } from '../../redux/slices/auth.slice';
import { logPageView } from '../../utils/ga.utils';
import { getVideoOrientationHeight } from '../../utils/functions';

let msg = ["See it was that simple. ", "It’s time to go Viral", "We are proud of you!"]
const PublishVideo = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const videoFile = useSelector((state: RootState) => state.video.videoFile)
    const videoId = useSelector((state: RootState) => state.video.videoId)
    const isLoading = useSelector((state: RootState) => state.video.isLoading)
    const currentService: any = useMemo(() => videoFile && videoFile.active_service?.length > 0 && videoFile.active_service[0], [videoFile])

    const user = useAppSelector((state: RootState) => state.auth.user)

    const guestId = useAppSelector((state: RootState) => state.auth.guestId)

    const [open, setOpen] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState("Go Back");

    const handleDrawerClose = () => {
        setOpen(!open);
    };

    const returnIcon = (txt: string) => {
        switch (txt) {
            case 'Go Back':
                return <ReplyIcon />
        }
    };

    const notify = (msg: string) => toast(msg);

    function giveMeMsg(): string {
        // give me random number 0 to 2
        const randomNumber = Math.floor(Math.random() * 3);
        return msg[randomNumber]
    }

    useEffect(() => {
        logPageView()

        if (!videoFile || !videoId) navigate("/")
        loadFont("Dancing Script")
    }, [])

    const returnVideoURL = (): string | undefined => { // Specify return type as string | undefined
        if (currentService == "aistudio") {
            return videoFile?.urls?.output_rendered_video;
        }
        if (currentService == "text_to_video") {
            return videoFile?.urls?.text_to_video_rendered_uri
        }
        if (currentService == "transcribe") {
            return videoFile?.urls?.rendered_captioned_video;
        }
        if (currentService == "compression")
            return videoFile?.urls?.compressed_updated_video_file
        if (currentService == "aishorts") {
            let index = videoId?.split("-")?.[1] || 0
            return videoFile?.urls?.output_rendered_videos?.[index]
        }
    };

    const returnBackNav = () => { // Specify return type as string | undefined
        if (currentService == "aistudio") {
            navigate("/ai-studio")
        }
        if (currentService == "text_to_video") {
            navigate("/text-video")

        }
        if (currentService == "transcribe") {
            navigate("/video")

        }
        if (currentService == "compression") {
            navigate("/")
        }
        else if (currentService == "aishorts")
            navigate("/ai-shortsv2")
    };

    const doMakeDiabled = () => {
        return (currentService == "transcribe" && (videoFile?.caption_render_processing || videoFile?.compression || videoFile?.video_in_queue))
    }

    useEffect(() => {

        dispatch(getVideoFiles({ userId: user?.id, videoId }))
        const interval = setInterval(() => {
            currentService == "transcribe" && videoFile?.video_in_queue && dispatch(getVideoFiles({ userId: user?.id || guestId, videoId }))
        }, 60000); // 60 seconds in milliseconds

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []); // Run effect only once on component mount

    const DoCompression = async () => {
        if ((currentService == "transcribe" && !videoFile?.caption_render_processing) && !videoFile?.compression && !videoFile?.video_in_queue)
            if (videoFile && videoId) {
                dispatch(getToken(videoFile?.user_id || guestId))

                let formData = new FormData()
                formData.append("user_id", videoFile?.user_id)
                formData.append("video_id", videoId)
                let response = await httpApi.post(`${SEVER_URL}compress_video_without_file`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        // 'Host': 'test.transpify.com',
                    }
                });
                if (response.data.status_code === 405 || response.data.status_code === 422) {
                    dispatch(getToken(videoFile?.user_id || guestId))
                    setTimeout(() => DoCompression(), 1000)
                    return
                }
                dispatch({ type: "video/doingCompression", payload: { compression: true } })
            }
    }

    const returnURLRender = () => {
        if (currentService === "aistudio") {
            return "aistudio_rendering_progress";
        } else if (currentService === "text_to_video") {
            return "ttv_rendering_progress";
        } else if (currentService === "transcribe") {
            if (videoFile?.caption_render_processing) {
                return "video_rendering_progress";
            } else if (videoFile?.compression) {
                return 'compression_progress';
            }
        }
        if (currentService == "aishorts") {
            return "aishorts_rendering_progress"
        }
    };

    let width = getVideoOrientationHeight(videoFile?.resolution)?.[0]
    let height = getVideoOrientationHeight(videoFile?.resolution)?.[1]

    if (currentService == "aishorts") {
        width = 1080 / 3
        height = 1920 / 3
    }


    return (
        <Fragment>
            <Header></Header>
            {!isLoading ? <>  <Container style={{ width: '100%', maxWidth: '100%', backgroundColor: 'white' }} maxWidth={false}>
                <Grid container spacing={2} sx={{ width: '100%', }}>
                    <Grid sx={{ border: "1px solid #DDD;" }} md={1}>
                        <div style={{}} className="drawer-container">
                            <Divider />
                            <List sx={{ marginTop: 2 }}>
                                {['Go Back'].map((text, index) => (
                                    <ListItem key={text + index} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            sx={{
                                                minHeight: 68,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                                flexDirection: open ? 'row' : 'column',
                                                backgroundColor: '#FFF',
                                            }}
                                            onClick={() => {
                                                if (text == "Go Back") returnBackNav()
                                                setCurrentTab(text)
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                    marginRight: open ? "25px" : "0px",
                                                    color: currentTab == text ? "#0075E1" : 'black',
                                                }}
                                            >
                                                {returnIcon(text)}
                                            </ListItemIcon>
                                            <ListItemText primary={text} sx={{
                                                opacity: open ? 1 : 1,
                                                color: currentTab == text ? "#0075E1" : 'black',
                                                textAlign: 'center'
                                            }} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                        <p className="msg-viral">{giveMeMsg() as string}</p>
                        <video src={returnVideoURL()}
                            playsInline
                            style={{ width, height, marginTop: 20 }}
                            controls></video>
                    </Grid>
                    <Grid item xs={12} md={5.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: 5 }}>
                        <VideoPublishModal compressed={videoFile?.compressed} disabled={doMakeDiabled()} currentExportUrl={returnVideoURL} DoCompression={DoCompression} />
                        {videoFile?.active_service[0] !== "compression" && <div className="display-flex center-adj">
                            <p className="text-adj">Need some adjustments? {currentService == 'aistudio' && ("Coming Soon")} </p>
                            <div onClick={() => {
                                if (currentService == "text_to_video") navigate("/text-video")
                                else if (currentService == 'aistudio') navigate("/ai-studio")
                                else if (currentService == 'transcribe') navigate("/video")
                                else if (currentService == 'aishorts') navigate("/ai-shortsv2")
                            }
                            } className="button-adj">
                                <p>Edit</p> <EditTwoToneIcon htmlColor="white"></EditTwoToneIcon> </div>
                        </div>
                        }

                    </Grid>
                </Grid>
            </Container>
                {((currentService == "transcribe" || currentService == "aistudio" || currentService == "aishorts") && (videoFile?.caption_render_processing || videoFile?.compression || videoFile?.video_in_queue || videoFile?.aistudio_rendering_ongoing || videoFile?.in_queue_waiting_for_rendering || videoFile?.aistudio_rendering_ongoing || videoFile?.in_queue_waiting_for_rendering || videoFile?.aishorts_rendering_ongoing)) &&
                    <VideoProgress renderurl={returnURLRender()} userId={videoFile?.user_id} videoId={videoFile?.video_id.split("-")?.[0]} close={false} checkForQueue={{ video_in_queue: videoFile?.video_in_queue || videoFile?.in_queue_waiting_for_rendering }}></VideoProgress>}</> : <></>
            }
        </Fragment>
    )
}

export default PublishVideo;