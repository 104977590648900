// exportSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';
import httpApi from '../../utils/httpApi';
import httpApi2 from '../../utils/httpApi2';
import { GOOGLE_FONTS_URL } from '../../utils/url';
import { hexToBBGGRR, rgbaToHex } from '../../utils/functions';
import { RootState } from '../store';
import { IMediaPlayer } from './mediaPlayer';
import { discordWebhook } from './feedbacks.slice';

// import { login } from './auth.actions';

type iVideoFile = {
  ass_modified_file?: string;
  json_original_file?: string;
  ass_original_file?: string;
  video_original_file?: string;
  updated_video?: string;
  srt_original_file?: string;
  vtt_original_file?: string;
  mp3_original_file?: string;
  vtt_modified_file?: string;
  this_is_tiktok_video?: string;
  updated_json_file?: string;
  emoji_predicted_file?: string;
  gif_predicted_file?: string;
  updated_transcriptionJson_file?: string;
  gifs_rendered_video_uri?: string;
  emoji_rendered_video_uri?: string;
  rendered_captioned_video?: string;
  aistudio_rendered_finalvideo?: string;
  aistudio_video_original_file?: string;
  text_to_video_rendered_uri?: string;
  TTV_8wordjson?: string;
  compressed_updated_video_file?: string;

};

type Storage = {
  duration: number;
  size: number
}

export interface IVideos {
  // isModified(arg0: string): unknown;
  active_service: string[];
  _id?: string;
  video_id: string;
  user_id: string;
  duration: number;
  modified: boolean;
  published: boolean;
  name: string;
  urls: iVideoFile | any;
  size: number;
  video_json_data: any;
  resolution: number[];
  caption_animation: string;
  language: string;
  refresh?: boolean;
  createdAt: Date;
  updatedAt: Date;
  rendered: boolean;
  caption_render_processing: boolean;
  rendering_inprocess_TTV?: boolean;
  transcription_processing?: boolean;
  rendered_captions?: boolean;
  video_in_queue?: boolean;
  compression?: boolean;
  compressed?: boolean;
  video_FPS?: number
  video_duration?: number
  video_meta_data?: any
  aistudio_rendering_ongoing?: boolean
  in_queue_waiting_for_rendering?: boolean
  aistudio_rendering_completed?: boolean
  video_height?: number
  video_width?: number
  video_id_old?: string
  aishorts_rendering_ongoing?: boolean
  sub_video_durations?: any
}

type VideoThumbnails = {
  [key: string]: string;
};
type VideoDataINT = {
  width: number,
  height: number,
  play: boolean,
  volume: number,
  duration: number,
  videoLength: number,
  muted: boolean

}

type UploadState = {
  videoId: string,
  service: string,
  platform: string,
}


interface ExportState {
  isLoading: boolean;
  error: string | null;
  currentTranscribe: string | null;
  recentProject: any;
  videoFile: IVideos | null;
  videoId: string | null;
  storageStats: Storage | null;
  toModified: boolean;
  musicId: number | null
  fonts: any
  timelineLayer: any
  videoThumbnails: VideoThumbnails
  videoData: VideoDataINT
  uploadState: UploadState | null
  aishortsIndex: { [key: string]: any };
}

const initialState: ExportState = {
  isLoading: false,
  error: null,
  currentTranscribe: null,
  recentProject: [],
  videoFile: null,
  videoId: '',
  storageStats: null,
  toModified: false,
  musicId: null,
  fonts: [],
  timelineLayer: [],
  videoThumbnails: {},
  videoData: {
    width: 0,
    height: 0,
    play: false,
    volume: 1,
    duration: 0,
    videoLength: 0,
    muted: false
  },
  uploadState: null,
  aishortsIndex: {}
};

// update ass file
export const updateAssFile = createAsyncThunk(
  'export/updateAssFile',
  async ({ videoId, user, guestId }: any, thunkApi: any) => {
    let videoData = thunkApi.getState().mediaPlayer[videoId]
    // console.log({ videoData })
    let formData = new FormData()
    let capColor = videoData.captionColor
    let wordCount = videoData.maxWords
    let position = videoData.captionPosition

    // // let fontSizeMul = orientation == 'vertical' ? 0.9 : isMobile ? 0.9 : isDesktop ? 0.9 : 1

    formData.append('video_id', videoId)
    if (user?.id) formData.append('user_id', user?.id)
    else if (guestId) formData.append('user_id', guestId)
    formData.append('primary', capColor[0])
    formData.append('secondary', capColor[1] || "#FFF")
    // formData.append('is_underline', updatedData.underline || underline ? '1' : '0')
    // formData.append('is_italic', updatedData.italic || italic ? '1' : '0')
    // formData.append('is_bold', updatedData.bold|| bold ? '1' : '0')
    formData.append('font_name', videoData.font)
    formData.append(
      'font_size',
      Math.round(videoData.fontSize * 1).toString(),
    )
    formData.append('lang', 'en')
    formData.append(
      'position',
      position == 0 ? '8' : position == 50 ? '5' : '2',
    )
    formData.append('max_words_per_screen', videoData.maxWords ? wordCount.toString() : '5')
    formData.append('styles', videoData.preset)
    const response = await httpApi.post(`/change_caption_preset`, formData);
    return response.data;
  }
);

// update music 
export const mergeAudio = createAsyncThunk(
  'export/mergeAudio',
  async (data: object) => {
    const response = await httpApi.post(`/merge_audio_and_video`, data);
    return response.data;
  }
);

// enhance music 
export const enhanceAudio = createAsyncThunk(
  'export/enhanceAudio',
  async (data: object) => {
    const response = await httpApi.post(`/enhance_audio`, data);
    return response.data;
  }
);

// me
export const fetchRecentProjects = createAsyncThunk(
  'export/fetchRecentProjects',
  async (id: string) => {
    // const response = await httpApi.get(`get_user_all_projects/${id}`);
    const response = await httpApi.get(`/get_user_all_data/${id}`);
    return response.data;
  }
);

export const updateEmbedCaptions = createAsyncThunk(
  'export/updateEmbedCaptions',
  async (data: any) => {
    const response = await httpApi.post(`embed_captions/`, data);
    return response.data;
  }
)

export const deleteVideo = createAsyncThunk(
  'export/deleteVideo',
  async ({ data, videoId, size, id }: any) => {
    const response = await httpApi.post(`delete_video`, data);
    if (response.status == 200) {
      // await api.delete(`/videos/${id}`)
      return {
        data: response.data,
        videoId, size
      };
    }
  }
)

export const getVideoFiles = createAsyncThunk(
  'export/getVideoFiles',
  async (data: any, thunkApi: any) => {
    let video_id = data.videoId.split("-")?.[0]
    // const response = await httpApi.get(`get_files/${data.userId}/${data.videoId}`);
    const response = await httpApi.get(`/get_user_data/${data.userId}/${video_id}`);
    // thunkApi.dispatch({ type: "mediaPlayer/DefaultChecker", payload: data.videoId })
    return response.data;
  }
)

export const getVideoFileAndExtract = createAsyncThunk(
  'export/getVideoFileAndExtract',
  async (data: any) => {
    let video_id = data.videoId.split("-")?.[0]
    // const response = await httpApi.get(`get_files/${data.userId}/${data.videoId}`);
    const response = await httpApi.get(`/get_user_data/${data.userId}/${video_id}`);
    return response.data;
  }
)

export const modifySubtitle = createAsyncThunk(
  'export/modifySubtitle',
  async (data: any, ThunkApi) => {
    const response = await httpApi.post(`modify_subtitles/`, data.form);
    ThunkApi.dispatch(getVideoFiles({ videoId: data.videoId }))
    return response.data;
  }
)

export const getStorageStats = createAsyncThunk(
  'export/getStorageStats',
  async (data: any) => {
    const response = await api.get(`/videosLogs/user_id/${data.userId}`);
    return response.data;
  }
)

export const changeSubtitleLang = createAsyncThunk(
  'export/changeSubtitleLang',
  async ({ form, lang, id, video_id }: any, thunkApi) => {
    const response = await httpApi.post(`transcribe_without_video`, form);
    await api.put(`/videos/${id}`, { language: lang })
    thunkApi.dispatch(getVideoFiles({ videoId: video_id }))
    thunkApi.dispatch(discordWebhook("Update Subtitle Lang"))

    return { data: response.data, lang }
  }
)

export const getGoogleFonts = createAsyncThunk(
  'export/getGoogleFonts',
  async () => {
    return fetch(GOOGLE_FONTS_URL)
      .then((response) => response.json())
  }
)

export const updateSubtitleJson = createAsyncThunk(
  'export/updateSubtitleJson',
  async ({ form, videoId, userId }: any, thunkApi: any) => {
    let video_id = videoId.split("-")?.[0]

    const response = await httpApi.post(`update_transcription?user_id=${userId}&video_id=${video_id}`, form);
    thunkApi.dispatch(discordWebhook("Update Subtitle Json"))

    return response.data;
  }
)

export const updateAICaptionSubtitle = createAsyncThunk(
  'export/updateAICaptionSubtitle',
  async ({ form, videoId, userId }: any, thunkApi: any) => {
    let video_id = videoId.split("-")?.[0]

    const { captionColor, maxWords, font, fontSize, preset, captionPosition }: IMediaPlayer = thunkApi.getState().mediaPlayer[videoId];

    let pri = hexToBBGGRR(captionColor?.[0] || '#FFF')
    let sec = hexToBBGGRR(captionColor?.[1] || '#FFF')

    // Clean up the values if necessary (e.g., remove leading &)
    pri = pri.startsWith('&') ? pri.substring(1) : pri;
    sec = sec.startsWith('&') ? sec.substring(1) : sec;

    // Encode the values to ensure they are URL-safe
    pri = encodeURIComponent(pri);
    sec = encodeURIComponent(sec);

    // Construct the URL with encoded parameters
    const url = `update_AI_caption_data?user_id=${userId}&video_id=${video_id}&primary=${pri}&secondary=${sec}&max_words_per_screen=${maxWords}&font_name=${font}&font_size=${fontSize}&position=${captionPosition}&styles=${preset}`;

    // Send the request
    const response = await httpApi.post(url, form);

    thunkApi.dispatch(getVideoFiles({ video_id, userId }))
    thunkApi.dispatch(discordWebhook("Update AI Caption"))
    return response.data;
  }
)

export const updateAddCaptions = createAsyncThunk(
  'export/updateAddCaptions',
  async ({ data, videoId, userId }: any, thunkApi: any) => {
    const response = await httpApi.post(`/add_captions`, data);
    thunkApi.dispatch(getVideoFiles({ videoId, userId }))
    thunkApi.disptach(discordWebhook("Add Caption"))
    return response.data;
  }
);

export const updateAIStudioCaptions = createAsyncThunk(
  'export/updateAIStudioCaptions',
  async ({ data, videoId, userId }: any, thunkApi: any) => {
    let video_id = videoId.split("-")?.[0]

    const response = await httpApi.post(`/aistudio_remotion_render`, data);
    thunkApi.dispatch(getVideoFiles({ videoId: video_id, userId }))
    return response.data;
  }
);

export const updateAIShortsCaptions = createAsyncThunk(
  'export/updateAIShortsCaptions',
  async ({ data, videoId, userId }: any, thunkApi: any) => {
    let video_id = videoId.split("-")?.[0]

    const response = await httpApi.post(`/render_aishorts_video`, data);
    thunkApi.dispatch(getVideoFiles({ videoId: video_id, userId }))
    return response.data;
  }
);

export const getVideoCompression = createAsyncThunk(
  'export/doCompression',
  async (data: any, thunkApi: any) => {
    const response = await httpApi.post(`/compress_video_without_file`, data)
    thunkApi.dispatch(discordWebhook("Compression WF"))
    return response.data
  }
)

export const updateAIStudioWord = createAsyncThunk(
  'export/updateAIStudioWord',
  async (data: any, thunkApi: any) => {
    let video_id = data.videoId.split("-")?.[0]

    let formData = new FormData()
    formData.append("user_id", data.user.id)
    formData.append("video_id", video_id)
    formData.append("max_words_per_screen", data.maxWords)
    const response = await httpApi.post(`/update_num_of_words`, formData)
    thunkApi.dispatch(discordWebhook("Update MaxWords AI Studio"))
    return response.data
  }
)

export const updateAIShortsWord = createAsyncThunk(
  'export/updateAIShortsWord',
  async (data: any, thunkApi: any) => {
    let video_id = data.videoId.split("-")?.[0]

    let formData = new FormData()
    formData.append("user_id", data.user.id)
    formData.append("video_id", video_id)
    formData.append("max_words_per_screen", data.maxWords)
    formData.append("video_index_number", data.index)
    const response = await httpApi.post(`/update_num_of_words_aishorts`, formData)
    thunkApi.dispatch(discordWebhook("Update MaxWords AI Shorts"))
    return { data: response.data, index: data.index }
  }
)

// Continuing in exportSlice.ts

const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    addRecentProject: (state, action: any) => {
      let temp = [...state.recentProject]
      temp.push(action.payload)
      state.recentProject = temp
    },
    addVideoId: (state, action: PayloadAction<any>) => {
      state.videoId = action.payload
      if (action.payload == null)
        state.videoFile = null
    },
    addMusicId: (state, action: PayloadAction<any>) => {
      if (action.payload != undefined) {
        state.musicId = action.payload.id
        let temp = [...state.timelineLayer]
        temp.push({ id: 1, start: 0, end: state.videoFile?.duration, text: action.payload.music_name, layerId: 1 })
        state.timelineLayer = temp
      } else {
        state.musicId = action.payload
        if (state.timelineLayer.length == 0) return
        let temp = [...state.timelineLayer]
        let ind = temp.findIndex(({ layerId }: any) => layerId === 1)
        temp.splice(ind, 1)
        state.timelineLayer = temp
      }
    },
    addShapeLayer: (state, action: PayloadAction<any>) => {
      let temp = [...state.timelineLayer]
      temp.push(action.payload)
      state.timelineLayer = temp
    },
    addVideoThumbnails: (state, action: PayloadAction<any>) => {
      state.videoThumbnails = action.payload
    },
    addVideoData: (state, action: PayloadAction<any>) => {
      state.videoData = { ...state.videoData, ...action.payload }
    },
    updateCurrentTranscribe: (state, action: PayloadAction<any>) => {
      state.currentTranscribe = action.payload
    },
    updateUploadState: (state, action: PayloadAction<any>) => {
      state.uploadState = action.payload
    },
    doingCompression: (state, action: PayloadAction<any>) => {
      state.videoFile = { ...state.videoFile, ...action.payload }
    },
    updateIndexVideoId: (state, action: PayloadAction<{ newVideoId: string, next: number }>) => {
      if (state.videoFile) {
        let vId = state.videoFile.video_id.split("-")?.[0];
        state.videoFile.video_id_old = vId;
        state.videoFile.video_id = action.payload.newVideoId;
        state.videoId = action.payload.newVideoId;
        state.aishortsIndex = {
          ...state.aishortsIndex,
          [vId]: action.payload.next
        }

      }
    },
    updateVideoLoadingState: (state, action: PayloadAction<any>) => {
      if (state.videoFile) {
        state.isLoading = action.payload
      }
    },
    defaultIndexVideoId: (state, action: PayloadAction<{ newVideoId: string, next: number }>) => {
      if (state.videoFile) {
        let video_id = state.videoFile.video_id
        state.videoFile.video_id_old = video_id.split("-")?.[0];
        state.videoFile.video_id = `${video_id}-${state.aishortsIndex[video_id]}`;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentProjects.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.toModified = false;
      })
      .addCase(fetchRecentProjects.fulfilled, (state, action: PayloadAction<any>) => {
        state.recentProject = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchRecentProjects.rejected, (state, action: any) => {
        state.recentProject = []
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(updateEmbedCaptions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.toModified = false;
      })
      .addCase(updateEmbedCaptions.fulfilled, (state, action: PayloadAction<any>) => {
        let temp = { ...state.videoFile }
        if (temp && temp.urls) {
          temp.urls["updated_video"] = action.payload.video_path;
        }
        state.toModified = true
        state.videoFile = temp as IVideos
        state.isLoading = false;
      })
      .addCase(updateEmbedCaptions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(deleteVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteVideo.fulfilled, (state, action: PayloadAction<any>) => {
        let temp = [...state.recentProject]
        let ind = temp.findIndex(({ video_id }) => video_id === action.payload.videoId)
        temp.splice(ind, 1)
        state.recentProject = temp
        // if (state.storageStats) {
        //   state.storageStats.size_in_MB = state.storageStats.size_in_MB - (action.payload.size || 0)

        // }
        state.isLoading = false;
      })
      .addCase(deleteVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(getVideoFiles.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.toModified = false
      })
      .addCase(getVideoFiles.fulfilled, (state, action: PayloadAction<any>) => {
        // state.recentProject = action.payload["files information"];
        if (action.payload._id) {

          let temp = { ...action.payload, refresh: true }

          if (temp.active_service[0] == 'aishorts') {
            let video_id = temp.video_id.split("-")?.[0]

            let currentIndex = state?.aishortsIndex?.[video_id] ?? 0

            state.aishortsIndex = {
              ...state.aishortsIndex,
              [video_id]: currentIndex,
            };

            temp.video_id_old = video_id;
            temp.video_id = `${video_id}-${currentIndex}`;
            state.videoId = `${video_id}-${currentIndex}`;
          }
          state.videoFile = temp as IVideos
          state.isLoading = false;
        }
      })
      .addCase(getVideoFiles.rejected, (state, action) => {
        console.log("getVideoFiles rej", action)
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(getVideoFileAndExtract.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getVideoFileAndExtract.fulfilled, (state, action: PayloadAction<any>) => {

        // Find the index of the item with the matching video_id
        const index = state.recentProject.findIndex((item: any) => item.video_id === action.payload.video_id);

        // If index is not found, add the new item to the end of the array
        if (index === -1) {
          state.recentProject = [...state.recentProject, { ...action.payload, performLoader: true }];
        } else {
          // If index is found, replace the item at that index with the new item
          state.recentProject = [
            ...state.recentProject.slice(0, index), // Keep the items before the found index
            { ...action.payload, performLoader: true }, // Add the new item
            ...state.recentProject.slice(index + 1) // Keep the items after the found index
          ];
        }

        state.isLoading = false;
      })
      .addCase(getVideoFileAndExtract.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(modifySubtitle.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(modifySubtitle.fulfilled, (state, action: PayloadAction<any>) => {
        let temp = { ...state.videoFile }
        if (temp.urls) {
          temp.urls["ass_modified_file"] = action.payload.modified_ass
          temp.urls["vtt_modified_file"] = action.payload.modified_vtt
        }

        state.videoFile = temp as IVideos
        state.isLoading = false;
      })
      .addCase(modifySubtitle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(getStorageStats.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getStorageStats.fulfilled, (state, action: PayloadAction<any>) => {
        state.storageStats = action.payload
        state.isLoading = false;
      })
      .addCase(getStorageStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(changeSubtitleLang.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        // state.videoFile = { ...state.videoFile, refresh: false }
      })
      .addCase(changeSubtitleLang.fulfilled, (state, action: PayloadAction<any>) => {
        // let file_urls = action.payload.data.file_urls
        // let videoFile = {
        //   ass_modified_file: "",
        //   json_original_file: "",
        //   ass_original_file: "",
        //   srt_original_file: "",
        //   srt_modified_file: "",
        //   vtt_original_file: "",
        //   vtt_modified_file: "",
        //   language: action.payload.lang
        // }
        if (state.videoFile)
          state.videoFile.language = action.payload.lang

        // file_urls.map((str: string) => {
        //   if (str.includes("original") && str.includes(".vtt"))
        //     videoFile.vtt_original_file = str
        //   else if (str.includes("modified") && str.includes(".vtt"))
        //     videoFile.vtt_modified_file = str
        //   else if (str.includes("original") && str.includes(".srt"))
        //     videoFile.srt_original_file = str
        //   else if (str.includes("modified") && str.includes(".srt"))
        //     videoFile.srt_modified_file = str
        //   else if (str.includes("original") && str.includes(".ass"))
        //     videoFile.ass_original_file = str
        //   else if (str.includes("modified") && str.includes(".ass"))
        //     videoFile.ass_modified_file = str
        // })

        // state.videoFile = { ...state.videoFile, ...Object.fromEntries(Object.entries(videoFile).filter(([_, v]) => v !== "")), refresh: true }
        // state.videoFile = []
        // state.storageStats = action.payload
        state.isLoading = false;
      })
      .addCase(changeSubtitleLang.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })

      .addCase(updateAssFile.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAssFile.fulfilled, (state, action: PayloadAction<any>) => {
        if (state.videoFile && state.videoFile.urls && action.payload.updated_ass_file) {
          state.videoFile.urls.ass_original_file = action.payload.updated_ass_file;
        }
        state.isLoading = false;

      })
      .addCase(updateAssFile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })

      .addCase(enhanceAudio.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(enhanceAudio.fulfilled, (state, action: PayloadAction<any>) => {
        if (state.videoFile && state.videoFile.urls) {
          state.videoFile.urls.video_original_file = action.payload.video_path;
        }
        state.isLoading = false;

      })
      .addCase(enhanceAudio.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(getGoogleFonts.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getGoogleFonts.fulfilled, (state, action: PayloadAction<any>) => {
        state.fonts = action.payload.items
        state.isLoading = false;

      })
      .addCase(getGoogleFonts.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(updateSubtitleJson.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateSubtitleJson.fulfilled, (state, action: PayloadAction<any>) => {
        if (state.videoFile && state.videoFile.urls)
          state.videoFile.urls.updated_json_file = action.payload.updated_json_file;
        state.isLoading = false;

      })
      .addCase(updateSubtitleJson.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(updateAICaptionSubtitle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAICaptionSubtitle.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        let { emoji_update_response, transcription_update_response } = action.payload.details
        if (state.videoFile) {
          state.videoFile.urls.emoji_predicted_file = emoji_update_response.update_json_sas_url
          state.videoFile.urls.updated_transcriptionJson_file = transcription_update_response.update_json_sas_url
        }
      })
      .addCase(updateAICaptionSubtitle.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(updateAddCaptions.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAddCaptions.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;

      })
      .addCase(updateAddCaptions.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(updateAIStudioCaptions.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAIStudioCaptions.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;

      })
      .addCase(updateAIStudioCaptions.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(updateAIShortsCaptions.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAIShortsCaptions.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;

      })
      .addCase(updateAIShortsCaptions.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(updateAIStudioWord.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAIStudioWord.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        if (state.videoFile?.urls) {
          state.videoFile.urls.caption_orig_json_uri = action.payload.updated_caption_file;
        }
      })
      .addCase(updateAIStudioWord.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(updateAIShortsWord.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAIShortsWord.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        if (state.videoFile?.urls) {
          let temp = [...state.videoFile.urls.caption_original_json_uris]
          let { index, data } = action.payload
          temp[index] = data.updated_caption_file;
          state.videoFile.urls.caption_original_json_uris = temp
        }
      })
      .addCase(updateAIShortsWord.rejected, (state, action) => {
        state.isLoading = false;
        // state.error = action.error.message || 'Failed to fetch';
      })


  },
});

export const { addRecentProject, addVideoId } = videoSlice.actions;
export default videoSlice.reducer;
