// gaUtils.ts
import ReactGA from 'react-ga4';


type EventCategory = 'User' | 'System' | 'UI Interaction' | string;
type EventAction = 'Clicked Button' | 'Visited Page' | string;

interface ILogEvent {
    category: EventCategory;
    action: EventAction;
    label?: string;
    value?: number;
}

const trackingId = "G-R1WWSF8GKP"

export const initGA = () => {
    // ReactGA.initialize(trackingId);
    ReactGA.initialize([
        {
            trackingId: trackingId,
        },]
    )
}

function hashUserID(userID: string): string {
    return userID
    // return crypto.createHash('sha256').update(userID).digest('hex');
}

export const logPageView = (pathname?: string) => {
    const path = pathname || window.location.pathname;
    ReactGA.send({ hitType: "pageview", page: path, title: "Custom Title" });
    // ReactGA.set({ page: path });
    // ReactGA.pageview(path);
}

export const setUser = (userID: string) => {
    ReactGA.set({ userId: hashUserID(userID) });
}

export const logEvent = (event: ILogEvent) => {
    if (event && event.category && event.action) {
        ReactGA.event({
            category: event.category,
            action: event.action,
            label: event.label || '',
            value: event.value
        });
    }
}

export const logException = (description: string, fatal: boolean = false) => {
    if (description) {
        // ReactGA.exception({ description, fatal });
    }
}
