import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material'


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid white',
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};


const ConfimationModal = ({ title, text, onClick, open, onClose }: any) => {
    return (<Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <>
                <Typography id="modal-modal-title" variant="h6" sx={{
                    color: "#4A4A4A",
                    textAlign: "center",
                    fontFamily: "Nunito",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                }} component="h2">
                    {title}
                </Typography>
                <Typography id="modal-modal-description" sx={{
                    color: "#000",
                    textAlign: "center",
                    fontFamily: "Nunito",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                }}>
                    {text}
                </Typography>
                <Button
                    variant="outlined"
                    sx={{
                        marginTop: 2,
                        backgroundColor: 'white',
                        borderColor: '#4623E9',
                        color: '#4623E9',
                        width: '70%'

                    }}
                    onClick={onClose}
                >
                    No
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        marginTop: 2,
                        backgroundColor: '#4623E9',
                        borderColor: '#4623E9',
                        color: 'white',
                        width: '70%',
                        '&:hover': {
                            backgroundColor: '#4623E9',  // Keep the same color on hover
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',  // Add a subtle box shadow to give a pressed effect
                        }
                    }}
                    onClick={onClick}
                >
                    Yes
                </Button>
            </>
        </Box>
    </Modal>)
}

export default ConfimationModal;