import React, { Fragment, useEffect, useState } from 'react';
import './style.css'
import { Container, Divider, FormControlLabel, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio, RadioGroup, TextField, ToggleButton, ToggleButtonGroup, Tooltip, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import InfoIcon from '@mui/icons-material/Info';
import useVideo from '../../hooks/useVideo';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';
import httpApi from '../../utils/httpApi';
import { SEVER_URL } from '../../utils/url';
import { getToken } from '../../redux/slices/auth.slice';
import VideoProgress from '../../components/ProgressVideo';
import { getVideoFiles } from '../../redux/slices/video.slice';
import { logEvent, logPageView } from '../../utils/ga.utils';
import toast from 'react-hot-toast';


const TextToVideo = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const guestId = useAppSelector((state: RootState) => state.auth.guestId)
    const userId = useAppSelector((state: RootState) => state.auth.user?.id)
    const videoFile = useAppSelector((state: RootState) => state.video.videoFile)
    const videoId = useAppSelector((state: RootState) => state.textToVideo.videoId)

    const [open, setOpen] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState("Go Back");
    const [videoTitle, setVideoTitle] = useState("")
    const [captions, setCaptions] = useState<{ text: string } | null>(null)
    const [loading, setLoading] = useState(false)
    const [scale, setScale] = useState('Landscape')

    const marks = [
        {
            value: 0,
            label: 'top',
        },
        {
            value: 50,
            label: 'center',
        },
        {
            value: 100,
            label: 'bottom',
        },
    ]

    useEffect(() => {
        if (videoId !== null && !videoFile?._id)
            dispatch(getVideoFiles({ userId: userId || guestId, videoId }))
    }, [videoId])

    useEffect(() => {
        if (videoFile?.urls?.TTV_8wordjson) {
            fetch(videoFile?.urls.TTV_8wordjson).then(response => response.json())
                .then(data => {
                    setCaptions(data)
                })
        }
    }, [videoFile])

    useEffect(() => {
        logPageView()
    }, [])




    function valuetext(value: number) {
        return `${value}`
    }

    function valueLabelFormat(value: number) {
        // if (marks.findIndex((mark) => mark.value === value) !== captionPosition)
        //     updateCaptionPosition(marks.findIndex((mark) => mark.value === value))
        return marks.findIndex((mark) => mark.value === value) + 1
    }

    const handleDrawerClose = () => {
        setOpen(!open);
    };

    const returnIcon = (txt: string) => {
        switch (txt) {
            case 'Go Back':
                return <ReplyIcon />
        }
    };

    function presentChangeHandler(name: string) {
        // let findPresent = PresetNew.find((pre) => pre.name === name)
        // if (findPresent && findPresent.captionColor && videoFile) {
        //     let orientation = getVideoOrientation(videoFile?.resolution[0], videoFile?.resolution[1])
        //     // console.log({ orientation })
        //     // loadFont(findPresent?.fontType, findPresent?.style)
        //     let fontSize = findPresent?.fontSize || 15
        //     const maxWords = name === "one_word" ? 1 :
        //         name === "s3xycaptions" ? (orientation === "vertical" ? 4 : 8) :
        //             5;

        //     updatePresets({
        //         captionColor: findPresent?.captionColor,
        //         font: findPresent?.fontType,
        //         variant: findPresent?.style,
        //         captionPosition: 100,
        //         fontSize: fontSize,
        //         preset: name,
        //         customCaption: true,
        //         videoToggle: videoToggle,
        //         italic: false,
        //         bold: false,
        //         underline: false,
        //         maxWords,
        //         visuals: visuals
        //     })
        // }
    }

    const generateVideo = async () => {
        if (videoTitle !== "" && !videoId && !loading) {
            setLoading(true)
            dispatch(getToken(userId || guestId))

            let formData = new FormData();

            formData.append("user_id", userId || guestId as string)
            formData.append("language", "en")
            formData.append("input_prompt", videoTitle)
            formData.append("orientation", scale)

            let response = null;

            response = await httpApi.post(`${SEVER_URL}text_to_video_instant`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'Host': 'test.transpify.com',
                }
            });

            if (response.data.status_code === 405) {
                dispatch(getToken(userId || guestId))
                setTimeout(() => generateVideo(), 1000)
                return
            }
            else {
                logEvent({ category: "Text", action: "Text to Video", label: userId || guestId as string })

                dispatch({ type: "texttovideo/upadateVideoId", payload: response.data[0].video_id })
                setLoading(false)

            }
        }
        else {
            if (videoTitle !== "") toast.error("Please fill title")
            else if (!videoId) toast.error("You cannot re-process video")
            else toast.error("Something went wrong!")
        }
    }



    return (
        <div style={{ marginBottom: isXs ? '10vh' : '0' }}> {/* Adjust padding to make room for the video player */}
            <Fragment>
                {!isXs && <Header></Header>}
                <Container sx={{ height: '100vh', backgroundColor: 'white' }} maxWidth={false}>
                    <Grid sx={{ width: '100%', height: '100%' }} container spacing={2}>

                        <Grid sx={{ border: "1px solid #DDD;" }} item md={1}>
                            <div style={{}} className="drawer-container">
                                <Divider />
                                <List sx={{ marginTop: 2 }}>
                                    {['Go Back'].map((text, index) => (
                                        <ListItem key={text + index} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 68,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                    flexDirection: open ? 'row' : 'column',
                                                    backgroundColor: '#FFF',
                                                }}
                                                onClick={() => {
                                                    if (text == "Go Back") navigate("/")
                                                    setCurrentTab(text)
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                        marginRight: open ? "25px" : "0px",
                                                        color: currentTab == text ? "#0075E1" : 'black',
                                                    }}
                                                >
                                                    {returnIcon(text)}
                                                </ListItemIcon>
                                                <ListItemText primary={text} sx={{
                                                    opacity: open ? 1 : 1,
                                                    color: currentTab == text ? "#0075E1" : 'black',
                                                    textAlign: 'center'
                                                }} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </Grid>
                        <Grid sx={{ padding: 3 }} md={11}>
                            <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.12)', paddingBottom: 10 }} md={12}>
                                <p className='text-main' style={{ margin: 0 }}>Text to Video</p>
                                <InfoIcon></InfoIcon>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginTop: 2 }} md={12}>
                                {/* <Grid item sx={{}} md={6} xs={12} sm={12}>
                                    <Grid container spacing={1} md={12} className='selecter-div' >
                                        <Grid container spacing={1} md={12} className='display-flex' style={{}}>
                                            <Grid md={4} sm={12} xs={12} sx={{display:"flex",justifyContent:'center',alignItems:'center'}} item> <p className='text-select-ex'>Select existing Voice-over Project</p></Grid>
                                            <Grid md={4} sm={12} xs={12} sx={{display:"flex",justifyContent:'center',alignItems:'center'}} item>  <p className='text-select-or'> - OR - </p></Grid>
                                            <Grid md={4} sm={12} xs={12} sx={{display:"flex",justifyContent:'center',alignItems:'center'}} item>  <button onClick={() => navigate("/publish")} style={{ marginLeft: 10 }} className="done-button box-shadoww">
                                                Create Voiceover
                                                <img src="/voice_selection.png"></img>
                                            </button></Grid>
                                        </Grid>
                                        <Grid item md={3} sm={6} xs={6}>
                                            <div className="voiceover-proj">
                                                <div className="voiceover-proj-voice">
                                                    <img src="/voice.png"></img>
                                                </div>
                                                <div>
                                                    <div className="display-flex space-between" style={{ justifyContent: 'space-between', marginTop: 10, marginBottom: 5 }}>
                                                        <p className='proj-voice-head'>Voice-overOOAd</p>
                                                        <p className="proj-voice-stamp">2h ago</p>
                                                    </div>
                                                    <p className="proj-voice-stamp">English - 4m 32s</p>

                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item md={3} sm={6} xs={6}>
                                            <div className="voiceover-proj">
                                                <div className="voiceover-proj-voice">
                                                    <img src="/voice.png"></img>
                                                </div>
                                                <div>
                                                    <div className="display-flex space-between" style={{ justifyContent: 'space-between', marginTop: 10, marginBottom: 5 }}>
                                                        <p className='proj-voice-head'>Voice-overOOAd</p>
                                                        <p className="proj-voice-stamp">2h ago</p>
                                                    </div>
                                                    <p className="proj-voice-stamp">English - 4m 32s</p>

                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div className='web-source'>
                                        <div className="web-source-head">
                                            <img src="/Input-prompt.png"></img>
                                        </div>
                                        <TextField sx={{ width: "100%" }} id="standard-basic" label="Enter URL to generate using Blog or Social Media Post" variant="standard" />
                                    </div>
                                </Grid> */}
                                <Grid item sx={{}} md={6} xs={12} sm={12}>
                                    <Grid md={12} className='selecter-div' >
                                        <Grid md={12} className='web-source'>
                                            <div className="web-source-head">
                                                <img src="/Input-prompt.png"></img>
                                            </div>
                                            <TextField disabled={!!videoId} sx={{ width: "100%", marginTop: 1 }} value={videoTitle} onChange={(e) => setVideoTitle(e.target.value)} id="standard-basic" label="enter prompt (max 8 words) e.g. how greatness is achieved." variant="standard" />
                                        </Grid>
                                        {captions !== null && <Grid md={12} className='web-source'>
                                            <div>
                                                <p className='caption-text'>{captions?.text}</p>
                                                {/* <Tooltip title="Editing text feature coming soon" placement="top-start">
                                                    <p className='caption-text' style={{ textDecoration: "underline", cursor: 'pointer' }}>All this text is editable</p>
                                                </Tooltip> */}
                                            </div>
                                        </Grid>}
                                        <Grid container sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: "20px" }} md={12}>
                                            <Grid item xs={12} md={6} sm={12}>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >
                                                    {["Landscape", "Portrait"].map((val) => <FormControlLabel
                                                        key={val}
                                                        value={val}
                                                        control={
                                                            <Radio
                                                                checked={val === scale}
                                                                onChange={(e) => {
                                                                    console.log(e.target.value)
                                                                    setScale(e.target.value)
                                                                }
                                                                }
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 15,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        sx={{
                                                            '& .MuiTypography-root': {
                                                                color: '#4B4B4B',
                                                                fontFamily: 'Poppins',
                                                                fontSize: 14,
                                                                fontStyle: 'normal',
                                                                fontWeight: 400 /* 316.667% */,
                                                                letterSpacing: -0.14,
                                                            },
                                                        }}
                                                        label={val}
                                                    />)}

                                                </RadioGroup>
                                            </Grid>
                                            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={12} md={6} sm={12}>
                                                <button onClick={generateVideo} style={{ marginLeft: 10 }} className="done-button box-shadoww">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                        <path d="M6.5025 3.6L4.0025 5L5.4025 2.5L4.0025 0L6.5025 1.4L9.0025 0L7.6025 2.5L9.0025 5L6.5025 3.6ZM18.5025 13.4L21.0025 12L19.6025 14.5L21.0025 17L18.5025 15.6L16.0025 17L17.4025 14.5L16.0025 12L18.5025 13.4ZM21.0025 0L19.6025 2.5L21.0025 5L18.5025 3.6L16.0025 5L17.4025 2.5L16.0025 0L18.5025 1.4L21.0025 0ZM12.3425 10.78L14.7825 8.34L12.6625 6.22L10.2225 8.66L12.3425 10.78ZM13.3725 5.29L15.7125 7.63C16.1025 8 16.1025 8.65 15.7125 9.04L4.0425 20.71C3.6525 21.1 3.0025 21.1 2.6325 20.71L0.2925 18.37C-0.0975 18 -0.0975 17.35 0.2925 16.96L11.9625 5.29C12.3525 4.9 13.0025 4.9 13.3725 5.29Z" fill="white" />
                                                    </svg>
                                                    Generate video
                                                </button>
                                            </Grid>
                                            {/* <div onClick={generateVideo} className='button-generate-video'>

                                                <p>Generate video</p>
                                            </div> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid style={{  }} md={6} xs={12} sm={12}>
                                    <div className='selecter-div' >
                                        <div className='head-voice display-flex'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                                                <path d="M6.66667 13.6668L0 7.00016L6.66667 0.333496L7.85 1.51683L2.36667 7.00016L7.85 12.4835L6.66667 13.6668Z" fill="#1C1B1F" />
                                            </svg>
                                            <p className='head-name'>VoiceOver00ad-EN</p>
                                        </div>
                                        <div className='display-flex' style={{ marginTop: 20 }}>
                                            <div className='voice-dec-img-div'>
                                                <img src="/voice.png"></img>
                                            </div>
                                            <div style={{ marginLeft: 15, marginTop: 10, marginBottom: 10, display: "flex", flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <div>
                                                    <p className='voice-dec-head'>English</p>
                                                    <p className='voice-dec-head-bold' style={{ marginTop: 10 }}>4m 32s</p>
                                                </div>
                                                <p className="edit-voiceover-button">Edit Voice-over</p>
                                            </div>
                                        </div>
                                    </div>
                                    <Grid md={12} sx={{ paddingLeft: 3, backgroundColor: 'white', marginTop: 2 }}>
                                        <p className='title-header mb-3'>Captions</p>
                                        <Grid xs={12} md={12} className="preset-main-box">

                                            {PresetNew.map(({ picture, name }) => (
                                                <Grid item xs={3.95} md={2.4}>
                                                    <div
                                                        style={{
                                                            // borderColor: name === preset ? '#00A3FF' : "rgba(255,255,255,0.2)",
                                                            borderWidth: "2px"

                                                        }}
                                                        onClick={() => presentChangeHandler(name)}
                                                        className="box cursor-pointer box-shadoww"
                                                    >
                                                        {name != "" && <img
                                                            onClick={() => presentChangeHandler(name)}
                                                            src={picture}></img>}
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Grid xs={12} md={12} sx={{
                                            overflow: "auto",
                                            whiteSpace: { xs: 'normal', sm: 'nowrap' },
                                            width: '100%',
                                            marginTop: 2
                                        }}>
                                            {Fonts.map(({ family }) => {
                                                const isSelected = font === family;
                                                return (
                                                    <div
                                                        onClick={() => updateFont(family)}
                                                        className={`font-circle cursor-pointer box-shadoww  ${isSelected ? 'font-circle-selected' : ''}`}
                                                        style={{
                                                            display: 'inline-flex', // Use inline-flex for items to align them in a line
                                                            marginRight: '10px', // Add some space between the font choices
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                fontFamily: family,
                                                                color: isSelected ? '#0075E1' : '#A1A1A1',
                                                                fontSize: 20
                                                            }}
                                                        >Aa</p>
                                                    </div>
                                                )
                                            })}
                                        </Grid>

                                    </Grid>
                                    <Grid md={12} sx={{ padding: 3, backgroundColor: 'white' }}>
                                        <p className='title-header mb-3'>Text Preferences:</p>
                                        <Grid container md={12}>
                                            <Grid sx={{}} sm={6} xs={6} md={4}>
                                                <p className='title-name mb-2'>Font Colors</p>
                                                <div className='display-flex'>    {!primary_secondaryPreset.find((str) => str == preset) && <Grid item sm={2} xs={2} md={2}>
                                                    <SketchExample
                                                        disabled={!customCaption}
                                                        name={'primary'}
                                                    />
                                                </Grid>}
                                                    {!primary_secondaryPreset.find((str) => str == preset) && !secondaryPreset.find((str) => str == preset) && (
                                                        <Grid item sm={2} xs={2} md={2}>
                                                            <SketchExample
                                                                disabled={!customCaption}
                                                                name={'secondary'}
                                                            />
                                                        </Grid>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid md={4} xs={6} sm={6}>
                                                <p className='title-name mb-2'>Visuals</p>
                                                <ToggleButtonGroup
                                                    color="primary"
                                                    value={visuals}
                                                    disabled={false}
                                                    exclusive
                                                    onChange={(_: any, value: string) => updateVisuals(value)}
                                                    aria-label="Platform"
                                                    className='box-shadoww'
                                                    sx={{ height: "32px" }}
                                                >
                                                    <ToggleButton value="emojis">Emojis</ToggleButton>
                                                    <ToggleButton value="GIFs">GIFs</ToggleButton>
                                                    <ToggleButton value="none">None</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid sx={{ paddingRight: "20px" }} sm={6} xs={6} md={4}>
                                                <p className='title-name mb-2'>Font size</p>
                                                <FontSizeChanger
                                                    disabled={!customCaption}
                                                />
                                            </Grid>
                                            <Grid sx={{ marginTop: '20px' }} sm={12} xs={12} md={8}>
                                                <p className='title-name mb-2'>Max words</p>
                                                <NumberButton num={preset == "one_word" ? 1 : 6} onChange={updateMaxWords}
                                                    PlatformDisabledVideo={() => { }} customCaption={customCaption} maxWords={maxWords}
                                                />
                                            </Grid>
                                            <Grid sx={{ marginTop: '20px', paddingRight: "20px" }} sm={12} xs={6} md={4}>
                                                <p className='title-name mb-2' >Position</p>
                                                <PositionSlide
                                                    size="small"
                                                    defaultValue={100}
                                                    marks={marks}
                                                    disabled={!customCaption}
                                                    aria-label="Restricted values"
                                                    onChange={(a, b) => updateCaptionPosition(b as number)}
                                                    valueLabelFormat={valueLabelFormat}
                                                    getAriaValueText={valuetext}
                                                    step={null}
                                                    valueLabelDisplay="auto"
                                                    sx={{ margin: "0.6px 10px" }}
                                                />
                                            </Grid>
                                            <Grid sm={12} xs={6} md={6}>
                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid item md={6} xs={12} sm={12}>
                                    <div style={{ width: '100%', maxWidth: '640px', marginBottom: '20px', position: 'relative' }}>
                                        <video
                                            playsInline
                                            style={{ width: '100%', borderRadius: '8px', height: '500px', }} controls>
                                            {videoFile?.urls?.text_to_video_rendered_uri && <source src={videoFile?.urls?.text_to_video_rendered_uri} type='video/mp4' />}
                                        </video>
                                    </div>
                                    <div style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        {/* <CustomizedButtons className={"box-shadoww"} onClick={publishVideo} background='#00A3FF' color='white' name="Render"></CustomizedButtons> */}
                                        {captions !== null && <button onClick={() => navigate("/publish")} style={{ marginLeft: 10 }} className="done-button box-shadoww">
                                            Publish
                                        </button>}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                {videoId && <VideoProgress close={false} renderurl={"ttv_rendering_progress"} userId={userId} videoId={videoId}></VideoProgress>}
            </Fragment>
        </div>
    )
}

export default TextToVideo;