import './style.css'
import { useEffect } from 'react';
import { Modal, Box, Typography, useMediaQuery, useTheme, FormControlLabel, Checkbox, Grid, } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';


const PodcastConfModal = ({ open, setOpen, onSubmit, currentTranscribe }: any) => {
    const theme = useTheme();
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : 900,
        maxWidth: '90%',
        bgcolor: 'background.paper',
        // border: '2px solid white',
        borderRadius: 8,
        boxShadow: 24,
        p: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };

    const podcastMsg = (_: any, value: any) => {
        const msgKey = currentTranscribe === "AI Shorts" ? "podcast-msg" : "studio-msg";
        localStorage.setItem(msgKey, value)
    }

    useEffect(() => {
        const msgKey = currentTranscribe === "AI Shorts" ? "podcast-msg" : "studio-msg";

        const storedValue = localStorage.getItem(msgKey);

        if (storedValue === null) {
            localStorage.setItem(msgKey, "false");
        }
    }, []);

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)", // This is the CSS property that blurs the background
                }
            }}
        >
            <Box sx={style}>
                <div style={{ position: 'absolute', right: 20, top: 15 }}>
                    <CloseIcon className="close-icon" onClick={() => setOpen(false)} />
                </div>

                {currentTranscribe == "AI Shorts" ? <> <p className='pd-head'>
                    Only for <span>Podcasts & Conferences</span>
                </p>
                    <div className="image-container" style={{ marginBottom: 15 }}>
                        <img src="https://viralmefast.azureedge.net/website-assets/PodcastModal/Demo1.png?sp=r&st=2024-07-11T18:51:48Z&se=2224-07-12T02:51:48Z&spr=https&sv=2022-11-02&sr=b&sig=k7JAj5L8ER%2FMhdJ7F%2BDKQe5z1mW67T2ZsOxBBgvp09U%3D" alt="Image One" />
                        <img src="https://viralmefast.azureedge.net/website-assets/PodcastModal/Demo2.png?sp=r&st=2024-07-11T18:52:12Z&se=2224-07-12T02:52:12Z&spr=https&sv=2022-11-02&sr=b&sig=7415%2BT8Lz%2Fn%2F1hFhI9FSv8lcd7NpalDFwv9x9gjBCHA%3D" alt="Image Two" />
                        {!isMobile && <img src="https://viralmefast.azureedge.net/website-assets/PodcastModal/Demo3.png?sp=r&st=2024-07-11T18:52:32Z&se=2224-07-12T02:52:32Z&spr=https&sv=2022-11-02&sr=b&sig=%2FGwjMgLCWvvx6uTGU0GHgEo%2FQmBPf61CzXDFOXSHXJU%3D" alt="Image One" />}
                        {!isMobile && <img src="https://viralmefast.azureedge.net/website-assets/PodcastModal/Demo4.png?sp=r&st=2024-07-11T18:52:53Z&se=2224-07-12T02:52:53Z&spr=https&sv=2022-11-02&sr=b&sig=UBZEzGp%2BcIE8UMf%2B145bn%2BvQEnnNKXWWJsrcTcdOUBY%3D" alt="Image Two" />}
                    </div>
                </> : <> <p className='pd-head'>
                    Only for <span>Talking Head Videos</span>
                </p>
                    <Grid container md={12} spacing={0} className="vid-container" style={{ marginBottom: 15 }}>
                        <Grid md={6} sm={12} xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'} item>
                            <video autoPlay loop muted src="https://viralmefast.azureedge.net/website-assets/Original-1c.mp4?sp=r&st=2024-07-11T18:16:54Z&se=2224-07-12T02:16:54Z&spr=https&sv=2022-11-02&sr=b&sig=3Ygt4OuIS0Kmn1LkW2Cw0ZuG%2FqKbDlSn9uEYosz70BY%3D" />
                            <p className='text-original'>ORIGINAL</p>
                        </Grid>
                        <Grid md={6} sm={12} xs={12} display={'flex'} flexDirection={'column'} alignItems={'center'} item>
                            <video autoPlay loop muted src="https://viralmefast.azureedge.net/website-assets/AIStudioc.mp4?sp=r&st=2024-07-11T18:15:27Z&se=2224-07-12T02:15:27Z&spr=https&sv=2022-11-02&sr=b&sig=iSZVFN8Bkxf0FJ1jJ60D2YTMfxpEurkuZhihd6NdkTI%3D" />
                            <p className='text-edited'>AI STUDIO EDITED</p>
                        </Grid>
                    </Grid>
                </>}
                <FormControlLabel control={<Checkbox onChange={podcastMsg} />} className="chkbox-label" label="Don’t show again" />
                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 15 }}>
                    <div
                        onClick={() => setOpen(false)}
                        className='pd-button'>
                        <p>Nevermind</p>
                    </div>
                    <div
                        onClick={() => { setOpen(false); onSubmit(true) }}
                        className='pd-button pd-button-2'>
                        <p>Yes, Proceed</p>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default PodcastConfModal;

