import { useEffect, useState } from 'react';
import { Modal, Box, Typography, useMediaQuery, useTheme, OutlinedInput, } from '@mui/material'
import { useAppDispatch } from '../../hooks/redux';
import GradientButton from '../Button/gradient'
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const MsgModal = ({ open, setOpen, msg }: any) => {
    const theme = useTheme();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [email, setEmail] = useState('')

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : 600,
        maxWidth: '90%',
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };


    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)", // This is the CSS property that blurs the background
                }
            }}
        >
            <Box sx={style}>
                <div style={{ position: 'absolute', right: 20, top: 10 }}>
                    <CloseIcon onClick={() => setOpen(false)}></CloseIcon>
                </div>
                <img style={{ height: 110, width: 110, objectFit: 'contain' }} src="/logo.png"></img>
                <p style={{
                    color: "#000",
                    fontFamily: "Roboto",
                    fontSize: 20,
                    marginBottom: 20
                }}>{msg}</p>
                <GradientButton onClick={() => setOpen(false)}>
                    OK
                </GradientButton>
            </Box>
        </Modal>
    );
}

export default MsgModal;
