// exportSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';
import httpApi from '../../utils/httpApi2';
import { SEVER_URL2 } from '../../utils/url';
import axios from 'axios';
import toast from 'react-hot-toast';
// import { login } from './auth.actions';

interface FeedbacksState {
  isLoading: boolean;
  error: string | null;
  feedbacks: any,
  message: string
}

const initialState: FeedbacksState = {
  isLoading: false,
  error: null,
  feedbacks: [],
  message: ''
};




export const getFeedbacks = createAsyncThunk(
  'feedbacks/getFeedbacks',
  async () => {
    const response = await api.get(`feedback`);
    return response.data;
  }
);


export const postFeedbacks = createAsyncThunk(
  'feedbacks/postFeedbacks',
  async (data: any, thunkApi) => {
    const response = await api.post(`feedback`, data);
    thunkApi.dispatch(getFeedbacks())
    return response.data;
  }
);

export const upvoteFeedbacks = createAsyncThunk(
  'feedbacks/upvoteFeedbacks',
  async (data: any, thunkApi) => {
    const response = await api.post(`feedback/upvote`, data);
    thunkApi.dispatch(getFeedbacks())
    return response.data;
  }
);

export const discordWebhook = createAsyncThunk(
  'feedbacks/discordWebhook',
  async (data: any, thunkApi: any) => {
    let { user, guestId } = thunkApi.getState().auth
    const response = await api.post(`discord/`, { message: data, id: user?.id || guestId as string });
    return response.data;
    // return false
  }
);

export const requestCaption = createAsyncThunk(
  'feedbacks/requestCaption',
  async (data: any, thunkApi: any) => {
    const response = await api.post(`caption-request/`, data);
    return response.data;
    // return false
  }
);


// Continuing in exportSlice.ts

const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbacks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.message = ''
      })
      .addCase(getFeedbacks.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.feedbacks = action.payload.data
      })
      .addCase(getFeedbacks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(postFeedbacks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(postFeedbacks.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        // state.feedbacks = action.payload;
      })
      .addCase(postFeedbacks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(upvoteFeedbacks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(upvoteFeedbacks.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.message = action.payload.message
        // state.feedbacks = action.payload;
      })
      .addCase(upvoteFeedbacks.rejected, (state, action) => {
        state.isLoading = false;

        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(requestCaption.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(requestCaption.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        toast.success("Caption Request make successfully!")
        // state.feedbacks = action.payload;
      })
      .addCase(requestCaption.rejected, (state, action) => {
        state.isLoading = false;

        state.error = action.error.message || 'Failed to fetch';
      })

  },
});

export const { } = feedbacksSlice.actions;
export default feedbacksSlice.reducer;
