// FontSizeChanger.tsx

import React, { useState } from 'react';
import { Button, Slider, TextField } from '@mui/material';
import useVideo from '../../hooks/useVideo';

interface FontSizeChangerProps {
    onSizeChange?: (size: number) => void;
    disabled?: boolean
    onChange?: any;
}

const FontSizeChanger: React.FC<FontSizeChangerProps> = ({ onChange = null, onSizeChange, disabled }) => {
    const { updateFontSize, fontSizeCaption } = useVideo()

    return (
        <div className={!disabled ? "box-shadoww" : ""}
            style={{
                display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                borderRadius: "3.413px",
                border: " 0.853px solid #D9D9D9",

                background: "#FFF",
            }}>
            <Slider
                defaultValue={fontSizeCaption || 28}
                value={fontSizeCaption}
                valueLabelDisplay="auto"
                onChange={(_, v) => onChange !== null ? onChange(Number(v)) : updateFontSize(Number(v))}
                marks
                min={1}
                max={75}
                sx={{ margin: "0.6px 10px" }}
                disabled={disabled}
            />
        </div >
    );
}

export default FontSizeChanger;