import { combineReducers, configureStore } from '@reduxjs/toolkit';
import mediaPlayer from "./slices/mediaPlayer";
import authSlice from './slices/auth.slice';
import exportSlice from './slices/export.slice';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import videoSlice from './slices/video.slice';
import tittiesSlice from './slices/titties.slice';
import profileSlice from './slices/profile.slice';
import feedbacksSlice from './slices/feedbacks.slice';
import textvideoSlice from './slices/textvideo.slice';

const rootReducer = combineReducers({
  mediaPlayer,
  auth: authSlice,
  export: exportSlice,
  video: videoSlice,
  titties: tittiesSlice,
  profile: profileSlice,
  feedback: feedbacksSlice,
  textToVideo: textvideoSlice
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['profile', "feedback"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(thunk),
  reducer: persistedReducer,
  devTools: true,
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
