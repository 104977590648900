import { useEffect, useRef } from 'react'
import { Grid, IconButton } from "@mui/material"
import { TitleHead } from "../TitleHead"
import { useAppDispatch, useAppSelector } from "../../hooks/redux"
import { VideoContext } from "../../context/video.context";
import { useContext, useState } from "react";
import TimeInputComponent from "../TimerPicker";
import { updateAICaptionSubtitle, updateSubtitleJson } from "../../redux/slices/video.slice";
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import axios from 'axios';
import emojis from '../../utils/emojis.json'
import useVideo from '../../hooks/useVideo';
import toast from 'react-hot-toast';


// EmojiInfo.ts
export interface EmojiInfo {
    keywords: string[];
    unicode: string;
    URL: string;
}

export interface EmojiDictionary {
    [key: string]: EmojiInfo;
}

const emojiData: EmojiDictionary = emojis as unknown as EmojiDictionary;

interface EmojiSegment {
    emoji_uri: string;
    emoji_key: string;
}

interface SubtitleEmoji {
    segments?: EmojiSegment[];
}

const Subtitle = ({ disabled = false }) => {
    const dispatch = useAppDispatch();
    const contextValue = useContext(VideoContext);

    if (!contextValue) {
        // handle the case where the context value is not provided
        throw new Error("VideoContext value is not provided");
    }

    const { subtitleJSON, setSubtitleJSON, mediaPlayerRef, setSubtitleEditable, subtitleEditable, subtitleEmoji, setSubtitleEmoji } = contextValue;
    const { visuals } = useVideo()

    const videoFile = useAppSelector(state => state.video.videoFile);

    const [editingText, setEditingText] = useState<any>(Array(subtitleJSON.segments.length).fill(""));

    const [subtitleGif, setSubtitleGif] = useState<SubtitleEmoji | null>(null)

    const [showEmojiPicker, setShowEmojiPicker] = useState(-1);
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const emojiPickerRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: any) => {
        if (emojiPickerRef.current && !emojiPickerRef?.current?.contains(event.target)) {
            setShowEmojiPicker(-1);
        }
    };

    useEffect(() => {
        if (showEmojiPicker !== -1) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showEmojiPicker]);



    useEffect(() => {
        if (videoFile?.urls?.emoji_predicted_file) {
            axios.get(videoFile?.urls?.emoji_predicted_file).then(({ data }) => {
                setSubtitleEmoji(data)
            })
        }
        if (videoFile?.urls?.gif_predicted_file) {
            axios.get(videoFile?.urls?.gif_predicted_file).then(({ data }) => {
                setSubtitleGif(data)
            })
        }
    }, [videoFile])


    const handleTextChange = (text: string, index: number) => {
        if (disabled) {
            toast.success("This feature is coming soon.", {
                id: "feature",
            })
            return
        }
        if (subtitleJSON.segments[index].text !== text)
            setSubtitleEditable({ ...subtitleEditable, [videoFile?.video_id || ""]: true })
        else {
            setSubtitleEditable({ ...subtitleEditable, [videoFile?.video_id || ""]: false })
        }
        const newEditingText = [...editingText];
        newEditingText[index] = text;
        setEditingText(newEditingText);
    };

    const handleBlur = (index: number) => {
        if (disabled) {
            toast.success("This feature is coming soon.", {
                id: "feature",
            })
            return
        }
        let tempSubtitleJSon = JSON.parse(JSON.stringify(subtitleJSON));
        tempSubtitleJSon.segments[index].text = editingText[index];
        setSubtitleJSON(tempSubtitleJSon);
        // let form = new FormData();
        // form.append("updated_json_data", JSON.stringify(tempSubtitleJSon));
        // form.append("video_id", videoFile?._id as string);
        // form.append("user_id", videoFile?.user_id as string);
        // dispatch(updateSubtitleJson({ form: { updated_json_data: tempSubtitleJSon }, videoId: videoFile?._id, userId: videoFile?.user_id }));
    };


    const onEmojiClick = (event: any, emojiObject: any, i: number) => {
        if (disabled) {
            toast.success("This feature is coming soon.", {
                id: "feature",
            })
            return
        }

        setChosenEmoji(emojiObject);

        const unified = event.unified as string; // Cast to string if you're sure it's a string
        const emojiKey = `_u${unified}`;
        let subEmoji = { ...subtitleEmoji };

        // TypeScript should now be okay with this because of the index signature in EmojiDictionary
        let findEMJ: EmojiInfo | undefined = emojiData[emojiKey];
        if (!findEMJ) {
            console.error(`No emoji found for key: ${emojiKey}`);
            return;
        }

        // Rest of your code using findEMJ...
        if (findEMJ) {
            // Make sure subtitleEmoji and its segments are not undefined before assignment
            if (subEmoji && subEmoji.segments) {
                // If the segment at index i does not exist, initialize it
                // if (!subEmoji.segments[i]) {
                //     subEmoji.segments[i] = { emoji_uri: '', keywords: [], unicode: '' };
                // }

                setSubtitleEditable({ ...subtitleEditable, [videoFile?.video_id || ""]: true })


                // Now it's safe to assign the emoji URI
                subEmoji.segments[i].emoji_uri = findEMJ.URL;
                subEmoji.segments[i].emoji_key = `emoji${emojiKey}`;
                setSubtitleEmoji(subEmoji)
                setShowEmojiPicker(-1)
            }
        }
    };

    const updateCaption = () => {
        if (disabled) {
            toast.success("This feature is coming soon.", {
                id: "feature",
            })
            return
        }

        // let form = new FormData();
        // form.append("updated_transcription_data", JSON.stringify(subtitleJSON));
        // form.append("updated_emoji_data", JSON.stringify(subtitleEmoji));
        // form.append("updated_gif_data", JSON.stringify(subtitleGif));

        let newTemp = {
            text: subtitleJSON.text,
            segments: subtitleJSON.segments
        }


        dispatch(updateAICaptionSubtitle({
            form: {
                updated_transcription_data: newTemp,
                updated_emoji_data: subtitleEmoji,
                // "updated_gif_data": subtitleGif
            }, videoId: videoFile?.video_id.split("-")?.[0], userId: videoFile?.user_id
        }));
    }

    return (
        <>
            <TitleHead name={"Transcription"} />
            <Grid sx={{ backgroundColor: "white", padding: 3, }} md={12}>
                {/* <div>
                    <div>
                        <p className='text-main'>Autosaved</p>
                    </div>
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>    <p className='title-header mb-3'></p>
                    {subtitleEditable[videoFile?.video_id || ""] && !disabled &&
                        <div onClick={updateCaption} className="apply-button box-shadoww">
                            <p className='text'>
                                Apply Changes
                            </p>
                        </div>
                    }
                </div>
                <div
                    className={`justify-between subtitle-text`}
                    id="subtitle-div"
                    style={{
                        height: (videoFile?.resolution?.[1] || videoFile?.video_height) ? (videoFile?.resolution?.[1] || videoFile?.video_height || 0) - 100 : 0,
                        overflowY: "auto",
                        minHeight: "600px",
                        paddingTop: 20
                    }}
                >
                    {subtitleJSON?.segments.map(({ text, start, end }: any, i: number) =>
                        <div key={text} style={{ width: '100%', alignContent: 'center', justifyContent: 'center', position: 'relative', marginBottom: 20 }} >

                            <div style={{ position: 'absolute', top: -10, justifyContent: 'space-between', width: "100%" }} className='display-flex'>
                                <div className='display-flex'>   <div style={{ width: "10px" }}></div>
                                    <TimeInputComponent value={start} id="start" onChange={() => { }} />
                                    <div style={{ width: "10px" }}></div>
                                    <TimeInputComponent value={end} id="end" onChange={() => { }} />
                                </div>
                                <div style={{}}>
                                    <IconButton style={{
                                        width: "34px",
                                        height: "34px",
                                        backgroundColor: "#F2F2F2"

                                    }}
                                        disabled={visuals !== 'emojis'}
                                        onClick={() => {
                                            setShowEmojiPicker(showEmojiPicker == i ? -1 : i)
                                        }}>
                                        {subtitleEmoji?.segments?.[i]?.emoji_uri ? (
                                            <img width="20" height="20" src={subtitleEmoji.segments[i].emoji_uri} alt="Emoji" />
                                        ) : (
                                            <InsertEmoticonIcon />
                                        )}
                                    </IconButton>
                                    {showEmojiPicker == i && (
                                        <div ref={emojiPickerRef} style={{ position: 'static' }}>
                                            <EmojiPicker
                                                onEmojiClick={(e, a) => onEmojiClick(e, a, i)}
                                                emojiStyle={EmojiStyle.GOOGLE}
                                                skinTonesDisabled
                                                style={{ zIndex: 10000 }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="subtitle-input-div" style={{ width: '100%', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                                <input
                                    onFocus={() => {
                                        handleTextChange(text, i)
                                        if (mediaPlayerRef && mediaPlayerRef.current)
                                            mediaPlayerRef.current.pause()
                                    }}
                                    onChange={(e) => handleTextChange(e.target.value, i)}
                                    onBlur={() => handleBlur(i)}

                                    className='no-hover-border subtitle-input-text'
                                    style={{
                                        width: `100%`,
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        height: "50px",
                                        outline: 'none'
                                    }}
                                    id={`subtitle-input-${i}`}
                                    value={editingText[i] || text}
                                />
                            </div>
                        </div>)}
                </div>
            </Grid>
        </>
    )
}

export default Subtitle;