/* eslint-disable object-shorthand */
/* eslint-disable no-else-return */
import { Sequence, OffthreadVideo } from 'remotion';

const normalizeVolume = (loudness: any) => {
    const targetLoudness = -6; // Target loudness in LUFS or similar unit
    const adjustment = targetLoudness - loudness;
    return Math.pow(10, adjustment / 20); // Convert adjustment to a linear volume scale
  };

// Light Leak Effect Component
export const LightLeakEffect = ({ src, start, duration }: any) => {

    // const transition = normalizeVolume(8.5); // Perfect
    const transition = normalizeVolume(9.5); // test
    return (
      <Sequence from={start} durationInFrames={duration} layout="none">
        <OffthreadVideo
          src={src}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            opacity: 0.65,
            zIndex: 100, // Ensuring it's above other components
          }}
          transparent
          volume={transition} // No sound for light leak effect
        />
      </Sequence>
    );
  };