import { useCurrentFrame, useVideoConfig, interpolate } from 'remotion';



export const ShakingText = ({text, start, end}:{text:any; start:any; end:any;}) => {
  const frame = useCurrentFrame();
  const { fps, width } = useVideoConfig();
  
  // Responsive font size calculation
  const fontSize = Math.max(70, width * 0.03); // Minimum of 16px or 3% of viewport width
  // Define key frames for animations
  const startFadeInFrame = 0*fps; // Starts immediately
  const endFadeInFrame = startFadeInFrame+0.15 * fps; // Lasts until the end of the first second
  const startZoomFrame = startFadeInFrame+0.15 * fps; // Starts zoom at 1 second
  const endZoomFrame = startZoomFrame + 0.25 * fps; // Ends zoom at 1.5 seconds

  // Calculate opacity, translateY, scale, and trembling effects
  const opacity = interpolate(frame, [startFadeInFrame, endFadeInFrame], [0, 1], { extrapolateLeft: 'clamp', extrapolateRight: 'clamp' });
  const translateY = interpolate(frame, [startFadeInFrame, endFadeInFrame], [100, 0], { extrapolateLeft: 'clamp', extrapolateRight: 'clamp' });
  const scale = interpolate(frame, [startZoomFrame, endZoomFrame], [1, 1.25], { extrapolateLeft: 'clamp', extrapolateRight: 'clamp' });
  const trembleIntensity = fps / 40;
  const trembleX = Math.sin(frame * 0.4) * trembleIntensity;
  const trembleY = Math.cos(frame * 0.4) * trembleIntensity;

  const style = {
    fontFamily: 'Komika Axis, Arial, sans-serif',
    fontSize: `${fontSize}px`,
    fontWeight: 700,
    color: '#fff',
    letterSpacing: '-0.01em',
    textTransform: 'uppercase',
    textShadow: 
    `0 0 0.15em #1da9cc,
    0 0 5px #fff,
    0 0 40px #00FFFFB8,
    0 0 80px #FFF,
    0 0 90px #0FF,
    0 0 100px #FFF

    `,
    userSelect: 'none',
    position: 'absolute',
    WebkitTextStroke: '2.75px #000',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, ${translateY}%) scale(${scale}) translate(${trembleX}px, ${trembleY}px)`,
    opacity: opacity,
    zIndex: 1090,
    width: '70%',
    textAlign: 'center',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word', // Add this line
    wordBreak: 'break-word'
    
  } as any;

  return <div style={style}>{text}</div>;
};



export const FlickeringText = ({text, start, end}:{text:any; start:any; end:any;}) => {
  const frame = useCurrentFrame();
  const { fps, width } = useVideoConfig();

  // Responsive font size calculation
  const fontSize = Math.max(70, width * 0.02); // Minimum of 16px or 2% of viewport width

  // Define key frames for animations
  const stompEndFrame = 0.25 * fps; // 0.5 seconds duration for the stomp effect

  // Calculate scale for stomp effect
  const scale = interpolate(frame, [0, stompEndFrame], [0.05, 1], {extrapolateLeft: 'clamp', extrapolateRight: 'clamp'});

  // Calculate flicker effect
  const flickerFrames = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80];
  const isFlickering = flickerFrames.includes(frame % 50);
  const color = isFlickering ? "#FF2F00" : "#AB0000"; // Flicker between dark red and firebrick
  const opacity = isFlickering ? 0.7 : 1; // Flicker opacity

  // Jitter effect
  const jitterX = Math.sin(frame / 3) * 2;
  const jitterY = Math.cos(frame / 3) * 2;

  const style = {
    position: "absolute",
    zIndex: 1090,
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%) scale(${scale}) translate(${jitterX}px, ${jitterY}px)`,
    fontFamily: "Komika Axis",
    fontWeight: "bold",
    color: color,
    width: "75%",
    fontSize: `${fontSize}px`,
    textAlign: "center", 
    textShadow: `
        0 0 15px rgba(255, 255, 255, 0.8),
        0 0 20px rgba(255, 255, 255, 0.6),
        0 0 20px rgba(255, 255, 255, 0.7),
        0 0 25px rgba(139, 0, 0, 0.8),
        0 0 30px rgba(139, 0, 0, 0.6),
        0 0 60px rgba(255, 255, 255, 0.6),
        0 0 70px rgba(255, 255, 0, 0.6),
        0 0 95px rgba(255, 255, 255, 0.6),
        0 0 120px rgba(255, 255, 0, 0.45),
        0 0 50px rgba(255, 255, 255, 0.6)
    `, // Enhanced white and dark red shadow
    letterSpacing: "0.07em", // Added letter spacing
    opacity: opacity,
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word', // Add this line
    wordBreak: 'break-word'    
  } as any;

  return <div style={style}>{text}</div>;
};