import { useEffect } from 'react';
import { Modal, Box, Typography, useMediaQuery, useTheme, } from '@mui/material'
import DragDropFile from './dragDrop';

import CloseIcon from '@mui/icons-material/Close';

const DragDropModal = ({ open, setOpen, currentTranscribe, submitVideo, setOpenDrag, submitAiStudio }: any) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%',
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...(isMobile ? { width: '90%' } : currentTranscribe === "AI Shorts" && { width: '60%' })
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)", // This is the CSS property that blurs the background
                }
            }}
        >
            <Box sx={style}>
                <div style={{ position: 'absolute', right: 20, top: 15 }}>
                    <CloseIcon className="close-icon" onClick={() => setOpen(false)} />
                </div>
                <DragDropFile setOpenDrag={setOpenDrag} currentTranscribe={currentTranscribe} submitVideo={submitVideo} submitAiStudio={submitAiStudio} />
            </Box>
        </Modal>
    );
}

export default DragDropModal;
