import React, { useState, useRef, useEffect, ChangeEvent, DragEvent, useContext } from 'react';
import './style.css'
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from '@ffmpeg/util'
import { Grid, IconButton, TextField, useMediaQuery, useTheme } from '@mui/material';
import { runInAction, observable, autorun } from 'mobx';
import DownloadIcon from '@mui/icons-material/Download';

import { RootState } from '../../redux/store';

import { useAppSelector } from '../../hooks/redux';

import { DashboardContext } from '../../context/dashboard.context';
import CustomizedButtons from '../Button';

import { VideoTrim } from '../TrimVideo/VideoTrim';
import MainStore from '../../utils/videoMain';
import { observer } from 'mobx-react';
import { downloadVideo } from '../../utils/download';
import toast from 'react-hot-toast';
import LanguageModal from '../LanguageModal';
import { GOOGLE_FONTS_API_KEY } from '../../utils/url';
import StudioModal from '../StudioModal';
import httpApi from '../../utils/httpApi';

const getYoutubeVideoId = (url: string) => {
  const videoIdMatch = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
  const shortIdMatch = url.match(/(?:youtube\.com\/shorts\/)([a-zA-Z0-9_-]{11})/);
  return videoIdMatch ? videoIdMatch[1] : shortIdMatch ? shortIdMatch[1] : null;
};

const validateYoutubeUrl = async (url: string) => {
  const videoId = getYoutubeVideoId(url);
  if (!videoId) {
    return { isValid: false, thumbnailUrl: null };
  }

  const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${GOOGLE_FONTS_API_KEY}&part=snippet`);
  const data = await response.json();

  if (data.items && data.items.length > 0) {
    const thumbnailUrl = data.items[0].snippet.thumbnails.high.url; // Get high quality thumbnail
    return { isValid: true, thumbnailUrl: thumbnailUrl };
  } else {
    return { isValid: false, thumbnailUrl: null };
  }
};
const mobxMainStores = observable([]);

// drag drop file component
const DragDropFile = ({ remain, remainingMinutes, currentTranscribe, submitVideo, setOpenDrag, submitAiStudio }: any): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const storageStats = useAppSelector((state: RootState) => state.video.storageStats)
  const recentProject = useAppSelector((state: RootState) => state.video.recentProject)

  const ffmpegRef = useRef(new FFmpeg());
  const videoTrimRef = useRef<HTMLVideoElement>(null)
  // drag state
  const [dragActive, setDragActive] = useState<boolean>(false);

  const contextValue = useContext(DashboardContext);

  if (!contextValue) {
    // handle the case where the context value is not provided
    throw new Error("VideoContext value is not provided");
  }

  const { inputRef, setUploadTransModal, file, setFile, videoRef, userId } = contextValue


  const [startTimes, setStartTimes] = useState<number[]>([]);
  const [endTimes, setEndTimes] = useState<number[]>([]);

  const [videoFiles, setVideoFiles] = useState<File[]>([]);
  const [videoSources, setVideoSources] = useState<MainStore[]>([]);
  const [videoDurations, setVideoDurations] = useState<number[]>([]);
  const [videoTrimmedUrls, setVideoTrimmedUrls] = useState<string[]>([]);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [draggedIndexVideo, setDraggedIndexVideo] = useState<number | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<any | null>(null);
  const [trimmerVideoData, setTrimmerVideoData] = useState<any>([])
  const [mainStores, setMainStores] = useState<MainStore[]>([]);
  const [videoLoading, setVideoLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [ytUrl, setYtUrl] = useState("")
  const [isValid, setIsValid] = useState<any>({
    isValid: false, thumbnailUrl: null
  });
  const [studioModal, setStudioModal] = useState<any>({
    name: null,
    modal: false
  })

  useEffect(() => {
    // Synchronize MobX observable with React state
    const disposer = autorun(() => {
      setMainStores(mobxMainStores.slice());
    });

    // Cleanup
    return () => disposer();
  }, []);

  const handleDragStartVideo = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragOverVideo = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Necessary for allowing a drop
  };

  const handleDrop = (index: number) => {
    if (draggedIndexVideo === null) return;

    const newVideoSources = [...videoSources];
    [newVideoSources[draggedIndexVideo], newVideoSources[index]] = [newVideoSources[index], newVideoSources[draggedIndexVideo]];

    setVideoSources(newVideoSources);
    setDraggedIndex(null); // Reset the dragged item index
  };

  let initialSliderValue: number = 0;

  const TranscribeMaxDuration = () => {
    switch (currentTranscribe) {
      case "ViralMe Studio":
        return 150;
      case "AI Captions":
        return 30 * 60;
      default:
        return 30 * 60
    }
  }

  useEffect(() => {
    if (currentTranscribe == "AI Shorts") {
      if (mainStores.length > 0)
        setStep(1)
      else
        setStep(0)
    }
    else {
      setStep(1)
    }
  }, [currentTranscribe, mainStores])


  const handleFileUpload = async (files: FileList) => {
    const uploadedFiles = Array.from(files); // Convert FileList to an array
    const maxSize = 500 * 1024 * 1024; // 50 MB size limit (example)
    const maxDuration = TranscribeMaxDuration() // 300 seconds (5 minutes) duration limit (example)

    // Filter valid files
    const validFiles = await Promise.all(
      uploadedFiles.map(async (file) => {
        if (file.size > maxSize) {
          toast.error(`file is too large. Maximum size is ${maxSize / 1024 / 1024} MB.`)

          console.error(`${file.name} is too large. Maximum size is ${maxSize / 1024 / 1024} MB.`);
          return null;
        }

        if (file.type.includes('video')) {
          const duration = await getVideoDuration(file);
          if (duration > maxDuration) {
            toast.error(`file is too long. Maximum duration is ${maxDuration} seconds.`)
            console.error(`${file.name} is too long. Maximum duration is ${maxDuration} seconds.`);
            return null;
          }
        }

        return file;
      })
    );

    // Remove invalid files (null values)
    const filteredFiles = validFiles.filter((file): file is File => file !== null);


    // Append new files to the existing videoFiles array
    setVideoFiles(prevFiles => [...prevFiles, ...uploadedFiles]);
    // setVideoFiles(prevFiles => [...filteredFiles]);

    // Create MainStore instances for each new file
    const newMainStores = filteredFiles.map(file => {
      let construct = new MainStore()
      if (file.type.includes('video')) {
        construct.loadVideo(file)
      }
      else {
        construct.setFile(file)
      }
      return construct
    });

    // Append new MainStore instances to the existing array
    setMainStores(prevStores => [...prevStores, ...newMainStores])
    // setMainStores(prevStores => [...newMainStores])
  };

  useEffect(() => {
    if (mainStores.length > 0) {
      setTimeout(() => {
        setSelectedVideo(mainStores.length - 1)
      }, 1000);
    }
  }, [mainStores, mainStores.length]); // This useEffect will trigger whenever mainStores changes



  const convertToHHMMSS = (val: string): string => {
    const secNum = parseInt(val, 10);
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;

    let hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
    let minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    let secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }

  const handleUploadAndCombine = async (isDownload = false) => {
    setVideoLoading(true)

    let combinedVideoBlob = null;
    let haveTrimVideo = false;

    // Loop through each store entry
    // const videosToProcess = mainStores.map(async ({ transform, file }) => {
    //   if (transform && transform.time) {
    //     // If transform.time exists, trim the video
    //     haveTrimVideo = true;
    //     let vidTrim = await handleTrim(file, transform.time[0], transform.time[1]) as any
    //     return vidTrim.blob
    //   } else {
    //     return fileToBlob(file)
    //       .then(blob => {
    //         // Use the Blob
    //         return blob
    //       })
    //       .catch(error => {
    //         // Handle errors
    //         console.error('Error:', error);
    //       });
    //   }
    // });
    // Resolve all the promises (trimmed and untrimmed video files)
    // const processedVideos = await Promise.all(videosToProcess);

    // Check if there are multiple videos or if trimming was applied
    if (mainStores.length > 1) {
      combinedVideoBlob = await combineVideos(mainStores as any) as Blob
    } else {
      combinedVideoBlob = mainStores[0].file
    }

    if (combinedVideoBlob) {
      if (isDownload) {
        downloadVideo(URL.createObjectURL(combinedVideoBlob))
        setOpenDrag(false)
        setVideoLoading(false)

      }
      else if (currentTranscribe == 'Reduce Video Size') {
        let newblob = await compressedVideo(combinedVideoBlob)
        if (newblob) {
          downloadVideo(URL.createObjectURL(newblob))
          // await handleFiles(newblob);
        }
      }
      else await handleFiles(combinedVideoBlob);
    }
  };

  const compressedVideo = async (file: Blob) => {
    try {
      // Initialize FFmpeg if not already initialized
      let ffmpeg = ffmpegRef.current;

      // Fetch the input file and write it to the FFmpeg filesystem
      await ffmpeg.writeFile('input.mp4', await fetchFile(file));
      console.log("1");

      // Run the FFmpeg command to compress the video
      await ffmpeg.exec([
        '-i', 'input.mp4',
        '-vcodec', 'libx265',
        '-preset', 'fast', // Use a faster preset
        '-crf', '28',      // Adjust CRF for a balance between quality and compression
        '-vf', 'scale=-2:720', // Downscale to 720p (adjust as necessary)
        'output.mp4'
      ]);
      console.log("2");

      // Read the output file from the FFmpeg filesystem
      const data = await ffmpeg.readFile('output.mp4');
      console.log("3");

      // Create a Blob URL for the output video
      const blob = new Blob([data], { type: 'video/mp4' });
      return blob;
    } catch (err) {
      console.error(err);
    } finally {
      setVideoLoading(false);
    }
  }


  //Trim functionality of the video
  const handleTrim = async (videoFile: any, startTime: number, endTime: number) => {

    try {
      let ffmpeg = ffmpegRef.current;
      // if (!ffmpeg.loaded) {
      //   await ffmpeg.load();
      // }
      const { name, type } = videoFile;

      // Write the video file to FFmpeg's filesystem
      await ffmpeg.writeFile(name, await fetchFile(videoFile));

      const videoFileType = type.split('/')[1];

      // Execute FFmpeg command to trim the video
      await ffmpeg.exec([
        '-i', name,
        '-ss', `${convertToHHMMSS(startTime.toString())}`,
        '-to', `${convertToHHMMSS(endTime.toString())}`,
        '-c:v', 'libx264',
        '-crf', '30',
        `trimmed_${name}`
      ]).catch((e) => console.log(e));

      // Read the trimmed file
      const trimmedData = await ffmpeg.readFile(`trimmed_${name}`);

      // Convert the trimmed data into a Blob
      const trimmedBlob = new Blob([trimmedData], { type });

      // Convert the trimmed data to a URL and update state
      const trimmedUrl = URL.createObjectURL(new Blob([trimmedData], { type }));

      setVideoTrimmedUrls(prevUrls => {
        const updatedUrls = [...prevUrls];
        updatedUrls.push(trimmedUrl)
        return updatedUrls;
      });

      return {
        blob: trimmedBlob,
        url: trimmedUrl,
        name: `trimmed_${name}`,
        type
      };


    } catch (error) {
      setVideoLoading(false)
      console.error('Error trimming video:', error);
      // Handle the error appropriately
    }
  }

  // const combineVideos = async (mainstore: any) => {
  //   const ffmpeg = ffmpegRef.current;

  //   try {
  //     const firstResolution = { width: 1280, height: 720 };
  //     // Prepare file processing
  //     const promises = mainstore.map(async ({ file, transform }: any, index: number) => {
  //       const arrayBuffer = await file.arrayBuffer();
  //       const fileName = `file${index}.mp4`;
  //       const outputFileName = `file${index}-30fps.mp4`;

  //       ffmpeg.writeFile(fileName, new Uint8Array(arrayBuffer));

  //       if (transform && transform.time) {
  //         await ffmpeg.exec([
  //           '-i', fileName,
  //           '-ss', `${convertToHHMMSS(transform.time[0].toString())}`,
  //           '-to', `${convertToHHMMSS(transform.time[1].toString())}`,
  //           '-c:v', 'libx264',
  //           '-crf', '30',
  //           "-vf",
  //           `scale=${firstResolution.width}:${firstResolution.height}:force_original_aspect_ratio=decrease,pad=${firstResolution.width}:${firstResolution.height}:(ow-iw)/2:(oh-ih)/2,fps=30`,
  //           outputFileName
  //         ])
  //       }
  //       else {
  //         await ffmpeg.exec(
  //           ["-i",
  //             fileName,
  //             "-vf",
  //             `scale=${firstResolution.width}:${firstResolution.height}:force_original_aspect_ratio=decrease,pad=${firstResolution.width}:${firstResolution.height}:(ow-iw)/2:(oh-ih)/2,fps=30`,
  //             outputFileName]
  //         );
  //       }
  //     });

  //     await Promise.all(promises);

  //     const fileListContent = mainstore
  //       .map(({ file }: any, index: number) => `file 'file${index}-30fps.mp4'`)
  //       .join("\n");
  //     ffmpeg.writeFile("fileList.txt",
  //       new TextEncoder().encode(fileListContent)
  //     );

  //     await ffmpeg.exec(["-f",
  //       "concat",
  //       "-safe",
  //       "0",
  //       "-i",
  //       "fileList.txt",
  //       "-c",
  //       "copy",
  //       "output.mp4"]);

  //     const data = await ffmpeg.readFile("output.mp4");
  //     const outputBlob = new Blob([data], { type: "video/mp4" });
  //     return outputBlob;
  //   } catch (error) {
  //     console.error("Error combining videos together:", error);
  //     return null;
  //   }
  // };

  const combineVideos = async (mainstore: any) => {
    const ffmpeg = ffmpegRef.current;

    try {
      const firstResolution = { width: 1280, height: 720 };

      // Prepare file processing
      const promises = mainstore.map(async ({ file, transform }: any, index: number) => {
        const arrayBuffer = await file.arrayBuffer();
        const fileName = `file${index}.mp4`;
        const outputFileName = `file${index}-30fps.mp4`;

        ffmpeg.writeFile(fileName, new Uint8Array(arrayBuffer));

        const inputOptions = ['-i', fileName];
        const trimOptions = transform && transform.time ? [
          '-ss', `${convertToHHMMSS(transform.time[0].toString())}`,
          '-to', `${convertToHHMMSS(transform.time[1].toString())}`
        ] : [];
        const outputOptions = [
          '-c:v', 'libx264',
          '-crf', '30',
          '-vf', `scale=${firstResolution.width}:${firstResolution.height}:force_original_aspect_ratio=decrease,pad=${firstResolution.width}:${firstResolution.height}:(ow-iw)/2:(oh-ih)/2,fps=30`,
          outputFileName
        ];

        await ffmpeg.exec([...inputOptions, ...trimOptions, ...outputOptions]);
      });

      await Promise.all(promises);

      const fileListContent = mainstore
        .map(({ file }: any, index: number) => `file 'file${index}-30fps.mp4'`)
        .join("\n");
      ffmpeg.writeFile("fileList.txt", new TextEncoder().encode(fileListContent));

      await ffmpeg.exec([
        "-f", "concat",
        "-safe", "0",
        "-i", "fileList.txt",
        "-c", "copy",
        "output.mp4"
      ]);

      const data = await ffmpeg.readFile("output.mp4");
      const outputBlob = new Blob([data], { type: "video/mp4" });
      return outputBlob;
    } catch (error) {
      console.error("Error combining videos together:", error);
      return null;
    }
  };

  useEffect(() => {
    load()
  }, []);

  const load = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('log', ({ message }) => { });
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });
  }


  //Get the duration of the video using videoRef
  useEffect(() => {
    const newDurations = videoSources.map((src, index: number) => {
      if (videoDurations[index]) return videoDurations[index]; // Skip if already set

      const videoElement = document.createElement('video');
      videoElement.src = String(src);
      videoElement.onloadedmetadata = () => {
        const updatedDurations = [...videoDurations];
        updatedDurations[index] = videoElement.duration;
        setVideoDurations(updatedDurations);
      };
      return 0; // Initialize with a default value
    });

    setVideoDurations(prevDurations => [...prevDurations, ...newDurations]);
  }, [videoSources]);

  //Called when handle of the nouislider is being dragged
  const updateOnSliderChange = (values: any, handle: any, videoIndex: number) => {
    // Clear the trimmed URL for the specific video
    setVideoTrimmedUrls(prevUrls => {
      const updatedUrls = [...prevUrls];
      updatedUrls[videoIndex] = '';
      return updatedUrls;
    });

    let readValue = values[handle] | 0;

    // Update the start or end time based on the handle
    if (handle === 0) { // First handle (start time)
      setStartTimes(prevStartTimes => {
        const updatedStartTimes = [...prevStartTimes];
        updatedStartTimes[videoIndex] = readValue;
        return updatedStartTimes;
      });
    } else if (handle === 1) { // Second handle (end time)
      setEndTimes(prevEndTimes => {
        const updatedEndTimes = [...prevEndTimes];
        updatedEndTimes[videoIndex] = readValue;
        return updatedEndTimes;
      });
    }
  };

  //Play the video when the button is clicked
  const handlePlay = () => {
    if (videoRef && videoRef.current) {
      videoRef.current.play();
    }
  };
  // handle drag events
  const handleDrag = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDropVideo = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      let newFile = e.dataTransfer.files
      handleFileUpload(newFile as any);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let newFile = e.target.files
      handleFileUpload(newFile as any);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };



  const handleFiles = async (file: Blob) => {
    // // if (!storageStats) return

    // // if (!userId) return
    // console.log("step2")
    // const fileSizeInMB = file.size / 1024 / 1024; // Convert file size to MB

    // let cums = recentProject?.reduce((sum: any, obj: any) => sum + obj.size, 0); // If storageStats?.size_in_MB is undefined, use 0 as default value

    // const totalSize = Math.round(cums / (1024 * 1024)) + fileSizeInMB;

    setFile(file)
    if (currentTranscribe == 'AI Shorts') {
      checkFileDuration(file)
    } else {
      submitVideo(file)
    }
    setVideoLoading(false)
    return
  };

  const checkFileDuration = (file: any) => {
    const video = document.createElement('video');
    const url = URL.createObjectURL(file);

    video.src = url;

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      const duration = video.duration / 60; // Duration in minutes

      if (duration <= 3) {
        toast.error("File size must be greater than 3 minutes");
      } else {
        submitAiStudio({ type: "custom" })
      }
    };
  };

  function getVideoDuration(file: any): any {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');

      video.onloadedmetadata = () => {
        // const duration = video.duration;
        // const minutes = Math.floor(duration / 60);
        // const seconds = Math.floor(duration % 60);
        // const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        resolve(video.duration);
      };

      video.onerror = () => {
        reject(new Error('Failed to load video'));
      };

      video.src = URL.createObjectURL(file);
    });
  }


  function fileToBlob(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target && event.target.result) {
          // Convert the file to a Blob
          const result = event.target.result as ArrayBuffer;
          const blob = new Blob([result], { type: file.type });
          resolve(blob);
        } else {
          reject(new Error('Failed to read the file'));
        }
      };

      reader.onerror = () => {
        reject(new Error('Failed to read the file'));
      };

      // Read the file as an ArrayBuffer
      reader.readAsArrayBuffer(file);
    });
  }


  const getVideosToCombine = () => {
    return videoFiles.map((file, index: number) => {
      // If there's a trimmed version of the video, use it; otherwise, use the original
      const trimmedUrl = videoTrimmedUrls[index];
      return trimmedUrl ? { url: trimmedUrl, isBlobUrl: true } : { file, isBlobUrl: false };
    });
  };



  // const handleUploadAndCombine = async () => {
  //   const videosToCombine = getVideosToCombine();
  //   const combinedVideoBlob = await combineVideos(videosToCombine);
  //   if (combinedVideoBlob) {
  //     await handleFiles(combinedVideoBlob);
  //   }
  // };

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault(); // Necessary for allowing a drop
  };

  // const handleDrop = (index: number) => {
  //   if (draggedIndex === null) return;

  //   // Reorder the videos
  //   const newVideoSources = [...videoSources];
  //   const newVideoFiles = [...videoFiles];
  //   const newVideoDurations = [...videoDurations];
  //   const newVideoTrimmedUrls = [...videoTrimmedUrls];

  //   // Swap the elements
  //   [newVideoSources[draggedIndex], newVideoSources[index]] = [newVideoSources[index], newVideoSources[draggedIndex]];
  //   [newVideoFiles[draggedIndex], newVideoFiles[index]] = [newVideoFiles[index], newVideoFiles[draggedIndex]];
  //   [newVideoDurations[draggedIndex], newVideoDurations[index]] = [newVideoDurations[index], newVideoDurations[draggedIndex]];
  //   [newVideoTrimmedUrls[draggedIndex], newVideoTrimmedUrls[index]] = [newVideoTrimmedUrls[index], newVideoTrimmedUrls[draggedIndex]];

  //   // Update state
  //   setVideoSources(newVideoSources);
  //   setVideoFiles(newVideoFiles);
  //   setVideoDurations(newVideoDurations);
  //   setVideoTrimmedUrls(newVideoTrimmedUrls);

  //   setDraggedIndex(null); // Reset the dragged item index
  // };


  const deleteVideoFromBoth = (index: number) => {
    // Filter out the video at the given index from videoFiles
    const newVideoFiles = videoFiles.filter((_, idx) => idx !== index);
    setVideoFiles(newVideoFiles);

    // Filter out the video at the given index from videoSources
    const newVideoSources = videoSources.filter((_, idx) => idx !== index);
    setVideoSources(newVideoSources);

    // If you have other related states (like durations, trimmed URLs, etc.), make sure to update them as well
    const newVideoDurations = videoDurations.filter((_, idx) => idx !== index);
    setVideoDurations(newVideoDurations);

    const newVideoTrimmedUrls = videoTrimmedUrls.filter((_, idx) => idx !== index);
    setVideoTrimmedUrls(newVideoTrimmedUrls);
  };

  const handleVideoSelect = (source: any) => {
    setSelectedVideo(source);
  };

  const handleTrimValuesChange = (start: number, end: number) => {
    // Handle trim value change
    // Update the video player or prepare to trim the video
    // make a state and add source and start and end time
    // find
    let trimmerVideoDataTemp = [...trimmerVideoData]
    let ind = trimmerVideoDataTemp.findIndex((item: any) => item.source == selectedVideo)
    if (ind > -1) {
      trimmerVideoDataTemp[ind].start = start
      trimmerVideoDataTemp[ind].end = end
      setTrimmerVideoData(trimmerVideoDataTemp)
    }
    else {
      trimmerVideoDataTemp.push({ source: selectedVideo, start, end })
      setTrimmerVideoData(trimmerVideoDataTemp)
    }
  };



  //   useEffect(() => {
  //     vidources.forEach((source, index) => {
  //       captureThumbnail(source).then((thumbnailUrl: any) => {
  //   // setVideoThumbnails(prevThumbnails => ({
  //   //   ...prevThumbnails,
  //   //   [source]: thumbnailUrl,
  //   // }));
  // })
  //         .catch(error => {
  //           console.error('Error capturing thumbnail:', error);
  //         });
  //     });
  //   }, [videoSources])

  const getIndexWithTimmerVideo = () => {
    return trimmerVideoData.findIndex((item: any) => item.source == selectedVideo)

  }

  const downloadCurrentVideo = async () => {
    if (selectedVideo == null) return
    let { file, transform } = mainStores[selectedVideo]
    if (transform && transform.time) {
      let vidTrim = await handleTrim(file, transform.time[0], transform.time[1]) as any
      // vidTrim has url download that video
      downloadVideo(vidTrim?.url)
    }
  }

  const handleChangeYT = async (e: any) => {
    const url = e.target.value;
    setYtUrl(url);

    const result = await validateYoutubeUrl(url);
    if (result.isValid) {
      console.log("Valid YouTube URL!");
      console.log("Thumbnail URL:", result.thumbnailUrl);
      setIsValid(result)

    } else {
      console.log("Invalid YouTube URL.");
      setIsValid(result)
    }
    const valid = await validateYoutubeUrl(url);

    if (!valid) toast.error("Invalid youtube url")
  };

  const aistudioFunc = (name: string) => {
    setStudioModal({
      name: name,
      modal: false
    })
  }


  return (
    <>
      {step == 1 ?
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>

          {/* <img src="/upload_file.png" /> */}
          {/* {file && <video control src={URL.createObjectURL(file)}></video>} */}
          {currentTranscribe == "ViralMe Studio" && <p className='note-drag-text'>it will take approx 30 minutes to edit the video and less than 150 second video is recommended.*</p>}
          <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // Changes layout based on screen size
            marginTop: 30
          }}>
            <div style={{
              display: 'flex',
              flexDirection: isMobile ? 'row' : 'column',
              border: '1px solid #00A3FF',
              borderRadius: 10,
              padding: 10,
              marginRight: isMobile ? 0 : 10, // Adjust margin for mobile view
              minWidth: isMobile ? '100%' : 140, // Adjust width for mobile view
              justifyContent: 'space-between'

            }}>
              <div style={{
                display: 'flex',
                // flexWrap: 'wrap',
                flexDirection: isMobile ? 'row' : 'column',
                borderRadius: 10,
                padding: 10,
                marginRight: isMobile ? 0 : 10, // Adjust margin for mobile view
                minWidth: isMobile ? '100%' : 140, // Adjust width for mobile view
                alignItems: 'center',
                overflowY: isMobile ? 'hidden' : 'auto', // Vertical scroll for desktop view
                overflowX: isMobile ? 'auto' : 'hidden', // Horizontal scroll for mobile view
                maxHeight: isMobile ? 'auto' : '400px' // Set maxHeight for scrolling in desktop view
              }}>
                {mainStores?.map((videoCons: any, index) => {
                  return (
                    <div
                      key={"videoCons" + index}
                      style={{
                        border: selectedVideo === index ? '2px solid gray' : 'white',
                        borderRadius: 10,
                        marginBottom: 10,
                        marginLeft: 10,
                        width: isMobile ? 60 : 130
                      }}
                      draggable
                      onDragStart={() => handleDragStart(index)}
                      onDragOver={handleDragOver}
                      onDrop={() => handleDrop(index)}
                      onClick={() => handleVideoSelect(index)}
                    >
                      <img
                        // Check if videoCons.file is an image, then use its own URL, else use the thumbnail or placeholder
                        src={videoCons.file && videoCons.file.type.startsWith('image/') ? URL.createObjectURL(videoCons.file) : (videoCons.thumbnail || '/placeholder-image.png')}
                        alt="thumbnail"
                        style={{ width: "100%", height: isMobile ? 50 : 90, objectFit: 'cover', borderRadius: 10 }}
                      />
                    </div>
                  )
                })}

                <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                  <input
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    multiple={true}
                    onChange={handleChange}
                    style={{ display: 'none' }} />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={dragActive ? 'drag-active' : ''}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50px', height: '50px', border: '2px dashed #ccc', borderRadius: '50%', cursor: 'pointer' }}>
                    <div>+</div>
                  </label>
                  <p>Upload</p>
                  {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDropVideo}></div>}
                  <video id="hiddenVideo" style={{ display: 'none' }} ref={videoRef}></video>
                </form>
              </div>
              <div onClick={() => mainStores.length > 0 && handleUploadAndCombine(true)} className="merge-down-btn">
                <DownloadIcon />
                <p>Merge & Download</p>
              </div>
            </div>
            <div style={{
              width: isMobile ? '100%' : '500px', // Adjust width for mobile view
              height: isMobile ? 300 : 400,
              marginTop: isMobile ? 10 : 0 // Add top margin on mobile when in column layout
            }}>
              <div className='video-element'>
                {selectedVideo != null ? <video
                  ref={videoTrimRef} // Attach the ref to the video element
                  src={mainStores[selectedVideo]?.video?.src}
                  controls
                  style={{ width: '100%', height: '100%' }}
                  playsInline
                /> :
                  <>   <p>
                    Upload your video <br /><span>&</span><br />
                    <span> Let's the Magic Begin! 🌟🎥✨</span></p></>
                }

              </div>
            </div>
          </div>
          <div style={{ marginTop: 20, width: "70%" }}>
            {selectedVideo != null && <VideoTrim
              time={mainStores[selectedVideo]?.transform?.time}
              video={mainStores[selectedVideo]?.video as HTMLVideoElement}
              videoRef={videoTrimRef}
              onChange={(time, direction) => {
                if (direction === 'left' && videoTrimRef.current) videoTrimRef.current.currentTime = time[0];
                else if (direction === 'right' && videoTrimRef.current) videoTrimRef.current.currentTime = time[1];
                runInAction(() => {
                  mainStores[selectedVideo].transform = {
                    ...mainStores[selectedVideo]?.transform,
                    time,
                  };
                });
              }}
            />}
            {/* {selectedVideo != null ?  <SliderWithVideoTime selectedVideo={selectedVideo} onTrimChange={handleTrimValuesChange} videoRef={videoTrimRef} /> : ''} */}
            {/* <VideoTrimmer selectedVideo={selectedVideo} onTrimChange={handleTrimValuesChange} videoTrimRef={videoTrimRef} /> */}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <p className='text-description'>Once Proceeded, you will not be able to make any changes.</p>
            {videoFiles.length > 0 && <div>
              <IconButton onClick={downloadCurrentVideo}>
                <DownloadIcon />
              </IconButton>
              <CustomizedButtons disabled={mainStores.length > 0 && videoLoading} onClick={() => handleUploadAndCombine()} color="white" background="#0085FF" name="Proceed" />
            </div>}
          </div>
          <LanguageModal
            open={videoLoading}
            check="false"
            type={'loading'}
          />
        </div>
        :
        <Grid container spacing={2} md={12} style={{ height: 500, paddingRight: 10, overflowY: 'auto' }}>
          <Grid item md={5.8} sm={12} xs={12} style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center" }}>

            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", width: '90%' }}>
              <img style={{ width: 200, height: 80, objectFit: 'contain' }} src="/youtube-icon.png"></img>
              <TextField sx={{ width: isMobile ? '70%' : "100%", marginTop: 1, }} className='yt-input' value={ytUrl} onChange={handleChangeYT} id="standard-basic" label="Youtube url" variant="standard" />
              {isValid.isValid && <img style={{ marginTop: 20, marginBottom: 20 }} src={isValid.thumbnailUrl}></img>}
              {/* <p className="yt-promote-text">
                This is where the video title would appear | ✨✨ It must support emojis a well from the title
              </p> */}
              <div
                onClick={() => {
                  if (isValid.isValid) {
                    submitAiStudio({ ytUrl, type: "youtube" })
                  }
                }}
                className={`yt-btn ${!isValid.isValid ? 'disabled' : ''}`}
              >
                <p className="yt-btn-p">
                  Proceed with YouTube Video
                </p>
              </div>
            </div>
          </Grid>
          {!isMobile && <Grid item md={0.1} sm={0.1} xs={0.1} className='port-border' />}
          <Grid item md={5.8} sm={12} xs={12} style={{ display: "flex", alignItems: "center", justifyContent: 'center', flexDirection: 'column' }}>
            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple={true}
                onChange={handleChange}
                style={{ display: 'none' }} />
              <div
                className={`drag-drop-border ${dragActive ? 'dragging' : ''}`}
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                onDragLeave={handleDrag}
                onDrop={handleDropVideo}
                style={{
                  ...(isMobile && { width: '100%', marginTop: 20 })
                }}
              >
                <p></p>
                <p>
                  Drag & Drop or {" "}
                  <span onClick={onButtonClick} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                    Browse
                  </span>
                </p>
                <p className="support-txt">Supported formats: MP4, MOV, WEBM, JPEG, PNG</p>
              </div>
            </form>
            <p className='select-desc'>
              You can select multiple media files at once.
            </p>
          </Grid>
        </Grid >
      }
      <StudioModal onSubmit={(data: any) => submitAiStudio({ ...data, ytUrl, studioModal })} setOpenModal={(status: any) => {
        setStudioModal({
          name: null,
          modal: false,
        })
      }} open={studioModal.modal}></StudioModal>
    </>
  );
}

export default observer(DragDropFile);
