import React, { useState, useEffect } from 'react';
import './style.css'
import { useNavigate, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme, useMediaQuery, Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputWithIcon from '../../components/InputBox';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { Hidden } from '@mui/material';
import OtpInput from 'react-otp-input';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useAppDispatch } from '../../hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { guestUser, signup, verifyEmail } from '../../redux/slices/auth.slice';
import { BASE_URL } from '../../utils/url';
import { logEvent } from '../../utils/ga.utils';
import useAuth from '../../hooks/useAuth';


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignUp() {
    const dispatch = useAppDispatch()
    const navigation = useNavigate()
    const { verify } = useParams()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs')); // xs corresponds to screens smaller than 600px by default
    const { signupAuth } = useAuth()

    const isVerified = useSelector((state: RootState) => state.auth.user?.isVerified);
    const guestId = useSelector((state: RootState) => state.auth.guestId);
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const isLoading = useSelector((state: RootState) => state.auth.isLoading);
    const userEmail = useSelector((state: RootState) => state.auth.email);

    const [step, setStep] = useState(1)
    const [otp, setOtp] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [referalCode, setReferalCode] = useState<string>('');
    const [errors, setErrors] = useState({
        fullName: false,
        email: false,
        password: false,
        rePassword: false,
    })

    useEffect(() => {
        // checkForGuestUser()
        const queryParams = new URLSearchParams(window.location.search);
        const referral = queryParams.get('referral');
        setReferalCode(referral as string)
    }, [])

    useEffect(() => {
        if (isAuthenticated && isVerified) {
            navigation("/")
        }
    }, [isVerified, isAuthenticated])


    useEffect(() => {
        if (verify) {
            setStep(1)
        }
    }, [verify])

    useEffect(() => {
        if (isVerified == false) setStep(1)
        else if (isVerified) setStep(2)
        else setStep(0)
    }, [isVerified])

    useEffect(() => {
        if (otp.length == 5 && userEmail) {
            dispatch(verifyEmail({ email: userEmail, code: otp }))
        }
    }, [otp])

    // const checkForGuestUser = () => {
    //     if (guestId == null) {
    //         const userAgent = navigator.userAgent;
    //         const screenResolution = `${window.screen.width}x${window.screen.height}`;

    //         // Send these details to the backend
    //         dispatch(guestUser({
    //             userAgent,
    //             screenResolution
    //         }))
    //     }
    // }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!email || !isValidEmail(email)) {
            // alert('Please enter a valid email.');
            setErrors({ ...errors, email: true })
        }
        if (!fullName || fullName == '') {
            setErrors({ ...errors, fullName: true })
        }
        if (!password || password.length < 8) {
            // alert('Password must be at least 6 characters long.');
            setErrors({ ...errors, password: true })
        }
        if (password !== rePassword) {
            setErrors({ ...errors, rePassword: true })
        }
        if (email && fullName && password && password.length >= 8 && password == rePassword) {
            logEvent({
                category: 'User',
                action: 'Signup',
            });
            setErrors({ email: false, fullName: false, password: false, rePassword: false })
            signupAuth({ email, password, fullname: fullName, referredBy: referalCode, guestId: guestId })
            // dispatch(signup({ email, password, fullname: fullName }))
        }
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    function hideEmail(email: string) {
        const domain = email.substring(email.lastIndexOf("@"));
        const firstPart = email.substring(0, 4);
        return `${firstPart}*******${domain}`;
    }

    const getErrorMessage = () => {
        if (errors.email) return "Please enter a valid email.";
        if (errors.password) return "Password must be at least 8 characters long.";
        if (errors.fullName) return "Full name is required."; // Add your message
        if (errors.rePassword) return "Passwords do not match."; // Add your message
        return "";
    };

    const errorMessage = getErrorMessage();

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Hidden mdDown>
                    <Grid
                        item
                        md={6}
                        className='background-leftside'
                    >
                        <Typography className='header-text-signup'>
                            Empowering Creators Worldwide
                        </Typography>
                    </Grid>
                </Hidden>
                <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={12} md={6} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: [4, 6, 8],
                            mx: [2, 3, 4],
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: 'inherit',
                            width: "50%",
                            '@media (max-width: 600px)': {
                                width: '80%',  // Set width to 80% on mobile
                            }
                        }}
                    >
                        <img src='/logo.png' onClick={() => navigation("/")} style={{ maxWidth: '100%', height: 'auto', marginBottom: 40, cursor: "pointer" }} />
                        {step == 0 ? <>
                            <Typography
                                className='dont-account-typo'
                                sx={{ fontSize: ["12px", "14px", "16px"] }}
                                style={{ marginBottom: 20 }}
                            >
                                Already have an account?  <Link href="/signin">Sign In</Link>
                            </Typography>

                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <InputWithIcon name="name" error={errors.fullName} onFocus={() => setErrors({ ...errors, fullName: false })} onChange={(e: any) => setFullName(e.target.value)} icon={<AccountBoxIcon />} placeholder='Full name' />
                                <InputWithIcon name="email" error={errors.email} onFocus={() => setErrors({ ...errors, email: false })} onChange={(e: any) => setEmail(e.target.value)} icon={<MailRoundedIcon />} placeholder='Email Address' />
                                <InputWithIcon name="password" error={errors.password} onFocus={() => setErrors({ ...errors, password: false })} secure onChange={(e: any) => setPassword(e.target.value)} icon={<LockRoundedIcon />} placeholder='Password' />
                                <InputWithIcon name="password" error={errors.rePassword} onFocus={() => setErrors({ ...errors, rePassword: false })} secure onChange={(e: any) => setRePassword(e.target.value)} icon={<LockRoundedIcon />} placeholder='Re-type Password' />
                                <InputWithIcon name="referalCode" value={referalCode} onChange={(e: any) => setReferalCode(e.target.value)} icon={<GroupAddIcon />} placeholder='Referal Code' />

                                {errorMessage && <Alert sx={{ marginTop: 3 }} severity="error">{errorMessage}</Alert>}

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={isLoading}
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        height: "50px",
                                        borderRadius: "8px",
                                        backgroundColor: '#0575E6',
                                        color: "#FFF",
                                        fontFamily: "Poppins",
                                        fontSize: ["10px", "12px", "14px"],
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                    }}
                                >
                                    Sign Up
                                </Button>

                                <Typography
                                    className='or-typo'
                                    sx={{ fontSize: ["12px", "14px", "14px"] }}
                                >
                                    OR
                                </Typography>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    href={`${BASE_URL}/auth/google`}
                                    disabled={isLoading}
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        height: "50px",
                                        borderRadius: "8px",
                                        backgroundColor: 'white',
                                        color: "rgba(0, 0, 0, 0.54);",
                                        fontFamily: "Poppins",
                                        fontSize: ["10px", "12px", "14px"],
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        display: 'flex',       // Added to align items in a row
                                        alignItems: 'center',  // Align items vertically centered
                                        justifyContent: 'center', // Align items horizontally centered
                                        textTransform: 'none',   // To prevent automatic uppercase of button text
                                        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)', // Optional: Add some shadow to button
                                        "&:hover": {
                                            backgroundColor: 'white',  // keep background color unchanged
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)', // apply slight shadow on hover
                                        },
                                        "&:focus": {
                                            backgroundColor: 'white', // keep background color unchanged
                                            boxShadow: '0 0 0 3px rgba(66, 133, 244, 0.5)', // apply a blue focus shadow
                                        },
                                        "&:active": {
                                            boxShadow: 'none', // remove shadow on active state
                                        }
                                    }}
                                    startIcon={<img src="google.png" alt="Google Icon" style={{ width: '24px', marginRight: '8px' }} />} // Use the Google icon
                                >
                                    Continue with Google
                                </Button>
                            </Box>
                        </>
                            : step == 1 ? <>
                                <Typography sx={{ marginTop: 10 }} className='otp-msg'>We have sent an OTP to your email </Typography>
                                <Typography className="otp-email">{hideEmail(email)}</Typography>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={5}
                                    renderSeparator={<span></span>}
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle={{
                                        height: isMobile ? 40 : 58,
                                        width: isMobile ? 40 : 58,
                                        backgroundColor: '#D9D9D9',
                                        borderRadius: 2,
                                        marginLeft: isMobile ? 8 : 15,
                                        marginRight: isMobile ? 8 : 15
                                    }}
                                    containerStyle={{ marginTop: 40, marginBottom: isMobile ? 30 : 60 }}
                                />

                                <Typography
                                    className='dont-account-typo'
                                    sx={{ fontSize: ["12px", "14px", "16px"] }}
                                    style={{ marginBottom: 20 }}
                                >
                                    Not your email?  <Link href="/signup"> Change Email</Link>
                                </Typography>
                            </> :
                                step == 2 ? <>
                                    <div className="round-icon">
                                        <DoneRoundedIcon sx={{ color: '#4AAF4F', fontSize: 40 }}></DoneRoundedIcon>
                                    </div>
                                    <Typography sx={{ marginTop: 10 }} className='otp-msg'>You passed a real vibe check :D </Typography>
                                    <Button
                                        href="/"
                                        variant="outlined"
                                        sx={{
                                            marginTop: 5,
                                            backgroundColor: 'white',
                                            borderColor: 'white',
                                            color: '#0575E6',
                                            '&:hover': {
                                                borderColor: '#0575E6' // Light blue on hover
                                            }
                                        }}
                                        endIcon={<ArrowForwardOutlinedIcon />}
                                    >
                                        Sign in
                                    </Button>
                                </> : <></>}
                    </Box>

                </Grid>
            </Grid>
        </ThemeProvider>
    )
}