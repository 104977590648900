import React from 'react';
import { Tabs, Tab, styled, useMediaQuery, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple className='box-shadoww' {...props} />)(
  ({ theme, selected }) => ({
    textTransform: 'none',
    fontFamily: 'DM Sans',
    fontSize: theme.typography.pxToRem(12),
    borderRadius: '10px',
    padding: 0,
    minHeight: 30,
    margin: theme.spacing(2.5, 2, 0, 0),
    boxShadow: "0px 2px 9px -4px rgba(0,0,0,0.64)",
    border: '1px solid transparent',
    color: theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.common.white,
      background: 'linear-gradient(99deg, #00A3FF 43.63%, #0075E1 94.71%)',
    },
    '&.Mui-selected': {
      color: theme.palette.common.white,
      background: 'linear-gradient(99deg, #00A3FF 43.63%, #0075E1 94.71%)',
    },
    '&:not(.Mui-selected)': {
      backgroundColor: theme.palette.grey[100],
      // color: theme.palette.grey[600],
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[400],
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(10),
      minHeight: 25,
      minWidth: 70,
      margin: theme.spacing(1.5, 1, 0, 0),
    },
  })
);

interface StyledTabProps {
  label: string;
  selected?: boolean;
}

const VideoTabs: React.FC<{ value: number, onChange: any, data: any }> = ({ value, onChange, data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event, event.target.value as number);
  };

  return isMobile ? (
    <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel>Filter</InputLabel>
      <Select
        value={value}
        onChange={handleSelectChange as any}
        label="Filters"
      >
        {data.map(({ label }: any, i: number) => (
          <MenuItem key={i} value={i}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <StyledTabs value={value} onChange={onChange}>
      {data.map(({ label }: any, i: number) => (
        <StyledTab key={i} label={label} selected={value === i} />
      ))}
    </StyledTabs>
  );
};

export default VideoTabs;
