/* eslint-disable @remotion/staticfile-no-relative */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable capitalized-comments */
import './utilities/fonts.css'
import CompositionAIShorts from "./Composition_ViralShorts";
import { useAppSelector } from "../../hooks/redux";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { VideoContext } from "../../context/video.context";
import useVideo from "../../hooks/useVideo";
import { Player } from "@remotion/player";
import toast from "react-hot-toast";
import { useMediaQuery, useTheme } from "@mui/material";


export const AIShortsRemotionPlayer: React.FC<{ uri: string, index: number }> = ({ uri, index }, any) => {
  const videoFile = useAppSelector(store => store.video.videoFile)
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));


  const [jsonAIStudio, setJsonAIStudio] = useState(null)
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState(0); // Used to force re-render the player
  const [toastShown, setToastShown] = useState(false);


  const contextValue = useContext(VideoContext);

  if (!contextValue) {
    // handle the case where the context value is not provided
    throw new Error("VideoContext value is not provided");
  }

  const { mediaPlayerRef, subtitleJSON, setSubtitleJSON } = contextValue;

  const {
    preset,
    font,
    fontSizeCaption,
    captionColor,
    maxWords,
    visuals,
    captionPosition,
    effects,
    PKG
  } = useVideo();

  // jsonData: jsonPodcast,
  // jsonCaptions: jsonCaptions,
  // videoLink: staticFile("text_2.mp4"),
  // video_width: 1920, // I forgot to add this; But You have to provide the width of the video 
  // template:"waveform", // captions-preset
  // emojis_on:1, // 0 or 1
  // config:{
  //   font_name: "Sriracha",
  //   font_size: 45, // 20 to 75
  //   colors: ["#00FF00", "#0062FF", "#FFFFFF", "#68DE00"],
  //   position: 6, // 1 to 9 Top to Bottom
  // },
  // broll: 1,
  // soundSFX:1,
  // bgmusic:1,
  // overlay:1,
  // zoom: 1,
  // filters: 1,
  // lightleaks: 1,
  // scene_transitions: 1,
  // laptop_overlay: 0,
  // billboard_overlay: 0, // Currently maintain it 0, will be used in future
  // logo_on: 1,

  // Initialize state
  const [videoProps, setVideoProps] = useState({
    jsonData: jsonAIStudio,
    jsonCaptions: subtitleJSON,
    videoLink: uri,
    video_width: 1920,
    template: preset,
    config: {
      font_name: font,
      font_size: fontSizeCaption,
      colors: captionColor,
      position: captionPosition,
      maxWords,
    },
    // gifs_on: visuals === 'GIFs' ? 1 : 0,
    emojis_on: visuals === 'emojis' ? 1 : 0,
    broll: effects?.broll ? 1 : 0,
    overlay: effects?.overlays ? 1 : 0,
    // motion_bg: effects?.motionbg_on ? 1 : 0,
    soundSFX: effects?.keyword_soundEffects_on ? 1 : 0,
    bgmusic: effects?.bg_music_on ? 1 : 0,
    zoom: effects?.zoom_on ? 1 : 0,
    filters: effects?.filters_on ? 1 : 0,
    scene_transitions: effects?.scene_transitions_on ? 1 : 0,
    logo_on: 0,
    billboard_overlay: 0,
    laptop_overlay: 0,
    lightleaks: 1,
    // segmented_video_uris: videoFile?.urls?.segmented_video_uris,
  });

  // Update state with useEffect
  useEffect(() => {
    if (videoFile == null) return
    setVideoProps({
      jsonData: jsonAIStudio,
      jsonCaptions: subtitleJSON,
      videoLink: uri,
      template: preset,
      video_width: videoFile.video_width || 1920,
      config: {
        font_name: font,
        font_size: fontSizeCaption,
        colors: captionColor,
        position: captionPosition,
        maxWords,
      },
      // gifs_on: visuals === 'GIFs' ? 1 : 0,
      emojis_on: visuals === 'emojis' ? 1 : 0,
      broll: effects?.broll ? 1 : 0,
      overlay: effects?.overlays ? 1 : 0,
      // motion_bg: effects?.motionbg_on ? 1 : 0,
      soundSFX: effects?.keyword_soundEffects_on ? 1 : 0,
      bgmusic: effects?.bg_music_on ? 1 : 0,
      zoom: effects?.zoom_on ? 1 : 0,
      filters: effects?.filters_on ? 1 : 0,
      scene_transitions: effects?.scene_transitions_on ? 1 : 0,
      logo_on: 0,
      billboard_overlay: effects?.billboard_overlay_on ? 1 : 0,
      laptop_overlay: effects?.laptop_overlay_on ? 1 : 0,
      lightleaks: effects?.lightleaks_on ? 1 : 0,
      // segmented_video_uris: videoFile?.urls?.segmented_video_uris,
    });
  }, [
    jsonAIStudio,
    subtitleJSON,
    videoFile,
    preset,
    font,
    fontSizeCaption,
    captionColor,
    captionPosition,
    maxWords,
    visuals,
    effects
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (videoFile?.urls?.edited_json_uris[index]) {
          const { data } = await axios.get(videoFile?.urls?.edited_json_uris[index]);
          setJsonAIStudio(data);
        }
        if (videoFile?.urls?.caption_original_json_uris[index]) {
          const { data } = await axios.get(videoFile?.urls?.caption_original_json_uris[index]);
          setSubtitleJSON(data);
        }
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, [videoFile, index]);


  const calculateFrameDuration = () => {
    if (videoFile !== null) {
      let val = Math.round(30 * videoFile?.sub_video_durations?.[index]?.duration || 40
        // (videoFile?.duration || videoFile?.video_duration || 20)
      )
      return val == 0 ? 1020 : val
    }
    else {
      return 0
    }
  }

  if (loading || !jsonAIStudio || !subtitleJSON || !videoFile) {
    return <div style={{height:1920}}>Loading...</div>;
  }

  // const renderLoading = useCallback(({ height, width }: any) => {
  //   return (
  //     <AbsoluteFill style={{ backgroundColor: 'gray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //       <div style={{ textAlign: 'center' }}>
  //         <Spinner />
  //         <div>Loading player ({height}x{width})</div>
  //       </div>
  //     </AbsoluteFill>
  //   );
  // }, []);


  const handleError = ({ error }: any) => {
    // console.error('Error occurred in video', error);

      toast.error('An error occurred while trying to play the video. Attempting to reload.', {
        id: "videoError",
      });
    return false;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: isXs ? 200 : '100%', backgroundColor: "white" }}>
      <Player
        component={CompositionAIShorts}

        ref={mediaPlayerRef}
        compositionWidth={1080}
        compositionHeight={1920}
        durationInFrames={calculateFrameDuration()}
        fps={30}
        // controls
        errorFallback={handleError}
        inputProps={videoProps}
        style={{
          width: "100%",
          height: "auto",
          maxWidth: "720px",
          aspectRatio: "9/16",
        }}
        controls
        showVolumeControls
        clickToPlay
        spaceKeyToPlayOrPause
        moveToBeginningWhenEnded
      // loop
      // renderLoading={renderLoading}
      // defaultProps={{
      //   jsonData: jsonPodcast,
      //   jsonCaptions: jsonCaptions,
      //   videoLink: staticFile("text_2.mp4"),
      //   video_width: 1920, // I forgot to add this; But You have to provide the width of the video 
      //   template: "waveform", // captions-preset
      //   emojis_on: 1, // 0 or 1
      //   config: {
      //     font_name: "Sriracha",
      //     font_size: 45, // 20 to 75
      //     colors: ["#00FF00", "#0062FF", "#FFFFFF", "#68DE00"],
      //     position: 6, // 1 to 9 Top to Bottom
      //   },
      //   broll: 1,
      //   soundSFX: 1,
      //   bgmusic: 1,
      //   overlay: 1,
      //   zoom: 1,
      //   filters: 1,
      //   lightleaks: 1,
      //   scene_transitions: 1,
      //   laptop_overlay: 0,
      //   billboard_overlay: 0, // Currently maintain it 0, will be used in future
      //   logo_on: 1,
      // }}

      />
    </div>
  );
};

export default AIShortsRemotionPlayer;