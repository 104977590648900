import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import Header from '../Header';
import { Button, Container, Grid, Typography, Snackbar, AvatarGroup, Avatar, useTheme, useMediaQuery } from '@mui/material';

import RecentProject from '../../components/RecentProject';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getToken, me } from '../../redux/slices/auth.slice';
import { RootState } from '../../redux/store';
import { addVideoId, fetchRecentProjects, getStorageStats, getVideoFileAndExtract } from '../../redux/slices/video.slice';
import BannerModal from '../../components/BannerModal';
import { logEvent, setUser } from '../../utils/ga.utils';
import packageData from '../../utils/packageData';
import PlansData from '../../utils/plans_data.json';
import { useNavigate } from 'react-router-dom';
import { ILimit } from '../../interface/user';
import CloseIcon from '@mui/icons-material/Close';


import CustomizedButtons from '../../components/Button';
import BorderLinearProgress from '../../components/BorderLinearProgress';
import DragDropModal from '../../components/DragDrop';
import { DashboardContext } from '../../context/dashboard.context';
import LanguageModal from '../../components/LanguageModal';
import { APP_URL, SEVER_URL } from '../../utils/url';
import httpApi from '../../utils/httpApi';
import GradientTypography from '../../components/GradientTypography';
import { generateRandomName } from '../../utils/functions';
import VideoProgress from '../../components/ProgressVideo';
import { discordWebhook } from '../../redux/slices/feedbacks.slice';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PodcastConfModal from '../../components/PodcastConfModal';
import MessageModal from '../../components/MessageModal';
import LanguageCaptionModal from '../../components/LanguageCaptionModal';
import toast from 'react-hot-toast';
import { VideoContext } from '../../context/video.context';
import useVideo from '../../hooks/useVideo';
import PremiumModal from '../../components/PremiumModal';


function Main() {
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const initialCallRef = useRef(false);

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const contextValue = useContext(DashboardContext);

  if (!contextValue) {
    // handle the case where the context value is not provided
    throw new Error("VideoContext value is not provided");
  }

  const { language, setLanguage, modalType, setModalType, inputRef, setUploadTransModal, uploadTransModal, file, studioData, activeButtons, alertModal, setAlertModal } = contextValue


  const contextValue2 = useContext(VideoContext);

  if (!contextValue2) {
    // handle the case where the context value is not provided
    throw new Error("VideoContext2 value is not provided");
  }

  const { premimumModal, setPremiumModal } = contextValue2

  const { PKG } = useVideo()
  const id = useAppSelector((state: RootState) => state.auth.user?.id)
  const user = useAppSelector((state: RootState) => state.auth.user)
  const token = useAppSelector((state: RootState) => state.auth.token)
  const guestId = useAppSelector((state: RootState) => state.auth.guestId)
  const recentProject = useAppSelector((state: RootState) => state.video.recentProject)
  const storageStats = useAppSelector((state: RootState) => state.video.storageStats)
  const isLoading = useAppSelector((state: RootState) => state.video.isLoading)
  const currentTranscribe = useAppSelector((state: RootState) => state.video.currentTranscribe)
  const videoId = useAppSelector((state: RootState) => state.video.videoId)
  const total_characters = useAppSelector(
    (state: RootState) => state.titties.total_characters,
  )

  const [bannerModal, setBannerModal] = useState<boolean>(false)
  const [storageData, setStorageData] = useState<any>([])
  const [limitation, setLimitation] = useState<ILimit>({
    "Name": "Free",
    "Transcription_Minutes": 30,
    "Characters_Audio": 2000,
    "Secure_Cloud_Storage": 1024
  },)

  const [open, setOpen] = React.useState(false);
  const [openDrag, setOpenDrag] = React.useState(false);
  const [videoForProgress, setVideoForProgress] = useState<string | null>(null)
  const [videoFile, setVideoFile] = useState<Blob>()
  const [loadingPerform, setLoadingPerform] = useState<any>({ user_id: null, video_id: null, active_service: null, aistudio_editing_progress: false })
  const [openPodcastModal, setOpenPodcastModal] = useState<boolean>(false)
  const [languageCaptionModal, setLanguageCaptionModal] = useState<boolean>(false)
  const [languageOption, setLanguageOption] = useState<number>(-1)
  const [aiShortsType, setAiShortsType] = useState({
    ytUrl: null,
    type: null
  })

  const handleCopyClick = () => {
    const textToCopy = `${APP_URL}signup?referral=${user?.referral}`;

    navigator.clipboard.writeText(textToCopy).then(() => {
      setOpen(true);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };


  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (user?.subscriptionStatus == "succeeded") {
      // find name of subscription product
      let mySubsPkg = packageData.find(({ cycle }: any) => cycle.find(({ subscriptionId }: any) => subscriptionId == user.subscriptionPlan?.id))
      if (mySubsPkg) {
        let PlansLimit = PlansData.find(({ Name }) => Name == mySubsPkg?.name)
        if (PlansLimit)
          setLimitation(PlansLimit as ILimit)
      }
    }
  }, [user])

  // useEffect(() => {
  //   dispatch({ type: "auth/removeToken" })
  // }, [])



  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;

  // Utili          ty to convert size to GB and round it.
  const convertSizeToGB = (size: number) => {
    return Math.round(((size / GB) * 100));
  };

  // Utility to calculate consumed percentage.
  const calculatePercentage = (used: number, total: number) => {
    const percentage = Math.round((used / total) * 100);
    return percentage > 100 ? 100 : percentage;
  };

  useEffect(() => {
    if (!storageStats) return;

    const totalSizeInGB = recentProject?.reduce((sum: any, obj: any) => sum + obj.size, 0);
    const consumedStorage = convertSizeToGB(totalSizeInGB);
    const freeStorage = Math.max(0, 100 - consumedStorage);

    const totalDuration = storageStats?.duration;
    const consumedMinutes = Math.round(totalDuration / 60);
    const consumedPercentage = calculatePercentage(consumedMinutes, limitation?.Transcription_Minutes || 0) as any

    const remainingStorageMB = Math.max(0, (limitation?.Secure_Cloud_Storage || 0) - (totalSizeInGB / MB));
    const remainingMinutes = Math.max(0, (limitation?.Transcription_Minutes || 0) - consumedMinutes);
    const remainingChars = Math.max(
      0,
      (limitation?.Transcription_Minutes || 0) - total_characters,
    )

    const storageData = [
      {
        name: 'File Storage',
        tooltip: 'Available Cloud Storage',
        size: `${Math.round(remainingStorageMB)} MBs Left`,
        stats: [
          { name: 'Consumed', size: consumedStorage },
          { name: 'Free', size: freeStorage },
        ],
        color: '#E39F43',
      },
      {
        name: 'Transcription',
        tooltip: 'Available Minutes every month',
        size: `${remainingMinutes} Mins Left`,
        stats: [
          { name: 'Consumed', size: consumedPercentage },
          { name: 'Available', size: 100 - consumedPercentage },
        ],
        color: '#0EC23E',
      },
      {
        name: 'Text to Speech',
        tooltip: 'Available Minutes every month',
        size: `${remainingChars} Chars Left`,
        stats: [
          { name: 'Consumed', size: consumedPercentage },
          { name: 'Available', size: 100 - consumedPercentage },
        ],
        color: '#FF8B85',
      },
    ]

    setStorageData(storageData);

  }, [recentProject, storageStats, isLoading]);

  useEffect(() => {
    let data = null
    recentProject?.find(({ performLoader, video_id, user_id, active_service }: any) => {
      if (performLoader) data = { user_id, video_id, active_service }
    })
    setLoadingPerform(data as any)

  }, [recentProject])

  const checkForGuestUser = () => {
    if (guestId == null && user == null) {
      const userAgent = navigator.userAgent;
      const screenResolution = `${window.screen.width}x${window.screen.height}`;

      // Send these details to the backend
      // dispatch(guestUser({
      //   userAgent,
      //   screenResolution
      // }))
    }

  }

  useEffect(() => {

    setLoadingPerform({ user_id: null, video_id: null, active_service: null, aistudio_editing_progress: false })

    dispatch({ type: "texttovideo/upadateVideoId", payload: null })

    // checkForGuestUser()

    const pkg = localStorage.getItem('pkg');
    if (pkg) {
      localStorage.removeItem('pkg');
      if (user && user.id) navigation(`/checkout/${JSON.parse(pkg)}`)
    }

    // const bannerDisplayed = localStorage.getItem('bannerDisplayed');
    // if (bannerDisplayed == 'false') {
    //   setBannerModal(true); // Show the banner if it hasn't been displayed before
    //   localStorage.setItem('bannerDisplayed', 'true'); // Set the key to prevent displaying the banner in future visits
    // }
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    if (token) {
      dispatch({ type: 'auth/setToken', payload: token })
      localStorage.setItem('token', token)
      logEvent({
        category: 'User',
        action: 'Google Auth',
      });
    }
    dispatch(me())
    // dispatch(getStorageStats({ userId: id || guestId }))
    dispatch(addVideoId(null))
    // dispatch(voiceData({ user_id: id || guestId }))
    dispatch(fetchRecentProjects(id as string))

    if (id) setUser(id);

  }, [])

  const getGreeting = (userName: string): JSX.Element => {
    const currentHour = new Date().getHours();
    const isMobile = window.innerWidth < 768; // Example breakpoint for mobile

    // Define shorter messages for mobile
    const eveningGreeting = isMobile ? "Lovely evening," : "Happy beautiful evening,";
    const nightGreeting = isMobile ? "Good night," : "Happy night work,";
    const lateNightGreeting = isMobile ? "Night owl," : "Happy late-night work,";
    const dayGreeting = isMobile ? "Good day," : "Good day,";

    if (currentHour >= 17 && currentHour < 20) {
      return <><span className='welcome-typo-gradient'>{eveningGreeting}</span> let's fill up some colors in your colorless life :D</>;
    } else if (currentHour >= 20 && currentHour <= 23) {
      return <><span className='welcome-typo-gradient'>{nightGreeting}</span> let's build something great</>;
    } else if (currentHour >= 0 && currentHour < 5) {
      return <><span className='welcome-typo-gradient'>{lateNightGreeting}</span> we just want to tell you that you are not alone</>;
    } else {
      return <><span className='welcome-typo-gradient'>{dayGreeting}</span> God gifted you another day to show them who you are</>;
    }
  };

  let transcibeData = [
    { name: 'ViralMe Studio', img: "https://viralmefast.azureedge.net/website-assets/Services/ViralMe_Studio.png?sp=r&st=2024-07-11T20:19:32Z&se=2224-07-12T04:19:32Z&spr=https&sv=2022-11-02&sr=b&sig=Ms2PXsyw2l3ZSdUt1P64yi3y20zecbofjED3ufj1Y%2Bc%3D", desc: 'Super edit your video using our magical ViralMe Studio', disabled: false, },
    { name: 'AI Shorts', img: "https://viralmefast.azureedge.net/website-assets/Services/AI_Shorts.png?sp=r&st=2024-07-11T20:16:58Z&se=2224-07-12T04:16:58Z&spr=https&sv=2022-11-02&sr=b&sig=FC6Sci2hBB%2BveoW%2BZEFKRg0EmQo6TXiUKlrFfU%2Bqmn4%3D", desc: "Turn your long videos into engaging shorts/reel.", disabled: false },
    { name: 'AI Captions', img: "https://viralmefast.azureedge.net/website-assets/Services/AI_Captions.png?sp=r&st=2024-07-11T20:16:29Z&se=2224-07-12T04:16:29Z&spr=https&sv=2022-11-02&sr=b&sig=jW2G%2Bed2dOamBeCk%2FE8PNI9HsArq0mX%2Bu47B6Tb%2F7zg%3D", desc: 'Add trending captions or subs to your videos', disabled: false, },
    // { name: 'AI Studio', img: "/service/AI_Studio.png", desc: "Super edit your video using our magical AI Studio.", disabled: false },
    { name: 'Text to Video', img: "https://viralmefast.azureedge.net/website-assets/Services/Prompt_to_Video.png?sp=r&st=2024-07-11T20:20:03Z&se=2224-07-12T04:20:03Z&spr=https&sv=2022-11-02&sr=b&sig=%2FubL%2F3l7yZgViXyFtPscnBDxBvnQyL63kk7BdQWT%2FWw%3D", desc: 'Create a faceless video with just a prompt', disabled: false },
    { name: 'Reduce Video Size', img: "https://viralmefast.azureedge.net/website-assets/Services/Reduce_Video_Size.png?sp=r&st=2024-07-11T20:18:15Z&se=2224-07-12T04:18:15Z&spr=https&sv=2022-11-02&sr=b&sig=vMP98nJx2riF8dhj1bw%2BOFWerybvWx%2F0JX1K1kFh%2FS8%3D", desc: 'Reduce video size by HEVC', disabled: false },
    { name: 'Denoise Audio', img: "https://viralmefast.azureedge.net/website-assets/Services/Denoise_Audio.png?sp=r&st=2024-07-11T20:17:31Z&se=2224-07-12T04:17:31Z&spr=https&sv=2022-11-02&sr=b&sig=UNllXXYCIbQxCrPnj8FeB6VWszxJG3jgUg6OI3vtgqI%3D", desc: 'Remove all types of noise without distorting quality', disabled: true },
    { name: 'Text to Speech', img: "https://viralmefast.azureedge.net/website-assets/Services/Text_to_Speech.png?sp=r&st=2024-07-11T20:18:54Z&se=2224-07-12T04:18:54Z&spr=https&sv=2022-11-02&sr=b&sig=dRRCdcxO2MlPPhxEUaXMtvGGcrJJ46SwiEf05YNJHog%3D", desc: 'Generate human realistic Voice-overs in any voice', disabled: true },
    { name: 'SuperMotion', img: "https://viralmefast.azureedge.net/website-assets/Services/SuperMotion.png?sp=r&st=2024-07-11T20:17:52Z&se=2224-07-12T04:17:52Z&spr=https&sv=2022-11-02&sr=b&sig=0vIWKA6siwxHEAg59G%2BpgVBejc8ZeAWbFP5%2B%2Fw7DMZY%3D", desc: 'Increase the video FPS to 60/120', disabled: true },
  ]


  const notifyMsg = (msg: string) => {
    toast((t) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{msg}</span>
        <button
          onClick={() => toast.dismiss(t.id)}
          style={{
            background: 'none',
            border: 'none',
            marginLeft: 'auto',
            cursor: 'pointer',
          }}
        >
          <CloseIcon style={{ width: 20, height: 20, color: '#fff' }} />
        </button>
      </div>
    ), {
      position: 'top-right',
      duration: Infinity,
      // Styling
      style: {
        backgroundColor: "#ffa500",
        color: "#fff"
      },
      className: '',
      // Change colors of success/error/loading icon
      iconTheme: {
        primary: '#fff',
        secondary: '#0075E1',
      },
      // Aria
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    });
  };
  const submitVideo = async (newFile: Blob, check = false) => {
    if ((currentTranscribe == "ViralMe Studio" || currentTranscribe == "AI Shorts") && check == false) {
      initialCallRef.current = false;
      setLanguageCaptionModal(true)
      return
    }
    if (currentTranscribe == 'AI Shorts') submitAiStudio({ ...aiShortsType, check: true })
    dispatch(getToken(id || guestId))

    setVideoFile(newFile)
    const formData = new FormData();
    const randomFileName = generateRandomName();

    // Create a Blob object from the file content
    const blob = new Blob([newFile], { type: 'video/mp4' });

    formData.append('file', blob, `${randomFileName}.mp4`);
    formData.append('user_id', id || guestId as string);

    if (currentTranscribe == "ViralMe Studio") {
      formData.append("language_option", languageOption.toString())
    }
    else {
      formData.append('transcribe_check', '1');
      formData.append("language", language.toLowerCase())

    }
    if (languageOption == 0) return
    // formData.append("video_id", vdid)


    logEvent({ category: "Video", action: currentTranscribe || "", label: id })

    setModalType("loading")
    setBannerModal(true)

    setUploadTransModal(true)

    try {
      let response: any;

      let url = `upload_video`
      if (currentTranscribe == "Denoise Audio") url = "denoise_video_audio_both"
      if (currentTranscribe == "ViralMe Studio") url = "aistudio_get_edited_json"
      if (currentTranscribe == 'Reduce Video Size') url = "compress_video"
      response = await httpApi.post(`${SEVER_URL}${url}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Host': 'test.transpify.com',
        }
      });
      if (response.data.status_code === 405 || response.data.status_code === 422) {
        dispatch(getToken(id || guestId))
        setTimeout(() => submitVideo(newFile, true), 1000)
        return
      }
      setLanguageCaptionModal(false)
      setOpenDrag(false)
      let vId = response.data[0].video_id
      setUploadTransModal(false)
      initialCallRef.current = false;

      if (response.data[1] == 408) {
        notifyMsg(response.data[0].message)
        dispatch(discordWebhook(`${currentTranscribe} ${response.data[0].message}`))

      } else {
        dispatch(getVideoFileAndExtract({ videoId: vId, userId: id || guestId }))
        // dispatch(fetchRecentProjects(id as string))

        dispatch(addVideoId(vId))
        setVideoForProgress(vId)
      }

      // setModalType("language")
      // navigation(`/video`)
    } catch (err) {
      setUploadTransModal(false)
      // setModalType("language")
      console.error(err);
    }
  }

  const submitAiStudio = async ({ type, ytUrl, check = false }: any) => {
    setAiShortsType({ type, ytUrl })
    if ((currentTranscribe == "ViralMe Studio" || currentTranscribe == "AI Shorts") && check == false) {
      initialCallRef.current = false;
      setLanguageCaptionModal(true)
      return
    }
    // setVideoLoading(true)
    // const { preset, position, font, fontSize, colors, visuals, maxWords } = studioData

    setModalType("loading")
    setBannerModal(true)

    setUploadTransModal(true)
    console.log({ type })
    if (type === "youtube") {
      try {
        let data: any = {
          "user_id": id,
          // "language": "en",
          "video_link": ytUrl,
          // "template": preset,
          // "font_name": font,
          // "font_size": fontSize,
          // "colors": colors,
          // "position": position,
          "is_youtube_video": true,
          "language_option": languageOption.toString()
          // "emojis_on": visuals === "none" ? 0 : 1,
          // "cap_num_words": maxWords
        };

        console.log({ data })

        // Object.keys(activeButtons).forEach((key) => {
        //   data[key] = activeButtons[key] ? '1' : '0'
        // });


        dispatch(discordWebhook(`${currentTranscribe} (youtube) Submit Video`))


        let response;
        response = await httpApi.post(`${SEVER_URL}edit_aishorts_with_youtube`, data);
        // if (response.data.status_code === 405 || response.data.status_code === 422) {
        //   dispatch(getToken(id || guestId))
        //   setTimeout(() => submitVideo(newFile), 1000)
        //   return
        // }
        // console.log("make_aishorts_with_youtube", response)
        dispatch(discordWebhook(`${currentTranscribe} - ${type} Submit Video`))
        setOpenDrag(false)
        let vId = response?.data?.[0]?.video_id || null

        setUploadTransModal(false)
        setVideoForProgress(vId)
        dispatch(getVideoFileAndExtract({ videoId: vId, userId: id || guestId }))
        // dispatch(fetchRecentProjects(id as string))

        dispatch(addVideoId(vId))
        // setModalType("language")
        // navigation(`/video`)
      } catch (err) {
        setUploadTransModal(false)
        // setModalType("language")
        console.error(err);
      }
    } else {
      try {
        let formData = new FormData()
        formData.append("user_id", id || "")
        formData.append("language_option", languageOption.toString())
        // formData.append("template", preset)
        // formData.append("emojis_on", visuals == "none" ? '0' : '1')
        // formData.append("font_name", font)
        // formData.append("font_size", fontSize.toString())
        // formData.append("position", position.toString())
        // formData.append("cap_num_words", maxWords.toString())
        // formData.append("emojis_on", visuals === "none" ? '0' : '1')
        // Object.keys(activeButtons).forEach((key) => {
        //   formData.append(key, activeButtons[key] ? '1' : '0');
        // });
        // formData.append("bg_music_on",)
        // formData.append("brolls_on",)
        // formData.append("keyword_soundEffects_on",)
        // formData.append("overlays_on",)
        // formData.append("zoom_on",)
        // formData.append("video_filters",)
        // formData.append("scene_transitions",)
        // formData.append("laptop_overlay",)

        // studioData?.colors.map((color: string) => {
        //   formData.append("colors", color)
        // }
        // )
        formData.append("is_youtube_video", 'false')
        formData.append("file", file)

        dispatch(discordWebhook(`${currentTranscribe} (upload) Submit Video`))

        let response;
        response = await httpApi.post(`${SEVER_URL}edit_aishorts_with_upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            // 'Host': 'test.transpify.com',
          }
        });
        // console.log("make_aishorts_with_upload", response)

        // if (response.data.status_code === 405 || response.data.status_code === 422) {
        //   dispatch(getToken(id || guestId))
        //   setTimeout(() => submitVideo(newFile), 1000)
        //   return
        // }
        dispatch(discordWebhook(`${currentTranscribe} - ${type} Submit Video`))
        setOpenDrag(false)
        let vId = response.data[0].video_id

        setUploadTransModal(false)
        setVideoForProgress(vId)
        dispatch(getVideoFileAndExtract({ videoId: vId, userId: id || guestId }))
        // dispatch(fetchRecentProjects(id as string))

        dispatch(addVideoId(vId))
        // setModalType("language")
        // navigation(`/video`)
      } catch (err) {
        setUploadTransModal(false)
        // setModalType("language")
        console.error(err);
      }
    }
  }




  useEffect(() => {
    setVideoForProgress(videoId)
  }, [videoId])

  const progressUrl = () => {
    if (loadingPerform && loadingPerform.active_service) {
      let currentService = loadingPerform.active_service[0];
      if (currentService === 'transcribe') {
        return "transcription_progress";
      } else if (currentService === 'compression') {
        return "compression_progress";
      } else if (currentService === 'aistudio') {
        return "aistudio_editing_progress";
      } else if (currentService === 'aishorts') {
        return "aishorts_editing_progress";
      }
    }
    return ""
  }

  const updateLanguageOption = (val: number) => {
    setLanguageOption(val)
  };

  useEffect(() => {
    if (languageOption !== -1) {
      initialCallRef.current = true;
      submitVideo(file, true);
      setLanguageCaptionModal(false);
    }
  }, [languageOption]);

  return (
    <Fragment>
      <Header></Header>
      <Container maxWidth="xl">
        <Grid container>
          <Grid sx={{ marginTop: 2 }} container spacing={2} rowGap={2} md={9.8}>
            <Grid item md={12}>
              <Typography className='welcome-typo'>{getGreeting(user?.fullName || "")}</Typography>
            </Grid>
            {/* <Typography className='welcome-typo'>Hi<span className='welcome-typo-gradient'>{" "}{user?.fullName},</span> What are we making today?</Typography> */}
            <Grid container rowGap={1} className='transcibe-grid' sx={{ padding: 2, }} md={12}>
              {/* {transcibeData.map((transribe, i) =>
                <Grid key={transribe.name} onClick={() => {
                  setVideoForProgress(null)
                  if (!transribe.disabled) {
                    dispatch({ type: 'video/updateCurrentTranscribe', payload: transribe?.name })
                    dispatch(discordWebhook(`${transribe?.name} Click on`))

                    if (transribe.name == "Text to Video") navigation("/text-video")
                    else setOpenDrag(true)
                  }
                }} className='transcribe-card cursor-pointer box-shadoww' sx={{}} item xs={5.9} md={2.9}>

                  {transribe.disabled ?
                    <GradientTypography text={transribe.name} gradient="linear-gradient(99deg, #777 43.63%, #777 94.71%)" />
                    : <div style={{ width: '100%' }}>
                      <img style={{ height: transribe.name == "AI Captions" ? 30 : 25 }} src={transribe.img} ></img>
                    </div>}
                  <Typography className='card-desc'>{transribe.desc}</Typography>
                </Grid>
              )}
               */}
              {transcibeData.map((transribe, i) => (
                <Grid
                  key={transribe.name}
                  onClick={() => {
                    setLanguageOption(-1)
                    setVideoForProgress(null);
                    if (transribe.disabled) return;

                    const { name } = transribe;

                    if (name == "AI Shorts" || name == "ViralMe Studio") {
                      if (!PKG) {
                        setPremiumModal(true)
                        return
                      }
                    }

                    dispatch({ type: 'video/updateCurrentTranscribe', payload: name });
                    dispatch(discordWebhook(`${name} Click on`));

                    switch (name) {
                      case "Text to Video":
                        navigation("/text-video");
                        break;
                      case "AI Shorts":
                      case "ViralMe Studio": {
                        // setAlertModal({ open: true, msg: "We are working on new updates. We are implementing a realtime render option so, the services will not work for atleast 8 - 12 hours. Kindly try again after that." })
                        // break;
                        const msgKey = name === "AI Shorts" ? "podcast-msg" : "studio-msg";
                        const msgTog = localStorage.getItem(msgKey);
                        if (msgTog === 'false' || msgTog === null) {
                          setOpenPodcastModal(true);
                        } else {
                          setOpenDrag(true);
                        }
                        break;
                      }
                      default:
                        setOpenDrag(true);
                    }
                  }}

                  className="transcribe-card cursor-pointer box-shadoww"
                  sx={{}}
                  item
                  xs={5.9}
                  md={2.9}
                >
                  {transribe.disabled ? (
                    <GradientTypography
                      text={transribe.name}
                      gradient="linear-gradient(99deg, #777 43.63%, #777 94.71%)"
                    />
                  ) : (
                    <div style={{}}>
                      <Typography
                        style={{
                          backgroundImage: `url(${transribe.img})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain', // Use 'contain' or 'cover' based on your needs
                          backgroundPosition: 'left',
                          // padding: '20px',
                          color: transribe.disabled ? '#777' : 'transparent', // Adjust text color if needed
                          width: '85%'
                        }}
                      >
                        {transribe.name}
                      </Typography>
                      {/* <img
                        style={{ height: transribe.name === "AI Captions" ? 30 : 25 }}
                        src={transribe.img}
                        alt={transribe.name}
                      /> */}
                    </div>
                  )}
                  <Typography
                    className="card-desc"
                  // style={{
                  //   backgroundImage: `url(${transribe.img})`,
                  //   backgroundSize: 'cover',
                  //   backgroundPosition: 'center',
                  //   padding: '20px',
                  //   color: transribe.disabled ? '#777' : 'white', // Adjust text color if needed
                  // }}
                  >
                    {transribe.desc}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            {/* <TemplateProject /> */}
            <RecentProject loadingPerform={loadingPerform} openProgressLoader={(data: any) => { setLoadingPerform(data) }} progressVId={loadingPerform?.video_id} />
          </Grid>
          {/* <Grid md={0.2}></Grid> */}
          <Grid sx={{ backgroundColor: "white" }} sm={12} md={2}>
            <Grid className='refer-friend' sx={{ margin: 3 }} xs={8} sm={5.5} md={12} item>
              <Typography className='refer-title'>Refer friends and earn</Typography>
              <Typography sx={{ marginTop: 1 }} className='refer-desc'>Earn up to 20 for every friend you refer. Unlimited referrals with no caps!</Typography>
              <AvatarGroup sx={{ marginTop: 1, marginBottom: 1 }} max={3}>
                <Avatar alt="Remy Sharp" src="/avatar/1.png" />
                <Avatar alt="Travis Howard" src="/avatar/2.png" />
                <Avatar alt="Trevor Henderson" src="/avatar/3.png" />
              </AvatarGroup>

              <CustomizedButtons onClick={() => navigation("/profile?tab=reward")} name='Invite a friend' size='small'
                background='linear-gradient(to right, #00a3ff, #0065ff)'
                color="white"
              // ic on={<AddIcon />}
              />
            </Grid>
            <Grid className='current-usage' sx={{ margin: 3 }} xs={8} sm={5.5} md={12} item>
              <Typography sx={{ color: 'black', marginBottom: 2 }} className='refer-title'>Current Usage</Typography>
              {storageData && storageData.length > 0 && storageData.map(({ color, stats }: any) =>
                <div style={{ marginTop: 10 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Typography className='current-usage-title'>Video Processing Minutes</Typography>
                    <Typography className='current-usage-level'>{stats[0].size}/100</Typography>
                  </div>
                  <BorderLinearProgress variant="determinate" value={stats[0].size || 0} />
                </div>
              )}
              <div style={{ marginTop: 100 }}></div>
              <CustomizedButtons name='Upgrade Plan' size='small'
                background='black'
                color="white"
                onClick={() => navigation("/pricing")}
              // icon={<AddIcon />}
              />
            </Grid>
            <Grid className='current-usage' sx={{ margin: 3 }} xs={8} sm={5.5} md={12} display={'flex'} alignContent={'center'} item>

              {/* <CustomizedButtons name='Request Feature' size='small'
                background='#ECECEC'
                color="black"
                onClick={() => navigation("/request-feedback")}
              // icon={<AddIcon />}
              /> */}
              <div
                onClick={() => navigation("/request-feedback")}
                className='req-button'>
                <p>Request Feature</p>
              </div>
              <div
                onClick={() => navigation("/product-updates")}
                className='up-button'>
                <p>Product Updates</p>
              </div>
              <div
                onClick={() => window.open('https://discord.gg/zZyjmEMNxx', '_blank')}
                className='fon-button'>
                <p> Talk to Founders 24/7</p>
              </div>
            </Grid>
          </Grid>

        </Grid>
      </Container>
      <DragDropModal setOpenDrag={setOpenDrag} currentTranscribe={currentTranscribe} open={openDrag} setOpen={setOpenDrag} submitVideo={submitVideo} submitAiStudio={submitAiStudio} />
      {/* <BannerModal open={bannerModal} setOpen={setBannerModal} text="Updates: We have added mov, webm and wmv support." duration="5000" /> */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Text copied to clipboard!"
        action={
          <Button color="secondary" size="small" onClick={handleClose}>
            Close
          </Button>
        }
      />

      <LanguageModal language={language} open={uploadTransModal} setOpenModal={(bool: boolean) => {
        setUploadTransModal(bool)
        // Reset the input value after processing the file
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }} type={modalType} onChange={setLanguage} onSubmit={submitVideo} />
      <BannerModal open={bannerModal} setOpen={setBannerModal} text="Video uploading may take sometime to load, please wait!" duration="3000" />
      {
        loadingPerform?.video_id &&
        <VideoProgress
          renderurl={progressUrl()}
          userId={loadingPerform.user_id}
          videoId={loadingPerform.video_id}
          checkForQueue={recentProject.find(({ video_id }: any) => video_id === loadingPerform.video_id)}
          moveForward={loadingPerform && loadingPerform?.active_service && (loadingPerform.active_service[0] === 'transcribe' || loadingPerform.active_service[0] === "aistudio" || loadingPerform.active_service[0] === "aishorts")}
          doReset={() => setLoadingPerform({ video_id: null, user_id: null, active_service: null, aistudio_editing_progress: false })}
          setLoadingPerform={(data: any) => {
            setLoadingPerform(data)
          }}
        >
        </VideoProgress>
      }
      <PodcastConfModal open={openPodcastModal} setOpen={setOpenPodcastModal} currentTranscribe={currentTranscribe} onSubmit={setOpenDrag}></PodcastConfModal>
      <div
        className="hover-scale"
        onClick={() => window.open('https://discord.gg/zZyjmEMNxx', '_blank')}
      >
        {!isXs && <span className="chat-text">Live Chat</span>}
        <ChatBubbleOutlineIcon />
      </div>
      <MessageModal open={alertModal.open} close={() => setAlertModal({ msg: "", open: false })} msg={alertModal.msg} />
      <LanguageCaptionModal open={languageCaptionModal} close={() => setLanguageCaptionModal(false)} updateLanguageOption={updateLanguageOption} />
      <PremiumModal open={premimumModal} setOpen={setPremiumModal}></PremiumModal>

    </Fragment >
  )
}

export default Main;