import axios from 'axios';
import { BASE_URL } from './url';
import { logEvent } from './ga.utils';



async function downloadVideo(url: string, name = "video"): Promise<void> {
  try {
    const downloadUrl = isIOS() ? `${BASE_URL}/download?url=${encodeURIComponent(url)}&name=${name}` : url;

    logEvent({ category: "Download Video", action: url, label: "" })

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = name;
    // if (!isIOS()) link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  } catch (error) {
    console.error(`Failed to download the video: ${error}`);
    throw new Error(`Failed to download the video: ${error}`);
  }
}

function isIOS(): boolean {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
}

function getFileTypeFromURL(url: string | undefined): string {
  if (url) {
    // Extracting the extension from the URL
    const extension = url.split('.').pop()?.split(/\#|\?/)[0].toLowerCase();

    // Mapping of common video file extensions to their MIME types
    const mimeTypes: { [key: string]: string } = {
      mp4: 'video/mp4',
      webm: 'video/webm',
      mkv: 'video/x-matroska',
      mov: 'video/quicktime',
      wmv: 'video/x-ms-wmv',
      avi: 'video/x-msvideo',
      flv: 'video/x-flv',
      ogv: 'video/ogg',
      m4v: 'video/x-m4v'
      // Add more as needed
    };

    if (extension && mimeTypes[extension]) {
      return mimeTypes[extension];
    }
  }

  return 'video/mp4'; // Default to mp4 if no match
}

// const downloadFile = async (url: string, filename: string) => {
//   try {
//     const response = await axios.get(url, {
//       responseType: 'blob', // To handle binary files
//     });

//     const blob = new Blob([response.data], { type: 'application/octet-stream' });

//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = filename;
//     link.click();
//     link.remove();
//   } catch (error) {
//     console.error(`Failed to download the file from ${url}: ${error}`);
//   }
// };

const downloadFile = async (url: string, filename: string = "video", content_type: string = "video/mp4") => {
  try {
    // Check if iOS and adjust the URL accordingly
    const downloadUrl = isIOS()
      ? `${BASE_URL}/download?url=${encodeURIComponent(url)}&name=${filename}&content_type=${content_type}`
      : url;

    const response = await axios.get(downloadUrl, {
      responseType: 'blob', // To handle binary files
    });

    const blob = new Blob([response.data], { type: 'application/octet-stream' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    link.remove();
  } catch (error) {
    console.error(`Failed to download the file from ${url}: ${error}`);
    throw new Error(`Failed to download the file from ${url}: ${error}`);
  }
};

export { downloadVideo, downloadFile }