import { Modal, Box, Typography, Button, useMediaQuery, useTheme, Grid, Switch, ToggleButtonGroup, ToggleButton, Alert } from '@mui/material'
import './style.css'
import PresetNew from '../../utils/presetsnew.json'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// ICONS
import CameraRollIcon from '@mui/icons-material/CameraRoll';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import HdrWeakIcon from '@mui/icons-material/HdrWeak';
import WbIridescentIcon from '@mui/icons-material/WbIridescent';
import TuneIcon from '@mui/icons-material/Tune';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AnimationIcon from '@mui/icons-material/Animation';
import SurroundSoundIcon from '@mui/icons-material/SurroundSound';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import NoiseAwareIcon from '@mui/icons-material/NoiseAware';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import CloseIcon from '@mui/icons-material/Close';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';

import { useContext, useEffect, useState } from 'react';
import Fonts from '../../utils/fonts';
import SketchExample from '../PIckerColor/colorPickerCus';
import NumberButton from '../NumberButton';
import GradientButton from '../Button/gradient';
import FontSizeChanger from '../FontSizeChanger/FontSize';
import PositionSlide from '../PositionSlider';
import { getToken } from '../../redux/slices/auth.slice';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { discordWebhook } from '../../redux/slices/feedbacks.slice';
import { DashboardContext } from '../../context/dashboard.context';
import toast from 'react-hot-toast';
import { getVideoFiles } from '../../redux/slices/video.slice';

const StudioModal = ({ onSubmit, open, setOpenModal }: any) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useAppDispatch()
    const contextValue = useContext(DashboardContext);

    if (!contextValue) {
        // handle the case where the context value is not provided
        throw new Error("VideoContext value is not provided");
    }

    const { studioData, setStudioData, activeButtons, setActiveButtons } = contextValue

    const id = useAppSelector(store => store.auth.user?.id)

    const [showAlert, setShowAlert] = useState(true);

    const handleButtonClick = (key: any) => {
        setActiveButtons((prev: any) => ({ ...prev, [key]: !prev[key] }));
        dispatch(discordWebhook(`aishorts video effect - ${key}`))

    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : "70%",
        maxWidth: '90%',
        maxHeight: '90vh', // Set maxHeight to a percentage of the viewport height
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: 'auto', // Enable vertical scrolling
        // paddingLeft: isMobile ? 2 : 20,
        // paddingRight: isMobile ? 2 : 20
    };


    const toggleButtons = [
        { key: 'brolls_on', label: 'B-Rolls', icon: CameraRollIcon },
        { key: 'overlays_on', label: 'Overlays', icon: AutoAwesomeIcon },
        { key: 'zoom_on', label: 'Cinematic Zoom', icon: ZoomInIcon },
        { key: 'scene_transitions', label: 'Transitions', icon: AnimationIcon },
        { key: 'enhanceAudio', label: 'Enhance Audio', disabled: true, icon: GraphicEqIcon },
        { key: 'video_filters', label: 'Filter', icon: LightbulbCircleIcon },
        { key: 'keyword_soundEffects_on', label: 'Sound Effects', icon: AudiotrackIcon },
        { key: 'removeSilence', label: 'Remove Silence', disabled: true, icon: NoiseAwareIcon },
        { key: 'bg_music_on', label: 'Background Music', icon: HeadphonesIcon },
        { key: 'laptop_overlay', label: 'Laptop Template', icon: LaptopMacIcon },
    ];

    function valuetext(value: number) {
        return `${value}`
    }

    const makeDefaultStyle = () => {
        setStudioData({
            preset: 'transpifyspecial',
            font: 'The Bold Font',
            colors: [
                "#FFFFFF",
                "#FF0000",
                "#AA00FF",
                "#009922"
            ],
            visuals: 'emojis',
            maxWords: 3,
            fontSize: 30,
            position: 6
        })
        setActiveButtons({
            brolls_on: true,
            overlays_on: true,
            zoom_on: true,
            scene_transitions: true,
            enhanceAudio: true,
            video_filters: true,
            keyword_soundEffects_on: true,
            bg_music_on: true,
            laptop_overlay: true,
        })
        toast("Default style is selected.")
    }

    useEffect(() => {
        dispatch(getToken(id))

    }, [])

    const marks = [
        {
            value: 1,
            label: 'Top',
        },
        {
            value: 2,
            label: '',
        },
        {
            value: 3,
            label: '',
        },
        {
            value: 4,
            label: '',
        },
        {
            value: 5,
            label: 'center',
        },
        {
            value: 6,
            label: '',
        },
        {
            value: 7,
            label: '',
        }, {
            value: 8,
            label: '',
        },
        {
            value: 9,
            label: 'Bottom',
        },
    ];



    return (
        <Modal
            open={open}
            // onClose={fal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modal-studio-box" sx={style}>
                <div style={{ position: 'absolute', right: 20, top: 15 }}>
                    <CloseIcon className="close-icon" onClick={() => setOpenModal(false)} />
                </div>
                <Grid container spacing={2} className="scrollable-content">
                    <Grid item xs={12}>
                        {showAlert && (
                            <Alert
                                severity="warning"
                                icon={<InfoOutlinedIcon fontSize="inherit" />}
                                onClose={() => setShowAlert(false)}
                            >
                                Whatever the language video is in, captions will be added in English
                            </Alert>
                        )}
                    </Grid>
                    <Grid item container spacing={1} justifyContent="center">
                        {toggleButtons.map(({ key, icon: Icon, label, disabled = false }) => (
                            <div
                                className={`icon-btn ${activeButtons[key] ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
                                onClick={() => !disabled && handleButtonClick(key)}
                            >
                                <Icon style={{ color: activeButtons[key] ? '#FFFFFF' : '#6D6D6D' }} />
                                <p>{label}</p>
                            </div>
                        ))}
                    </Grid>
                    <Grid item xs={12} container spacing={0.5} justifyContent="center" className="preset-main-box">
                        {PresetNew.slice(0, 6).map(({ picture, name, disabled, colors }) => {
                            if (disabled) return
                            return <Grid key={name} item xs={3.95} md={2.4}>
                                <div
                                    style={{
                                        borderColor: name === studioData.preset ? '#00A3FF' : "rgba(255,255,255,0.2)",
                                        borderWidth: "2px",
                                        minWidth: '155px', minHeight: '80px'
                                    }}
                                    onClick={() => setStudioData({ ...studioData, preset: name, colors, maxWords: studioData.maxWords == 1 ? 3 : studioData.maxWords })}
                                    className="box cursor-pointer box-shadoww"
                                >
                                    {name != "" && <img
                                        // onClick={() => setStudioData({ ...studioData, preset: name, colors })}
                                        src={picture}></img>}
                                </div>
                            </Grid>
                        })}
                    </Grid>
                    <Grid item xs={12} container spacing={2} justifyContent="center" className="preset-main-box">
                        {PresetNew.slice(6, 12).map(({ picture, name, disabled, colors }) => {
                            if (disabled && name !== "one_word") return
                            return <Grid key={name} item xs={3.95} md={2.4}>
                                <div
                                    style={{
                                        borderColor: name === studioData.preset ? '#00A3FF' : "rgba(255,255,255,0.2)",
                                        borderWidth: "2px",
                                        minWidth: '155px', minHeight: '80px'
                                    }}
                                    onClick={() => setStudioData({ ...studioData, preset: name, colors, maxWords: name === "one_word" ? 1 : studioData.maxWords == 1 ? 3 : studioData.maxWords })}
                                    className="box cursor-pointer box-shadoww"
                                >
                                    {name != "" && <img
                                        // onClick={() => setStudioData({ ...studioData, preset: name, colors })}
                                        style={(name == "ViralMe_AI_Studio_Captions") ? { width: 140, objectFit: 'contain' } : {}}
                                        src={picture}></img>}
                                </div>
                            </Grid>
                        })}
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                        <div className="scrollable-container">
                            {Fonts.map(({ family }) => {
                                const isSelected = studioData.font === family;
                                return (
                                    <div
                                        onClick={() => setStudioData({ ...studioData, font: family })}
                                        className={`font-circle cursor-pointer ${studioData.preset === "plaintext" ? "box-shadoww" : ""} ${(isSelected && studioData.preset === "plaintext") ? 'font-circle-selected' : ''}`}
                                        style={{
                                            display: 'inline-flex',
                                            marginRight: '10px',
                                        }}
                                        key={family}
                                    >
                                        <p
                                            style={{
                                                fontFamily: family,
                                                color: (isSelected && studioData.preset === "plaintext") ? '#0075E1' : '#A1A1A1',
                                                fontSize: 20
                                            }}
                                        >Aa</p>
                                    </div>
                                );
                            })}
                        </div>
                    </Grid>
                    <Grid container item xs={12} spacing={2} justifyContent="center">
                        <Grid item sx={{}} sm={12} xs={12} md={4}>
                            {studioData.colors && studioData.colors.length > 0 && <> <p className='title-name mb-2'>Font Colors</p>
                                <div className='display-flex'>
                                    {studioData.colors && studioData.colors.length > 0 && studioData.colors?.map((_: any, i: number) =>
                                        <Grid item key={i} sm={2} xs={2} md={2}>
                                            <SketchExample
                                                name={i}
                                                updateCaptionColor={(colors: string[]) => setStudioData({ ...studioData, colors })}
                                                captionColor={studioData.colors}
                                            />
                                        </Grid>
                                    )}
                                </div></>}
                        </Grid>
                        <Grid item md={4} xs={12} sm={12}>
                            <p className='title-name mb-2'>Visuals</p>
                            <ToggleButtonGroup
                                color="primary"
                                value={studioData.visuals || "none"}
                                exclusive
                                onChange={(_: any, value: string) => { if (value !== null) setStudioData({ ...studioData, visuals: value }) }}
                                aria-label="Platform"
                                className='box-shadoww'
                                sx={{ height: "32px" }}
                            >
                                <ToggleButton value="emojis">Emojis</ToggleButton>
                                {/* <ToggleButton value="GIFs">GIFs</ToggleButton> */}
                                <ToggleButton value="none">None</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item sx={{}} sm={12} xs={12} md={4}>
                            <p className='title-name mb-2'>Max words</p>
                            <NumberButton num={studioData.preset == "one_word" ? 1 : 8} onChange={(maxWords: number) => setStudioData({ ...studioData, maxWords })}
                                PlatformDisabledVideo={(studioData.visuals !== "none")} maxWords={studioData.maxWords}
                            />
                        </Grid>
                        <Grid item sx={{}} sm={12} xs={12} md={4}>
                            <p className='title-name mb-2'>Font Size</p>
                            <FontSizeChanger
                                fontSizeCaption={studioData.fontSize}
                                disabled={false}
                                updateFontSize={(val: number) => setStudioData({ ...studioData, fontSize: val })}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12} md={4}>
                            <p className='title-name mb-2' >Position</p>
                            <PositionSlide
                                min={1}
                                max={9}
                                disabled={studioData.preset === "transpify_special"}
                                aria-label="Small steps"
                                onChange={(a, b) => setStudioData({ ...studioData, position: b as number })}
                                getAriaValueText={valuetext}
                                value={studioData.position}
                                valueLabelDisplay="auto"
                                sx={{ margin: "0.6px 10px" }}
                                defaultValue={studioData.position || 6}
                                marks={marks}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} item sx={{ marginTop: 2 }} md={12} sm={12} xs={12}>
                        <Grid item sm={12} xs={12} md={6} style={{ display: 'flex', alignItems: 'self-end' }} >
                            <div onClick={makeDefaultStyle}>
                                <p className='default-set'>Default Style</p>
                            </div>

                            <p style={{ marginLeft: 5 }} className='desc-end'> (Recommended)</p>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} display={'flex'} justifyContent={"flex-end"}>
                            <GradientButton onClick={() => onSubmit({ studioData, activeButtons, check: false })}>
                                <p>
                                    Create Shorts
                                </p>
                            </GradientButton>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} md={12} sm={12} xs={12}>

                        <p className='desc-end'>
                            All shorts will be created in the same configurations
                        </p>
                    </Grid>
                </Grid>
            </Box>
        </Modal >
    );
}

export default StudioModal;
