import React, { useEffect, useState } from 'react';
import './style.css'
import { SEVER_URL } from '../../utils/url';
import { Modal, Box, Typography, useMediaQuery, useTheme, CircularProgressProps, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';
import { addVideoId, getVideoFiles } from '../../redux/slices/video.slice';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { mediaPlayerAction } from '../../redux/slices/mediaPlayer';


function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: -0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          className='text-progress'
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}




const VideoProgress = ({ userId, videoId, renderurl = "video_rendering_progress", checkForQueue, moveForward = false, doReset, setLoadingPerform = () => { }, close = true }: any) => {
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const videoFile = useAppSelector((state: RootState) => state.video.videoFile)
  const [progress, setProgress] = useState<number | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(true);
  const [randomNumber, setRandomNumber] = useState<number>(0)
  const initialMessages = [
    "🕒 Hang tight! We’re stirring up a delightful experience for you.",
    "💭 While you wait, imagine a world where load times are instant.",
    "☕ Sip some patience while we prepare your digital delight.",
    "✨ Brewing up some digital magic! Your turn is next.",
    "⚡ In the queue, but don’t worry – our servers are caffeinated!",
    "🙊 Our code monkeys are typing furiously to serve you.",
    "🧘‍♂️ In the meantime, ponder the meaning of life.",
    "🎟 Queueing you up like a VIP at an exclusive nightclub ;)",
    "🎓 Your patience level is about to reach ‘expert mode’.",
    "🐵 Hold tight, we are waking up our drunk servers.",
    "For fast rendering / response, please consider subscribing the creator plan"
  ];

  if (videoFile?.active_service[0] === "aishorts") {
    initialMessages.unshift("⏳ Thank you for your patience! This process will take some time to render all the shorts. Feel free to leave the browser and check back in about 30 minutes.");
  }

  const [msg, setMsg] = useState<string[]>(initialMessages);


  const notify = (msg: string) => toast(msg);

  useEffect(() => {
    if (checkForQueue && (checkForQueue?.video_in_queue || checkForQueue?.in_queue_waiting || checkForQueue?.in_queue_waiting_aistudio_edit)) {
      notify("Video is in queue, Please wait!")
      setProgress(5)
      return
    }
    const url = `${SEVER_URL}${renderurl}/${userId}/${videoId}`;
    const eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setProgress(data.progress);

      // Check if progress is 100%, if so, close the modal
      if (data.progress == 100)
        dispatch(getVideoFiles({ userId: userId, videoId }))
      if (data.progress >= 100) {
        if (moveForward) {
          doReset()
          dispatch({ type: "mediaPlayer/setDefault", payload: videoId })
          dispatch(addVideoId(videoId))
          if (checkForQueue.active_service[0] == "transcribe") navigation(`/video`)
          else if (checkForQueue.active_service[0] == "aistudio") navigation(`/ai-studio`)
          else if (checkForQueue.active_service[0] == "aishorts") navigation(`/ai-shortsv2`)
          setOpen(false);
        }
        setOpen(false); // This line will close the modal when progress is complete
      }
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      eventSource.close();
    };

    return () => {
      setLoadingPerform({ user_id: null, video_id: null, active_service: null })
      eventSource.close();
      setOpen(false)
    };
  }, [userId, videoId, setOpen]); // Added setOpen to the dependency array

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '90%' : "30%",
    maxWidth: '90%',
    bgcolor: 'background.paper',
    border: '2px solid white',
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backdropFilter: "blur(3px)", // Apply a blur effect to the background
    background: "rgba(255, 255, 255, 1)" // Semi-transparent background
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Shuffle the msg array
      const shuffledMsg = [...msg].sort(() => Math.random() - 0.5);
      setMsg(shuffledMsg);

      // Set a random number based on the shuffled array length
      setRandomNumber(Math.floor(Math.random() * shuffledMsg.length));
    }, 10000); // 10 seconds in milliseconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []); // Run effect only once on component mount
  return (
    <Modal
      open={open}
      onClose={() => {
        if (close) {
          setLoadingPerform({ user_id: null, video_id: null, active_service: null })
          setOpen(false)
        }
      }}
      aria-labelledby="video-progress-modal-title"
      aria-describedby="video-progress-modal-description"
    >
      <Box sx={style}>
        {checkForQueue && checkForQueue?.video_in_queue ? <p className='text-msg'>video is in queue</p> : <CircularProgressWithLabel size={100} sx={{ color: '#00A3FF' }} value={progress as number} />}
        <p className='text-msg'> {msg[randomNumber]}
        </p>
      </Box>
    </Modal>
  );
};

export default VideoProgress;
