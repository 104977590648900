const Fonts = [
    {
        "family": "The Bold Font"
    },
    {
        "family": "Roboto"
    },
    {
        "family": "Garamond"
    },
    {
        "family": "Petemoss"
    },
    {
        "family": "Meie Script"
    },
    {
        "family": "Play"
    },
    {
        "family": "Lora"
    },
    {
        "family": "Cuprum"
    },
    {
        "family": "Noto Sans"
    },
    {
        "family": "NovaMono"
    },
    {
        "family": "Oleo Script"
    },
    {
        "family": "Pacifico"
    },
    {
        "family": "Comic Sans MS"
    },
    {
        "family": "Ribeye Marrow"
    },
    {
        "family": "Playball"
    },
    {
        "family": "Post No Bills Jaffna Extrabold"
    },
    {
        "family": "Shalimar"
    },
    {
        "family": "Shantell Sans"
    },
    {
        "family": "Ubuntu Mono"
    },
    {
        "family": "Poiret One"
    }
]

export default Fonts