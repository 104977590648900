/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import "./App.css";
import './utils/firebase'
import { Provider, useSelector } from 'react-redux';
import { RootState, store } from "./redux/store";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Toaster } from 'react-hot-toast';


import { SignIn, SignUp, Video, Main, Payment, CheckoutForm, Titties, ResetPassword, Working, Profile } from "./screens";
import { me, resetAuthError } from "./redux/slices/auth.slice";
import { useAppDispatch } from "./hooks/redux";
import { resetExportError } from "./redux/slices/export.slice";
import ProtectedRoute from "./screens/ProtextedRoute";
import { initGA, logPageView } from "./utils/ga.utils";
import { getDatabase, ref, onValue, set } from 'firebase/database';
import FeedbackRequest from "./screens/Feedback";
import DenoiseAudio from "./screens/DenoiseAudio";
import PublishVideo from "./screens/PublishVideo";
import TextToVideo from "./screens/TextToVideo";
import AIShorts from "./screens/AIShorts";
import Upload from "./screens/Upload";
import ProductUpdate from "./screens/ProductUpdate";
import AIStudioVideo from "./screens/AIStudioVideo";
import AIShortsVideo from "./screens/AIShortsVideo";
// import PrivacyPolicy from "./screens/Privacy/privacypolicy";
// import TermsOfUse from "./screens/Privacy/termsofuse";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoute><Main /></ProtectedRoute>
  },
  {
    path: "/video",
    element: <ProtectedRoute><Video /></ProtectedRoute>
  },
  {
    path: "/ai-studio",
    element: <ProtectedRoute><AIStudioVideo /></ProtectedRoute>
  },
  {
    path: "/titties",
    element: <ProtectedRoute><Titties /></ProtectedRoute>
  },
  {
    path: "/profile",
    element: <ProtectedRoute><Profile /></ProtectedRoute>
  },
  {
    path: "/google",
    element: <ProtectedRoute><></></ProtectedRoute>
  },
  {
    path: '/signin',
    element: <SignIn />
  },
  {
    path: '/signup',
    element: <SignUp />
  },
  {
    path: '/reset-password',
    element: <ResetPassword />
  },
  {
    path: '/pricing',
    element: <ProtectedRoute><Payment /></ProtectedRoute>
  },
  {
    path: '/checkout/:subscriptionId',
    element: <ProtectedRoute><CheckoutForm /></ProtectedRoute>
  },
  {
    path: '/request-feedback',
    element: <ProtectedRoute><FeedbackRequest /></ProtectedRoute>
  },
  {
    path: '/denoise',
    element: <ProtectedRoute><DenoiseAudio></DenoiseAudio></ProtectedRoute>
  },
  {
    path: '/text-video',
    element: <ProtectedRoute><TextToVideo></TextToVideo></ProtectedRoute>
  },
  {
    path: '/publish',
    element: <ProtectedRoute><PublishVideo></PublishVideo></ProtectedRoute>
  },
  {
    path: '/publish-aishorts/:videoIndex',
    element: <ProtectedRoute><AIShorts></AIShorts></ProtectedRoute>
  },
  {
    path: '/ai-shortsv2',
    element: <ProtectedRoute><AIShortsVideo></AIShortsVideo></ProtectedRoute>
  },
  {
    path: '/upload',
    element: <ProtectedRoute><Upload></Upload></ProtectedRoute>
  },
  {
    path: '/product-updates',
    element: <ProductUpdate></ProductUpdate>
  },
  // {
  //   path: '/privacy-policy',
  //   element: <PrivacyPolicy></PrivacyPolicy>
  // },
  // {
  //   path: '/terms-of-use',
  //   element: <TermsOfUse></TermsOfUse>
  // }
]);


function App() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const [transpifyWorking, setTranspifyWorking] = useState(false);  // State to store the Firebase value

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && !isAuthenticated && token !== "null") {
      dispatch({ type: 'SET_TOKEN', payload: token });
      setTimeout(() => {
        dispatch(me());
      }, 2000);
    }

  }, [dispatch]);

  // console.log('API Key:', process.env.SEVER_URL);

  useEffect(() => {
    // (process.env.NODE_ENV === "production" ||
    //   process.env.REACT_APP_ENV === "STAGING") &&
    //   GlobalDebug(false);

    initGA();
    logPageView();

    // As soon as the component mounts (i.e., on app start), reset the messages
    dispatch(resetAuthError());
    dispatch(resetExportError());

  }, []);

  useEffect(() => {
    const db = getDatabase();
    const transpifyWorkingRef = ref(db, '/transpifyWorking'); // Adjust the path to the actual location in your Firebase Database

    // Listen for changes in the value
    const unsubscribe = onValue(transpifyWorkingRef, (snapshot) => {
      setTranspifyWorking(snapshot.val());
    });

    return () => unsubscribe(); // Cleanup the listener on component unmount
  }, []);

  return (
    <Provider store={store}>
      {transpifyWorking ? <Working /> : <RouterProvider router={router} />}
      <Toaster />
    </Provider>
  );
}

export default App;
