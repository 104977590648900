import { InputBase } from "@mui/material"
import { styled, } from '@mui/material/styles';

const InputField = styled(InputBase)(({ theme, error }) => ({
    width: '100%',
    marginTop: 6,
    // 'label + &': {
    //     marginTop: theme.spacing(3),
    // },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? 'rgba(217, 217, 217, 0.07)' : 'rgba(217, 217, 217, 0.07)',
        border: '1px solid',
        borderColor: error ? "red" : theme.palette.mode === 'light' ? '#ced4da' : '#ced4da',
        // width: '100%',
        // height: 10,
        color: "#4E4E4E",
        fontFamily: "Inter",
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        // padding: '6px 12px',
        // transition: theme.transitions.create([
        //     'border-color',
        //     'background-color',
        //     'box-shadow',
        // ]),

        // '&:focus': {
        //     boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        //     borderColor: theme.palette.primary.main,
        // },
    },
}));

export default InputField

