import { Modal, Box, Typography, Button, useMediaQuery, useTheme, Grid, Switch } from '@mui/material'
import CustomizedSelects from '../DropDown/CustomizedSelect';
import languageJSON from '../../utils/language.json';
import AutoComplete from '../AutoComplete';
import { getKeyByValue } from '../../utils/functions';


const LanguageModal = ({ type, onChange, onSubmit, open, check = true, setOpenModal, msg, language = "em" }: any) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : "40%",
        maxWidth: '90%',
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };


    return (
        <Modal
            open={open}
            onClose={() => { (type == "language" && setOpenModal) && setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {type === "language" ? (
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                            color: "#4A4A4A",
                            textAlign: "center",
                            fontFamily: "Nunito",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                        }}>
                            Select a language and Caption for transcription
                        </Typography>
                        <div style={{ width: "50%", padding: isMobile ? "10px 0" : "20px 0" }}>
                            <AutoComplete className="sixth-step" selected={(languageJSON as any)[language]}
                                handleChange={(e: any, value: string) => {
                                    const key = getKeyByValue(languageJSON, value)
                                    onChange(key)
                                }}
                                options={
                                    Object.entries(languageJSON).map(([value, name]) => ({
                                        value,
                                        label: name
                                    }))
                                }
                            />
                        </div>
                        {/* <div style={{ display: "flex" }}>
                            <Grid item xs={8} md={8}>
                                <Typography sx={{
                                    color: "#000",
                                    textAlign: "center",
                                    fontFamily: "Nunito",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                }} className='overlay-title'>Harmozi Captions</Typography>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Switch onChange={(e) => {
                                    setHarmoziCaption(e.target.checked)
                                }} value={harmoziCaptions} />
                            </Grid>
                        </div> */}
                        <Typography id="modal-modal-description" sx={{
                            color: "#000",
                            textAlign: "center",
                            fontFamily: "Nunito",
                            fontSize: "11.794px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                        }}>
                            You can select any language available for the transcription, it doesn’t have to be the same as video’s.
                        </Typography>
                        <Button variant="outlined"
                            sx={{
                                marginTop: 2,
                                backgroundColor: 'white',
                                borderColor: '#4623E9',
                                color: '#4623E9',
                                '&:hover': {
                                    borderColor: '#4623E9' // Light blue on hover
                                }
                            }}
                            onClick={onSubmit}>
                            Transcribe
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                            color: "#4A4A4A",
                            textAlign: "center",
                            fontFamily: "Nunito",
                            fontSize: "22px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                        }}>
                            {msg ? msg : check ? "Magic is happening, behind the screen!" : "let's do it"}
                        </Typography>
                        <img style={{ width: 100, marginTop: 10 }} src="./Loading.gif" alt="Loading" />
                    </>
                )}
            </Box>
        </Modal>
    );
}

export default LanguageModal;
