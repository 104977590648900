// TimelineShapeLayer.tsx
import React, { useState, useEffect, useCallback, useContext } from 'react';

import { Rect, Text, Group } from 'react-konva';

import './SliderStyles.css';
import { VideoContext } from '../../context/video.context';

const TimelineShapeLayer = ({ scale, scrollContainerRef, shapeLayerYPosition, videoDuration }) => {
    const padding = 10; // // Padding value to be applied consistently to all layers
    const [blockJustClicked, setBlockJustClicked] = useState(false); // // for de-selection of shape block
    // const shapeBlocksYPosition = -3; // Y position of the shape blocks on scale
    const [scrollPosition, setScrollPosition] = useState(0); // Initial scroll position

    const contextValue = useContext(VideoContext);

    if (!contextValue) {
        // handle the case where the context value is not provided
        throw new Error("VideoContext value is not provided");
    }

    const { shapes, setShapes, scaleLength, selectedBlockId,setSelectedBlockId } = contextValue;

    // useEffect(() => {
    //     const handleWindowClick = () => {
    //         if (!blockJustClicked) setSelectedBlockId(null);
    //     };

    //     const handleKeyDown = (event) => {
    //         if ((event.key === 'Delete' || event.key === 'Backspace') && selectedBlockId != null) {
    //             setShapes(shapes.filter(shape => shape.id !== selectedBlockId));
    //             setSelectedBlockId(null);
    //         }
    //     };

    //     window.addEventListener('keydown', handleKeyDown);
    //     window.addEventListener('click', handleWindowClick);

    //     return () => {
    //         window.removeEventListener('keydown', handleKeyDown);
    //         window.removeEventListener('click', handleWindowClick);
    //     };
    // }, [shapes, selectedBlockId, blockJustClicked]);

    // Function to handle dragging of shape blocks 
    // const handleDragEnd = (id, x) => {

    //     // Adjust for padding while ensuring that the shape block can go to 0
    //     const newStart = Math.max(0, (x - padding) / scale);

    //     const shape = shapes.find(c => c.id === id);
    //     if (!shape) {
    //         return; // Shape not found, exit early
    //     }

    //     // Keep the duration of the shape block constant
    //     const duration = shape.end - shape.start;
    //     const newEnd = newStart + duration;

    //     // Update the shape's start and end times
    //     setShapes(shapes.map(c => c.id === id ? { ...c, start: newStart, end: newEnd } : c));
    // };

    // const checkBoundaries = (pos, currentShape, shapes, scale, padding) => {
    //     const shapeWidth = (currentShape.end - currentShape.start) * scale;
    //     let newX = pos.x - padding;

    //     const sortedShapes = [...shapes].sort((a, b) => a.start - b.start || a.id - b.id);
    //     const currentIndex = sortedShapes.findIndex(c => c.id === currentShape.id);
    //     const prevShape = sortedShapes[currentIndex - 1];
    //     const nextShape = sortedShapes[currentIndex + 1];

    //     // Adjust left and right boundaries
    //     const leftBoundary = prevShape ? (prevShape.end * scale) : 0;
    //     const rightBoundary = nextShape ? (nextShape.start * scale) : (scale * scaleLength);

    //     if (newX < leftBoundary) {
    //         newX = leftBoundary;
    //     } else if (newX + shapeWidth > rightBoundary) {
    //         newX = rightBoundary - shapeWidth;
    //     }

    //     newX += padding;

    //     return { x: newX, y: shapeLayerYPosition };
    // };

    // Function to handle dragging of shape blocks
    const handleDragEnd = (id, x) => {
        // Adjust for padding while ensuring that the shape block can go to 0
        const newStart = Math.max(0, (x - padding) / scale);

        const shape = shapes.find(c => c.id === id);
        if (!shape) {
            return; // Shape not found, exit early
        }

        // Keep the duration of the shape block constant
        const duration = shape.end - shape.start;
        let newEnd = newStart + duration;

        // Ensure the shape block does not exceed the video duration
        if (videoDuration) {
            newEnd = Math.min(newEnd, videoDuration);
        }

        // Update the shape's start and end times
        setShapes(shapes.map(c => c.id === id ? { ...c, start: newStart, end: newEnd } : c));
    };

    const checkBoundaries = (pos, currentShape, shapes, scale, padding) => {
        const shapeWidth = (currentShape.end - currentShape.start) * scale;
        let newX = pos.x - padding;

        const sortedShapes = [...shapes].sort((a, b) => a.start - b.start || a.id - b.id);
        const currentIndex = sortedShapes.findIndex(c => c.id === currentShape.id);
        const prevShape = sortedShapes[currentIndex - 1];
        const nextShape = sortedShapes[currentIndex + 1];

        // Adjust left and right boundaries
        const leftBoundary = prevShape ? (prevShape.end * scale) : 0;
        let rightBoundary = nextShape ? (nextShape.start * scale) : (scale * scaleLength);

        // If video duration is available, use it as the right boundary
        if (videoDuration) {
            rightBoundary = Math.min(rightBoundary, videoDuration * scale);
        }

        if (newX < leftBoundary) {
            newX = leftBoundary;
        } else if (newX + shapeWidth > rightBoundary) {
            newX = rightBoundary - shapeWidth;
        }

        newX += padding;

        return { x: newX, y: shapeLayerYPosition };
    };

    // Function to handle resizing of shape blocks
    const handleLeftResizeMouseDown = (e, shapeId) => {
        e.cancelBubble = true;
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        function handleMouseMove(moveEvent) {
            const stage = e.target.getStage();
            const stageBox = stage.container().getBoundingClientRect();
            const newPosition = moveEvent.clientX - stageBox.left;
            let newStart = (newPosition - padding) / scale;

            const sortedShapes = [...shapes].sort((a, b) => a.time.start - b.start || a.id - b.id);
            const currentIndex = sortedShapes.findIndex(c => c.id === shapeId);
            const shape = sortedShapes[currentIndex];
            const prevShape = currentIndex > 0 ? sortedShapes[currentIndex - 1] : null;

            const minDuration = 0.5;
            const maxStart = shape.end - minDuration;
            let minStart = 0;

            if (prevShape) {
                minStart = Math.max(prevShape.end, 0);
            }

            newStart = Math.max(newStart, minStart);
            newStart = Math.min(newStart, maxStart);

            setShapes(shapes.map(c =>
                c.id === shapeId ? { ...c, start: newStart, end: shape.end } : c
            ));
        }

        function handleMouseUp() {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
    };

    // // Function to handle resizing of shape blocks
    // const handleRightResizeMouseDown = (e, shapeId) => {
    //     e.cancelBubble = true;
    //     window.addEventListener('mousemove', handleMouseMove);
    //     window.addEventListener('mouseup', handleMouseUp);

    //     function handleMouseMove(moveEvent) {
    //         const stage = e.target.getStage();
    //         const stageBox = stage.container().getBoundingClientRect();
    //         const newPosition = moveEvent.clientX - stageBox.left;
    //         let newEnd = (newPosition - padding) / scale;

    //         const sortedShapes = [...shapes].sort((a, b) => a.start - b.start || a.id - b.id);
    //         const currentIndex = sortedShapes.findIndex(c => c.id === shapeId);
    //         const shape = sortedShapes[currentIndex];
    //         const nextShape = sortedShapes[currentIndex + 1];

    //         const minDuration = 0.5;
    //         const minEnd = shape.start + minDuration;
    //         let maxEnd = scaleLength;

    //         if (nextShape) {
    //             maxEnd = Math.min(nextShape.start, scaleLength);
    //         }

    //         newEnd = Math.max(newEnd, minEnd);
    //         newEnd = Math.min(newEnd, maxEnd);

    //         setShapes(shapes.map(c => 
    //             c.id === shapeId ? { ...c, end: newEnd } : c
    //         ));
    //     }

    //     function handleMouseUp() {
    //         window.removeEventListener('mousemove', handleMouseMove);
    //         window.removeEventListener('mouseup', handleMouseUp);
    //     }
    // };

    const handleRightResizeMouseDown = (e, shapeId) => {
        e.cancelBubble = true;
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        function handleMouseMove(moveEvent) {
            const stage = e.target.getStage();
            const stageBox = stage.container().getBoundingClientRect();
            const newPosition = moveEvent.clientX - stageBox.left;
            let newEnd = (newPosition - padding) / scale;

            const sortedShapes = [...shapes].sort((a, b) => a.start - b.start || a.id - b.id);
            const currentIndex = sortedShapes.findIndex(c => c.id === shapeId);
            const shape = sortedShapes[currentIndex];
            const nextShape = sortedShapes[currentIndex + 1];

            const minDuration = 0.5;
            const minEnd = shape.start + minDuration;
            let maxEnd = scaleLength;

            // If video duration is available, use it as the maxEnd
            if (videoDuration) {
                maxEnd = Math.min(maxEnd, videoDuration);
            }

            if (nextShape) {
                maxEnd = Math.min(maxEnd, nextShape.start);
            }

            newEnd = Math.max(newEnd, minEnd);
            newEnd = Math.min(newEnd, maxEnd);

            setShapes(shapes.map(c =>
                c.id === shapeId ? { ...c, end: newEnd } : c
            ));
        }

        function handleMouseUp() {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
    };

    // Function to scroll the container when dragging shape blocks
    const handleDragMove = (id, x) => {
        const shape = shapes.find(c => c.id === id);
        // Adjust the block edges considering the padding
        const blockLeftEdge = x - padding;
        const blockRightEdge = x + (shape.end - shape.start) * scale + padding;
        const viewportRightEdge = scrollContainerRef.current.scrollLeft + scrollContainerRef.current.clientWidth;
        const scrollThreshold = 50; // pixels threshold from the edges

        // Trigger scrolling when the block right edge is near the right edge of the viewport
        if (blockRightEdge >= viewportRightEdge - scrollThreshold) {
            const newScrollPosition = scrollContainerRef.current.scrollLeft + 20;
            setScrollPosition(newScrollPosition);
            scrollContainerRef.current.scrollLeft = newScrollPosition;
        }

        // Trigger scrolling when the block left edge is near the left edge of the viewport
        // Taking into account the padding and the threshold
        if (blockLeftEdge <= scrollContainerRef.current.scrollLeft + scrollThreshold) {
            const newScrollPosition = scrollContainerRef.current.scrollLeft - 20;
            setScrollPosition(newScrollPosition);
            scrollContainerRef.current.scrollLeft = newScrollPosition;
        }
    };

    // Function to create draggable shape blocks with boundaries
    const createShapeBlocks = () => {
        return shapes.map((shape) => {
            const blockWidth = (shape.end - shape.start) * scale;
            const boundaryWidth = 5; // Width of the left/right boundary rectangles
            const boundaryHeight = 2; // Height of the top/bottom boundary rectangles
            const innerBoundaryOffset = 5; // Offset for boundaries inside the block
            const isSelected = shape.id === selectedBlockId;

            return (
                // console.log("shape id click here is: ", shape.id),
                <Group
                    key={shape.id}
                    id={`shape-block-${shape.id}`} // Assign unique ID to each shape block
                    x={shape.start * scale + padding}
                    // y={shapeBlocksYPosition}
                    y={shapeLayerYPosition}
                    draggable
                    onDragStart={() => setSelectedBlockId(shape.id)}
                    // onDragEnd={(e) => handleDragEnd(shape.id, e.target.x())}
                    onDragEnd={(e) => {
                        const rawXPosition = e.target.x();
                        // Call handleDragEnd with the raw x position
                        handleDragEnd(shape.id, rawXPosition);
                    }}
                    // dragBoundFunc={(pos) => checkBoundaries(pos, shape, shapes, scale)}
                    dragBoundFunc={(pos) => checkBoundaries(pos, shape, shapes, scale, padding)}
                    onDragMove={(e) => handleDragMove(shape.id, e.target.x())}  // Scroll the container when dragging
                    onClick={() => {
                        setSelectedBlockId(shape.id);
                        setBlockJustClicked(true);
                        setTimeout(() => setBlockJustClicked(false), 10);

                    }}
                >
                    {/* Shape block */}
                    <Rect
                        width={blockWidth}
                        height={30}
                        fill='#DEDAF4'
                    />

                    {/* Shape text */}
                    <Text
                        text={shape.text}
                        fontSize={15}
                        fontFamily="Calibri"
                        fill="red"
                        align="center"
                        verticalAlign="middle"
                        width={blockWidth}
                        height={30}
                    />

                    {/* Top boundary (inside the block) */}
                    <Rect
                        x={0}
                        y={-boundaryHeight}
                        width={blockWidth}
                        height={boundaryHeight}
                        fill={isSelected ? 'green' : '#FF0000'}
                    />

                    {/* Bottom boundary (inside the block) */}
                    <Rect
                        x={0}
                        y={30}
                        width={blockWidth}
                        height={boundaryHeight}
                        fill={isSelected ? 'green' : '#FF0000'}
                    />

                    {/* Left boundary (inside the block) */}
                    <Rect
                        onMouseDown={(e) => {
                            handleLeftResizeMouseDown(e, shape.id);
                            setSelectedBlockId(shape.id); // Manually set selected block
                        }}
                        x={innerBoundaryOffset - innerBoundaryOffset}
                        width={boundaryWidth}
                        height={30}
                        fill={isSelected ? 'green' : '#FF0000'}
                    />

                    {/* Right boundary (inside the block) */}
                    <Rect
                        onMouseDown={(e) => {
                            handleRightResizeMouseDown(e, shape.id);
                            setSelectedBlockId(shape.id); // Manually set selected block
                        }}
                        x={blockWidth - innerBoundaryOffset}
                        width={boundaryWidth}
                        height={30}
                        fill={isSelected ? 'green' : '#FF0000'}
                    />
                </Group>
            );
        });
    };


    return (
        createShapeBlocks()
    );
};

export default TimelineShapeLayer;