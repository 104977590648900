import React, { useCallback } from 'react'
import api from '../utils/api'
import { useAppDispatch } from './redux'

const useExport = () => {
  const dispatch = useAppDispatch()

  const youtubeExportUpload = useCallback((credential: any) => {
    dispatch({ type: 'export/Youtube_Pending' })
    api
      .post('/upload/youtube', credential)
      .then(({ data }) => {
        dispatch({ type: 'export/Youtube_Success', payload: data })
      })
      .catch(({ response
      }) => {
        dispatch({ type: 'export/Youtube_Error', payload: { error: response.data } })
      })
  }, [])

  return {
    youtubeExportUpload,
  }
}

export default useExport
