import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from '../../redux/store'; // Adjust the path to your store

type ToastMessage = {
    type: 'error' | 'info' | 'success' | 'warning';  // These are the typical values for AlertColor
    content: string;
};

const Toast = () => {
    const [show, setShow] = useState(false);
    const [messageContent, setMessageContent] = useState<ToastMessage>({ type: "info", content: "" });

    const error = useSelector((state: RootState) => state.auth.error);
    const { success, message, error: errorExport } = useSelector((state: RootState) => state.export);

    useEffect(() => {
        if (error) {
            setMessageContent({ type: "error", content: error });
            setShow(true);
        } else if (success && message) {
            setMessageContent({ type: errorExport ? "error" : "success", content: message });
            setShow(true);
        }
        else {
            setShow(false);
        }
    }, [error, success, message]);

    return (
        <Snackbar
            open={show}
            onClose={() => setShow(false)}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            action={
                <IconButton size="small" color="inherit" onClick={() => setShow(false)}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
        >
            <Alert onClose={() => setShow(false)} severity={messageContent.type} variant="filled">
                {messageContent.content}
            </Alert>
        </Snackbar>
    );
};

export default Toast;
