export default [
    {
        "name": "Free",
        "bank_statement_name": "Free",
        "prod_id": "prod_Q4qvEO6Or4IaVW",
        "color": "",
        "style":{
            "bg":"#f2dccd"
        },
        "description": "For werewolves, who wanna start out. You will be able to create upto 38 Shorts or Reels and more than 15 AI generated video using Text to Video. ",
        "cycle": [
            {
                "currency": "usd",
                "subscriptionId": "",
                "price": 0.00,
                "without_discount": 0.00,
                "type": "yearly"
            },
            {
                "currency": "usd",
                "subscriptionId": "",
                "price": 0.00,
                "type": "monthly"
            }
        ],
        "feature": [
            {
                "value": "2 mins",
                "name": "ViralMe Studio",
                "status": true
            },
            {
                "value": "10 mins",
                "name": "ViralMe AI Shorts",
                "status": true
            },
            {
                "value": "10 mins",
                "name": "AI Captions",
                "status": true
            },
            {
                "value": "5 mins",
                "name": "Text to Video",
                "status": true
            },
            {
                "value": "2 mins",
                "name": "AI Voiceover",
                "status": true
            },
            {
                "value": "5 mins",
                "name": "SuperMotion & Video Compression",
                "status": true
            },
            {
                "value": "Unlimited",
                "name": "Audio Denoiser",
                "status": true
            },
        ]
    },
    {
        "name": "Starter Plan",
        "bank_statement_name": "Starter Plan",
        "prod_id": "prod_Q4qvEO6Or4IaVW",
        "style":{
            "bg":"#fff6f6"
        },
        "color": "black",
        "description": "For newbies, who wanna start out. You will be able to create upto 38 Shorts or Reels and more than 15 AI generated video using Text to Video. ",
        "cycle": [
            {
                "currency": "usd",
                "subscriptionId": "price_1PEhDKCEDzUkS6E0fVLjNqXG",
                "price": 5.00,
                "yearly_price": 60.00,
                "without_discount": 120.00,
                "type": "yearly"
            },
            {
                "currency": "usd",
                "subscriptionId": "price_1PEhDKCEDzUkS6E0BJIM39ra",
                "price": 10.00,
                "type": "monthly"
            }
        ],
        "feature": [
            {
                "value": "8 mins",
                "name": "ViralMe Studio",
                "status": true
            },
            {
                "value": "30 mins",
                "name": "ViralMe AI Shorts",
                "status": true
            },
            {
                "value": "30 mins",
                "name": "AI Captions",
                "status": true
            },
            {
                "value": "15 mins",
                "name": "Text to Video",
                "status": true
            },
            {
                "value": "5 mins",
                "name": "AI Voiceover",
                "status": true
            },
            {
                "value": "15 mins",
                "name": "SuperMotion & Video Compression",
                "status": true
            },
            {
                "value": "Unlimited",
                "name": "Audio Denoiser",
                "status": true
            },
        ]
    },

    {
        "name": "Creator Plan",
        "bank_statement_name": "Creator Plan",
        "prod_id": "prod_Q4qylE9DGUyiqf",
        "tax_code": "txcd_10103000",
        "color": "black",
        "style":{
            "bg":"#f5f0e1"
        },
        "description": "For creators, who want to expand their existing market. You will be able to create up to 114 Shorts or Reels (every month) and more than 30 AI generated videos using Text to Video. ",
        "cycle": [
            {
                "currency": "usd",
                "subscriptionId": "price_1PEhHKCEDzUkS6E0FTRxhp9v",
                "price": 15.00,
                "yearly_price": 180.00,
                "without_discount": 300.00,
                "type": "yearly"
            },
            {
                "currency": "usd",
                "subscriptionId": "price_1PEhGCCEDzUkS6E0Xvmtcg2d",
                "price": 25.00,
                "type": "monthly"
            }
        ],
        "feature": [
            {
                "value": "24 mins",
                "name": "ViralMe Studio",
                "status": true
            },
            {
                "value": "90 mins",
                "name": "ViralMe AI Shorts",
                "status": true
            },
            {
                "value": "90 mins",
                "name": "AI Captions",
                "status": true
            },
            {
                "value": "30 mins",
                "name": "Text to Video",
                "status": true
            },
            {
                "value": "15 mins",
                "name": "AI Voiceover",
                "status": true
            },
            {
                "value": "30 mins",
                "name": "SuperMotion & Video Compression",
                "status": true
            },
            {
                "value": "Unlimited",
                "name": "Audio Denoiser",
                "status": true
            },
        ]
    },
    {
        "name": "Influencer Plan",
        "bank_statement_name": "Influencer PKG",
        "prod_id": "prod_Q4r27pAUd9j4vG",
        "tax_code": "txcd_10103000",
        "color": "black",
        "style":{
            "bg":"#efb5a3"
        },
        "description": "For Influencers, with massive followers reach. You will be able to create upto 300 Shorts or Reels, or upto 5 complete podcasts edits with more than 60 AI generated videos using Text to Video. ",
        "cycle": [
            {
                "currency": "usd",
                "subscriptionId": "price_1PEhLVCEDzUkS6E0LXve3YKc",
                "price": 50.00,
                "yearly_price": 600.00,
                "without_discount": 960.00,
                "type": "yearly"
            },
            {
                "currency": "usd",
                "subscriptionId": "price_1PEhKTCEDzUkS6E0q4ZDg7wv",
                "price": 80.00,
                "type": "monthly"
            }
        ],
        "feature": [
            {
                "value": "100 mins",
                "name": "ViralMe Studio",
                "status": true
            },
            {
                "value": "200 mins",
                "name": "ViralMe AI Shorts",
                "status": true
            },
            {
                "value": "200 mins",
                "name": "AI Captions",
                "status": true
            },
            {
                "value": "60 mins",
                "name": "Text to Video",
                "status": true
            },
            {
                "value": "30 mins",
                "name": "AI Voiceover",
                "status": true
            },
            {
                "value": "60 mins",
                "name": "SuperMotion & Video Compression",
                "status": true
            },
            {
                "value": "Unlimited",
                "name": "Audio Denoiser",
                "status": true
            },
        ]
    },

    {
        "name": "LIFETIME ACCESS",
        "bank_statement_name": "LIFETIME_ACCESS",
        "prod_id": "prod_QUgGi1Tq5rlyOn",
        "tax_code": "txcd_10103000",
        "color": "black",
        "style":{
            "bg":"#ffe7e0"
        },
        "description": "Lifetime plan renewal limitation every month. You will be able to create upto 300 Shorts or Reels, or upto 5 complete podcasts edits with more than 60 AI generated videos using Text to Video. ",
        "cycle": [
            {
                "currency": "usd",
                "subscriptionId": "price_1PdgtqCEDzUkS6E0dW4kMmma",
                "price": 2999.00,
                "yearly_price": 2999.00,
                "without_discount": 4000.00,
                "type": "yearly"
            },
            {
                "currency": "usd",
                "subscriptionId": "price_1PdgtqCEDzUkS6E0dW4kMmma",
                "price": 2999.00,
                "type": "monthly"
            }
        ],
        "feature": [
            {
                "value": "50 mins",
                "name": "ViralMe Studio",
                "status": true
            },
            {
                "value": "180 mins",
                "name": "ViralMe AI Shorts",
                "status": true
            },
            {
                "value": "240 mins",
                "name": "AI Captions",
                "status": true
            },
            {
                "value": "60 mins",
                "name": "Text to Video",
                "status": true
            },
            {
                "value": "Unlimited",
                "name": "AI Voiceover",
                "status": true
            },
            {
                "value": "Unlimited",
                "name": "SuperMotion & Video Compression",
                "status": true
            },
            {
                "value": "Unlimited",
                "name": "Audio Denoiser",
                "status": true
            },
        ]
    },

]
