/* eslint-disable no-negated-condition */
/* eslint-disable no-else-return */
/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
// /* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @remotion/from-0 */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable capitalized-comments */
/* eslint-disable prefer-const */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */

import React, { useRef } from "react";
import {
  useCurrentFrame,
  useVideoConfig,
  interpolate,
  OffthreadVideo,
  Sequence,
  AbsoluteFill,
} from "remotion";
import { SegmentedVideosMotionBg } from "./SegmentedVideosMotionBg";

export const ZoomEffectVideoWithFilters = ({
  videoLink,
  jsonData,
  filters,
  zoom,
  motion_bg,
  segmented_video_uris
}: {
  videoLink: string;
  jsonData: any;
  filters: boolean;
  zoom: boolean;
  motion_bg: boolean;
  segmented_video_uris: string[];
}) => {
  const frame = useCurrentFrame();
  const { fps, width, height, durationInFrames } = useVideoConfig();
  const scaleRef = useRef(1);

  // Convert seconds to frames
  const secondsToFrames = (seconds: number) => Math.round(seconds * fps);


  ///// Here we will apply filer code
  // Function to create overlay styles
  const getOverlayStyle = (filterId: string) => {
    if (filterId === "studioLight") {
      return {
        width,
        height,
        position: "absolute",
        top: 0,
        left: 0,
        background:
          "radial-gradient(circle at center, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.65) 100%)",
        boxShadow: "inset 0 0 100px 50px rgba(0, 0, 0, 0.5)",
        mixBlendMode: "multiply",
      } as any;
    } else if (filterId === "orange_shade") {
      return {
        width,
        height,
        position: "absolute",
        top: 0,
        left: 0,
        background:
          "linear-gradient(to right, rgba(156, 75, 0, 0) 0%, rgba(255, 122, 0, 0.36) 63.2707%, #FFBE90 100%)",
        mixBlendMode: "multiply",
        opacity: 0.7,
      } as any;
    } else if (filterId === "halftone_grey") {
      const baseWidth = 608; // DONT REMOVE THIS. This is used for halftone_grey filter
      const baseHeight = 1080; // DONT REMOVE THIS. This is used for halftone_grey filter
      const baseSizeWidth = 2; // px
      const baseSizeHeight = 3.5; // px
      // Calculate dynamic backgroundSize based on current resolution
      const backgroundSizeWidth = baseSizeWidth * (width / baseWidth);
      const backgroundSizeHeight = baseSizeHeight * (height / baseHeight);

      return {
        width,
        height,
        position: "absolute",
        top: 0,
        left: 0,
        backgroundImage: `
            radial-gradient(circle, black 45%, transparent 25%),
            radial-gradient(circle, black 20%, transparent 35%)`,
        backgroundPosition: "0 0, 25px 25px",
        backgroundSize: `${backgroundSizeWidth}px ${backgroundSizeHeight}px`,
        opacity: 0.75, // Adjust based on desired effect
        pointerEvents: "none",
        zIndex: 2,
      } as any;
    }

    return {} as any;
  };

  ////////  Zoom main logic below /////
  const applyZoomEffect = (segment: any, frame: number) => {
    const startFrame = secondsToFrames(segment.zoom_start);
    const endFrame = secondsToFrames(segment.zoom_end);

    // test here
    const easeInOutQuad = (t: number) =>
      t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    const easeInOutCubic = (t: number) =>
      t < 0.5 ? 4 * t : 1 - Math.pow(-2 * t + 2, 9) / 2;

    const easeInOutQuad2 = (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
    const easeInOutCubic2 = (t: number) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
    const easeInOutSine = (t: number) => -(Math.cos(Math.PI * t) - 1) / 2;

    const zoomDurationSeconds = segment.zoom_end - segment.zoom_start;
    const zoomDurationFrames = endFrame - startFrame;

    let easingFunction;
    if (zoomDurationSeconds <= 0.5) {
      easingFunction = easeInOutQuad2;
    } else if (zoomDurationSeconds <= 1.5) {
      easingFunction = easeInOutCubic2;
    } else {
      easingFunction = easeInOutSine;
    }


    if (frame >= startFrame && frame <= endFrame) {
      // test here
      const progress = (frame - startFrame) / zoomDurationFrames;
      const easedProgress = easingFunction(progress);
      ///

      // const progress = (frame - startFrame) / (endFrame - startFrame);
      const detection = segment.detections.reduce((prev: any, current: any) => {
        const prevArea = prev.bounding_box.width * prev.bounding_box.height;
        const currentArea =
          current.bounding_box.width * current.bounding_box.height;
        return prevArea > currentArea ? prev : current;
      });
      const bbox = detection.bounding_box;
      const bboxCenterX = bbox.xmin + bbox.width / 2;
      const bboxCenterY = bbox.ymin + bbox.height / 2;

      let scale = 1;
      let blur = "none";

      switch (segment.zoom_type) {
        case "verySlowZoomIn":
          const verySlowZoomInProgress =
            (frame - startFrame) / (endFrame - startFrame);
          scale = interpolate(verySlowZoomInProgress, [0, 1], [1, 1.15]);
          break;
        case "verySlowZoomOut":
          const verySlowZoomOutProgress = (frame - startFrame) / (endFrame - startFrame);
          if ((segment.zoom_end - segment.zoom_start) <= 0.6) {
            scale = interpolate(verySlowZoomOutProgress, [startFrame, endFrame], [scaleRef.current, 1]);
            break;
          } else {
            scale = interpolate(verySlowZoomOutProgress, [0, 1], [scaleRef.current, 1], {
              easing: easeInOutCubic,
            });
            break;
          }
        case "instantSharpZoomIn":
          scale = 1.5;
          break;
        case "instantSharpZoomOut":
          const zoomOutDuration = 0.25 * fps;
          const zoomOutStartFrame = Math.max(
            startFrame,
            frame - zoomOutDuration
          );
          const zoomOutProgress = (frame - zoomOutStartFrame) / zoomOutDuration;
          const easedZoomOutProgress = easeInOutQuad(
            Math.min(zoomOutProgress, 1)
          );
          scale = interpolate(easedZoomOutProgress, [0, 1], [1.5, 1]);
          break;
        case "instantZoomBlur":
          const effectProgress = frame - startFrame; // Calculate progress in frames from the start
          const blurEffectDuration1 = Math.min(
            0.8 * fps,
            endFrame - startFrame
          ); // Ensures the effect doesn't exceed 0.5 seconds or endFrame
          // Apply blur effect
          const blurAmount = interpolate(
            effectProgress,
            [0, blurEffectDuration1],
            [20, 0],
            {
              extrapolateLeft: "clamp",
              extrapolateRight: "clamp",
            }
          );
          blur = `blur(${blurAmount}px)`;

          // Keep scale constant
          scale = 1;

          break;
        case "defaultZoomIn":
          scale = interpolate(frame, [startFrame, endFrame], [1, 1.3]);
          break;
        case "defaultZoomOut":
          if (segment.zoom_end - segment.zoom_start <= 1.0) {
            scale = interpolate(
              easedProgress,
              [0, 1],
              [1.7, 1]
            );
            break;
          } else {
            scale = interpolate(
              easedProgress,
              [0, 1],
              [1.7, 1]

            );
            break;
          }
        case "instantZoomOutBlur":
          const effectDuration = endFrame - startFrame;
          const blurEffectDuration = Math.min(effectDuration, fps / 2);
          if (frame <= startFrame + blurEffectDuration) {
            const blurAmount = interpolate(
              frame,
              [startFrame, startFrame + blurEffectDuration],
              [10, 0],
              {
                extrapolateLeft: "clamp",
                extrapolateRight: "clamp",
              }
            );
            blur = `blur(${blurAmount}px)`;
          }
          scale = interpolate(frame, [startFrame, endFrame], [1.5, 1]);
          break;
      }

      const translateX = (width / 2 - bboxCenterX) * (1 - 1 / scale);
      const translateY = (height / 2 - bboxCenterY) * (1 - 1 / scale);

      scaleRef.current = scale;

      return {
        transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
        blur: blur !== "none" ? blur : undefined,
      };
    }

    return {};
  };

  const zoomStyle = jsonData.segments.reduce((style: any, segment: any) => {
    // if (segment.zoom_on && segment.detections && segment.detections.length > 0) {
    if (
      zoom &&
      segment.zoom_on &&
      segment.detections &&
      segment.detections.length === 1
    ) {
      const zoomEffect = applyZoomEffect(segment, frame);
      return { ...style, ...zoomEffect };
    }
    return style;
  }, {});

  ////// Filter logic here /////
  const currentSegment = jsonData.segments.find((segment: any) => {
    const startFrame = secondsToFrames(segment.start);
    const endFrame = secondsToFrames(segment.end);
    return frame >= startFrame && frame <= endFrame;
  });
  const filterStyle =
    currentSegment && currentSegment.filter === "grey"
      ? "grayscale(100%)"
      : "none";
  const filterId = filters && currentSegment && currentSegment.filter_on ? currentSegment.filter : null;

  return (
    <AbsoluteFill>
      <svg style={{ height: 0 }}>
        <defs>
          <filter id="studioLight">
            <feColorMatrix
              type="matrix"
              values=" 1.920  0.000  0.000  0.000 -0.008 
                      0.000  1.920  0.000  0.000 -0.008 
                      0.000  0.000  1.920  0.000 -0.008 
                      0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="orange_shade">
            <feColorMatrix
              type="matrix"
              values=" 1.720  0.000  0.000  0.000 -0.035 
                      0.000  1.720  0.000  0.000 -0.035 
                      0.000  0.000  1.720  0.000 -0.035 
                      0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="colorgrading">
            <feColorMatrix
              type="matrix"
              values="1.500  0.000  0.000  0.000 -0.035 
                    0.000  1.500  0.000  0.000 -0.035 
                    0.000  0.000  1.500  0.000 -0.035 
                    0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="grey">
            <feColorMatrix
              type="matrix"
              values="0.213  0.715  0.072  0.000  0.000 
                    0.213  0.715  0.072  0.000  0.000 
                    0.213  0.715  0.072  0.000  0.000 
                    0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="sepia">
            <feColorMatrix
              type="matrix"
              values="0.393  0.768  0.188  0.000  0.000 
                    0.349  0.685  0.167  0.000  0.000 
                    0.272  0.533  0.130  0.000  0.000 
                    0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="nightlife">
            <feColorMatrix
              type="matrix"
              values=" 2.360 -0.327 -0.033  0.000  0.005 
            -0.097  2.130 -0.033  0.000  0.005 
            -0.097 -0.327  2.430  0.000  0.005 
             0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="high_exposure">
            <feColorMatrix
              type="matrix"
              values=" 2.000  0.000  0.000  0.000 -0.006 
                      0.000  2.000  0.000  0.000 -0.006 
                      0.000  0.000  2.000  0.000 -0.006 
                      0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="hue_excite_funky">
            <feColorMatrix
              type="matrix"
              values=" 0.040 -0.035  0.995  0.000  0.000 
                    0.345  0.869 -0.214  0.000  0.000 
                  -0.584  1.390  0.191  0.000  0.000 
                    0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="hue_vintage">
            <feColorMatrix
              type="matrix"
              values="-0.577  1.420  0.159  0.000  0.000 
                      0.428  0.432  0.139  0.000  0.000 
                      0.413  1.440 -0.855  0.000  0.000 
                      0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
        </defs>
      </svg>
      <Sequence from={0} durationInFrames={durationInFrames}>
        <OffthreadVideo
          src={videoLink}
          style={{
            width,
            height,
            ...zoomStyle,
            position: "absolute",
            zIndex: -5,
            filter: `${zoomStyle.blur || ""} ${filterId === "halftone_grey"
                ? "grayscale(100%)"
                : filterId
                  ? `url(#${filterId})`
                  : ""
              }`,
          }}
          volume={75}
        />
      </Sequence>

      {motion_bg && <SegmentedVideosMotionBg segments={jsonData.segments} fps={fps} zoomStyle={zoomStyle} segmented_video_uris={segmented_video_uris} />}

      {filterId &&
        ["studioLight", "orange_shade", "halftone_grey"].includes(filterId) && (
          <div style={getOverlayStyle(filterId)} />
        )}
    </AbsoluteFill>
  );
};
