import { Grid } from "@mui/material";
import AutoComplete from "../AutoComplete";
import languageJSON from '../../utils/language.json'
import CustomizedButtons from "../Button";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/store";
import useVideo from "../../hooks/useVideo";
import ConfimationModal from "../ConfirmationModal";
import { useCallback, useContext, useState } from "react";
import { logEvent } from "../../utils/ga.utils";
import { changeSubtitleLang } from "../../redux/slices/video.slice";
import { getToken } from "../../redux/slices/auth.slice";
import { VideoContext } from "../../context/video.context";
import { getKeyByValue } from "../../utils/functions";
import MsgModal from "../MsgModal";

export const TitleHead: React.FC<{ name: string }> = ({ name }) => {
    const dispatch = useAppDispatch()
    const contextValue = useContext(VideoContext);
    const [msgModal, setMsgModal] = useState(false)

    if (!contextValue) {
        // handle the case where the context value is not provided
        throw new Error("VideoContext value is not provided");
    }

    const {
        setPremiumModal
    } = contextValue;

    const fileUrls = useAppSelector((state: RootState) => state.video.videoFile)
    const { videoId, user, language, updateLanguage } = useVideo()
    const [confirmModal, setConfirmModal] = useState(false)
    const [lang, setLang] = useState("en")


    const changeSubsLang = () => {
        if (user?.id && language && fileUrls?._id) {
            dispatch(getToken(user?.id))
            setConfirmModal(false)

            setTimeout(() => {
                console.log({ language }, { lang })
                let form = new FormData()

                form.append("user_id", user?.id)
                form.append("video_id", videoId.split("-")?.[0])
                form.append("language", lang)

                logEvent({ category: "Video", action: 'Change Language', label: videoId.split("-")?.[0] })
                dispatch(changeSubtitleLang({ form, lang: lang, id: fileUrls?._id, video_id: fileUrls?.video_id.split("-")?.[0] }))

            }, 500);
        }
    }


    const handleLanguageChange = useCallback((e: any, value: string) => {
        const key = getKeyByValue(languageJSON, value);
        if (language !== key) {
            console.log({ key }, { language })
            updateLanguage(key || language as string);
            setLang(language as string)
        }
    }, [language, updateLanguage]);

    return (
        <Grid sx={{ backgroundColor: 'white', padding: 3, display: 'flex', borderBottom: '1px solid #DDD' }} md={12}>
            <Grid md={8}>
                <p className='text-main'>{name}</p>
            </Grid>
            <Grid sx={{ display: 'flex' }} md={3}>
                {/* <AutoComplete className="sixth-step box-shadoww" selected={(languageJSON as any)["en"]}
                    handleChange={handleLanguageChange}
                    options={
                        Object.entries(languageJSON).map(([value, name]) => ({
                            value,
                            label: name
                        }))
                    }
                /> */}
                <div style={{ width: 10 }}></div>
            </Grid>

            <Grid md={1}>
                {/* <CustomizedButtons
                    // onClick={ExportVideoYoutube}
                    onClick={() => {
                        if (user?.subscriptionStatus === "succeeded") {
                            // setConfirmModal(true)
                            setMsgModal(true)
                        }
                        else {
                            setPremiumModal(true)
                        }
                    }}
                    name="Subtitles"
                    size="small"
                    className={"box-shadoww"}
                /> */}
            </Grid>

            <ConfimationModal title="Comfirmation" text="Are you sure you want to change the language?" onClick={changeSubsLang} open={confirmModal} onClose={() => setConfirmModal(false)} />
            <MsgModal open={msgModal} setOpen={setMsgModal} msg="Update subtitle feature will be available in next 48 hours." ></MsgModal>

        </Grid>)
}