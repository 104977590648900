/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { AbsoluteFill, OffthreadVideo, useCurrentFrame, useVideoConfig } from 'remotion';

export const VerticalOneHeadView = ({
    videoLink,
    totalVideoWidth,
    talkinghead,
    zoomStyle, // Add zoomStyle as a prop
    overlayDelay
}: {
    videoLink: string;
    totalVideoWidth: number;
    talkinghead: { x: number, y: number, width: number, height: number };
    zoomStyle: any; // Add zoomStyle as a prop type
    overlayDelay: any;
}) => {
    const { width, height } = useVideoConfig(); // This should be 1080x1920 for portrait
    const frame = useCurrentFrame();
    // const faceCenter = (talkinghead.x + talkinghead.width) / 2;
    const faceCenter = (talkinghead.x + talkinghead.width) / 2;
    const objectFitPosition = (faceCenter / totalVideoWidth) * 100;


    return ( 
        <AbsoluteFill>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <AbsoluteFill>
                        <div
                            style={{
                                width: width,
                                height: '100%',
                                overflow: 'hidden'
                                // transform: `translateX(${translateX}px)`,
                            }}
                        >
                            <OffthreadVideo
                                src={videoLink}
                                style={{
                                    width: width,
                                    height: '100%',
                                    objectFit: 'cover',
                                    
                                    objectPosition: `${objectFitPosition}%`,
                                    ...zoomStyle // Apply the zoom style
                                }}
                                volume={frame >= overlayDelay ? 1 : 0}
                            />
                        </div>
                    </AbsoluteFill>
                </div>
        </AbsoluteFill> 
    );
};

export default VerticalOneHeadView;