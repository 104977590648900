import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css'; // Custom styles if any
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';
import { useMemo, useRef, useState } from 'react';

const images = [
    './thumbnail.png',
    './thumbnail.png',
    './thumbnail.png',
    // Add paths to all images you want to include
];

const CustomSlider = ({ data }: any) => {
    const dispatch = useAppDispatch()
    let sliderRef = useRef<Slider>(null);

    const videoFile = useAppSelector((state: RootState) => state.video.videoFile);
    const aishortsIndex = useAppSelector((state: RootState) => state.video.aishortsIndex);
    const aishortsIndexValue = useMemo(() => {
        let i = aishortsIndex?.[videoFile?.video_id_old || 0]
        sliderRef?.current?.slickGoTo(i)
        return i
    }, [videoFile, aishortsIndex])

    const [activeIndex, setActiveIndex] = useState(aishortsIndex?.[videoFile?.video_id_old || 0] || 0);



    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        beforeChange: (current: number, next: number) => handleSlideClick(next)
    };

    const formatTime = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        let formattedTime = '';

        if (hours > 0) {
            formattedTime += `${hours} hr`;
        }

        if (minutes > 0) {
            if (hours > 0) {
                formattedTime += ' ';
            }
            formattedTime += `${minutes} min`;
        }

        return formattedTime || '0 min'; // Return "0 min" if no hours or minutes
    };

    const handleSlideClick = (next: number) => {
        setActiveIndex(next);
        dispatch({ type: "video/updateIndexVideoId", payload: { newVideoId: `${videoFile?.video_id_old}-${next}`, next } });
    }


    return (
        <div className="custom-slider">
            <Slider {...settings}
                ref={sliderRef}
            >
                {data?.map((img: any, index: number) => (
                    <div
                        key={index}
                        className={`slide ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => handleSlideClick(index)}
                    >
                        <img src={"/thumbnail.png"} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </Slider>
            {/* <div className="slider-info">
                <p>{`${activeIndex + 1} of ${data.length} Viral Shorts from ${formatTime(duration)} Video`}</p>
            </div> */}
        </div>
    );
};

export default CustomSlider;
