import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import { persistor, store } from "./redux/store";
import Toast from "./components/Toast";
import { PersistGate } from 'redux-persist/integration/react';
import VideoContextProvider from "./context/video.context";
import DashboardContextProvider from "./context/dashboard.context";
// import { Notifications } from "react-push-notification";
// import './il8n';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <>
    {/* <Notifications /> */}
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DashboardContextProvider>
          <VideoContextProvider>
            <App />
            <Toast />
          </VideoContextProvider>
        </DashboardContextProvider>
      </PersistGate>
    </Provider>
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
