import { styled } from '@mui/material/styles';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiNativeSelect-select': {
        color: "blue",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 12,
        height: '24px',
        padding: '2px 26px 2px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: "#000",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

export default function CustomizedSelects({ items, defaultValue, handleChange, value, disabled, fullWidth, name, className }: {
    items: { name: string, value: string | number }[],
    defaultValue?: string,
    handleChange?: any,
    value?: string,
    disabled?: boolean
    fullWidth?: boolean
    name?: string
    className?: any
}) {
    return (
        <NativeSelect
            id="demo-customized-select-native"
            value={value}
            onChange={handleChange}
            fullWidth
            input={<BootstrapInput />}
            className={className}
            sx={{

            }}
            style={{
                width: '100%',
            }}
            defaultValue={defaultValue}
            disabled={disabled}
        >
            {/* <option aria-label="None" value="" /> */}
            {name == "font" && <option value="Custom" aria-label="Custom">Custom</option>}
            {items.map((val, index) => <option key={index} value={val.value}>{val.name}</option>)}
            {/* <option value={20}>Twenty</option>
            <option value={30}>Thirty</option> */}
        </NativeSelect>
    );
}