/* eslint-disable capitalized-comments */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {AbsoluteFill, OffthreadVideo, Sequence, useCurrentFrame, useVideoConfig} from 'remotion';

export const BlurBackgroundTemplate = ({videoLink,overlayDelay}: {videoLink: string, overlayDelay:any}) => {
	// const {width, height} = useVideoConfig();
	const frame = useCurrentFrame();

	return (
		<AbsoluteFill>
			<Sequence layout="none">
				{/* Blurred Background Video */}
				<OffthreadVideo
					src={videoLink}
					style={{
						width: 'auto', // Auto width for scaling based on height
						height: '100%',
						objectFit: 'cover',
						filter: 'blur(10px)', // Applying blur effect
						transform: 'scale(1.0)', // Slightly scale up to avoid edges being unblurred
					}}
					volume={0}
				/>

				{/* Centered Clear Video */}
				<div
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						width: '100%', // Full width of the container
						height: 'auto', // Auto height to maintain aspect ratio
						transform: 'translate(-50%, -50%)', // Centering the video
						// Drop shadow
						boxShadow: '0px 0px 30px rgba(128, 0, 128, 0.75)'
					}}
				>
					<OffthreadVideo
						src={videoLink}
						style={{
							width: '100%',
							height: 'auto',
							objectFit: 'cover',
						}}
						volume={frame >= overlayDelay ? 1 : 0}
					/>
				</div>
			</Sequence>
		</AbsoluteFill>
	);
};

export default BlurBackgroundTemplate;