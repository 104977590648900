import React, { useState } from 'react';
import { Modal, Box, Typography, Button, FormControl, OutlinedInput, useMediaQuery } from '@mui/material';
import GradientButton from '../Button/gradient';
import "./style.css";
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { requestCaption } from '../../redux/slices/feedbacks.slice';
import toast from 'react-hot-toast';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '2px solid white',
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

const RequestCaptions = ({ open, onClose }: any) => {
    const user = useAppSelector((state) => state.auth.user)

    const dispatch = useAppDispatch()
    const [link, setLink] = useState('');
    const [desc, setDesc] = useState('');

    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const submitRC = () => {
        if (link !== "" && desc !== "") {
            dispatch(requestCaption({ link, description: desc, user_id: user?.id }))
            onClose()
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                ...style,
                width: isSmallScreen ? '90%' : '600px',
                padding: isSmallScreen ? '16px' : '32px',
            }}>
                <Typography id="modal-modal-title" variant="h6" className='request-title' component="h2">
                    Request your <span className="request-title-cus">Custom Preset</span> for Captions
                </Typography>
                <div style={{ width: '100%', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <FormControl variant="standard" sx={{ mb: 2, mt: 2, width: '100%' }}>
                        <p className="input-title">Sample Video or Image Link that shows your captions</p>
                        <OutlinedInput value={link} onChange={(e: any) => setLink(e.target.value)} />
                    </FormControl>
                    <FormControl variant="standard" sx={{ mb: 2, mt: 2, width: '100%' }}>
                        <p className="input-title">Description</p>
                        <OutlinedInput
                            style={{ width: '100%' }}
                            value={desc}
                            onChange={(e: any) => setDesc(e.target.value)}
                            multiline
                            rows={5}
                        />
                    </FormControl>
                    <p className='msg-title'>Your brand style will not be publicly available but only for you - For Free!</p>
                    <GradientButton onClick={submitRC}>Request for Free</GradientButton>
                </div>
            </Box>
        </Modal>
    );
}

export default RequestCaptions;
