import packageData from "./packageData";

export function getKeyByValue(object: { [key: string]: string }, value: string): string | undefined {
    const entry = Object.entries(object).find(([key, val]) => val === value);
    return entry && entry[0];
}

type VideoOrientation = 'vertical' | 'horizontal' | 'square';
export function getVideoOrientation(width: number, height: number): VideoOrientation {
    if (width > height) {
        return 'horizontal';
    } else if (width < height) {
        return 'vertical';
    } else {
        return 'square';

    }
}

export function generateRandomName() {
    const vowels = 'aeiou';
    const consonants = 'bcdfghjklmnpqrstvwxyz';

    const getRandomChar = (characters: any) =>
        characters.charAt(Math.floor(Math.random() * characters.length));

    const length = Math.floor(Math.random() * 3) + 6; // Random length between 6 and 8
    let name = '';

    for (let i = 0; i < length; i++) {
        // Alternate between consonants and vowels
        if (i % 2 === 0) {
            name += getRandomChar(consonants);
        } else {
            name += getRandomChar(vowels);
        }
    }

    return name;
}


export function secondsToHMSM(seconds: any) {
    // Calculate hours, minutes, seconds, and milliseconds
    const hours = Math.floor(seconds / 3600);
    const remainingAfterHours = seconds % 3600;

    const minutes = Math.floor(remainingAfterHours / 60);
    const remainingAfterMinutes = remainingAfterHours % 60;

    const secs = Math.floor(remainingAfterMinutes);
    const ms = Math.round((remainingAfterMinutes - secs) * 100);

    // Format to hh:mm:ss:ms
    return ` ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}.${String(ms).padStart(2, '0')}`;
}

export function formatDate(inputDate: string): string {
    const date = new Date(inputDate);

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const day = date.getDate().toString().padStart(2, '0');
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
}

export function checkSubsPlan(user: any): any {
    if (user?.subscriptionStatus == "succeeded") {
        // find name of subscription product
        let found = packageData.find(({ cycle }: any) => cycle.find(({ subscriptionId }: any) => subscriptionId == user.subscriptionPlan?.id)) || null
        if (found)
            return found
        else return null
    }
    return null
}


export function formatMBSize(bytes: number) {
    const mbSize = bytes / (1024 * 1024);
    return `${mbSize.toFixed(2)}`; // This will give you the size in MB with 2 decimal places.
}

export function shortenFileName(file: string, maxLength = 25) {
    // Check if the file has the expected extension
    let fileName = file.toLowerCase(); // Convert to lowercase for a case-insensitive check

    let extension = "";  // Store the file extension

    if (fileName.endsWith('.webm')) {
        extension = '.webm';
    } else if (fileName.endsWith('.mkv')) {
        extension = '.mkv';
    } else if (fileName.endsWith('.mov')) {
        extension = '.mov';
    } else if (fileName.endsWith('.wmv')) {
        extension = '.wmv';
    } else if (fileName.endsWith('.mp4')) {
        extension = '.mp4';
    } else if (fileName.endsWith('.avi')) {
        extension = '.avi';
    } else {
        return "Invalid file type";  // Handle unexpected file extension
    }

    // Remove the extension for shortening
    let baseName = file.slice(0, file.length - extension.length);

    // If the baseName is shorter than or equal to the maxLength, return the original
    if (baseName.length <= maxLength) {
        return file;
    }

    // Shorten and add "..." to indicate truncation, and then append the original extension
    let shortened = baseName.slice(0, maxLength - 3) + '...' + extension;

    return shortened;
}

export function rgbaToHex(r: number, g: number, b: number, a = 1) {
    const toHex = (value: any) => {
        let hex = value.toString(16)
        return hex.length === 1 ? '0' + hex : hex
    }
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
    // return `&H${toHex(b)}${toHex(g)}${toHex(r)}`
}

export function hexToBBGGRR(hex: string) {
    // Remove the leading #
    hex = hex.replace('#', '');

    // Convert #RGB to #RRGGBB
    if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('');
    }

    // Parse the r, g, b values
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Convert to BBGGRR format
    const toHex = (value: number) => {
        let hex = value.toString(16).toUpperCase();
        return hex.length === 1 ? '0' + hex : hex;
    };

    return `&H${toHex(b)}${toHex(g)}${toHex(r)}`;
}

type Movie = {
    title: string;
    genre: string;
};

export function extractGenres(data: Movie[]): string[] {
    let genresSet = new Set<string>();
    data.forEach(item => {
        let genres = item.genre.split(",")
        genres.forEach(genre => {
            genresSet.add(genre);
        });
    });
    return Array.from(genresSet);
}


export const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
};

export default function getRandomId() {
    return Math.random().toString(36).substring(7);
}

export const captureThumbnail = (source: any) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');

        // Set crossOrigin to anonymous for remote URLs
        if (typeof source === 'string' && !source.startsWith('blob:')) {
            video.crossOrigin = 'anonymous';
        }

        video.src = source;

        video.addEventListener('loadeddata', () => {
            try {
                video.currentTime = 1; // skip to 1 second to capture a non-blank frame
            } catch (e) {
                reject(e);
            }
        });

        video.addEventListener('seeked', () => {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
            if (ctx === null) {
                reject(new Error('Could not get canvas context'));
                return;
            }
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
                if (blob === null) {
                    reject(new Error('Could not create blob from canvas'));
                    return;
                }
                resolve(URL.createObjectURL(blob));
            }, 'image/jpeg');
        });

        video.addEventListener('error', (e) => {
            reject(e);
        });

        // Load the video
        video.load();
    });
};

export const getMusicDuration = (file: any) => {
    return new Promise((resolve, reject) => {
        const audio = new Audio();
        audio.src = URL.createObjectURL(file);

        audio.addEventListener('loadedmetadata', () => {
            resolve(audio.duration);
            URL.revokeObjectURL(audio.src); // Clean up memory
        });

        audio.addEventListener('error', () => {
            reject('Error loading audio file');
        });
    });
};

export const adjustScaleLength = (totalDuration: number) => {
    const scaleSettings = [
        { maxDuration: 120, scale: 14, scaleLength: 120 },
        { maxDuration: 180, scale: 10, scaleLength: 180 },
        { maxDuration: 360, scale: 4, scaleLength: 360 },
        { maxDuration: 600, scale: 2.7, scaleLength: 600 },
        { maxDuration: 900, scale: 1.8, scaleLength: 900 },
        { maxDuration: 1200, scale: 1.4, scaleLength: 1200 },
        { maxDuration: 1500, scale: 1.3, scaleLength: 1500 },
        { maxDuration: 1800, scale: 0.9, scaleLength: 1800 },
        { maxDuration: 2100, scale: 0.8, scaleLength: 2100 },
        { maxDuration: 2400, scale: 0.8, scaleLength: 2400 },
        { maxDuration: 2700, scale: 0.7, scaleLength: 2700 },
        { maxDuration: 3000, scale: 0.6, scaleLength: 3000 },
        { maxDuration: 3300, scale: 0.5, scaleLength: 3300 },
        { maxDuration: 3600, scale: 0.5, scaleLength: 3600 },
        { maxDuration: 3900, scale: 0.4, scaleLength: 3900 },
        { maxDuration: 4200, scale: 0.4, scaleLength: 4200 },
        { maxDuration: 4500, scale: 0.3, scaleLength: 4500 },
        { maxDuration: 4800, scale: 0.3, scaleLength: 4800 },
        { maxDuration: 5400, scale: 0.3, scaleLength: 5400 },
        { maxDuration: Infinity, scale: 18, scaleLength: 90 } // Default case
        // The last object in scaleSettings serves as the default case, applying if none of the other conditions are met.
    ];

    const setting = scaleSettings.find(s => totalDuration <= s.maxDuration);
    if (setting) {
        return {
            scale: setting.scale,
            scaleLength: setting.scaleLength
        };
    }
    else return null
};

export const addNewMusicBlockBasedOnMarker = (markerTimestamp: number, newBlockDuration: number, musicTracks: any) => {
    // Sort tracks by start time
    const sortedTracks = [...musicTracks].sort((a, b) => a.modifiedStartTime - b.modifiedStartTime);

    // Find index of the block where the marker is and the next block
    const currentBlockIndex = sortedTracks.findIndex(track =>
        markerTimestamp >= track.modifiedStartTime && markerTimestamp <= track.modifiedEndTime
    );

    // If the marker is on an existing block, add the new block after the last block
    if (currentBlockIndex !== -1) {
        const lastBlock = sortedTracks[sortedTracks.length - 1];
        return createMusicBlock(lastBlock.modifiedEndTime, newBlockDuration);
    }

    // If marker is not on an existing block, check available space between nearest blocks
    if (currentBlockIndex === -1) {
        const leftBlock = sortedTracks.reduce((acc, block) => block.modifiedEndTime <= markerTimestamp ? block : acc, null);
        const rightBlock = sortedTracks.find(block => block.modifiedStartTime > markerTimestamp);

        // If there's enough space between left and right blocks, add new block after left block
        if (leftBlock && rightBlock && rightBlock.modifiedStartTime - leftBlock.modifiedEndTime >= newBlockDuration) {
            return createMusicBlock(leftBlock.modifiedEndTime, newBlockDuration);
        }
    }

    // Add the new block after the last block or at the start
    const lastBlock = sortedTracks[sortedTracks.length - 1];
    const newBlockStartTime = lastBlock ? lastBlock.modifiedEndTime : 0;

    return createMusicBlock(newBlockStartTime, newBlockDuration);
};

export const createMusicBlock = (startTime: number, duration: number) => ({
    id: `track-${Date.now()}`,
    originalStartTime: startTime,
    originalDuration: duration,
    originalEndTime: startTime + duration,
    modifiedStartTime: startTime,
    modifiedDuration: duration,
    modifiedEndTime: startTime + duration,
    preTrimStartTime: startTime,
    preTrimEndTime: startTime + duration,
    musicTrimDuration: duration,
    trimDurationAmount_L: 0,
    trimDurationAmount_R: 0,
});

export function getSizesWithoutPadding(element: HTMLElement) {
    const computedStyles = getComputedStyle(element);

    // Initialize width and height with the clientWidth and clientHeight
    let width = element.clientWidth;
    let height = element.clientHeight;

    // Helper function to safely parse the padding values
    const parsePadding = (value: any) => {
        const number = parseFloat(value);
        return isNaN(number) ? 0 : number; // If parsing fails, return 0
    };

    // Subtract the padding values from width and height
    height -= parsePadding(computedStyles.paddingTop) + parsePadding(computedStyles.paddingBottom);
    width -= parsePadding(computedStyles.paddingLeft) + parsePadding(computedStyles.paddingRight);

    return [width, height];
}


//check video orientation is portrait or landscape
export function getVideoOrientationHeight(resolution: number[] | undefined) {
    // return the custom width and height on array
    if (!resolution) return [1280, 720];
    return resolution[0] > resolution[1] ? [1280, 360] : [406, 720];
    // return resolution

}
