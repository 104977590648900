import React, { useCallback } from 'react'
import api from '../utils/api'
import { useAppDispatch } from './redux'
import { getToken } from '../redux/slices/auth.slice'
import { discordWebhook } from '../redux/slices/feedbacks.slice'

const useAuth = () => {
  const dispatch = useAppDispatch()


  function removeQueryParams() {
    const { protocol, host, pathname } = window.location;
    const cleanURL = `${protocol}//${host}${pathname}`;
    window.history.pushState({}, '', cleanURL);
  }

  const loginAuth = useCallback((credential: any) => {
    dispatch({ type: 'auth/Login_Pending' })
    api
      .post('/auth/login', credential)
      .then(({ data }) => {

        localStorage.setItem("bannerDisplayed", "false")
        dispatch({ type: 'auth/Login_Success', payload: data })
        dispatch(getToken(data?.data.user.id))
        dispatch(discordWebhook("login"))
      })
      .catch(({ response
      }) => {

        dispatch({ type: 'auth/Login_Error', payload: { err: response?.data?.error } })
      })
  }, [])

  const signupAuth = useCallback((credential: any) => {
    dispatch({ type: 'auth/Signup_Pending' })
    api
      .post('/auth/signup', credential)
      .then(({ data }) => {
        dispatch(discordWebhook("signup"))

        dispatch({ type: 'auth/Signup_Success', payload: data })
      })
      .catch(({ response
      }) => {
        dispatch({ type: 'auth/Signup_Error', payload: { err: response?.data?.error } })
      })
  }, [])

  const recoverPasswordAuth = useCallback((credential: any) => {
    dispatch({ type: 'auth/Recover_Password_Pending' })
    api
      .post('/auth/recover-password', credential)
      .then(({ data }) => {
        dispatch(discordWebhook("Recover password"))

        dispatch({ type: 'auth/Recover_Password_Success', payload: data })
      })
      .catch(({ response
      }) => {
        dispatch({ type: 'auth/Recover_Password_Error', payload: { err: response?.data?.error } })
      })
  }, [])

  const resetPasswordAuth = useCallback((credential: any) => {
    dispatch({ type: 'auth/Reset_Password_Pending' })
    api
      .post('/auth/reset-password', credential)
      .then(({ data }) => {
        dispatch(discordWebhook("Reset Password"))

        dispatch({ type: 'auth/Reset_Password_Success', payload: data })
        removeQueryParams()
      })
      .catch(({ response
      }) => {
        dispatch({ type: 'auth/Reset_Password_Error', payload: { err: response?.data?.error } })
      })
  }, [])

  const changePasswordAuth = useCallback((credential: any) => {
    dispatch({ type: 'auth/Change_Password_Pending' })
    api
      .post('/auth/change-password', credential)
      .then(({ data }) => {
        dispatch(discordWebhook("Change Password"))

        dispatch({ type: 'auth/Change_Password_Success', payload: data })
        dispatch({
          type: "export/setToast", payload: {
            message: "Password successfully changed",
            success: true
          }
        })
        removeQueryParams()
      })
      .catch(({ response
      }) => {
        dispatch({ type: 'auth/Change_Password_Error', payload: { err: response?.data?.error } })
      })
  }, [])

  return {
    loginAuth,
    signupAuth,
    recoverPasswordAuth,
    resetPasswordAuth,
    changePasswordAuth
  }
}

export default useAuth
