

import { TextField } from "@mui/material"
import { styled, } from '@mui/material/styles';

const LongInput = styled(TextField)(({ theme }) => ({
    width: "100%",
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? 'rgba(217, 217, 217, 0.07)' : 'rgba(217, 217, 217, 0.07)',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? 'rgba(191, 191, 191, 0.12);' : 'rgba(191, 191, 191, 0.12);',
        // width: '100%',
        height: "100%",
        minHeight: 80,
        color: "#4E4E4E",
        fontFamily: "Inter",
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    }
}))

export default LongInput;