// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA8AR2JoigzQtsNPaNXKOecMKsJkd-FQlY",
    authDomain: "arbaz-yousuf-a9bf1.firebaseapp.com",
    databaseURL: "https://arbaz-yousuf-a9bf1.firebaseio.com",
    projectId: "arbaz-yousuf-a9bf1",
    storageBucket: "arbaz-yousuf-a9bf1.appspot.com",
    messagingSenderId: "219618774383",
    appId: "1:219618774383:web:a3463d3564e67934ddac9a"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

