const steps = [
    {
        selector: '.first-step',
        content: 'Meet Your Video Player: Watch the video you uploaded come to life!',
    },
    {
        selector: '.first-step1',
        content: 'Play the video',
        disableActions: true
    },
    {
        selector: '.second-step',
        content: 'See Your Words: View transcribed subtitles, capturing every word.',
        disableActions: false

    },
    {
        selector: '.third-step',
        content: 'Spot an Error ? : Edit the subtitles anytime, though errors are rare.',
        disableActions: false

    },

    {
        selector: '.fifth-step',
        content: 'Grab Your Files: Download subtitles as SRT, VTT, or ASS with a click.',
        disableActions: false

    },
    {
        selector: '.sixth-step',
        content: 'Speak Any Language: Re - transcribe in your preferred language from the dropdown.',
        disableActions: false

    },
    {
        selector: '.seventh-step',
        content: 'Fancy Captions ? : Embed them easily with a toggle(optional).',
        disableActions: false

    },
    {
        selector: '.seventh-step1',
        content: 'Please enable the caption',
        disableActions: true
    },
    {
        selector: '.fourth-step',
        content: 'Edit on the Fly: Make direct changes on the video player.',
        disableActions: false

    },
    {
        selector: '.eighth-step',
        content: 'Personalize Your Caption: Choose your font, color, and vertical position.',
        disableActions: false

    },
    {
        selector: '.nine-step',
        content: 'Watch as You Edit: See changes live on the video player.',
        disableActions: false

    },
    {
        selector: '.ten-step',
        content: 'Ready ? Hit Render! : Satisfaction guaranteed..',
        disableActions: false

    },
    {
        selector: '.eleven-step',
        content: 'See the Magic: View rendered results in the Modified section.',
        disableActions: false

    },
    {
        selector: '.twelve-step',
        content: 'Share or Save: Export to social platforms or download to your device.',
        disableActions: false

    },


]

export { steps }