import React, { useState } from 'react';

import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import InputWithIcon from '../../components/InputBox';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { logEvent } from '../../utils/ga.utils';
import useAuth from '../../hooks/useAuth';


const ChangePassword = ({ setTab }: any) => {
    const { changePasswordAuth } = useAuth()

    const [password, setPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [errors, setErrors] = useState({
        currentPassword: false,
        password: false,
        rePassword: false
    })

    function onChangePassword(event: any) {
        event.preventDefault();
        if (!currentPassword || currentPassword.length < 8) {
            // alert('Password must be at least 6 characters long.');
            setErrors({ ...errors, currentPassword: true })
        }
        if (!password || password.length < 8) {
            // alert('Password must be at least 6 characters long.');
            setErrors({ ...errors, password: true })
        }
        if (password !== rePassword) {
            setErrors({ ...errors, rePassword: true })
        }
        if (currentPassword && password && password == rePassword) {
            logEvent({
                category: 'User',
                action: 'Change Password',
            });
            setErrors({ password: false, rePassword: false, currentPassword: false })
            changePasswordAuth({ password, currentPassword })
        }
    }

    const getErrorMessage = () => {
        if (errors.currentPassword) return "Password must be at least 8 characters long.";
        if (errors.password) return "Password must be at least 8 characters long.";
        if (errors.rePassword) return "Passwords do not match."; // Add your message
        return "";
    };

    const errorMessage = getErrorMessage();

    return (
        <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={12} md={12} >
            <Box
                sx={{
                    my: [4, 6, 8],
                    mx: [2, 3, 4],
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: 'inherit',
                    width: "50%",
                    '@media (max-width: 600px)': {
                        width: '80%',  // Set width to 80% on mobile
                    }
                }}
            >
                <img src='/logo.png' style={{ maxWidth: '40%', height: 'auto', marginBottom: 40 }} />
                <Typography sx={{ marginTop: 10 }} className='otp-msg'>Change Password</Typography>
                <Box component="form" noValidate onSubmit={onChangePassword} sx={{ mt: 1 }}>
                    <InputWithIcon name="password" error={errors.currentPassword} onFocus={() => setErrors({ ...errors, currentPassword: false })} secure onChange={(e: any) => setCurrentPassword(e.target.value)} icon={<LockRoundedIcon />} placeholder='Current Password' />
                    <InputWithIcon name="password" error={errors.password} onFocus={() => setErrors({ ...errors, password: false })} secure onChange={(e: any) => setPassword(e.target.value)} icon={<LockRoundedIcon />} placeholder='Password' />
                    <InputWithIcon name="password" error={errors.rePassword} onFocus={() => setErrors({ ...errors, rePassword: false })} secure onChange={(e: any) => setRePassword(e.target.value)} icon={<LockRoundedIcon />} placeholder='Re-type Password' />
                    {errorMessage && <Alert sx={{ marginTop: 3 }} severity="error">{errorMessage}</Alert>}

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={false}
                        sx={{
                            mt: 3,
                            mb: 2,
                            height: "50px",
                            borderRadius: "8px",
                            backgroundColor: '#0575E6',
                            color: "#FFF",
                            fontFamily: "Poppins",
                            fontSize: ["10px", "12px", "14px"],
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                        }}
                    >
                        Change Password
                    </Button>
                    <Button
                        onClick={() => setTab("profile")}
                        fullWidth
                        variant="text"
                        disabled={false}
                        sx={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontSize: ["10px", "12px", "14px"],
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",

                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>

        </Grid>
    )

}

export default ChangePassword