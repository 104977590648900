// FontSizeChanger.tsx

import React, { useState } from 'react';
import { Button, Slider, TextField } from '@mui/material';

interface FontSizeChangerProps {
    onSizeChange?: (size: number) => void;
    disabled?: boolean
    updateFontSize: (size: number) => void
    fontSizeCaption: number
}

const FontSizeChanger: React.FC<FontSizeChangerProps> = ({ updateFontSize, fontSizeCaption, disabled }) => {

    return (
        <div className={!disabled ? "box-shadoww" : ""}
            style={{
                display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                borderRadius: "3.413px",
                border: " 0.853px solid #D9D9D9",

                background: "#FFF",
            }}>
            <Slider
                defaultValue={fontSizeCaption || 30}
                value={fontSizeCaption}
                valueLabelDisplay="auto"
                onChange={(_, v) => updateFontSize(Number(v))}
                marks
                min={1}
                max={75}
                sx={{ margin: "0.6px 10px" }}
                disabled={disabled}
            />
        </div >
    );
}

export default FontSizeChanger;