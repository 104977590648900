import { Fragment, useEffect, useState } from "react"
import './style.css'
import { Box, Button, Card, Container, FormControl, Grid, OutlinedInput, Snackbar, TextField } from "@mui/material"
import Header from "../Header";
import {
    CardContent,
    Typography,
    IconButton,
    InputAdornment,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SearchIcon from '@mui/icons-material/Search';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getFeedbacks, postFeedbacks } from "../../redux/slices/feedbacks.slice";
import { isValidEmail } from "../../utils/functions";
import EmailFedbackModal from "../../components/EmailModal-Feedback";


const FeedbackRequest = () => {
    const dispatch = useAppDispatch()

    const [request, setRequest] = useState({ email: '', details: '' });
    const [open, setOpen] = useState(false);
    const [feedback_id, setFeedbackId] = useState('');

    const { feedbacks, message } = useAppSelector(state => state.feedback)
    const [SBopen, setSBOpen] = useState(false);

    useEffect(() => {
        // If there is a message, show the Snackbar
        if (message !== '') {
            setSBOpen(true);

            // Set a timer to close the Snackbar after 3 seconds
            const timer = setTimeout(() => {
                setSBOpen(false);
            }, 3000);

            // Clear the timer if the component unmounts
            return () => clearTimeout(timer);
        }
        else setSBOpen(false);
    }, [message]); // Only re-run the effect if the message changes


    useEffect(() => {
        dispatch(getFeedbacks())
    }, [])

    const updateRequestInput = (e: any, field: string) => {
        setRequest({
            ...request,
            [field]: e.target.value
        })
    }

    const submitFeedback = () => {
        if (!request.email || !isValidEmail(request.email)) {
            alert('Please enter a valid email address')
            return
        }
        if (request.email === '' || request.details === '') {
            alert('Please fill in all fields')
            return
        }
        dispatch(postFeedbacks({ ...request, email: request.email.trim() }))
        setRequest({ email: '', details: '' })
    }

    const modalUpVote = (id: string) => {
        setFeedbackId(id)
        setOpen(true)
    }

    return (
        <Fragment>
            <Header />
            <Container maxWidth="xl" sx={{ marginTop: 5, margin: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={5}>
                        <Card
                            sx={{ marginTop: 4 }}
                        >
                            <Box
                                p={4}
                                component="form"
                                display="flex"
                                flexDirection="column"
                                autoComplete="off"
                            >
                                <h2 className="heading-text">Feature Request</h2>

                                <FormControl variant="standard" sx={{ mb: 2, mt: 2 }}>
                                    <p className="text-feedback">Your Email</p>
                                    <OutlinedInput value={request.email} onChange={(e) => updateRequestInput(e, "email")} />
                                </FormControl>

                                <FormControl variant="standard" sx={{ mb: 2 }}>
                                    <p className="text-feedback">Feedback Details</p>
                                    <OutlinedInput value={request.details} onChange={(e) => updateRequestInput(e, "details")} multiline rows={5} />
                                </FormControl>

                                <Button onClick={submitFeedback} variant="contained" color="primary">
                                    Submit Feedback
                                </Button>
                            </Box>
                        </Card>
                    </Grid>

                    <Grid sx={{
                        paddingTop: 4,
                    }} md={7}>
                        <Card
                            sx={{ margin: 2 }}
                        >
                            <Box
                                p={4}
                                className="feature-requests-container">
                                <Box className="feature-request-header">
                                    <TextField
                                        className="search-field"
                                        label="Search..."
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                                {feedbacks && feedbacks?.map((request: any, index: number) => (
                                    <Card key={index} className="feature-request-card">
                                        <CardContent>
                                            {/* <Typography className="feature-request-title" variant="h5" component="h2">
                                                {request.title}
                                            </Typography> */}
                                            <p>
                                                {request.details}
                                            </p>
                                            <Box sx={{ cursor: 'pointer' }} onClick={() => modalUpVote(request._id)} className="feature-request-votes">
                                                <IconButton size="small">
                                                    <ArrowDropUpIcon />
                                                </IconButton>
                                                <Typography variant="body2" className="vote-count">
                                                    {request?.upvote?.length || 0}
                                                </Typography>
                                            </Box>
                                            <Box className="feature-request-status">
                                                {/* <Typography variant="body2" className={request.status === 'inProgress' ? 'in-progress' : ''}>
                                                    {request.status === 'inProgress' ? 'In Progress' : 'Suggestion'}
                                                </Typography> */}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={SBopen}
                // onClose={handleClose}
                message={message}
            />
            <EmailFedbackModal open={open} setOpen={setOpen} feedback_id={feedback_id} setFeedbackId={setFeedbackId} ></EmailFedbackModal>
        </Fragment>
    )
}

export default FeedbackRequest;
