import { Box, Grid, IconButton } from "@mui/material";
import MyToggleButton from "../StyledToggleButton";
import { useEffect, useState } from "react";

import '../../screens/Video/style.css'
import './style.css'

// ICONS
import CameraRollIcon from '@mui/icons-material/CameraRoll';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import TuneIcon from '@mui/icons-material/Tune';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AnimationIcon from '@mui/icons-material/Animation';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import { Slider, Tooltip, } from '@mui/material';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import useVideo from "../../hooks/useVideo";
import { useAppSelector } from "../../hooks/redux";
import { IVideoEffect } from "../../redux/slices/mediaPlayer";

// A custom tooltip component for the help icon
const HelpTooltip = (props: any) => (
    <Tooltip title="Adjust the frequency of each effect">
        <IconButton {...props}>
            <HelpOutlineIcon />
        </IconButton>
    </Tooltip>
);


const VideoEffect: React.FC<{ service?: string }> = ({ service = "aistudio" }) => {
    const initialToggleButtons = [
        { key: 'broll', label: 'B-Rolls', icon: CameraRollIcon },
        { key: 'overlays', label: 'Overlays', icon: AutoAwesomeIcon },
        { key: 'motionbg_on', label: 'Motion Backgrounds', icon: WallpaperIcon },
        { key: 'keyword_soundEffects_on', label: 'Sound Effects', icon: GraphicEqIcon },
        { key: 'bg_music_on', label: 'Background Music', icon: HeadphonesIcon },
        { key: 'filters_on', label: 'Filter', icon: LightbulbCircleIcon },
        { key: 'scene_transitions_on', label: 'Transitions', icon: AnimationIcon },
        { key: 'zoom_on', label: 'Cinematic Zoom', icon: ZoomInIcon },
    ];

    const [toggleButtons, setToggleButtons] = useState(initialToggleButtons);
    const { effects, updateEffects } = useVideo()
    const [videoEffectModal, setVideoEffectModal] = useState<boolean>(false);
    const [sliderValues, setSliderValues] = useState({
        bRolls: 30,
        lightLeaks: 30,
        aiZoom: 30,
        transitions: 30,
    });


    // Handler to change slider values
    const handleSliderChange = (effect: any) => (event: any, newValue: any) => {
        setSliderValues({ ...sliderValues, [effect]: newValue });
    };

    const handleToggle = (key: keyof IVideoEffect) => {
        let temp = { ...effects } as IVideoEffect
        temp[key] = !temp[key]
        updateEffects(temp)
    };

    useEffect(() => {
        console.log({ service })
        if (service === 'aishorts') {
            const updatedToggleButtons = initialToggleButtons.filter(
                (button) => button.key !== 'motionbg_on'
            );

            updatedToggleButtons.push(
                // { key: 'billboard_overlay_on', label: 'Billboard Overlay', icon: DeveloperBoardIcon },
                { key: 'laptop_overlay_on', label: 'Laptop Overlay', icon: LaptopMacIcon },
                { key: 'lightleaks_on', label: 'Light Leaks', icon: LeakAddIcon }
            );

            setToggleButtons(updatedToggleButtons);
        }
    }, [service]);

    return (
        <div>
            <div className='display-flex' style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <p className='title-header mb-7'>Video Effects</p>
                {/* <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState: any) => (
                        <div>
                            <IconButton
                                {...bindToggle(popupState)}
                            ><TuneIcon></TuneIcon>
                            </IconButton>
                            <Popper
                                {...bindPopper(popupState)}
                                placement="left-start"
                                disablePortal={false}
                                modifiers={[
                                    {
                                        name: 'flip',
                                        enabled: true,
                                        options: {
                                            altBoundary: true,
                                            rootBoundary: 'document',
                                            padding: 8,
                                        },
                                    },
                                    {
                                        name: 'preventOverflow',
                                        enabled: true,
                                        options: {
                                            altAxis: true,
                                            altBoundary: true,
                                            tether: true,
                                            rootBoundary: 'document',
                                            padding: 8,
                                        },
                                    },
                                    // {
                                    //   name: 'arrow',
                                    //   enabled: false,
                                    //   options: {
                                    //     element: arrowRef,
                                    //   },
                                    // },
                                ]}
                            >
                                <Box sx={{ p: 2, border: '1px solid #ccc', backgroundColor: 'white', borderRadius: '8px', width: 280 }}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Typography variant="subtitle2">Effects Frequency</Typography>
                                        <HelpTooltip />
                                    </Grid>
                                    {Object.entries(sliderValues).map(([effect, value]) => {
                                        const Icon = effect === 'bRolls' ? CameraRollIcon :
                                            effect === 'lightLeaks' ? AutoAwesomeIcon :
                                                effect === 'aiZoom' ? CenterFocusWeakIcon :
                                                    ShuffleIcon; // Assuming ShuffleIcon for Transitions

                                        return (
                                            <Box key={effect} sx={{ mt: 1 }}>
                                                <div style={{ display: 'flex', alignItems: 'center', }}> <Icon style={{ fontSize: 12 }} sx={{ mr: 1 }} />
                                                    <Typography sx={{ flexGrow: 1, fontSize: 15, fontFamily: "Roboto" }}>{toggleButtons?.find(({ key }) => key == effect)?.label || ""}</Typography>
                                                </div>
                                                <p style={{ fontSize: 12, fontFamily: "Roboto", justifyContent: 'center', display: 'flex' }}>Every 3s</p>
                                                <Slider
                                                    value={value}
                                                    onChange={handleSliderChange(effect)}
                                                    aria-labelledby="continuous-slider"
                                                    min={0}
                                                    max={60}
                                                    valueLabelDisplay="auto"
                                                />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Popper>
                        </div>
                    )}
                </PopupState> */}
            </div>

            <Grid container style={{ marginLeft: 0 }} spacing={2}>
                {toggleButtons.map(({ key, label, icon: Icon }) => (
                    <div
                        className={`icon-btn ${effects && effects[key as keyof IVideoEffect] ? 'active' : ''}`}
                        onClick={() => handleToggle(key as keyof IVideoEffect)}
                    >
                        <Icon className="icon-effect" style={{ color: effects && effects[key as keyof IVideoEffect] ? '#FFFFFF' : '#6D6D6D' }} />
                        <p>{label}</p>
                    </div>
                    // <MyToggleButton
                    //     key={key}
                    //     selected={toggleStates[key as keyof ToggleStates]}
                    //     value="check"

                    //     onChange={() => handleToggle(key as keyof ToggleStates)}
                    // >
                    //     <Icon style={{
                    //         fontSize: '16px',
                    //         color: toggleStates[key as keyof ToggleStates] ? 'white' : 'gray',
                    //     }} />
                    //     <p style={{ marginLeft: 5, fontFamily: 'No' }}>{label}</p>
                    // </MyToggleButton>
                ))}
            </Grid>
        </div>
    );
};

export default VideoEffect;
