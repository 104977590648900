import { useEffect, useState } from 'react';
import { Modal, Box, Typography, useMediaQuery, useTheme, OutlinedInput, } from '@mui/material'
import CustomizedButtons from '../Button';
import { isValidEmail } from '../../utils/functions';
import { useAppDispatch } from '../../hooks/redux';
import { upvoteFeedbacks } from '../../redux/slices/feedbacks.slice';

const EmailFedbackModal = ({ open, text, setOpen, feedback_id, setFeedbackId }: any) => {
    const theme = useTheme();
    const dispatch = useAppDispatch()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [email, setEmail] = useState('')   
    
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : 600,
        maxWidth: '90%',
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };

    const ValidateEmailAndCallApi = () => {
        // validate email and dispatch api
        if (!email || !isValidEmail(email)) {
            alert('Please enter a valid email address')
            return
        }
        dispatch(upvoteFeedbacks({ feedback_id, email: email.trim() }))
        setOpen(false)
        setFeedbackId('')
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)", // This is the CSS property that blurs the background
                }
            }}
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                    color: "#4A4A4A",
                    textAlign: "center",
                    fontFamily: "Nunito",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                }}>
                    <p>Enter Your Email to Submit Upvote</p>
                </Typography>
                <OutlinedInput sx={{margin:"15px 0px"}} value={email} placeholder='email' onChange={(e) => setEmail(e.target.value)} />

                <CustomizedButtons onClick={ValidateEmailAndCallApi} color="#0085FF" name="Upvote" />
            </Box>
        </Modal>
    );
}

export default EmailFedbackModal;
