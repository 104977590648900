import React, { Fragment, useState, useRef, useEffect, useMemo, useContext } from 'react'
import './style.css'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
    Container,
    Grid,
    FormControlLabel,
    Tab,
    Tabs,
    Divider,
    FormGroup,
    Checkbox,
    useMediaQuery,
    ToggleButton,
    ToggleButtonGroup,
    Box,
    Tooltip,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid';
import { useTour } from '@reactour/tour'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import Timeline from "../../components/SubtitleEbith/Timeline";

import Header from '../Header'
import VideoPlayer from '../../components/VideoPlayer'
import PositionSlide from '../../components/PositionSlider'
import { styled, useTheme } from '@mui/material/styles'

import SketchExample from '../../components/PIckerColor'
import useVideo from '../../hooks/useVideo'
import FontSizeChanger from '../../components/FontSizeChanger'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { downloadVideo } from '../../utils/download'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import {
    fetchYoutubeVideoCategory,
    uploadVimeoVideo,
    uploadTiktokVideo,
    uploadReelVideo,
} from '../../redux/slices/export.slice'
import { mediaPlayerAction } from '../../redux/slices/mediaPlayer'
import {
    enhanceAudio,
    getVideoFiles,
    updateAICaptionSubtitle,
} from '../../redux/slices/video.slice'
import LanguageModal from '../../components/LanguageModal'
import { BASE_URL } from '../../utils/url'
import useExport from '../../hooks/useExport'
import { logEvent, logPageView } from '../../utils/ga.utils'
import PresetNew from '../../utils/presetsnew.json'
import { getVideoOrientation } from '../../utils/functions'
import './timeline.css'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { VideoContext } from '../../context/video.context'
import MusicCard from '../../components/MusicCard'
import SceneBlocks from '../../components/SceneBlock'

import DoneIcon from '@mui/icons-material/Done';
import { TitleHead } from '../../components/TitleHead'
import Subtitle from '../../components/Subtitle'
import Fonts from '../../utils/fonts'
import { loadFont } from '../../utils/fontLoader'
import NumberButton from '../../components/NumberButton';
import { discordWebhook } from '../../redux/slices/feedbacks.slice';
import RequestCaptions from '../../components/RequestCaptions';
import PremiumModal from '../../components/PremiumModal';
import BackIcon from '../../components/BackIcon';
import { DashboardContext } from '../../context/dashboard.context';
import MessageModal from '../../components/MessageModal';
import VideoEffect from '../../components/VideoEffect';
import { getToken } from '../../redux/slices/auth.slice';

interface Errors {
    title?: boolean
    description?: boolean
    privacyStatus?: boolean
    tags?: boolean
}

const label = { inputProps: { 'aria-label': 'Switch demo' } }


interface StyledTabsProps {
    children?: React.ReactNode
    value: number
    onChange: (event: React.SyntheticEvent, newValue: number) => void
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        centered
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-root': {
        justifyContent: 'space-between', // Distributes the tabs evenly
        alignItems: 'center',
        height: 33,
        display: 'flex',
        width: '40%',
        // width: '80%'
    },
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#635ee7',
    },
})

interface StyledTabProps {
    label: string
    icon: Element | null
    iconPosition: string | null
}

const StyledTab = styled((props: any) => (
    <Tab
        disableRipple
        icon={props.img ? <img src={props.img} alt="Tab image" /> : props.icon}
        {...props}
    />
))(({ theme, disabled }) => ({
    flex: 1, // This ensures each tab takes up equal space
    textAlign: 'center',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    // marginRight: theme.spacing(1),
    color: '#FFF',
    backgroundColor: '#898989',

    '&.Mui-selected': {
        color: 'rgba(0, 0, 0, 0.70);',
        backgroundColor: '#6A6A6A',
        borderRadius: '6px'
    },
    '&.MuiButtonBase-root': {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: 11,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 24 /* 218.182% */,
        letterSpacing: -0.11,
    },
    '&.MuiSvgIcon-root': {
        // marginRight: 1,
        height: 17,
    },

    // '&.Mui-focusVisible': {
    //     backgroundColor: 'rgba(100, 95, 228, 0.32)',
    // },
}))

const marks = [
    {
        value: 0,
        label: 'top',
    },
    {
        value: 50,
        label: 'center',
    },
    {
        value: 100,
        label: 'bottom',
    },
]


type ChildFunctions = {
    handleTimeUpdate: (e: any) => string
}

type PrivacyStatus = 'Public' | 'Unlisted' | 'Private' | 'Friend' | 'Only Me'
type Platform = 'youtube' | 'tiktok' | 'vimeo' | 'reels' | 'device'

interface PrivacyStatusMap {
    [key: string]: PrivacyStatus[]
}

interface TabMap {
    [key: number]: Platform
}

const PrivacyStatusMain: PrivacyStatusMap = {
    youtube: ['Public', 'Unlisted', 'Private'],
    tiktok: ['Public', 'Friend', 'Private'],
    vimeo: ['Public', 'Friend', 'Only Me'],
    reels: ['Public', 'Friend'],
}

const TabMain: TabMap = {
    0: 'youtube',
    1: 'tiktok',
    2: 'vimeo',
    3: 'reels',
    4: 'device',
}

const sidebar = [{ name: 'Go Back', disabled: false }, { name: 'AI Studio', disabled: false }, { name: "Transcription", disabled: false }, { name: "OverDub", disabled: true }, { name: "Dub", disabled: true }, { name: "Add Voice-over", disabled: true }, { name: "Add Media", disabled: true }]

function AIStudioVideo() {
    const dispatch = useAppDispatch()
    const { setIsOpen, setCurrentStep, isOpen } = useTour()
    const navigate = useNavigate()

    const contextValue = useContext(VideoContext);

    if (!contextValue) {
        // handle the case where the context value is not provided
        throw new Error("VideoContext value is not provided");
    }

    const { subtitleJSON, setSubtitleJSON, subtitleEmoji, premimumModal, setPremiumModal } = contextValue;

    const contextValue2 = useContext(DashboardContext);

    if (!contextValue2) {
        // handle the case where the context value is not provided
        throw new Error("VideoContext value is not provided");
    }

    const { setAlertModal, alertModal } = contextValue2


    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        updateCustomCaption,
        customCaption,
        preset,
        updateCaptionPosition,
        clearCaption,
        captionPosition,
        updatePresets,
        font,
        variant,
        fontSizeCaption,
        captionColor,
        updateFont,
        updateVariant,
        updateVideoToggle,
        videoToggle,
        language,
        updateLanguage,
        bold,
        updateBold,
        updateItalic,
        italic,
        underline,
        updateUnderline,
        maxWords,
        updateMaxWords,
        visuals,
        updateVisuals,
        addCaptions
    } = useVideo()
    const musicId = useAppSelector((state: RootState) => state.video.musicId)
    const { youtubeExportUpload } = useExport()

    const user = useSelector((state: RootState) => state.auth.user)
    const guestId = useSelector((state: RootState) => state.auth.guestId)

    const youtubeVideoCategory = useSelector(
        (state: RootState) => state.export.youtubeVideoCategory,
    )
    const exportField = useSelector(
        (state: RootState) => state.export.exportField,
    )
    const isLoading = useSelector((state: RootState) => state.export.isLoading)
    const videoFile = useSelector((state: RootState) => state.video.videoFile)
    const videoId = useSelector((state: RootState) => state.video.videoId)
    const isLoadingVideo = useSelector(
        (state: RootState) => state.video.isLoading,
    )
    const recentProject = useSelector(
        (state: RootState) => state.video.recentProject,
    )

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [privacyStatus, setPrivacyStatus] = useState('public')
    const [tags, setTags] = useState<string[]>([])
    const [categoryId, setCategoryId] = useState('')
    const [captions, setCaptions] = useState([])
    const [platform, setPlatform] = useState(0)
    const [videoTab, setVideoTab] = useState(0)
    const [accordian, setAccordian] = useState<string[]>([])
    const [errors, setErrors] = useState<Errors>({
        title: false,
        description: false,
        privacyStatus: false,
        tags: false,
    })
    const [loaderExport, setLoaderExport] = useState<boolean>(false)
    const [maxWordError, setMaxWordError] = useState(false)
    const [currentTab, setCurrentTab] = useState("AI Studio")
    const [open, setOpen] = useState(false);
    const [frames, setFrames] = useState<any>({
        "video": [],
        "subtitle-1": [],
        "65079f30-47a8-4469-833e-4f0eea04d233": []
    })
    const [publishModal, setPublishModal] = useState(false)
    const [captionReqMoal, setCaptionReqModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const scrollableRef = useRef<any>(null);
    const [atTop, setAtTop] = useState(true);
    const presetContainerRef1 = useRef<HTMLDivElement | null>(null);
    const presetContainerRef2 = useRef<HTMLDivElement | null>(null);
    const fontContainerRef = useRef<HTMLDivElement | null>(null)
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [hoveredItem, setHoveredItem] = useState<string | null>(null);

    useEffect(() => {
        const handleMouseDown = (e: MouseEvent) => {
            setIsDragging(true);
            setStartX(e.pageX - (presetContainerRef1.current?.offsetLeft || 0));
            setScrollLeft(presetContainerRef1.current?.scrollLeft || 0);
        };

        const handleMouseMove = (e: MouseEvent) => {
            if (!isDragging) return;
            e.preventDefault();
            const x = e.pageX - (presetContainerRef1.current?.offsetLeft || 0);
            const walk = (x - startX) * 3; // Scroll-fast
            if (presetContainerRef1.current) {
                presetContainerRef1.current.scrollLeft = scrollLeft - walk;
            }
        };

        const handleMouseUp = () => {
            setIsDragging(false);
        };

        const handleWheel = (event: WheelEvent) => {
            if (event.deltaY !== 0) {
                event.preventDefault();
                if (presetContainerRef1.current && presetContainerRef1.current.contains(event.target as Node)) {
                    presetContainerRef1.current.scrollLeft += event.deltaY;
                } else if (presetContainerRef2.current && presetContainerRef2.current.contains(event.target as Node)) {
                    presetContainerRef2.current.scrollLeft += event.deltaY;
                } else if (fontContainerRef.current && fontContainerRef.current.contains(event.target as Node)) {
                    fontContainerRef.current.scrollLeft += event.deltaY;
                }
            }
        };

        const presetContainer1 = presetContainerRef1.current;
        const presetContainer2 = presetContainerRef2.current;
        const fontContainer = fontContainerRef.current;

        if (presetContainer1 && presetContainer2 && fontContainer) {
            presetContainer1.addEventListener('mousedown', handleMouseDown);
            presetContainer1.addEventListener('mousemove', handleMouseMove);
            presetContainer1.addEventListener('mouseup', handleMouseUp);
            presetContainer1.addEventListener('mouseleave', handleMouseUp);
            presetContainer1.addEventListener('wheel', handleWheel);

            presetContainer2.addEventListener('mousedown', handleMouseDown);
            presetContainer2.addEventListener('mousemove', handleMouseMove);
            presetContainer2.addEventListener('mouseup', handleMouseUp);
            presetContainer2.addEventListener('mouseleave', handleMouseUp);
            presetContainer2.addEventListener('wheel', handleWheel);

            fontContainer.addEventListener('mousedown', handleMouseDown);
            fontContainer.addEventListener('mousemove', handleMouseMove);
            fontContainer.addEventListener('mouseup', handleMouseUp);
            fontContainer.addEventListener('mouseleave', handleMouseUp);
            fontContainer.addEventListener('wheel', handleWheel);
        }

        return () => {
            if (presetContainer1 && presetContainer2 && fontContainer) {
                presetContainer1.removeEventListener('mousedown', handleMouseDown);
                presetContainer1.removeEventListener('mousemove', handleMouseMove);
                presetContainer1.removeEventListener('mouseup', handleMouseUp);
                presetContainer1.removeEventListener('mouseleave', handleMouseUp);
                presetContainer1.removeEventListener('wheel', handleWheel);

                presetContainer2.removeEventListener('mousedown', handleMouseDown);
                presetContainer2.removeEventListener('mousemove', handleMouseMove);
                presetContainer2.removeEventListener('mouseup', handleMouseUp);
                presetContainer2.removeEventListener('mouseleave', handleMouseUp);
                presetContainer2.removeEventListener('wheel', handleWheel);

                fontContainer.removeEventListener('mousedown', handleMouseDown);
                fontContainer.removeEventListener('mousemove', handleMouseMove);
                fontContainer.removeEventListener('mouseup', handleMouseUp);
                fontContainer.removeEventListener('mouseleave', handleMouseUp);
                fontContainer.removeEventListener('wheel', handleWheel);
            }
        };
    }, [isDragging, startX, scrollLeft]);

    const listStyle = {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
        },
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(!open);
    };

    useEffect(() => {

        // get request from videoFile.urls.caption_orig_json_uri and save it to setCaptions
        if (videoFile?.urls?.caption_orig_json_uri) {
            setLoading(true)
            fetch(videoFile?.urls?.caption_orig_json_uri)
                .then(response => response.json())
                .then(data => {
                    // make a seperate id each segment
                    setCaptions(data.segments.map((segment: any) => ({ ...segment, id: uuidv4() })));
                    setSubtitleJSON(data);
                    setLoading(false)

                });
        }
        if (videoFile?.aistudio_rendering_ongoing || videoFile?.in_queue_waiting_for_rendering) navigate("/publish")

        // if (videoFile?.video_json_data) {
        //     let temp = [...videoFile?.video_json_data?.segments] as any
        //     setCaptions(temp)
        //         let tempFrames = { ...frames };

        // const subtitleFrames = videoFile.video_json_data.segments.map(({ end, start, text }:any) => ({
        //             second: start,
        //             duration: end, // Calculate duration from start and end times
        //             name: text,
        //             // Calculate xPosition based on the 'second' value relative to the video duration
        //             xPosition: ((start / videoFile.duration) * 100).toFixed(2) + '%',
        //             // You might adjust the calculation according to your specific layout or requirements
        //         }));

        //         tempFrames['video'] = {
        //             name: videoFile?.name,
        //             second: 0,
        //             duration: videoFile?.duration ?? 0,
        //             xPosition: ((0 / (videoFile?.duration ?? 1)) * 100).toFixed(2) + '%',
        //         }

        //         tempFrames["subtitle-1"] = subtitleFrames;
        //         setFrames(tempFrames);
        //         console.log("habibi im here", { tempFrames });
        // }
    }, [videoFile]);


    const privacyType = useMemo(() => PrivacyStatusMain[TabMain[platform]], [
        platform,
    ])
    const [searchParams, setSearchParams] = useSearchParams()
    const maxWordsArr = useMemo(() => {
        let items = [];

        if (preset === "s3xycaptions") {
            items = [{
                value: 4,
                name: "4"
            }, {
                value: 8,
                name: "8"
            }];
        } else {
            items = Array.from({ length: 8 }, (_, i) => ({
                value: i + 1,
                name: (i + 1).toString()
            }));
        }
        return items
    }, [preset])

    useEffect(() => {
        if (videoId && videoId !== '') {
            dispatch({ type: "mediaPlayer/setDefault", payload: videoId })

            if (videoId !== '' && (user?.id || guestId))
                dispatch(getVideoFiles({ userId: user?.id || guestId, videoId }))
        }
        else {
            navigate("/")
        }
    }, [videoId, user?.id, guestId])

    useEffect(() => {
        //temperory 349 line
        // dispatch(addVideoId('67231104-6be7-4a70-817a-6392d5209d63'))

        logPageView()
        if (!youtubeVideoCategory?.length) dispatch(fetchYoutubeVideoCategory())
        Fonts.map(({ family }) => loadFont(family))


    }, [])


    const returnVideoURL = (): string | undefined => { // Specify return type as string | undefined
        let currentService = videoFile?.active_service[0]
        if (currentService == "aistudio") {
            return videoFile?.urls?.aistudio_rendered_finalvideo;
        }
        if (currentService == "text_to_video") {
            return videoFile?.urls?.text_to_video_rendered_uri
        }
        if (currentService == "transcribe") {
            return videoFile?.urls?.rendered_captioned_video;
        }
        if (currentService == "compression")
            return videoFile?.urls?.compressed_updated_video_file
    };
    useEffect(() => {
        scrollToTop()

        let videoURL = returnVideoURL()
        let url = null

        if (
            videoFile &&
            videoFile.urls &&
            videoFile.urls.hasOwnProperty('vtt_modified_file')
        )
            url = videoFile?.urls['vtt_modified_file']
        else if (
            videoFile &&
            videoFile.urls &&
            videoFile.urls.hasOwnProperty('vtt_original_file')
        )
            url = videoFile?.urls['vtt_original_file']

        if (exportField) {
            setTitle(exportField.title)
            setDescription(exportField.description)
            setPrivacyStatus(exportField.privacyStatus)
            setTags(exportField.tags)
            setCategoryId(exportField.categoryId)
            setAccordian(exportField.accordian)
        }

        let obj = {
            videoURL: videoURL,
            title: exportField?.title || title,
            description: exportField?.description || description,
            privacyStatus,
            tags: exportField?.tags,
            categoryId,
            platform,
            lang: 'en',
            vttURL: url,
            publishAt: exportField?.publishAt
        }

        logEvent({
            category: 'Video',
            action: `Upload Video ${searchParams.get('upload')}`,
            label: videoURL,
        })

        switch (searchParams.get('upload')) {
            case 'youtube':
                youtubeExportUpload(obj)
                // dispatch(uploadYoutubeVideo(obj))
                break
            case 'vimeo':
                dispatch(uploadVimeoVideo(obj))
                break
            case 'tiktok':
                dispatch(uploadTiktokVideo(obj))
                break
            case 'reel':
                dispatch(uploadReelVideo(obj))
                break
            default:
                break
        }

        setTimeout(() => {
            const tour = localStorage.getItem('tour')
            if (
                !tour ||
                (tour == 'false' && videoFile?.caption_animation == 'default')
            ) {
                // setIsOpen(true)
                localStorage.setItem('tour', 'true') // Set the key to prevent displaying the banner in future visits
            }
        }, 2000)
    }, [])


    function valuetext(value: number) {
        return `${value}`
    }

    function valueLabelFormat(value: number) {
        // if (marks.findIndex((mark) => mark.value === value) !== captionPosition)
        //     updateCaptionPosition(marks.findIndex((mark) => mark.value === value))
        return marks.findIndex((mark) => mark.value === value) + 1
    }

    function presentChangeHandler(name: string) {
        dispatch(discordWebhook(`Clicked on preset ${name}`))

        let findPresent = PresetNew.find((pre) => pre.name === name)
        if (findPresent && findPresent.colors && videoFile) {
            let orientation = getVideoOrientation(videoFile?.resolution[0], videoFile?.resolution[1])
            // console.log({ orientation })
            // loadFont(findPresent?.fontType, findPresent?.style)
            let fontSize = findPresent?.fontSize || 15
            let position = findPresent?.position || captionPosition
            let capMaxWords: any = name === "one_word" ? 1 :
                name === "s3xycaptions" ? (orientation === "vertical" ? 4 : 8) :
                    name === "transpify_special" ? 3 : maxWords;

            updatePresets({
                captionColor: findPresent?.colors,
                captionPosition: position,
                font: findPresent?.fontType,
                variant: findPresent?.style,
                fontSize: fontSize,
                preset: name,
                customCaption: true,
                videoToggle: videoToggle,
                italic: false,
                bold: false,
                underline: false,
                maxWords: 3,
                visuals: visuals,
            })
        }
    }

    function ExportVideoYoutube() {
        let videoURL = videoFile?.urls?.updated_video
            ? videoFile.urls?.updated_video
            : videoFile?.urls?.video_original_file

        let tempErrors: Errors = {}

        if (!title) tempErrors.title = true
        if (!description) tempErrors.description = true
        if (!privacyStatus) tempErrors.privacyStatus = true
        if (tags.length <= 0) tempErrors.tags = true

        setErrors(tempErrors)
        if (platform == 4) {
            setLoaderExport(true)
            downloadVideo(
                videoURL as string,
                recentProject?.find(({ video_id }: any) => video_id == videoId).name,
            )
                .then(() => {
                    setLoaderExport(false)
                })
                .catch(() => {
                    setLoaderExport(false)
                })
        }
        if (title && description && privacyStatus && tags.length > 0) {
            dispatch({
                type: 'export/UPDATE_EXPORT',
                payload: {
                    videoURL,
                    title,
                    description,
                    privacyStatus,
                    tags,
                    categoryId,
                    accordian,
                },
            })
            logEvent({
                category: 'Video',
                action: 'Export Click',
                label: TabMain[platform],
            })
            setTimeout(() => {
                switch (platform) {
                    case 0:
                        window.location.replace(`${BASE_URL}/auth/google?id=${user?.id}`)
                        break
                    case 1:
                        window.location.replace(`${BASE_URL}/auth/tiktok?id=${user?.id}`)
                        break
                    case 2:
                        window.location.replace(`${BASE_URL}/auth/vimeo?id=${user?.id}`)
                        break
                    case 3:
                        window.location.replace(`${BASE_URL}/auth/instagram?id=${user?.id}`)
                        break
                    case 4:
                        setLoaderExport(true)
                        downloadVideo(
                            videoURL as string,
                            recentProject?.find(({ video_id }: any) => video_id == videoId)
                                .name,
                        )
                        setTimeout(() => {
                            setLoaderExport(false)
                        }, 3000)
                        break
                    default:
                        break
                }
            }, 1000)
        }
    }


    function rgbaToHex(r: number, g: number, b: number, a = 1) {
        const toHex = (value: any) => {
            let hex = value.toString(16)
            return hex.length === 1 ? '0' + hex : hex
        }

        return `&H${toHex(b)}${toHex(g)}${toHex(r)}`
    }

    const getFirstError = (): string | null => {
        for (let key in errors) {
            if (errors[key as keyof Errors]) {
                // Capitalize the first letter of the key and return the error message.
                const field = key.charAt(0).toUpperCase() + key.slice(1)
                return `${field} is required`
            }
        }
        return null
    }

    const audioRef = useRef<HTMLAudioElement | null>(null);


    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.muted = true;
        }
    }, [audioRef])



    const addEhanceVideo = () => {
        let data = new FormData()
        if (user && videoId) {
            data.append("user_id", user?.id)
            data.append("video_id", videoId)
            dispatch(enhanceAudio(data))
        }
    }

    const returnIcon = (txt: string, currentTab: string, hoveredItem: string | null) => {
        const isActive = txt === currentTab || txt === hoveredItem;
        switch (txt) {
            case 'Go Back':
                return <BackIcon />;
            case "AI Studio":
                return isActive ? <img src={'/sidebar/blue/AIStudio.svg'} alt="AI Studio" /> : <img src={'/sidebar/AIStudio.svg'} alt="AI Studio" />;
            case "Transcription":
                return isActive ? <img src={'/sidebar/blue/Transcription.svg'} alt="Transcription" /> : <img src={'/sidebar/Transcription.svg'} alt="Transcription" />;
            case "Dub":
                return isActive ? <img src={'/sidebar/blue/Dub.svg'} alt="Dub" /> : <img src={'/sidebar/Dub.svg'} alt="Dub" />;
            case "OverDub":
                return isActive ? <img src={'/sidebar/blue/OverDub.svg'} alt="OverDub" /> : <img src={'/sidebar/OverDub.svg'} alt="OverDub" />;
            case "Add Voice-over":
                return isActive ? <img src={'/sidebar/blue/TextToSpeech.svg'} alt="TextToSpeech" /> : <img src={'/sidebar/TextToSpeech.svg'} alt="TextToSpeech" />;
            case 'Add Media':
                return isActive ? <img src={'/sidebar/blue/AddMedia.svg'} alt="AddMedia" /> : <img src={'/sidebar/AddMedia.svg'} alt="AddMedia" />;
        }
    }



    const changeVideoTab = (event: React.SyntheticEvent, newValue: number) => {
        setVideoTab(newValue);
    }

    const PlatformDisabledVideo = () => {
        return false
    }

    const publishVideo = () => {

        // on services
        // setAlertModal({
        //     open: true, msg:
        //         "We are working on new updates. We are implementing a realtime render option so, the services will not work for atleast 8 - 12 hours. Kindly try again after that."
        // })
        // return

        if (videoFile && !videoFile.in_queue_waiting_for_rendering && !videoFile?.aistudio_rendering_ongoing) {
            dispatch(discordWebhook(`Render AI Studio Video: ${videoId}`))
            addCaptions(); // Call addCaptions if status is not "Processing"
        }
        setTimeout(() => navigate("/publish"), 1000)
    }

    useEffect(() => {

        dispatch(getToken(user?.id))

    }, [])


    const updateCaption = () => {
        dispatch(updateAICaptionSubtitle({
            form: {
                updated_transcription_data: subtitleJSON,
                updated_emoji_data: subtitleEmoji,
                // "updated_gif_data": subtitleGif
            }, videoId: videoFile?.video_id, userId: videoFile?.user_id
        }));
    }

    const scrollToTop = () => {
        if (scrollableRef.current && isXs) {
            console.log("scrollToTop", scrollableRef.current)
            scrollableRef.current.scrollTop = 0;
        }
    }


    const marks = [
        {
            value: 1,
            label: 'Top',
        },
        {
            value: 2,
            label: '',
        },
        {
            value: 3,
            label: '',
        },
        {
            value: 4,
            label: '',
        },
        {
            value: 5,
            label: 'center',
        },
        {
            value: 6,
            label: '',
        },
        {
            value: 7,
            label: '',
        }, {
            value: 8,
            label: '',
        },
        {
            value: 9,
            label: 'Bottom',
        },
    ];

    return (
        <div style={{ marginBottom: isXs ? '10vh' : '0', backgroundColor: 'white' }}> {/* Adjust padding to make room for the video player */}
            {/* <VideoProgress userId={user?.id || guestId} videoId={videoId} /> */}
            {isXs && audioRef && (
                <div style={{ position: 'fixed', top: 0, left: 0, right: 0, height: '30vh', zIndex: 1000, backgroundColor: "black" }}>
                    <div style={{ backgroundColor: "white", width: '100%', justifyContent: 'space-between', display: 'flex', padding: '9px', paddingLeft: "12px", paddingRight: "12px" }}>
                        <div onClick={() => navigate("/")}><ArrowBackIcon htmlColor='black'></ArrowBackIcon></div>
                        <div style={{ display: "flex" }}>
                            <Tooltip title="Render Video">
                                <div onClick={publishVideo}>
                                    <PublishOutlinedIcon htmlColor='black'></PublishOutlinedIcon>
                                </div>
                            </Tooltip>
                            {videoFile?.urls?.rendered_captioned_video &&
                                <Tooltip title="Done">
                                    <div onClick={() => {
                                        dispatch(discordWebhook(`Video Done ${videoId}`))
                                        navigate("/publish")
                                    }}>
                                        <DoneIcon htmlColor='black'></DoneIcon>
                                    </div>
                                </Tooltip>}

                        </div>
                    </div>
                    <VideoPlayer
                        // isOpen={isOpen}
                        // setIsOpen={setIsOpen}
                        // setCurrentStep={setCurrentStep}
                        // audioRef={audioRef}
                        // subtitleJSON={subtitleJSON}
                        // setSubtitleJSON={setSubtitleJSON}
                        isXs={isXs}
                    />
                </div>
            )
            }
            <Fragment>
                {!isXs && <Header></Header>}
                <Container style={{ paddingTop: isXs ? '37vh' : '0', width: '100%', maxWidth: '100%', backgroundColor: 'white', marginBottom: "220px" }}
                    ref={scrollableRef}
                    className={`scrollable-container`} maxWidth={false}>

                    <Grid sx={{ width: '100%' }} container spacing={2}>
                        {!isXs && <Grid sx={{ border: "1px solid #DDD;" }} md={0.8}>
                            <div style={{}} className="drawer-container">
                                <Divider />
                                <List sx={{ marginTop: 2 }}>
                                    {sidebar.map(({ name, disabled }, index) => (
                                        <ListItem key={name + index} disablePadding sx={{ display: 'block' }}
                                            onMouseEnter={() => setHoveredItem(name)}
                                            onMouseLeave={() => setHoveredItem(null)}

                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 80,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                    flexDirection: open ? 'row' : 'column',
                                                    backgroundColor: '#FFF',
                                                }}
                                                disabled={disabled}
                                                onClick={() => {
                                                    if (name == "Go Back") navigate("/")
                                                    setCurrentTab(name)
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                        marginRight: open ? "25px" : "0px",
                                                        color: currentTab == name ? "#0075E1" : 'black',
                                                    }}
                                                >
                                                    {returnIcon(name, currentTab, hoveredItem)}
                                                </ListItemIcon>
                                                <ListItemText primary={name} sx={{
                                                    opacity: open ? 1 : 1,
                                                    color: currentTab == name ? "#0075E1" : '#888',
                                                    textAlign: 'center',
                                                    fontSize: '11px',

                                                }}
                                                    className='myListItemText'
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </Grid>}
                        {currentTab == 'AI Studio' ?
                            <Grid sx={{ marginTop: 2 }} md={6.5}>
                                <TitleHead name={"AI Studio"} />
                                <Grid md={12} sx={{ padding: 3, backgroundColor: 'white' }}>
                                    <VideoEffect />
                                </Grid>
                                <Grid md={12} sx={{ paddingLeft: 3, backgroundColor: 'white', marginTop: 2 }}>
                                    <p className='title-header mb-3'>Captions</p>
                                    {isXs ?
                                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            <Grid
                                                container
                                                spacing={0.5}
                                                xs={12}
                                                md={12}
                                                sx={{
                                                    overflow: 'auto',
                                                    whiteSpace: { xs: 'normal', sm: 'nowrap' },
                                                    width: '100%',
                                                }}
                                                className="preset-main-box"
                                            >
                                                {PresetNew.map(({ picture, name, disabled, access }) => {
                                                    const service = "aistudio";
                                                    if (disabled || !access.includes(service)) return null;
                                                    return (
                                                        <Grid item xs={4} md={2.4} key={name}>
                                                            <div
                                                                style={{
                                                                    borderColor: name === preset ? '#00A3FF' : 'rgba(255,255,255,0.2)',
                                                                    borderWidth: '2px',
                                                                }}
                                                                onClick={() => (!PlatformDisabledVideo() || visuals !== 'none') && presentChangeHandler(name)}
                                                                className="box cursor-pointer box-shadoww"
                                                            >
                                                                {name && (
                                                                    <img
                                                                        onClick={() => (!PlatformDisabledVideo() || visuals !== 'none') && presentChangeHandler(name)}
                                                                        src={picture}
                                                                        alt={name}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </div>
                                        :
                                        <>
                                            <Grid xs={12} md={12} sx={{
                                                overflow: "auto",
                                                whiteSpace: { xs: 'normal', sm: 'nowrap' },
                                                width: '100%',
                                            }} className="preset-main-box"
                                                ref={presetContainerRef1}>
                                                {PresetNew.slice(0, 16).map(({ picture, name, disabled }) => {
                                                    if (disabled) return
                                                    return <Grid key={name} item xs={3.95} md={2.4}>
                                                        <div
                                                            style={{
                                                                borderColor: name === preset ? '#00A3FF' : "rgba(255,255,255,0.2)",
                                                                borderWidth: "2px",
                                                                // minWidth: '155px', minHeight: '80px'
                                                            }}
                                                            onClick={() => (!PlatformDisabledVideo() || visuals !== "none") && presentChangeHandler(name)}
                                                            className="box cursor-pointer box-shadoww"
                                                        >
                                                            {name != "" && <img
                                                                onClick={() => (!PlatformDisabledVideo() || visuals !== "none") && presentChangeHandler(name)}
                                                                src={picture}></img>}
                                                        </div>
                                                    </Grid>
                                                })}
                                            </Grid>
                                            <Grid xs={12} md={12} sx={{
                                                overflow: "auto",
                                                whiteSpace: { xs: 'normal', sm: 'nowrap' },
                                                width: '100%',
                                            }} className="preset-main-box"
                                                ref={presetContainerRef2}
                                            >
                                                {PresetNew.slice(16, 25).map(({ picture, name, disabled }) => {
                                                    if (disabled) return
                                                    return <Grid key={name} item xs={3.95} md={2.4}>
                                                        <div
                                                            style={{
                                                                borderColor: name === preset ? '#00A3FF' : "rgba(255,255,255,0.2)",
                                                                borderWidth: "2px",
                                                                // minWidth: '155px', minHeight: '80px'
                                                            }}
                                                            onClick={() => (!PlatformDisabledVideo() || visuals !== "none") && presentChangeHandler(name)}
                                                            className="box cursor-pointer box-shadoww"
                                                        >
                                                            {name != "" && <img
                                                                onClick={() => (!PlatformDisabledVideo() || visuals !== "none") && presentChangeHandler(name)}
                                                                src={picture}></img>}
                                                        </div>
                                                    </Grid>
                                                })}
                                                <Grid item xs={3.95} md={2.4}>
                                                    <div
                                                        style={{
                                                            color: 'white',
                                                            // minWidth: '155px',?
                                                            width: "114px"
                                                        }}
                                                        className={`box-cs cursor-pointer box-shadoww ${user?.subscriptionStatus !== "succeeded" ? 'disabled' : ''}`}
                                                        onClick={() => user?.subscriptionStatus === "succeeded" ? setCaptionReqModal(true) : setPremiumModal(true)}
                                                    >
                                                        <p className='request-custom'>
                                                            Request your<br />
                                                            <span className='request-custom-yellow'>Custom Preset</span>
                                                        </p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    {!isXs && <Grid xs={12} md={12} sx={{
                                        overflow: "auto",
                                        whiteSpace: { xs: 'normal', sm: 'nowrap' },
                                        width: '100%',
                                        marginTop: 2
                                    }}
                                        ref={fontContainerRef}
                                    >
                                        {Fonts.map(({ family }) => {
                                            const isSelected = font === family;
                                            const isDisabled = preset !== "plaintext";
                                            return (
                                                <div
                                                    onClick={() => !PlatformDisabledVideo() && preset === "plaintext" && updateFont(family)}
                                                    className={`font-circle cursor-pointer ${isDisabled ? 'font-circle-disabled' : ''} ${preset === "plaintext" ? "box-shadoww" : ""} ${isSelected ? 'font-circle-selected' : ''}`}
                                                    style={{
                                                        display: 'inline-flex', // Use inline-flex for items to align them in a line
                                                        marginRight: '10px', // Add some space between the font choices
                                                    }}
                                                    aria-disabled={preset !== "plaintext"}
                                                    key={family}
                                                >
                                                    <p
                                                        style={{
                                                            fontFamily: family,
                                                            color: isSelected ? '#0075E1' : '#A1A1A1',
                                                            fontSize: 20
                                                        }}
                                                        aria-disabled={preset !== "plaintext"}
                                                    >
                                                        Aa
                                                    </p>
                                                </div>
                                            )
                                        })}

                                    </Grid>
                                    }
                                </Grid>
                                <Grid md={12} sx={{ padding: 3, backgroundColor: 'white' }}>
                                    {/* <p className='title-header mb-3'>Text Preferences:</p> */}
                                    <Grid container md={12}>
                                        <Grid sx={{}} sm={6} xs={6} md={4}>
                                            {captionColor && captionColor.length > 0 && <> <p className='title-name mb-2'>Font Colors</p>
                                                <div className='display-flex'>
                                                    {captionColor && captionColor.length > 0 && captionColor?.map((_, i) =>
                                                        <Grid item sm={2} xs={2} md={2}>
                                                            <SketchExample
                                                                disabled={PlatformDisabledVideo() || !customCaption}
                                                                name={i}
                                                            />
                                                        </Grid>
                                                    )}
                                                </div></>}
                                        </Grid>
                                        <Grid md={4} xs={6} sm={6}>
                                            <p className='title-name mb-2'>Visuals</p>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={visuals || "none"}
                                                disabled={PlatformDisabledVideo()}
                                                exclusive
                                                onChange={(_: any, value: string) => { if (value !== null) updateVisuals(value) }}
                                                aria-label="Platform"
                                                className='box-shadoww'
                                                sx={{ height: "32px" }}
                                            >
                                                <ToggleButton value="emojis">Emojis</ToggleButton>
                                                <ToggleButton value="GIFs">GIFs</ToggleButton>
                                                <ToggleButton value="none">None</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                        <Grid sx={{ paddingRight: "20px" }} sm={12} xs={12} md={4}>
                                            <p className='title-name mb-2'>Font size</p>
                                            <FontSizeChanger
                                                disabled={PlatformDisabledVideo() || preset === "s3xycaptions" || !customCaption}
                                            />
                                        </Grid>
                                        {/*  <Grid sm={6} xs={6} md={4}>
                                           <p className='title-name mb-2'>Font Preferences</p>
                                            <Grid sx={{ display: 'flex' }} item sm={1.5} xs={1.5} md={1.5}>
                                                <IconButton
                                                    disabled={PlatformDisabledVideo() || !customCaption}
                                                    sx={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '2px',
                                                        backgroundColor: bold ? "#C7E2F1" : '#FFF',
                                                        border: '0.9px solid #D9D9D9',
                                                        marginRight: "4px"
                                                    }}
                                                    onClick={() => updateBold(!bold)}
                                                    aria-label="bold"
                                                >
                                                    <FormatBoldIcon
                                                        sx={{
                                                            color: 'black',
                                                            opacity: bold ? 1 : 0.5, // Toggle icon opacity based on bold state
                                                        }}
                                                    />
                                                </IconButton>
                                                <IconButton
                                                    disabled={PlatformDisabledVideo() || !customCaption}
                                                    sx={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '2px',
                                                        backgroundColor: italic ? "#C7E2F1" : '#FFF',
                                                        border: '0.9px solid #D9D9D9',
                                                        marginRight: "4px"
                                                    }}
                                                    onClick={() => updateItalic(!italic)}
                                                    aria-label="italic"
                                                >
                                                    <FormatItalicIcon
                                                        sx={{
                                                            color: 'black',
                                                            opacity: italic ? 1 : 0.5, // Toggle icon opacity based on italic state
                                                        }}
                                                    />
                                                </IconButton>
                                                <IconButton
                                                    disabled={PlatformDisabledVideo() || !customCaption}
                                                    sx={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '2px',
                                                        backgroundColor: underline ? "#C7E2F1" : '#FFF',
                                                        border: '0.9px solid #D9D9D9',
                                                        marginRight: "4px"
                                                    }}
                                                    onClick={() => updateUnderline(!underline)}
                                                    aria-label="italic"
                                                >
                                                    <FormatUnderlinedIcon
                                                        sx={{
                                                            color: 'black',
                                                            opacity: underline ? 1 : 0.5, // Toggle icon opacity based on italic state
                                                        }}
                                                    />
                                                </IconButton>
                                            </Grid> 
                                        </Grid>*/}

                                        <Grid sx={{ marginTop: '20px' }} sm={12} xs={12} md={8}>
                                            <p className='title-name mb-2'>Max words</p>
                                            <NumberButton num={preset == "one_word" ? 1 : 6} onChange={(num: number) => {
                                                if (num !== maxWords) {
                                                    updateMaxWords(num)
                                                }
                                            }}
                                                PlatformDisabledVideo={(PlatformDisabledVideo || visuals !== "none" || !customCaption)} customCaption={customCaption} maxWords={maxWords}
                                            />
                                        </Grid>
                                        <Grid sx={{ marginTop: '20px', paddingRight: "20px" }} sm={12} xs={12} md={4}>
                                            <p className='title-name mb-2' >Position</p>
                                            {/* <div className='box-shadoww' style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                borderRadius: "3.413px",
                                                border: " 0.853px solid #D9D9D9",

                                                background: "#FFF",
                                            }}> */}
                                            <PositionSlide
                                                // size="small"
                                                defaultValue={5}
                                                // marks={marks}
                                                min={1}
                                                max={9}
                                                disabled={PlatformDisabledVideo() || preset === "transpify_special" || !customCaption}
                                                aria-label="Small steps"
                                                onChange={(a, b) => updateCaptionPosition(b as number)}
                                                // valueLabelFormat={valueLabelFormat}
                                                getAriaValueText={valuetext}
                                                // step={null}
                                                value={captionPosition}
                                                // value={preset === "transpify_special" ? 50 : captionPosition}
                                                valueLabelDisplay="auto"
                                                sx={{ margin: "0.6px 10px" }}
                                                marks={marks}
                                            />
                                            {/* </div> */}
                                        </Grid>
                                        <Grid sm={12} xs={6} md={6}>
                                        </Grid>


                                    </Grid>
                                </Grid>
                                <Grid md={12} sx={{ padding: 3, backgroundColor: 'white' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>    <p className='title-header mb-3'>Audio Preferences: (Coming Soon)</p>
                                        {/* <div className="apply-button box-shadoww">
                                            <p className='text'>
                                                Apply Changes
                                            </p>
                                        </div> */}
                                    </div>
                                    <Grid container md={12}>
                                        <Grid md={6}>
                                            <p className='audio-title'>Music</p>
                                            <MusicCard disabled={true} />
                                        </Grid>
                                        <Grid md={6}>
                                            <div style={{ marginLeft: isXs ? 0 : 40, marginTop: 10 }}>
                                                <p className='audio-title'>Audio Effects</p>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox />}
                                                            disabled={true}
                                                            label="Denoise Audio"
                                                            sx={{
                                                                '& .MuiFormControlLabel-label': {
                                                                    fontFamily: "Noto Sans", // Replace with your actual font family
                                                                    fontSize: '14px', // Adjust the size as needed
                                                                    color: '#757575' // Use the color you want for the text
                                                                },
                                                                '& .MuiSvgIcon-root': { // This is if you want to style the checkbox icon itself
                                                                    fontSize: '20px',
                                                                    color: '#D4D4D8',
                                                                    borderRadius: '4px'
                                                                }
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    {/* <MyToggleButton
                                                        selected={false}
                                                        value="check"
                                                        disabled={!musicTracks}
                                                        onChange={addEhanceVideo}
                                                    >
                                                        Enhance Audio
                                                    </MyToggleButton>
                                                    <MyToggleButton
                                                        selected={false}
                                                        value="check"
                                                        disabled={!musicTracks}
                                                        onChange={addEhanceVideo}
                                                    >
                                                        Hook Effects
                                                    </MyToggleButton> */}
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            currentTab == 'Transcription' ?
                                <Grid sx={{ marginTop: 2 }} md={6.5}>
                                    {captions.length > 0 && <Subtitle disabled={true} />}
                                </Grid >
                                :
                                currentTab == "Add Media" ? <Grid sx={{ marginTop: 2 }} md={6.5}>
                                    <SceneBlocks />
                                </Grid> : ""}
                        {!isXs && <Grid
                            className="sticky-mobile"
                            sx={{ backgroundColor: "white", padding: 3, marginTop: 2, }} md={4.5}>
                            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <Box className="box-shadoww" sx={{ bgcolor: '#ECECEC', overflowX: 'auto', width: '250px' }}>
                                    <StyledTabs
                                        value={videoTab}
                                        onChange={(_: any, value: number) => setVideoTab(value)}
                                        aria-label="styled tabs example"
                                        sx={{
                                            height: 20,
                                            minHeight: 28,
                                            alignItems: 'center',
                                            '& .MuiTab-root': {
                                                minWidth: 'auto',
                                                '@media (max-width:600px)': {
                                                    padding: '6px 12px',
                                                },
                                            },
                                        }}

                                    // scrollButtons="auto"
                                    >
                                        {/* <StyledTab
                                        disabled="true"
                                        label="AI Short"
                                    /> */}
                                        <StyledTab label="AI Studio" />
                                    </StyledTabs>
                                </Box>
                            </div>
                            {/* {audioRef &&  */}
                            <Grid display={'flex'} justifyContent={"center"} alignItems={"flex-start"} md={12}>
                                <VideoPlayer
                                    videoTab={videoTab}
                                />
                            </Grid>
                            {/* } */}
                            <Grid md={12}>
                                <div style={{ width: '100%', marginTop: 30 }}>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <button onClick={publishVideo} style={{ marginLeft: 10 }} className="done-button box-shadoww">
                                            {videoFile?.aistudio_rendering_completed ? "Re-render" : "Render"}
                                        </button>
                                        {videoFile?.aistudio_rendering_completed &&
                                            <button onClick={() => {
                                                dispatch(discordWebhook(`AI Studio Video Done ${videoId}`))
                                                navigate("/publish")
                                            }} style={{ marginLeft: 10 }} className="done-button box-shadoww">
                                                Publish
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.8202 5.27654L18.7528 11.3605C18.8427 11.4527 18.9065 11.5526 18.9443 11.6601C18.982 11.7676 19.0006 11.8829 19 12.0058C19 12.1287 18.9811 12.2439 18.9434 12.3515C18.9056 12.459 18.8421 12.5589 18.7528 12.651L12.8202 18.735C12.6554 18.904 12.4493 18.9922 12.2018 18.9996C11.9543 19.0069 11.741 18.9188 11.5618 18.735C11.382 18.566 11.2882 18.3546 11.2804 18.1008C11.2727 17.847 11.3589 17.6282 11.5393 17.4445L15.9438 12.9276L5.89888 12.9276C5.6442 12.9276 5.43057 12.8391 5.25798 12.6621C5.0854 12.4851 4.9994 12.2663 5 12.0058C5 11.7446 5.086 11.5255 5.25798 11.3485C5.42997 11.1715 5.6436 11.0834 5.89888 11.084L15.9438 11.084L11.5393 6.56708C11.3745 6.39808 11.2882 6.18299 11.2804 5.92181C11.2727 5.66063 11.3589 5.44554 11.5393 5.27654C11.7041 5.09218 11.9139 5 12.1685 5C12.4232 5 12.6404 5.09218 12.8202 5.27654Z" fill="white" />
                                                </svg>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </Grid>

                        </Grid>}
                        {isXs && <div style={{ width: '100%', marginBottom: 10 }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                {videoFile?.aistudio_rendering_ongoing == false && <button onClick={publishVideo} style={{ marginLeft: 10 }} className="done-button box-shadoww">
                                    {videoFile?.urls?.rendered_captioned_video ? "Re-render" : "Render"}
                                </button>}
                                {videoFile?.urls?.rendered_captioned_video &&
                                    <button onClick={() => {
                                        dispatch(discordWebhook(`Video Done ${videoId}`))
                                        navigate("/publish")
                                    }} style={{ marginLeft: 10 }} className="done-button box-shadoww">
                                        Done
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.8202 5.27654L18.7528 11.3605C18.8427 11.4527 18.9065 11.5526 18.9443 11.6601C18.982 11.7676 19.0006 11.8829 19 12.0058C19 12.1287 18.9811 12.2439 18.9434 12.3515C18.9056 12.459 18.8421 12.5589 18.7528 12.651L12.8202 18.735C12.6554 18.904 12.4493 18.9922 12.2018 18.9996C11.9543 19.0069 11.741 18.9188 11.5618 18.735C11.382 18.566 11.2882 18.3546 11.2804 18.1008C11.2727 17.847 11.3589 17.6282 11.5393 17.4445L15.9438 12.9276L5.89888 12.9276C5.6442 12.9276 5.43057 12.8391 5.25798 12.6621C5.0854 12.4851 4.9994 12.2663 5 12.0058C5 11.7446 5.086 11.5255 5.25798 11.3485C5.42997 11.1715 5.6436 11.0834 5.89888 11.084L15.9438 11.084L11.5393 6.56708C11.3745 6.39808 11.2882 6.18299 11.2804 5.92181C11.2727 5.66063 11.3589 5.44554 11.5393 5.27654C11.7041 5.09218 11.9139 5 12.1685 5C12.4232 5 12.6404 5.09218 12.8202 5.27654Z" fill="white" />
                                        </svg>
                                    </button>
                                }
                            </div>
                        </div>}
                        {/* Mobile Footer */}
                        <div className="footer-container" style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            backgroundColor: theme.palette.background.paper,
                            borderTop: `1px solid ${theme.palette.divider}`,
                            zIndex: 1000,
                        }}>
                            <List style={listStyle}>
                                {[{ name: 'AI Caption', disabled: false }, { name: "Transcription", disabled: false }].map(({ name, disabled }, index) => (
                                    <ListItem key={name + index}
                                        onClick={() => {
                                            if (name == "Go Back") navigate("/")
                                            setCurrentTab(name)
                                        }}
                                        disablePadding sx={{ display: 'block', }}>
                                        <ListItemButton className="display-flex">
                                            <ListItemIcon>
                                                {returnIcon(name, currentTab, hoveredItem)}
                                            </ListItemIcon>
                                            {/* <ListItemText primary={text} /> */}
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Grid>
                    {/* <Button variant="contained" onClick={scrollToTop}>
                        Scroll to Top
                    </Button> */}
                </Container>
                {captions && captions.length > 0 && <Timeline disabled={true} captions={captions} setCaptions={setCaptions} videoDuration={videoFile?.duration} />}

                <LanguageModal
                    open={isLoadingVideo || isLoading || loading}
                    check="false"
                    type={'loading'}
                />

                <RequestCaptions open={captionReqMoal} onClose={() => setCaptionReqModal(false)}></RequestCaptions>
                {/* <VideoPublishModal open={publishModal} setOpen={setPublishModal}></VideoPublishModal> */}
                <PremiumModal open={premimumModal} setOpen={setPremiumModal}></PremiumModal>
                <MessageModal open={alertModal.open} close={() => setAlertModal({ msg: "", open: false })} msg={alertModal.msg} />

            </Fragment>
        </div >
    )
}

export default AIStudioVideo
