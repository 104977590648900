/* eslint-disable @remotion/volume-callback */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable array-callback-return */
/* eslint-disable @remotion/from-0 */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-sort-props */
/* eslint-d?isable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-extend-native */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-else-return */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
/* eslint-disable capitalized-comments */

import {
  AbsoluteFill,
  Sequence,
  useVideoConfig,
  OffthreadVideo,
  Audio,
  Img,
  delayRender,
  continueRender,
  Loop,
  cancelRender,
  useCurrentFrame,
} from "remotion";
import { getVideoMetadata } from "@remotion/media-utils";
import { interpolate } from "remotion";
import { useState, useEffect, useRef } from "react";
import { ZoomEffectVideoWithFiltersPodcast } from "./Composition_Zoom_With_Filters_Podcast";
import { CaptionsOverlay } from "./Composition_Captions_Podcast";
import { Shake } from "reshake";
import "./utilities/fonts.css";
import { LightLeakEffect } from "./LightLeaks";
import { secondsToFrames } from "./utilities/util";
import React from "react";
import { Gif } from "@remotion/gif";
import { FlickeringText, ShakingText } from "./MotionBG_captions";
import filtersOverlay from "./Filters";



// Function to generate random numbers based on a seed
function seededRandom(max: any, min: any, seed: any) {
  max = max || 1;
  min = min || 0;
  seed = (seed * 9301 + 49297) % 2380;
  const rnd = seed / 2380.0;
  return min + rnd * (max - min);
}

const groupRandomSegments = (segments: any, fps: any) => {
  const thirtySecondsInFrames = 30 * fps;


  // Sort the remaining segments by start time
  const sortedSegments = segments.sort((a: any, b: any) => a.start - b.start);

  // Split the video into two time windows
  const firstGroup = sortedSegments.filter(
    (segment: any) => secondsToFrames(segment.start, fps) <= thirtySecondsInFrames
  );
  const secondGroup = sortedSegments.filter(
    (segment: any) =>
      secondsToFrames(segment.start, fps) > thirtySecondsInFrames &&
      secondsToFrames(segment.start, fps) <= 2 * thirtySecondsInFrames
  );

  if (firstGroup.length < 2 || secondGroup.length < 2) return [];

  // Generate random indices for more diverse selections
  const seed = segments.length;
  const randomValue1 = Math.floor(seededRandom(0, firstGroup.length - 2, seed));
  const randomValue2 = Math.floor(
    seededRandom(0, secondGroup.length - 2, seed + 1)
  );

  return [
    {
      segments: firstGroup.slice(randomValue1, randomValue1 + 3),
      textStyle: "shaking",
    },
    {
      segments: secondGroup.slice(randomValue2, randomValue2 + 3),
      textStyle: "flickering",
    },
  ];
};




export const CompositionAIShorts = ({
  videoLink,
  jsonData,
  jsonCaptions,
  video_width,
  template,
  emojis_on,
  config,
  broll,
  soundSFX,
  bgmusic,
  overlay,
  zoom,
  filters,
  scene_transitions,
  lightleaks,
  laptop_overlay,
  billboard_overlay = 0,
  logo_on,
}: {
  jsonData: any;
  jsonCaptions: any;
  videoLink: any;
  video_width: any;
  template: any;
  emojis_on: any;
  config: any;
  broll: any;
  soundSFX: any;
  bgmusic: any;
  overlay: any;
  zoom: any;
  filters: any;
  scene_transitions: any;
  lightleaks: any;
  laptop_overlay: any;
  billboard_overlay: any;
  logo_on: any;
}) => {
  const { fps, width, height, durationInFrames } = useVideoConfig();
  const currentFrame = useCurrentFrame();
  const ref = useRef<HTMLCanvasElement>(null);
  // Convert seconds to frames
  const secondsToFrames = (seconds: number) => Math.round(seconds * fps);
  const fadeDurationFrames = Math.round(0.05 * fps);
  const fadeDurationFramesBg = Math.round(fps); // 0.25 seconds for fade in and out

  const fadeDurationFramesBgMusic = Math.min(secondsToFrames(0.5), fps);
  const fadeInEnd = fadeDurationFramesBgMusic;
  const fadeOutStart = durationInFrames - fadeDurationFramesBgMusic;
  // Watermark URL
  const watermarkURL =
    "https://viralmefast.azureedge.net/aistudio/Template_Assets/ViralMe_Watermark_logo.png?sp=r&st=2024-06-18T10:14:34Z&se=2224-06-18T18:14:34Z&spr=https&sv=2022-11-02&sr=b&sig=x0SjxEDchKhJqS53iqNyBhesXM22ADh2tQyBbeGk7Q4%3D";



  // Conditional rendering based on the broll prop
  const renderBroll = broll === 1 || broll === "1" || broll === "true";
  const renderSoundSFX =
    soundSFX === 1 || soundSFX === "1" || soundSFX === "true";
  const renderOverlay = overlay === 1 || overlay === "1" || overlay === "true";
  const renderSceneTransitions =
    scene_transitions === 1 ||
    scene_transitions === "1" ||
    scene_transitions === "true";
  const renderBgMusic = bgmusic === 1 || bgmusic === "1" || bgmusic === "true";
  const render_lightleaks = lightleaks === 1 || lightleaks === "1" || lightleaks === "true";




  ///////////////// delay render code here ////////////

  const getnoisefilterIndex = (segments: any[]) => {
    const lightleakSegment = segments.find(segment => segment.lightleak === 'filter');
    return lightleakSegment ? segments.indexOf(lightleakSegment) % filtersOverlay.Blob_URL.length : 0;
  };

  const noisefilterIndex = getnoisefilterIndex(jsonData.segments);
  const filteroverlayUrl = filtersOverlay.Blob_URL[noisefilterIndex];


  const [handle] = useState(
    () =>
      delayRender("Loading external resources", {
        timeoutInMilliseconds: 10000000,
      }) as any
  );
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {



    const promises = jsonData.segments.map((segment: any) => {
      return new Promise((resolve, reject) => {
        if (!segment.brolls_uri) return resolve(true);
        const img = new Image();

        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = segment.brolls_uri; // Assuming you need to load images for brolls
      });
    });

    Promise.all(promises)
      .then(() => {
        setLoaded(true);
        continueRender(handle);
      })
      .catch((error) => {
        console.error("Failed to load all resources:", error);
        continueRender(handle);
      });

    const promises2 = jsonData.segments.map((segment: any) => {
      return new Promise((resolve, reject) => {
        if (!segment.overlays_uri) return resolve(true);
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = segment.overlays_uri; // Assuming you need to load images for brolls
      });
    });

    Promise.all(promises2)
      .then(() => {
        setLoaded(true);
        continueRender(handle);
      })
      .catch((error) => {
        console.error("Failed to load all resources:", error);
        continueRender(handle);
      });

    return () => continueRender(handle); // Cleanup function to ensure we clear the handle
  }, [jsonData, handle]);

  if (!loaded) {
    return null; // You can return a loading screen here if you prefer
  }


  const LoopedVideoOverlay = ({ src }: any) => {
    const [videoDuration, setVideoDuration] = useState<null | number>(null);
    const handle = useState(() => delayRender())[0];

    useEffect(() => {
      getVideoMetadata(src)
        .then(({ durationInSeconds }) => {
          setVideoDuration(Math.floor(durationInSeconds * fps));
          continueRender(handle);
        })
        .catch((error) => {
          console.error("Error fetching video metadata:", error);
          cancelRender(handle);
        });
    }, [src, fps, handle]);

    if (!videoDuration) {
      return null; // Optionally return a placeholder or loader
    }

    return (
      <Loop durationInFrames={videoDuration} times={Math.floor(durationInFrames / videoDuration + 1)}>
        <OffthreadVideo
          src={src}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            opacity: 0.3, // Using confidence score for opacity
          }}
        />
      </Loop>
    );
  };


  const normalizeVolume = (loudness: any) => {
    const targetLoudness = -6; // Target loudness in LUFS or similar unit
    const adjustment = targetLoudness - loudness;
    return Math.pow(10, adjustment / 20); // Convert adjustment to a linear volume scale
  };




  ///////////////////// Shake Component //////////////////////
  const shakePeriods: { start: number; duration: number }[] = [];

  jsonData.segments.forEach((segment: any) => {
    if (
      segment.broll_transition1SFX &&
      segment.transition1Effect_type === "position_vibrate"
    ) {
      shakePeriods.push({
        start: segment.broll_trans1SFX_start_time - 0.125,
        duration: 0.4, // Assuming a constant duration for simplicity
      });
    }
    if (
      segment.broll_transition2SFX &&
      segment.transition2Effect_type === "position_vibrate"
    ) {
      shakePeriods.push({
        start: segment.broll_trans2SFX_start_time - 0.125,
        duration: 0.4, // Assuming a constant duration for simplicity
      });
    }
  });

  // Initialize default styles
  let isShaking = false;
  let currentOpacity = 1; // Default opacity when not shaking
  let currentBlur = 0; // Default blur when not shaking

  shakePeriods.forEach((period) => {
    const startFrame = period.start * fps;
    const endFrame = startFrame + period.duration * fps;
    if (currentFrame >= startFrame && currentFrame <= endFrame) {
      isShaking = true;
      // Interpolate opacity and blur during the shake period
      const progress = (currentFrame - startFrame) / (endFrame - startFrame);
      currentOpacity = interpolate(progress, [0, 0.55, 1], [1, 1, 1]);
      currentBlur = interpolate(progress, [0, 0.55, 1], [0, 8, 12]); // Blur interpolates from 0px to 4px to 0px
    }
  });

  //////////////////// Light Leak Effect //////////////////////
  // Calculate light leak effects from JSON
  const lightLeaks = jsonData.segments.flatMap((segment: any) => {
    const effects = [];
    if (segment.transition1Effect_type === "light_leak") {
      const startFrame = secondsToFrames(segment.broll_trans1SFX_start_time);
      const duration = secondsToFrames(
        (segment.broll_trans1SFX_exact_time -
          segment.broll_trans1SFX_start_time) *
        2
      );
      effects.push({
        src: segment.broll_transition1_sfx_uri,
        start: startFrame,
        duration,
      });
    }
    if (segment.transition2Effect_type === "light_leak") {
      const startFrame = secondsToFrames(segment.broll_trans2SFX_start_time);
      const duration = secondsToFrames(
        (segment.broll_trans2SFX_exact_time -
          segment.broll_trans2SFX_start_time) *
        2
      );
      effects.push({
        src: segment.broll_transition2_sfx_uri,
        start: startFrame,
        duration,
      });
    }
    return effects;
  });

  const lightLeaksSceneChange = jsonData.scene_change_person_coordinates.flatMap((segment: any) => {
    const effects = [];
    if (segment.scene_transition_on && segment.transition1Effect_type === "light_leak") {
      const startFrame = secondsToFrames(segment.transition_start_time);
      const duration = secondsToFrames(
        (segment.transition_end_time -
          segment.transition_start_time) *
        2
      );
      effects.push({
        src: segment.scene_transition_sfx_uri,
        start: startFrame,
        duration,
      });
    }
    return effects;
  });
  // Gather first and last segments for each unique motion background key
  const motionBgGroups = jsonData.segments.reduce(
    (acc: any, segment: any, index: any) => {
      if (segment.motionbg) {
        const key = segment.motionbg_key;
        if (!acc[key]) {
          acc[key] = { firstSegment: segment, lastSegment: segment };
        } else {
          acc[key].lastSegment = segment; // Always update to ensure it captures the last segment
        }
      }
      return acc;
    },
    {}
  );


  // Utility to check if a given time falls within any of the broll intervals
  const isWithinBroll = (time: any, brollIntervals: any) => {
    return brollIntervals.some((interval: any) => time >= interval.start && time <= interval.end);
  };

  // Step 1: Collect B-roll timings
  const collectBrollTimings = (segments: any) => {
    return segments.reduce((intervals: any, segment: any) => {
      if (segment.brolls_on) {
        intervals.push({
          start: segment.brolls_start_time,
          end: segment.brolls_end_time
        });
      }
      return intervals;
    }, []);
  };

  // Step 2: Filter jsonCaptions based on B-roll timings
  const filterCaptions = (captions: any, brollIntervals: any) => {
    return captions.filter((caption: any) => {
      return !isWithinBroll(caption.start, brollIntervals) && !isWithinBroll(caption.end, brollIntervals);
    });
  };

  // Example usage:
  const brollIntervals = collectBrollTimings(jsonData.segments);
  const filteredCaptions = filterCaptions(jsonCaptions.segments, brollIntervals);


  const groupedSegments = groupRandomSegments([...jsonCaptions.segments], fps); // Clone to avoid mutating original segments

  const motionBackgroundUrls = [
    "https://viralmefast.azureedge.net/aistudio/Motion_Background/Landscape/Landscape/9999-222013864.mp4?se=2200-08-31T06%3A41%3A37Z&sp=r&sv=2023-01-03&sr=b&sig=dl5qStKwyTk6fsIutRN0hnZWhw%2BJfDaaW0dtPxJ56LY%3D",
    "https://viralmefast.azureedge.net/aistudio/Motion_Background/Landscape/Landscape/121184-724686056_small.mp4?se=2200-08-31T06%3A41%3A21Z&sp=r&sv=2023-01-03&sr=b&sig=PWLhtowkSWYLfkHMqR5xMwZIJkXw1Ilv4c7F7Sc1q6Y%3D"
  ];


  const LightLeaksUrl = [
    "https://viralmefast.azureedge.net/aistudio/Transitions_Light_Leaks/Transitions_FX%20%281%29.webm?se=2200-09-03T10%3A41%3A24Z&sp=r&sv=2023-01-03&sr=b&sig=WpxYbCRQO6V6Ue6Ssa9oSuFohpttemZjvZ2MSRZQhfY%3D",
    "https://viralmefast.azureedge.net/aistudio/Transitions_Light_Leaks/Transitions_FX%20%2810%29.webm?se=2200-09-03T10%3A41%3A24Z&sp=r&sv=2023-01-03&sr=b&sig=W26xXUFJrt%2BCU/TSWKCXN1tqbkF%2B/eFpWmSWhSiD/kw%3D",
    "https://viralmefast.azureedge.net/aistudio/Transitions_Light_Leaks/Transitions_FX%20%2811%29.webm?se=2200-09-03T10%3A41%3A24Z&sp=r&sv=2023-01-03&sr=b&sig=8SgvUcbPvWVlSie5atJDFTRt4HoqiOHkf2HJZJ5BaIY%3D",
    "https://viralmefast.azureedge.net/aistudio/Transitions_Light_Leaks/Transitions_FX%20%2813%29.webm?se=2200-09-03T10%3A41%3A25Z&sp=r&sv=2023-01-03&sr=b&sig=WVVpPOvhS0NMkHIvHVOUFC3VOIggirc83L7LsBUMQQE%3D",
  ];

  const emojiUrls = [
    "https://viralmefast.azureedge.net/website-assets/webapp/pointing-down.gif?sp=r&st=2024-07-09T19:43:00Z&se=2224-07-10T03:43:00Z&spr=https&sv=2022-11-02&sr=b&sig=%2BgRm2BtqcmQDAWfDK41IRlpd6JYxun6qF%2BaB2SMcwts%3D",
    "https://viralmefast.azureedge.net/aistudio/Emojis/emoji_u1f4a5.gif?se=2200-08-30T05%3A47%3A02Z&sp=r&sv=2023-01-03&sr=b&sig=WfHxbc4f7JlFPCBdp/t0lmhwJYdYUKlvaMmfSy9WEkQ%3D",
    "https://viralmefast.azureedge.net/aistudio/Emojis/emoji_u1f525.gif?se=2200-08-30T05%3A47%3A35Z&sp=r&sv=2023-01-03&sr=b&sig=HpRkxGc0ejcYcf6xMNB9qgcbEopisffbtfYzWleK1b4%3D"
  ];

  let emojiSeed = 123; // Initialize with a specific seed for emojis
  let lightLeakSeed = 456; // Initialize with a different seed for light leaks

  function getRandomEmojiUrl() {
    const index = Math.floor(seededRandom(emojiUrls.length, 0, emojiSeed++));
    return emojiUrls[index];
  }

  function getRandomLightLeakUrl() {
    const index = Math.floor(seededRandom(LightLeaksUrl.length, 0, lightLeakSeed++));
    return LightLeaksUrl[index];
  };




  return (
    <AbsoluteFill>







      {/* Conditional Light Leaks and Grouped Segments based on the template */}
      {template === "neon" && (
        <>
          {/* Light Leaks at the beginning and end of each group */}
          {groupedSegments.map((group, idx) => {
            const groupStartFrame = secondsToFrames(group.segments[0].start);
            const groupEndFrame = secondsToFrames(
              group.segments[group.segments.length - 1].end
            );

            const lightLeakStart = getRandomLightLeakUrl();
            const lightLeakEnd = getRandomLightLeakUrl();

            return (
              <React.Fragment key={idx}>
                <LightLeakEffect
                  src={lightLeakStart}
                  start={groupStartFrame - Math.round(0.2 * fps)}
                  duration={Math.round(0.4 * fps)}
                />
                <LightLeakEffect
                  src={lightLeakEnd}
                  start={groupEndFrame - Math.round(0.2 * fps)}
                  duration={Math.round(0.4 * fps)}
                />
              </React.Fragment>
            );
          })}
          {groupedSegments.map((group, idx) => {
            const groupStartFrame = secondsToFrames(group.segments[0].start);
            const groupEndFrame = secondsToFrames(
              group.segments[group.segments.length - 1].end
            );
            const groupDurationInFrames = Math.max(
              0,
              groupEndFrame - groupStartFrame
            );


            const motionBgUrl = motionBackgroundUrls[idx % motionBackgroundUrls.length]; // Use modulo to cycle through the array if necessary
            const emojiUrl = getRandomEmojiUrl(); // Get a random emoji URL for each segment

            return (
              <Sequence
                key={idx}
                from={groupStartFrame}
                durationInFrames={groupDurationInFrames}
                layout="none"
              >
                <OffthreadVideo
                  src={motionBgUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    opacity: 1,
                    zIndex: 800,
                  }}
                  volume={0}
                />

                <div style={{ display: 'inline-block', filter: 'drop-shadow(0.45rem 0.95rem 1.5rem rgba(0, 0, 0, 1))', zIndex: 950, position: 'absolute', width: '100%', height: '100%' }} >
                  <Gif
                    ref={ref}
                    src={emojiUrl}
                    style={{
                      position: 'absolute',
                      left: '50%', // Center horizontally
                      top: '50%', // Center vertically
                      transform: 'translate(-50%, -85%)', // Ensure it's centered on both axes
                      zIndex: 950, // Higher zIndex for visibility
                      WebkitTextStroke: '1.9px #fff',
                    }}
                    width={width / 2} // Adjust size as needed
                    height={height / 2}
                    fit="contain"
                    playbackRate={1}
                    loopBehavior="loop"
                  />
                </div>


                {group.segments.map((segment: any, segIndex: any) => {

                  // Adjust segment duration to start precisely after the previous segment ends
                  const segmentStartFrame =
                    secondsToFrames(segment.start) - groupStartFrame;
                  const nextSegmentStart = group.segments[segIndex + 1]
                    ? secondsToFrames(group.segments[segIndex + 1].start) -
                    groupStartFrame
                    : groupEndFrame;
                  const segmentDurationInFrames =
                    nextSegmentStart - segmentStartFrame;



                  return (
                    <Sequence
                      key={segIndex}
                      from={segmentStartFrame}
                      durationInFrames={segmentDurationInFrames}
                    >

                      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                        {idx === 0 ? (
                          <ShakingText
                            text={segment.text}
                            start={segment.start}
                            end={segment.end}
                          />
                        ) : (
                          <FlickeringText
                            text={segment.text}
                            start={segment.start}
                            end={segment.end}
                          />
                        )}
                      </div>
                    </Sequence>
                  );
                })}
              </Sequence>
            );
          })}</>)}





      {/* Conditional rendering for Scene Transitions */}
      {isShaking && renderSceneTransitions ? (
        <Shake
          h={0} // Horizontal shake
          v={160} // Vertical shake
          r={0} // Rotation shake
          dur={1350} // Duration of each shake in milliseconds
          int={99.9}
          max={100}
          fixed={true}
          fixedStop={false}
          freez={false}
          style={{
            width: "100%",
            height: "100%",
            opacity: currentOpacity,
            filter: `blur(${currentBlur}px)`, // Apply the dynamic blur
          }}
        >
          {/* Render Zoom Effect with Filters */}
          <ZoomEffectVideoWithFiltersPodcast
            videoLink={videoLink}
            jsonData={jsonData}
            video_width={video_width}
            zoom_on={zoom}
            filters_on={filters}
            laptop_overlay={laptop_overlay}
            render_lightleaks={render_lightleaks}
            billboard_overlay={billboard_overlay}
          />
        </Shake>
      ) : (
        <ZoomEffectVideoWithFiltersPodcast
          videoLink={videoLink}
          jsonData={jsonData}
          video_width={video_width}
          zoom_on={zoom}
          filters_on={filters}
          laptop_overlay={laptop_overlay}
          render_lightleaks={render_lightleaks}
          billboard_overlay={billboard_overlay}
        />
      )}



      {/* Render keyword sound effects */}
      {jsonData.keyword_soundSFX.segments.map((segment: any, index: any) => {
        if (renderSoundSFX && segment.soundSFX_on) {
          const startFrame = secondsToFrames(segment.soundSFX_start_time);
          // const endFrame = secondsToFrames(segment.soundSFX_end_time);     // original
          const endFrame = secondsToFrames(segment.soundSFX_end_time); // test 0.5
          const durationFrames = endFrame - startFrame;

          return (
            <Sequence
              key={`sound-effect-${index}`}
              from={startFrame}
              durationInFrames={durationFrames}
              layout="none"
            >
              <Audio
                src={segment.soundSFX_uri}
                volume={(f) => {
                  const fadeInEnd = fadeDurationFrames;
                  const fadeOutStart = durationFrames - fadeDurationFrames;
                  return interpolate(
                    f,
                    [0, fadeInEnd, fadeOutStart, durationFrames],
                    // [0, 0.03, 0.03, 0], // original
                    [0, 0.04, 0.04, 0], // test
                    { extrapolateLeft: "clamp", extrapolateRight: "clamp" }
                  );
                }}
              />
            </Sequence>
          );
        }
        return null;
      })}

      {/* Background music logic */}
      {jsonData.segments.length > 0 &&
        renderBgMusic &&
        jsonData.segments[0].background_music_on && (
          <Audio
            src={jsonData.segments[0].background_music_uri}
            volume={(f) =>
              interpolate(
                f,
                [0, fadeInEnd, fadeOutStart, durationInFrames],
                // [0, 0.04, 0.04, 0],
                [0, 0.045, 0.045, 0],
                // [0, 0.05, 0.05, 0],
                // [0, 0.09, 0.09, 0], // original
                { extrapolateLeft: "clamp", extrapolateRight: "clamp" }
              )
            }
            loop
          />
        )}

      {/* Iterate over Segments  .... Iterating over segments */}
      {jsonData.segments.map((segment: any, index: any) => {
        const elements = [];

        // B-roll handling
        // Handle split-screen B-Roll
        if (renderBroll && segment.brolls_on) {
          if (segment.brolls_on && segment.broll_type === "split_broll") {
            const brollStartFrame = secondsToFrames(segment.brolls_start_time);
            const brollDuration = secondsToFrames(
              segment.brolls_end_time - segment.brolls_start_time
            );

            elements.push(
              <Sequence
                key={`split-broll-${index}`}
                from={brollStartFrame}
                durationInFrames={brollDuration}
                layout="none"
              >
                <AbsoluteFill
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* B-roll on bottom half with fade effect */}
                  <div
                    style={{
                      height: height / 2,
                      width: "100%",
                      position: "relative", // Container for the video
                    }}
                  >
                    <OffthreadVideo
                      src={segment.brolls_uri}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        maskImage:
                          "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 15%)", // CSS mask for fade effect
                        WebkitMaskImage:
                          "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 15%)", // For WebKit browsers
                      }}
                      volume={0}
                      startFrom={0} // This should start the video from the beginning
                    />
                  </div>
                </AbsoluteFill>
              </Sequence>
            );
          }

          // Add B-Roll if applicable
          if (segment.brolls_on && segment.broll_type === "original_broll") {
            const brollStartFrame = secondsToFrames(segment.brolls_start_time);
            const brollDuration = secondsToFrames(
              segment.brolls_end_time - segment.brolls_start_time
            );

            elements.push(
              <Sequence
                key={`broll-${index}`}
                from={brollStartFrame}
                durationInFrames={brollDuration}
                layout="none"
              >
                {isShaking && renderSceneTransitions ? (
                  <Shake
                    h={0} // Horizontal shake
                    v={160} // Vertical shake
                    r={0} // Rotation shake
                    dur={1350} // Duration of each shake in milliseconds
                    int={99.9}
                    max={100}
                    fixed={true}
                    fixedStop={false}
                    freez={false}
                    style={{
                      width: "100%",
                      height: "100%",
                      opacity: currentOpacity,
                      filter: `blur(${currentBlur}px)`, // Apply the dynamic blur
                    }}
                  >
                    {/* MAIN VIDEO */}
                    <OffthreadVideo
                      src={segment.brolls_uri}
                      style={{
                        width,
                        height,
                        objectFit: "cover", // Ensures the video covers the area without losing aspect ratio
                        position: "absolute",
                      }}
                      volume={0} // B-Roll clips are muted
                    />
                  </Shake>
                ) : (
                  <OffthreadVideo
                    src={segment.brolls_uri}
                    style={{
                      width,
                      height,
                      objectFit: "cover", // Ensures the video covers the area without losing aspect ratio
                      position: "absolute",
                    }}
                    volume={0} // B-Roll clips are muted
                  />
                )}
              </Sequence>
            );
          }

          // Handle B-Roll Transition 1 Sound Effects
          if (
            segment.broll_transition1SFX &&
            segment.broll_transition1_sfx_uri
          ) {
            const startFrame = secondsToFrames(
              segment.broll_trans1SFX_start_time
            );
            const durationFrames = secondsToFrames(0.5); // Hardcoded duration of 0.5 seconds

            const brollvolume1 = normalizeVolume(12.5); // test

            elements.push(
              <Sequence
                key={`broll-transition1-sfx-${index}`}
                from={startFrame}
                durationInFrames={durationFrames}
                layout="none"
              >
                {/* <Audio src={segment.broll_transition1_sfx_uri} volume={1.5} /> */}
                <Audio src={segment.broll_transition1_sfx_uri} volume={brollvolume1} />
              </Sequence>
            );
          }

          // Handle B-Roll Transition 2 Sound Effects
          if (
            segment.broll_transition2SFX &&
            segment.broll_transition2_sfx_uri
          ) {
            const startFrame = secondsToFrames(
              segment.broll_trans2SFX_start_time
            );
            const durationFrames = secondsToFrames(0.5); // Hardcoded duration of 0.5 seconds

            const brollvolume = normalizeVolume(12.5); // test

            elements.push(
              <Sequence
                key={`broll-transition2-sfx-${index}`}
                from={startFrame}
                durationInFrames={durationFrames}
                layout="none"
              >
                {/* <Audio src={segment.broll_transition2_sfx_uri} volume={0.15} /> */}
                <Audio
                  src={segment.broll_transition2_sfx_uri}
                  volume={brollvolume}
                />
              </Sequence>
            );
          }
        }
        // Overlay handling
        if (renderOverlay && segment.overlays_on) {
          const overlayStartFrame = secondsToFrames(
            segment.overlays_start_time
          );
          const overlayEndFrame = secondsToFrames(segment.overlays_end_time);
          const overlayDuration = overlayEndFrame - overlayStartFrame;
          // const isImageOverlay =
          //   segment.overlays_uri.endsWith(".png") ||
          //   segment.overlays_uri.endsWith(".jpg");
          const extension = segment.overlays_uri
            ? new URL(segment.overlays_uri).pathname
              .split(".")
              .pop()
              ?.toLowerCase()
            : "";
          const isImageOverlay = extension === "png";

          elements.push(
            <Sequence
              key={`overlay-${index}`}
              from={overlayStartFrame}
              durationInFrames={overlayDuration + 0.0001}
              layout="none"
            >
              {isImageOverlay ? (
                <Img
                  src={segment.overlays_uri}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    opacity: 0.3,
                  }}
                />
              ) : (

                <LoopedVideoOverlay src={segment.overlays_uri} />
              )}
            </Sequence>
          );
        }

        return elements;
      })}

      {/* Handle Scene Transition Sound Effects */}
      {jsonData.scene_change_person_coordinates.map(
        (scene: any, index: any) => {
          if (
            renderSceneTransitions &&
            scene.scene_transition_on &&
            scene.scene_transition_sfx_uri
          ) {
            const startFrame = secondsToFrames(scene.start_time);
            const durationFrames = secondsToFrames(0.5); // Hardcoded duration of 0.5 seconds

            // const volume = normalizeVolume(4.5); // work perfect
            const volume = normalizeVolume(12.5); // work perfect

            return (
              <Sequence
                key={`scene-transition-sfx-${index}`}
                from={startFrame}
                durationInFrames={durationFrames}
                layout="none"
              >
                {/* <Audio src={scene.scene_transition_sfx_uri} volume={0.15} /> */}
                <Audio src={scene.scene_transition_sfx_uri} volume={volume} />
              </Sequence>
            );
          }
          return null;
        }
      )}

      {/* Captions and Emojis Overlay */}
      <CaptionsOverlay
        jsonData={jsonData}
        jsonCaptions={jsonCaptions}
        template={template}
        emojis_on={emojis_on}
        config={config}
      />

      {/* Render light leak effects */}
      {renderSceneTransitions &&
        lightLeaksSceneChange.map((leak: any, index: any) => (
          <LightLeakEffect
            key={index}
            src={leak.src}
            start={leak.start}
            duration={leak.duration}
          />
        ))}

      {renderSceneTransitions &&
        lightLeaks.map((leak: any, index: any) => (
          <LightLeakEffect
            key={index}
            src={leak.src}
            start={leak.start}
            duration={leak.duration}
          />
        ))}

      {/* Watermark */}

      {logo_on === "1" || logo_on === 1 || logo_on === "true" ? (
        <Img
          src={watermarkURL}
          style={{
            position: "absolute",
            height: "8%", // Logo size relative to video width
            top: "80px",
            zIndex: 1000,
            right: "0px",
            objectFit: "contain",
            opacity: 0.75, // Adjust opacity to make it less intrusive
            filter: "drop-shadow(0.10rem 0.15rem 0.5rem rgba(1, 1, 1, 0.85))",
          }}
        />
      ) : null}

    </AbsoluteFill>
  );
};

export default CompositionAIShorts;