import React from "react";
import { AbsoluteFill, OffthreadVideo, Img, useVideoConfig } from "remotion";

export const BillBoardOverlayTemplate = ({
  backgroundImageLink,
  overlayVideoLink,
}: {
  backgroundImageLink: string;
  overlayVideoLink: string;
}) => {
  const { width, height } = useVideoConfig();

  // Coordinates for the "billboard" area
  const topLeft = { x: 390, y: 609 };
  const topRight = { x: 662, y: 632 };
  const bottomLeft = { x: 367, y: 1073 };
  const bottomRight = { x: 666, y: 1088 };

  // Compute the CSS clip-path polygon
  const computeClipPath = (tl:any, tr:any, bl:any, br:any) => {
    const w = width;
    const h = height;

    const toPercent = (x:any, y:any) => `${(x / w) * 100}% ${(y / h) * 100}%`;

    return `polygon(
        ${toPercent(tl.x, tl.y)},
        ${toPercent(tr.x, tr.y)},
        ${toPercent(br.x, br.y)},
        ${toPercent(bl.x, bl.y)}
    )`;
};

const clipPath = computeClipPath(topLeft, topRight, bottomLeft, bottomRight);


  // Calculate the overlay dimensions
  const overlayWidth = Math.abs(topRight.x - topLeft.x);
  const overlayHeight = Math.abs(bottomLeft.y - topLeft.y);

  return (
    <AbsoluteFill>
      <Img
        src={backgroundImageLink}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 400,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: `100%`,
          height: `100%`,
          clipPath: clipPath,
          WebkitClipPath: clipPath,
          overflow: "hidden",
          zIndex: 401,
        }}
      >
        <OffthreadVideo
          src={overlayVideoLink}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "fill",
          }}
          volume={0}
          startFrom={0} // Ensuring the video starts from the beginning
        />
      </div>
    </AbsoluteFill>
  );
};

export default BillBoardOverlayTemplate;
