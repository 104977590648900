import React, { useState, useEffect, Fragment } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Container } from "@mui/material";


import CheckoutForm from '../../components/checkoutform';
import Header from '../Header';
import { useParams } from 'react-router-dom';

// const stripePromise = loadStripe('pk_test_51NeN68CEDzUkS6E03tDJnTh9MLrLplOYy577QX8rq1lOPOnTZRTk8JmNz2jRu510HtpO2UYuOHVEgEPGVEQLXkdW00mr3P4l2a');
const stripePromise = loadStripe('pk_live_51NeN68CEDzUkS6E0zEtL9zIL71u82JrfOl7k15KCmABifSSsW2h4AxjY0LuF7BrGRxHz9fYKCRm9ySkhO2ojDIlA00ChexM7CH');

function Checkout() {
    const { subscriptionId } = useParams()

    return (
        <Fragment>
            <Header></Header>
            <Container maxWidth="lg">
                <Elements stripe={stripePromise}>
                    <CheckoutForm subscriptionId={subscriptionId as string} />
                </Elements>
            </Container>
        </Fragment>
    );
}

export default Checkout;