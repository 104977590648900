import './style.css'
import React, { useMemo, useState, useEffect } from "react";
// import { makeStyles } from '@emotion/styled';
import { Link, useParams, useLocation } from 'react-router-dom';

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import { Checkbox } from "@mui/material";
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
import packageData from '../../utils/packageData';
import AlertModal from '../../components/AlertModal';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';
import PaymentCheckout from '../PaymentCheckout';
import { BASE_URL } from '../../utils/url';
import toast from 'react-hot-toast';
import { getProfile } from '../../redux/slices/profile.slice';


const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


export default function Pricing(props: any) {
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state: RootState) => state.auth.user)

  const query = useQuery();
  const success = query.get('success');
  const canceled = query.get('canceled');

  const PKG = useMemo(() => {
    if (user?.subscriptionPlan == null) return null
    return packageData.map(data => {
      return data.cycle.find(cy => cy.subscriptionId == user?.subscriptionPlan.id);
    }).filter(Boolean)[0] || null // filters out undefined or null values
  }, [user?.subscriptionPlan]);

  const content = {
    option1: "Monthly",
    option2: "Annual",
    ...props.content,
  };

  const [state, setState] = React.useState({
    checkbox: true,
  });
  const [paymentModal, setPaymentModal] = useState({ open: false, subscriptionId: null })

  const handleChange = (event: any) => {
    setState({ ...state, checkbox: event.target.checked });
  };

  const cardStyles = {
    borderRadius: "20px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    border: "transparent",
    display: "flex",
    // paddingLeft: "35px",
  };

  const checkAndNavigate = (pkg: any) => {
    if (user && user.id) {
      // setPaymentModal({ open: true, subscriptionId: pkg?.subscriptionId })
      navigation(`/checkout/${pkg?.subscriptionId}`)
    }
    else {
      localStorage.setItem('pkg', JSON.stringify(pkg?.subscriptionId))
      navigation('/signup')
    }
  }

  useEffect(() => {

    if (success == 'true') {
      dispatch(getProfile(user?.id as string))
      toast("Payment Successfully")
    }
    if (canceled == 'true') toast("Payment Cancelled")

  }, [success, canceled])



  return (
    <section className="section">
      <Header></Header>
      <Container maxWidth="lg">
        <Grid item sx={{ marginTop: 2 }} xs={12}>
          <Link to="/"><KeyboardBackspaceIcon htmlColor='rgba(0, 0, 0, 0.54)' /></Link>
        </Grid>
        <Box py={2} textAlign="center">
          <Box mb={3}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h3" className='header-payment' component="h2" gutterBottom={true}>
                {/* <Typography variant="h3" component="span"> */}
                All the Features Across{" "}
                {/* </Typography> */}
                {/* <Typography variant="h3" component="span" color="primary"> */}
                All Plans
                {/* </Typography> */}
              </Typography>
              <Box>
                <Typography variant="subtitle1" component="span">
                  {content["option1"]}
                </Typography>
                &nbsp;{" "}
                <Switch
                  name="checkbox"
                  color="primary"
                  checked={state.checkbox}
                  onChange={handleChange}
                />{" "}
                &nbsp;
                <Typography variant="subtitle1" component="span">
                  {content["option2"]}
                </Typography>
                <span className='discount-per'> Save -25%</span>
              </Box>
            </div>
          </Box>
          <Card variant="outlined" className="box-shadoww" sx={cardStyles}>
            <Grid container>
              {/* <Grid className='package-caption' md={4} lg={4} sx={{ marginTop: 5 }} >
                <Typography>
                  Transcription & Captions
                </Typography>
                <ul>
                  <li>Transcribe & Translate in Almost Global Languages</li>
                  <li>Effortless Subtitles Generation</li>
                  <li>One Click Video Captioning</li>
                  <li>Trendy Captions for TikTok & Shorts</li>
                  <li>Easy Captions Editor with ~1600 Fonts & 16.7M Colors</li>
                  <li>Real-Time Caption Editing Over Video</li>
                  <li>Instant Edits Without Rendering</li>
                  <li>Word-to-Word Highlighting</li>
                  <li>Compatibility with HDR & H.265 UltraHD Videos</li>
                  <li>Export Directly to YouTube, TikTok, Insta & Vimeo</li>
                </ul>
                <Typography>
                  Transcription & Captions
                </Typography>
                <ul>
                  <li>Instant Self Voice Cloning</li>
                  <li>10 Voice Library</li>
                  <li>High Fidelity Audio Output</li>
                </ul>
                <Typography>
                  Robust Performance & Seamless Experience
                </Typography>
                <ul>
                  <li>Secure Cloud Storage</li>
                  <li>Background processing even after Browser is closed</li>
                  <li>One Click Simplicity; Optimized for Mobile & Desktop</li>
                </ul>
              </Grid> */}
              <Grid md={12} lg={12}>
                <Grid container md={12} lg={12} rowSpacing={1} display={'flex'} alignItems={'center'} sx={{ display: "flex", flexWrap: "wrap", }}>
                  {packageData?.map(({ name, description, cycle, feature, color,style }: any) => {
                    let type = state.checkbox ? "yearly" : "monthly"
                    let pkg = cycle?.find((data: any) => data.type == type)
                    let paid = -1
                    if (PKG) {
                      paid = cycle.findIndex(({ subscriptionId }: any) => subscriptionId == PKG.subscriptionId)
                    }

                    return (
                      <Grid item xs={12} sm={8} md={2.4} lg={2.4}>
                        <CardContent sx={{ width: '100%', textAlign: 'start', backgroundColor:style.bg}}>
                          <Box px={1}>
                            <div className='display-flex' style={{ justifyContent: 'space-between' }}>
                              <Typography className="font-DmSans" sx={{ fontWeight: '700' }} variant="h6" component="h6" gutterBottom={true}>
                                ${pkg?.price}
                                <Typography
                                  color="textSecondary"
                                  component="span"
                                >
                                  / {name == "LIFETIME ACCESS"? "lifetime": !state.checkbox ? "month" : "month"}
                                </Typography>
                              </Typography>
                              {state.checkbox && <div>
                                <Typography className='discount-typo'>${pkg.without_discount || 0}</Typography>
                              </div>
                              }
                            </div>
                            <Typography className='name-pkg-typo'  fontSize={14}  sx={{ fontStyle: "italic", textAlign: 'start', color: color }}>
                              {/* <span style={{ fontStyle: "normal", color: color, fontWeight: '700' }}>
                            Free {" "}
                          </span> */}
                              {name}
                            </Typography>
                            <Typography className="font-DmSans desc-pkg-typo" component="p" color="#848199" fontSize={10} sx={{ textAlign: 'start', height: 110 }}>
                              {description}
                            </Typography>
                            {feature.map((fea: any) =>
                              <Box mt={0.5} sx={{ display: "flex", justifyContent: 'flex-start', alignItems: 'center' }}>
                                <CheckCircleIcon sx={{ width: "15px", marginRight: 0.7 }} />
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle1"
                                  component="p"
                                  fontSize={12}
                                  className="font-DmSans"
                                >
                                  <span style={{ color: color }}>{fea.value}</span> {fea.name}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                          <Box mt={2} >
                            {name !== "Free" && user?.subscriptionStatus === "succeeded" ?
                              <form action={`${BASE_URL}/pay/create-portal-session`} method="POST">
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <input
                                    type="hidden"
                                    id="customer"
                                    name="customer"
                                    value={user?.stripeCustomerId}
                                  />
                                  <Button
                                    variant="outlined"
                                    className="font-DmSans primaryAction"
                                    id="checkout-and-portal-button" type="submit"
                                    size="large"
                                    sx={{
                                      borderRadius: '20px', width: '70%', height: "40px", borderColor: color, color: color, fontSize: 12,
                                    }}
                                  >
                                    {paid !== -1 ? "Subscribed" : "Choose plan"}
                                  </Button>
                                </div>
                              </form>
                              : name !== "Free" ?
                                  <form action={`${BASE_URL}/pay/create-checkout-session?id=${user?.id}`} method="POST">
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>

                                    <input type="hidden" name="lookup_key" value={pkg.subscriptionId} />
                                    <Button
                                      variant="outlined"
                                      className="font-DmSans primaryAction"
                                      id="checkout-and-portal-button" type="submit"
                                      size="large"
                                      sx={{
                                        borderRadius: '20px', width: '70%', height: "40px", borderColor: color, color: color, fontSize: 12,
                                      }}
                                    >
                                      {paid !== -1 ? "Subscribed" : "Choose plan"}
                                    </Button>
                                </div>
                                  </form>
                                : <div style={{ height: "40px" }}></div>}
                          </Box>
                        </CardContent>
                      </Grid>

                    )
                  }
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
        {/* <PaymentCheckout open={paymentModal.open} subscriptionId={paymentModal.subscriptionId} onClose={() => setPaymentModal({ open: false, subscriptionId: null })}></PaymentCheckout> */}
        <AlertModal open={false} />
      </Container>
    </section >
  );
}
