import "./style.css"
import { Autocomplete, TextField } from '@mui/material'
import styled from '@emotion/styled';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    width: '100%',
    height: '33px',
    '& .MuiInputBase-root': {
        height: '33px',

    },
    '& .MuiInputBase-input': {
        color: '#000',    // Set text color
        borderColor: '#ced4da', // Border color
        borderWidth: '2px', // Border width
        fontFamily: "Poppins",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 400,
        height: '15px',
        marginTop: -7
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#ced4da', // Border color
    },
    '& .MuiAutocomplete-clearIndicator': {
        display: "none",
    },
    '& .MuiOutlinedInput-root': {
        paddingRight: "none"
    },
    '& .MuiAutocomplete-inputRoot': {
        paddingRight: "none"
    },
    '& .MuiAutocomplete-hasPopupIcon': {
        paddingRight: "none"
    }
}));

interface IAutoComplete {
    disabled?: boolean,
    selected: string | undefined,
    handleChange: any,
    options: any,
    className?: string
}

const AutoComplete = ({ disabled, selected, handleChange, options, className }: IAutoComplete) => {
    return (
        <StyledAutocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-demo"
            defaultValue={selected}
            onInputChange={(event: any, value) => {
                handleChange(event, value);
            }}
            className={className}
            value={selected || null}
            options={options}
            renderInput={(params: any) => (
                <TextField
                    sx={{ width: "100%", fontFamily: selected || "The Bold Font" }}
                    {...params}
                />
            )}
            renderOption={(props, option: any) => {
                return (
                    <li {...props} style={{ fontFamily: option?.value || "Airal" }}>
                        {option.label}
                    </li>
                )
            }}
        />)
}

export default AutoComplete;