import React, { useState, useEffect } from 'react';
import './style.css'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme, useMediaQuery, Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputWithIcon from '../../components/InputBox';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { Hidden } from '@mui/material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import PrivacyTipRoundedIcon from '@mui/icons-material/PrivacyTipRounded';
import { useAppDispatch } from '../../hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { logEvent } from '../../utils/ga.utils';
import useAuth from '../../hooks/useAuth';


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function ResetPassword() {
    const dispatch = useAppDispatch()
    const theme = useTheme();
    const { recoverPasswordAuth, resetPasswordAuth } = useAuth()
    const [searchParams, setSearchParams] = useSearchParams();

    const isLoading = useSelector((state: RootState) => state.auth.isLoading);
    const resetPassword = useSelector((state: RootState) => state.auth.resetPassword)

    const [step, setStep] = useState(0)
    const [otp, setOtp] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [errors, setErrors] = useState({
        email: false,
    })
    const [errors3, setErrors3] = useState({
        password: false,
        rePassword: false
    })


    useEffect(() => {
        let token = searchParams.get("token")
        if (token) {
            setStep(2)
            return
        }
        else if (resetPassword == "pending") {
            setStep(1)
            return
        }
        else if (resetPassword == "success") {
            setStep(3)
            return
        }

    }, [searchParams, resetPassword])

    useEffect(() => {
        if (resetPassword == "success") {
            setStep(3)
        }
    }, [resetPassword])




    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!email || !isValidEmail(email)) {
            // alert('Please enter a valid email.');
            setErrors({ ...errors, email: true })
        }

        if (email) {
            logEvent({
                category: 'User',
                action: 'Recover Password',
            });
            setErrors({ email: false })
            recoverPasswordAuth({ email })
        }
    };


    const onResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!password || password.length < 8) {
            // alert('Password must be at least 6 characters long.');
            setErrors3({ ...errors3, password: true })
        }
        if (password !== rePassword) {
            setErrors3({ ...errors3, rePassword: true })
        }
        if (password && password == rePassword && searchParams.get("token")) {
            logEvent({
                category: 'User',
                action: 'Reset Password',
            });
            setErrors3({ password: false, rePassword: false })
            resetPasswordAuth({ password, token: searchParams.get("token"), email: searchParams.get("email") })
        }
    };


    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };

    function hideEmail(email: string) {
        const domain = email.substring(email.lastIndexOf("@"));
        const firstPart = email.substring(0, 4);
        return `${firstPart}*******${domain}`;
    }

    const getErrorMessage = () => {
        if (errors.email) return "Please enter a valid email.";
        return "";
    };

    const getErrorMessage3 = () => {
        if (errors3.password) return "Password must be at least 8 characters long.";
        if (errors3.rePassword) return "Passwords do not match."; // Add your message
        return "";
    };

    const errorMessage = getErrorMessage();

    const errorMessage3 = getErrorMessage3();

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Hidden mdDown>
                    <Grid
                        item
                        md={6}
                        className='background-leftside'
                    >
                        <Typography className='header-text-rp'>
                            Empowering Creators Worldwide
                        </Typography>
                    </Grid>
                </Hidden>
                <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={12} md={6} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: [4, 6, 8],
                            mx: [2, 3, 4],
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: 'inherit',
                            width: "50%",
                            '@media (max-width: 600px)': {
                                width: '80%',  // Set width to 80% on mobile
                            }
                        }}
                    >
                        <img src='/logo.png' style={{ maxWidth: '100%', height: 'auto', marginBottom: 40 }} />
                        {step == 0 ? <>
                            <Typography
                                className='dont-account-typo'
                                sx={{ fontSize: ["12px", "14px", "16px"] }}
                                style={{ marginBottom: 20 }}
                            >
                                Enter your user email to reset the password
                            </Typography>

                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>

                                <InputWithIcon name="email" error={errors.email} onFocus={() => setErrors({ ...errors, email: false })} onChange={(e: any) => setEmail(e.target.value)} icon={<MailRoundedIcon />} placeholder='Email Address' />

                                {errorMessage && <Alert sx={{ marginTop: 3 }} severity="error">{errorMessage}</Alert>}


                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={isLoading}
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        height: "50px",
                                        borderRadius: "8px",
                                        backgroundColor: '#0575E6',
                                        color: "#FFF",
                                        fontFamily: "Poppins",
                                        fontSize: ["10px", "12px", "14px"],
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                    }}
                                >
                                    Reset Password
                                </Button>
                            </Box>
                        </>
                            : step == 1 ? <>
                                <div className="round-icon">
                                    <PrivacyTipRoundedIcon sx={{ color: '#4AAF4F', fontSize: 40 }}></PrivacyTipRoundedIcon>
                                </div>
                                <Typography sx={{ marginTop: 10 }} className='otp-msg'>You have received a passwrod reset email</Typography>
                                <Typography sx={{ marginTop: 10, color: 'red' }} className='otp-msg'>Link will be expred in 30 minutes</Typography>

                            </>
                                : step == 2 ? <>
                                    <Typography sx={{ marginTop: 10 }} className='otp-msg'>Reset Password for</Typography>
                                    <Typography className="otp-email">{hideEmail(searchParams.get("email") || "")}</Typography>
                                    <Box component="form" noValidate onSubmit={onResetPassword} sx={{ mt: 1 }}>
                                        <InputWithIcon name="password" error={errors3.password} onFocus={() => setErrors3({ ...errors3, password: false })} secure onChange={(e: any) => setPassword(e.target.value)} icon={<LockRoundedIcon />} placeholder='Password' />
                                        <InputWithIcon name="password" error={errors3.rePassword} onFocus={() => setErrors3({ ...errors3, rePassword: false })} secure onChange={(e: any) => setRePassword(e.target.value)} icon={<LockRoundedIcon />} placeholder='Re-type Password' />
                                        {errorMessage3 && <Alert sx={{ marginTop: 3 }} severity="error">{errorMessage3}</Alert>}

                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            disabled={isLoading}
                                            sx={{
                                                mt: 3,
                                                mb: 2,
                                                height: "50px",
                                                borderRadius: "8px",
                                                backgroundColor: '#0575E6',
                                                color: "#FFF",
                                                fontFamily: "Poppins",
                                                fontSize: ["10px", "12px", "14px"],
                                                fontStyle: "normal",
                                                fontWeight: 600,
                                                lineHeight: "normal",
                                            }}
                                        >
                                            Reset Password
                                        </Button>
                                    </Box>
                                </> :
                                    step == 3 ? <>
                                        <div className="round-icon">
                                            <DoneRoundedIcon sx={{ color: '#4AAF4F', fontSize: 40 }}></DoneRoundedIcon>
                                        </div>
                                        <Typography sx={{ marginTop: 10 }} className='otp-msg'>Your new password has set up</Typography>
                                        <Typography sx={{ marginTop: 10 }} className='otp-msg'>Let's roll in your big project.</Typography>
                                        <Button
                                            href="/signin"
                                            variant="outlined"
                                            sx={{
                                                marginTop: 5,
                                                backgroundColor: 'white',
                                                borderColor: 'white',
                                                color: '#0575E6',
                                                '&:hover': {
                                                    borderColor: '#0575E6' // Light blue on hover
                                                }
                                            }}
                                            endIcon={<ArrowForwardOutlinedIcon />}
                                        >
                                            Sign in
                                        </Button>
                                    </> : <></>}
                    </Box>

                </Grid>
            </Grid>
        </ThemeProvider >
    )
}