import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useEffect, useState } from "react";
import { useAppDispatch } from "../hooks/redux";
import { me } from "../redux/slices/auth.slice";

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }: ProtectedRouteProps) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const [state, setState] = useState(false)

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        if (!token) setState(true)
        if (token) {
            localStorage.setItem('token', token)

            setTimeout(() => {
                dispatch(me())
                navigate("/")
            }, 2000);
        }
    }, [])


    if (!isAuthenticated && state) {
        return <Navigate to="/signin" replace />
    }

    return <>{children}</>;
}

export default ProtectedRoute 