import React from 'react';
import { Typography, styled } from '@mui/material';

interface GradientTypographyProps {
  text: string;
  gradient: string;
}

// Create a styled component that applies the gradient text styles
const GradientText = styled(Typography)<{ gradient: string }>(({ theme,gradient }) => ({
  fontFamily: 'Roboto',
  fontSize: '28px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '34px', // Adjusted for better appearance
  marginTop: '0px',
  background: gradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  display: 'inline', // Required for background clip to work
  [theme.breakpoints.down('sm')]: { // Media query for small screens
    fontSize: '18px', // Reduced font size for mobile devices
    lineHeight: '24px', // Adjust line height accordingly
  },
}));

const GradientTypography: React.FC<GradientTypographyProps> = ({ text, gradient }) => {
  return <GradientText gradient={gradient}>{text}</GradientText>;
};

export default GradientTypography;
