import { Cancel } from "@mui/icons-material";
import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";

interface TagsProps {
    data: string;
    handleDelete: (data: string) => void;
}

const Tags: React.FC<TagsProps> = ({ data, handleDelete }) => {
    return (
        <Box
            sx={{
                background: "#4E4E4E",
                height: "100%",
                display: "flex",
                padding: "2px 5px",
                margin: "0 0.2rem 0 0",
                justifyContent: "center",
                alignContent: "center",
                borderRadius: "5px",
                color: "#ffffff",
            }}
        >
            <Stack direction='row' gap={1}>
                <Typography sx={{ fontSize: 10, fontFamily: "Poppins" }}>{data}</Typography>
                <Cancel
                    sx={{ cursor: "pointer", fontSize: 15, alignSelf: 'center' }}
                    onClick={() => {
                        handleDelete(data);
                    }}
                />
            </Stack>
        </Box>
    );
};

const InputTags: React.FC<{ tags: string[], setTags: any, error?: boolean }> = ({ tags, setTags, error }) => {
    const tagRef = useRef<HTMLInputElement>(null);

    const handleDelete = (value: string) => {
        const newTags = tags.filter((val) => val !== value);
        setTags(newTags);
    };

    const handleOnChange = () => {
        if (tagRef.current && tagRef.current.value.includes(',')) {
            const newTag = tagRef.current.value.replace(',', '').trim();
            if (newTag) {
                setTags([...tags, newTag]);
            }
            tagRef.current.value = "";
        }
    };

    const handleOnSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (tagRef.current && tagRef.current.value) {
            setTags([...tags, tagRef.current.value.trim()]);
            tagRef.current.value = "";
        }
    };

    const handleOnBlur = () => {
        if (tagRef.current && tagRef.current.value.trim()) {
            setTags([...tags, tagRef.current.value.trim()]);
            tagRef.current.value = "";
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <form onSubmit={handleOnSubmit}>
                <TextField
                    inputRef={tagRef}
                    fullWidth
                    variant='standard'
                    size='small'
                    sx={{
                        backgroundColor: '#FCFCFC',
                        borderColor: error ? "red" : '#FCFCFC'
                    }}
                    margin='none'
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    InputProps={{
                        startAdornment: (
                            <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }}>
                                {tags.map((data, index) => (
                                    <Tags data={data} handleDelete={handleDelete} key={index} />
                                ))}
                            </Box>
                        ),
                    }}
                />
            </form>
        </Box>
    );
}

export default InputTags;
