import { useEffect } from 'react';
import "./style.css"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { rewardList } from '../../redux/slices/profile.slice';
import { RootState } from '../../redux/store';
import { checkSubsPlan, formatDate } from '../../utils/functions';
import { Avatar, Typography } from '@mui/material';



export default function RewardTable() {
    const dispatch = useAppDispatch()

    const user = useAppSelector((state: RootState) => state.auth?.user)
    const rewardUserList = useAppSelector((state: RootState) => state.profile.rewardUserList)

    useEffect(() => {
        dispatch(rewardList({ referral: user?.referral }))
    }, [user])

    return (
        <TableContainer sx={{ marginTop: 3 }} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ backgroundColor: '#F4F4F5', borderRadius: "5px" }}>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Plan Name</TableCell>
                        <TableCell>Rewards</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rewardUserList && rewardUserList?.map((row) => {
                        let plan = checkSubsPlan(row)
                        return (<TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
                            className="tableBody"
                        >
                            <TableCell sx={{ display: 'flex' }} component="th">
                                <Avatar alt={row?.fullName} src={row?.pictureUrl || "/avatar.png"} />
                                <div style={{ marginLeft: '10px' }}>
                                    <Typography className="cell-typo">{row.fullName || "-"}</Typography>
                                    <Typography className="cell-typo color-light">{row.email}</Typography>
                                </div>
                            </TableCell>
                            <TableCell>{formatDate(row.createdAt)}</TableCell>
                            <TableCell>{plan ? plan?.name : "-"}</TableCell>
                            <TableCell>{row.reward}</TableCell>
                        </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}