import React from 'react';
import ReplyIcon from '@mui/icons-material/Reply';
import { styled } from '@mui/system';

const StyledReplyIcon = styled(ReplyIcon)({
    color: "gray",
    fontSize: 40,
    transition: "color 0.3s",
    '&:hover': {
        color: "#008AFF",
    },
});

const BackIcon = () => {
    return <StyledReplyIcon />;
};

export default BackIcon;