// timeline.tsx
import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';

import { Stage, Layer, Rect, Text, Line, Group, RegularPolygon } from 'react-konva';
// import VideoPlayer from './VideoPlayer';
// import AudioPlayer from './AudioPlayer';
// import InteractiveLayer from './InteractiveLayer';
// import VideoOverlay from './VideoOverlay';
import TimelineShapeLayer from './TimelineShapeLayer';
import VoiceOverLayer from './VoiceoverLayer';
// import ShapeLayer from './ShapeLayers';
import './SliderStyles.css';
import * as htmlToImage from 'html-to-image';
import { VideoContext } from '../../context/video.context';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { updateAICaptionSubtitle } from '../../redux/slices/video.slice';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles'

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { useMediaQuery } from '@mui/material';
import { useCurrentPlayerFrame } from '../RemotionPlayer/useCurrentPlayerFrame';
import toast from 'react-hot-toast';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const Timeline = ({ captions, setCaptions, videoDuration, disabled = false }) => {

    const dispatch = useDispatch()
    const contextValue = useContext(VideoContext);

    const theme = useTheme()
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    if (!contextValue) {
        // handle the case where the context value is not provided
        throw new Error("VideoContext value is not provided");
    }

    const { shapes, setShapes, videoFile, musicTracks, setMusicTracks, markerXPosition, voiceOverTracks, setMarkerXPosition, adjustScaleLength, setVoiceOverTracks, currentTime, mediaPlayerRef, scaleLength, setScaleLength, selectedMusicBlockId, setSelectedMusicBlockId, subtitleJSON, setSubtitleJSON, subtitleEditable, setSubtitleEditable, subtitleEmoji, setSubtitleEmoji } = contextValue;
    // const markerXPosition = useCurrentPlayerFrame(mediaPlayerRef)

    const [scale, setScale] = useState(90); // Initial scale is 20 pixels per second

    const rulerHeight = 12; // Height of the ruler area
    const padding = 10; // // Padding value to be applied consistently to all layers
    const containerWidthWithPadding = scaleLength * scale + padding * 2; // Adjust the containerWidth to include the padding on both ends
    const containerHeight = 225; // height of the marker  => 185 was default
    const mainContainerRef = useRef(null); // for de-selection of caption block
    const [blockJustClicked, setBlockJustClicked] = useState(false); // // for de-selection of caption block
    // In your component where you manage the state of the marker's x position:
    const initialMarkerPositionOnScale = 0;
    const [selectedBlockId, setSelectedBlockId] = useState(null); // Initial selected block ID

    const captionLayerHeight = 80;  // gap between caption layer and other layers... it is also the size of the canva.
    const spaceBetweenLayers = 10; // Space between caption layer and first shape layer
    const spaceBetweenCaptionsAndScale = 24; // Space between caption layer and scale lines
    const [scrollPosition, setScrollPosition] = useState(0); // Initial scroll position
    const scrollContainerRef = useRef(null); // Reference for the scrollable container

    ////////////////// video player work below/////////////////////////////
    const videoTrackHeight = 70;
    // Adjust the y position for the video layer to be below the caption layer
    const videoLayerYPosition = rulerHeight + captionLayerHeight + spaceBetweenLayers - 35;
    const [videoCurrentTime, setVideoCurrentTime] = useState(0); // State to track video current time
    const [videoSrc, setVideoSrc] = useState("");
    const [videoFileName, setVideoFileName] = useState(""); // it holds video file name
    ////////////////// video player work above/////////////////////////////

    // const musicLayerYPosition = rulerHeight + captionLayerHeight + spaceBetweenLayers + videoTrackHeight - 48; // Calculate the Y position for the Music Layer
    const musicLayerYPosition = rulerHeight + captionLayerHeight + spaceBetweenLayers + 5;
    let currentResizeHandler = null; // Global variable to hold the current music block resize handler

    // const [longestAudioDuration, setLongestAudioDuration] = useState(0); // state for tracking audio length.
    const needScaleAdjustmentRef = useRef(false);
    const musicLastEndTimeRef = useRef(0);

    const currentVoiceOverResizeHandler = null; // Global variable to hold the current voiceOver block resize handler
    const [selectedVoiceOverBlockId, setSelectedVoiceOverBlockId] = useState(null);
    const voiceOverLastEndTimeRef = useRef(0);
    const needScaleAdjustmentVoiceOverRef = useRef(false);
    const voiceOverLayerHeight = 40;
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentPlayedMusic, setCurrentPlayedMusic] = useState(null)
    const audioRef = useRef(new Audio(''));

    /////////// DOM work /////////
    const fileInputRef = useRef(null);

    // useEffect(() => {
    //     setMarkerXPosition(currentFrame);
    // }, [currentFrame])

    useEffect(() => {
        if (mediaPlayerRef?.current) {

            mediaPlayerRef.current.addEventListener("timeupdate", (e) => {
                // console.log("current frame is " + e.detail.frame, e.detail.frame / videoFile.video_FPS); // current frame is 120
                setMarkerXPosition(Math.round(e.detail.frame / videoFile.video_FPS));
            });
        }
    }, [mediaPlayerRef?.current]);



    // //////////////////////////// shape layer work below  /////////////////////////////////////
    // const [shapes, setShapes] = useState([
    //     { id: 1, start: 10, end: 15, text: 'Surprise Surprise', x: 10, y: 70, width: 100, height: 100, fill: 'white', isImg:false, shapeImg: null, imgBlob: null },
    //     { id: 2, start: 16, end: 22, text: 'Hello Kalia', x: 100, y: 70, width: 100, height: 100, fill: 'white', isImg:false, shapeImg: null, imgBlob: null },
    //     // { id: 3, start: 30, end: 40, text: 'Donkey', x: 100, y: 70, width: 100, height: 100, fill: 'red' },
    // ]);
    /////////////////////////////////// shape layer work above ///////////////////////////

    useEffect(() => {
        // handle caption block click 
        const handleWindowClick = () => {
            if (!blockJustClicked) {
                setSelectedBlockId(null);
            }
        };

        // handles deletetion of caption block
        const handleKeyDown = (event) => {
        //        if (disabled) {
        //     toast.success("This feature is coming soon.",{
            //     id: "feature",
            //   })
        //     return
        // }

            if ((event.key === 'Delete' || event.key === 'Backspace') && selectedBlockId != null) {
                setSubtitleEditable({ ...subtitleEditable, [videoFile?.video_id]: true })
                setCaptions(captions.filter(caption => caption.id !== selectedBlockId));
                let tempSubtitleJSon = JSON.parse(JSON.stringify(subtitleJSON));
                let ind = tempSubtitleJSon.segments.find(({ id }) => id == selectedBlockId);
                tempSubtitleJSon.segments.splice(ind, 1)
                setSubtitleJSON(tempSubtitleJSon)

                setSelectedBlockId(null); // Deselect after deletion
            }
        };

        // Add event listeners
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('click', handleWindowClick);

        // Cleanup event listeners
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('click', handleWindowClick);
        };
    }, [captions, selectedBlockId, blockJustClicked]);

    /////////// Music component useEffect below .....................
    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.key === 'Delete' || event.key === 'Backspace') && selectedMusicBlockId) {
                deleteMusicBlock();
            }
        };
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedMusicBlockId]);
    //////////////////////////// Music component useEffect above /////////////////////////////

    const calculateContainerHeight = () => {
        const shapeLayerHeight = 40;
        const musicLayerHeight = 20;
        const videoLayerHeight = 20;
        const voiceOverLayerHeight = 40;
        // let totalHeight = rulerHeight + captionLayerHeight + videoTrackHeight + spaceBetweenLayers + shapeLayerHeight;
        let totalHeight = rulerHeight + captionLayerHeight + spaceBetweenLayers + musicLayerHeight + videoLayerHeight + shapeLayerHeight + voiceOverLayerHeight;
        if (captions.length > 0) {
            totalHeight = captionLayerHeight
        }
        if (videoFile.duration) {
            totalHeight = rulerHeight + captionLayerHeight + spaceBetweenLayers
        }
        if (musicTracks.length > 0) {
            // Add height for the music layer
            totalHeight = rulerHeight + captionLayerHeight + spaceBetweenLayers + musicLayerHeight + videoLayerHeight;
        }
        if (shapes.length > 0) {
            // Add height for the shape layer
            totalHeight = rulerHeight + captionLayerHeight + spaceBetweenLayers + musicLayerHeight + videoLayerHeight + shapeLayerHeight;
        }
        if (voiceOverTracks.length > 0) {
            // Add height for the shape layer
            totalHeight = rulerHeight + captionLayerHeight + spaceBetweenLayers + musicLayerHeight + videoLayerHeight + shapeLayerHeight + voiceOverLayerHeight;
        }
        return totalHeight;
    };

    const createRulerLines = () => {

        const calculateStepBasedOnScale = (scale) => {
            if (scale >= 20) return 1;  // Step every second for largest zoom
            if (scale >= 10) return 2;  // Step every 3 seconds for medium zoom
            if (scale >= 9) return 3;
            if (scale >= 8) return 4;
            if (scale >= 7) return 5;
            if (scale >= 5) return 5;  // Step every 5 seconds for smaller zoom
            if (scale >= 4) return 6;
            if (scale >= 3) return 8;
            if (scale >= 2.5) return 9;
            if (scale >= 2) return 10; // Step every 10 seconds for smaller zoom
            if (scale >= 1.5) return 12;
            if (scale >= 1) return 20;
            if (scale >= 0.9) return 20;
            if (scale >= 0.8) return 21;
            if (scale >= 0.7) return 25;
            if (scale >= 0.6) return 30;
            if (scale >= 0.5) return 30;
            if (scale >= 0.45) return 40;
            if (scale >= 0.4) return 50;
            if (scale >= 0.35) return 60;
            if (scale >= 0.33) return 70;
            if (scale >= 0.3) return 80;
            if (scale >= 0.28) return 80;
            if (scale >= 0.2) return 90;
            if (scale >= 0.1) return 120;
            return 20;
        }

        const lines = [];
        const step = calculateStepBasedOnScale(scale);

        // console.log("isLongVideo  2 is: ", isLongVideo)

        for (let i = 0; i <= scaleLength; i += step) {
            const positionX = i * scale + padding;
            const isMinuteLine = i % 60 === 0; // Check if it's a minute line
            const strokeWidth = isMinuteLine ? 2 : 1; // Bolder line for minutes

            lines.push(
                <Line
                    key={i}
                    points={[positionX, 0, positionX, rulerHeight]}
                    stroke="#000"
                    strokeWidth={strokeWidth}
                />
            );
            // Adjust label for long videos
            if (videoFile.duration) {
                const minutes = Math.floor(i / 60);
                const seconds = i % 60;
                const label = seconds === 0 ? `${minutes}m` : `${seconds}s`;

                lines.push(
                    <Text
                        key={`text-${i}`}
                        x={positionX - 10}
                        y={rulerHeight + 5}
                        text={label}
                        fontSize={11}
                        fontFamily="Noto Sans"
                        fill="#6A6A6A"
                    />
                );
            } else {
                const minutes = Math.floor(i / 60);
                const seconds = i % 60;
                const label = seconds === 0 ? `${minutes}m` : `${seconds}s`;
                // Label every second for shorter videos
                lines.push(
                    <Text
                        key={`text-${i}`}
                        x={positionX - 10}
                        y={rulerHeight + 5}
                        // text={`${i}s`}
                        text={label}
                        fontSize={12}
                        fontFamily="Noto Sans"
                        fill="#6A6A6A"
                    />
                );
            }
        }
        return lines;
    };

    // const handleDragEnd = (id, x) => {

    //     // Adjust for padding while ensuring that the caption block can go to 0
    //     const newStart = Math.max(0, (x - padding) / scale);

    //     const caption = captions.find(c => c.id === id);
    //     if (!caption) {
    //         return; // Caption not found, exit early
    //     }

    //     // Keep the duration of the caption block constant
    //     const duration = caption.end - caption.start;
    //     const newEnd = newStart + duration;

    //     // Update the caption's start and end times
    //     setCaptions(captions.map(c => c.id === id ? { ...c, start: newStart, end: newEnd } : c));
    // };

    const updateCaption = () => {
           if (disabled) {
            toast.success("This feature is coming soon.",{
                id: "feature",
              })
            return
        }

        // let form = new FormData();
        // form.append("updated_transcription_data", JSON.stringify(subtitleJSON));
        // form.append("updated_emoji_data", JSON.stringify(subtitleEmoji));
        // form.append("updated_gif_data", JSON.stringify(subtitleGif));

        setSubtitleEditable({ ...subtitleEditable, [videoFile?.video_id]: false })

        dispatch(updateAICaptionSubtitle({
            form: {
                updated_transcription_data: subtitleJSON,
                updated_emoji_data: subtitleEmoji,
                // "updated_gif_data": subtitleGif
            }, videoId: videoFile?.video_id.split("-")?.[0], userId: videoFile?.user_id
        }));
    }


    const handleDragEnd = (id, x) => {
           if (disabled) {
            toast.success("This feature is coming soon.",{
                id: "feature",
              })
            return
        }

        setSubtitleEditable({ ...subtitleEditable, [videoFile?.video_id]: true })
        const newStart = Math.max(0, (x - padding) / scale);
        const caption = captions.find(c => c.id === id);
        let tempSubtitleJSon = JSON.parse(JSON.stringify(subtitleJSON));

        if (!caption) return;

        const maxEndTime = videoDuration ? Math.min(videoDuration, scaleLength) : scaleLength;
        const newEnd = Math.min(newStart + (caption.end - caption.start), maxEndTime);

        setCaptions(captions.map(c => c.id === id ? { ...c, start: newStart, end: newEnd } : c));
        let idxx = captions.findIndex(c => c.id === id)
        tempSubtitleJSon.segments[idxx].start = newStart;
        tempSubtitleJSon.segments[idxx].end = newEnd;

        setSubtitleJSON(tempSubtitleJSon)
    };

    // Function to handle text change in caption cards
    const handleCaptionChange = (id, newText) => {
        setCaptions(captions.map(caption =>
            caption.id === id ? { ...caption, text: newText } : caption
        ));
    };

    // const checkBoundaries = (pos, currentCaption, captions, scale, padding) => {
    //     const captionWidth = (currentCaption.end - currentCaption.start) * scale;
    //     let newX = pos.x - padding;

    //     const sortedCaptions = [...captions].sort((a, b) => a.start - b.start || a.id - b.id);
    //     const currentIndex = sortedCaptions.findIndex(c => c.id === currentCaption.id);
    //     const prevCaption = sortedCaptions[currentIndex - 1];
    //     const nextCaption = sortedCaptions[currentIndex + 1];

    //     // Adjust left and right boundaries
    //     const leftBoundary = prevCaption ? (prevCaption.end * scale) : 0;
    //     const rightBoundary = nextCaption ? (nextCaption.start * scale) : (scale * scaleLength);

    //     if (newX < leftBoundary) {
    //         newX = leftBoundary;
    //     } else if (newX + captionWidth > rightBoundary) {
    //         newX = rightBoundary - captionWidth;
    //     }

    //     newX += padding;

    //     return { x: newX, y: rulerHeight + spaceBetweenCaptionsAndScale };
    // };

    const checkBoundaries = (pos, currentCaption, captions, scale, padding, videoDuration) => {
        const captionWidth = (currentCaption.end - currentCaption.start) * scale;
        let newX = pos.x - padding;

        const sortedCaptions = [...captions].sort((a, b) => a.start - b.start || a.id - b.id);
        const currentIndex = sortedCaptions.findIndex(c => c.id === currentCaption.id);
        const prevCaption = sortedCaptions[currentIndex - 1];
        const nextCaption = sortedCaptions[currentIndex + 1];

        // Adjust left and right boundaries
        const leftBoundary = prevCaption ? (prevCaption.end * scale) : 0;
        let rightBoundary = nextCaption ? (nextCaption.start * scale) : (scale * scaleLength);

        // Confine within video duration if available
        if (videoDuration && videoDuration > 0) {
            const videoDurationBoundary = videoDuration * scale;
            rightBoundary = Math.min(rightBoundary, videoDurationBoundary);
        }

        if (newX < leftBoundary) {
            newX = leftBoundary;
        } else if (newX + captionWidth > rightBoundary) {
            newX = rightBoundary - captionWidth;
        }

        newX += padding;


        return { x: newX, y: rulerHeight + spaceBetweenCaptionsAndScale };
    };


    const handleLeftResizeMouseDown = (e, captionId) => {
        e.cancelBubble = true;
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        function handleMouseMove(moveEvent) {
               if (disabled) {
            toast.success("This feature is coming soon.",{
                id: "feature",
              })
            return
        }

            const stage = e.target.getStage();
            const stageBox = stage.container().getBoundingClientRect();
            const newPosition = moveEvent.clientX - stageBox.left;
            let newStart = (newPosition - padding) / scale;

            const sortedCaptions = [...captions].sort((a, b) => a.start - b.start || a.id - b.id);
            const currentIndex = sortedCaptions.findIndex(c => c.id === captionId);
            const caption = sortedCaptions[currentIndex];
            const prevCaption = currentIndex > 0 ? sortedCaptions[currentIndex - 1] : null;

            const minDuration = 0.5;
            const maxStart = caption.end - minDuration;
            let minStart = 0;

            if (prevCaption) {
                minStart = Math.max(prevCaption.end, 0);
            }

            newStart = Math.max(newStart, minStart);
            newStart = Math.min(newStart, maxStart);

            setSubtitleEditable({ ...subtitleEditable, [videoFile?.video_id]: true })


            // update SUbtitle JSON
            let tempSubtitleJSon = JSON.parse(JSON.stringify(subtitleJSON));

            let idxx = captions.findIndex(c => c.id === captionId)

            tempSubtitleJSon.segments[idxx].start = newStart;
            tempSubtitleJSon.segments[idxx].end = caption.end;

            setSubtitleJSON(tempSubtitleJSon)

            setCaptions(captions.map(c =>
                c.id === captionId ? { ...c, start: newStart, end: caption.end } : c
            ));

        }

        function handleMouseUp() {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
    };

    // const handleRightResizeMouseDown = (e, captionId) => {
    //     e.cancelBubble = true;
    //     window.addEventListener('mousemove', handleMouseMove);
    //     window.addEventListener('mouseup', handleMouseUp);

    //     function handleMouseMove(moveEvent) {
    //         const stage = e.target.getStage();
    //         const stageBox = stage.container().getBoundingClientRect();
    //         const newPosition = moveEvent.clientX - stageBox.left;
    //         let newEnd = (newPosition - padding) / scale;

    //         const sortedCaptions = [...captions].sort((a, b) => a.start - b.start || a.id - b.id);
    //         const currentIndex = sortedCaptions.findIndex(c => c.id === captionId);
    //         const caption = sortedCaptions[currentIndex];
    //         const nextCaption = sortedCaptions[currentIndex + 1];

    //         const minDuration = 0.5;
    //         const minEnd = caption.start + minDuration;
    //         let maxEnd = scaleLength;

    //         if (nextCaption) {
    //             maxEnd = Math.min(nextCaption.start, scaleLength);
    //         }

    //         newEnd = Math.max(newEnd, minEnd);
    //         newEnd = Math.min(newEnd, maxEnd);

    //         setCaptions(captions.map(c => 
    //             c.id === captionId ? { ...c, end: newEnd } : c
    //         ));

    //         console.log(`MouseMove event: New Position: ${newPosition}, New End (scale): ${newEnd}, Max End: ${maxEnd}, Min End: ${minEnd}, Padding: ${padding}, Scale: ${scale}`);
    //     }

    //     function handleMouseUp() {
    //         window.removeEventListener('mousemove', handleMouseMove);
    //         window.removeEventListener('mouseup', handleMouseUp);
    //     }
    // };

    const handleRightResizeMouseDown = (e, captionId) => {
        e.cancelBubble = true;
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        function handleMouseMove(moveEvent) {
               if (disabled) {
            toast.success("This feature is coming soon.",{
                id: "feature",
              })
            return
        }

            const stage = e.target.getStage();
            const stageBox = stage.container().getBoundingClientRect();
            const newPosition = moveEvent.clientX - stageBox.left;
            let newEnd = (newPosition - padding) / scale;

            const sortedCaptions = [...captions].sort((a, b) => a.start - b.start || a.id - b.id);
            const currentIndex = sortedCaptions.findIndex(c => c.id === captionId);
            const caption = sortedCaptions[currentIndex];
            const nextCaption = sortedCaptions[currentIndex + 1];

            const minDuration = 0.5; // Minimum duration of a caption
            const minEnd = caption.start + minDuration;

            // Determine the maximum end based on video duration and the start of the next caption
            let maxEnd = videoDuration ? Math.min(videoDuration, scaleLength) : scaleLength;
            if (nextCaption) {
                maxEnd = Math.min(maxEnd, nextCaption.start);
            }

            // Ensure the new end time is within the allowed range
            newEnd = Math.max(newEnd, minEnd);
            newEnd = Math.min(newEnd, maxEnd);

            setSubtitleEditable({ ...subtitleEditable, [videoFile?.video_id]: true })

            // update SUbtitle JSON
            let tempSubtitleJSon = JSON.parse(JSON.stringify(subtitleJSON));

            let idxx = captions.findIndex(c => c.id === captionId)

            tempSubtitleJSon.segments[idxx].end = newEnd;

            setSubtitleJSON(tempSubtitleJSon)

            setCaptions(captions.map(c =>
                c.id === captionId ? { ...c, end: newEnd } : c
            ));

            // console.log(`MouseMove event: New Position: ${newPosition}, New End (scale): ${newEnd}, Max End: ${maxEnd}, Min End: ${minEnd}, Padding: ${padding}, Scale: ${scale}`);
        }

        function handleMouseUp() {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
    };

    useEffect(() => {
        const videoElement = mediaPlayerRef.current;

        if (!videoElement) return; // Check if the video element exists

        // Get the current time of the video
        const videoCurrentTime = markerXPosition;

        // Check if the video is currently paused
        const isVideoPaused = videoElement.paused;

        // Loop through each music track
        musicTracks.forEach(musicTrack => {
            // Check if the video is playing and its current time falls within the music track duration
            if (!isVideoPaused &&
                videoCurrentTime >= musicTrack.modifiedStartTime &&
                videoCurrentTime <= musicTrack.modifiedEndTime) {
                // Play the music with specific volume
                playMusic(musicTrack.file, musicTrack.volume, musicTrack.modifiedStartTime, musicTrack.id);
            }
            else {
                stopMusic()
            }
        });
    }, [mediaPlayerRef, musicTracks]);

    // Function to play music
    const playMusic = (file, volume, startTime, id) => {
        // Create a new audio element
        const musicURL = URL.createObjectURL(file);

        if (id === currentPlayedMusic) return
        setCurrentPlayedMusic(id)
        audioRef.current.src = musicURL
        // Convert volume from range [1, 100] to [0, 1]
        const validVolume = volume / 100;

        // Ensure the volume is within the valid range [0, 1]
        const clampedVolume = Math.max(0, Math.min(1, validVolume));
        audioRef.current.volume = clampedVolume;

        // Set the playback start time
        // audioRef.current.seekTo(30 / startTime)

        // Play the audio
        audioRef.current.play();
    };

    // Function to stop music
    const stopMusic = () => {
        if (audioRef?.current)
            // Pause the audio
            audioRef.current.pause();
    };


    const handleButtonClick = () => {
        let tempSubtitleJSon = JSON.parse(JSON.stringify(subtitleJSON))
        let isMarkerOnBlock = false;
        let nextBlockStartTime = null;

        // Iterate through captions to check if the marker is on any block
        captions.forEach(caption => {
            if (markerXPosition >= caption.start && markerXPosition <= caption.end) {
                isMarkerOnBlock = true;
            } else if (caption.start > markerXPosition && (nextBlockStartTime === null || caption.start < nextBlockStartTime)) {
                nextBlockStartTime = caption.start;
            }
        });

        if (!isMarkerOnBlock) {
            const newBlockStart = Math.max(0, markerXPosition);
            let newBlockEnd = nextBlockStartTime !== null ? nextBlockStartTime : scaleLength;

            // If there's no block ahead, add a default duration to the new block
            if (nextBlockStartTime === null) {
                newBlockEnd = Math.min(newBlockStart + 5, scaleLength); // Default duration of 5 seconds
            }


            // Create a new block, ensuring it starts at the current marker position
            const newBlock = {
                id: new Date().getTime() + 1, // Assign a new unique id // Current timestamp as ID
                start: newBlockStart,
                end: newBlockEnd,
                text: '' // Empty string for new block
            };


            // Find the index where the new block should be inserted based on start time
            let insertionIndex = tempSubtitleJSon.segments.findIndex(segment => segment.start > newBlock.start);

            // If no index is found (i.e., new block should be inserted at the end), set insertionIndex to the length of the array
            if (insertionIndex === -1) {
                insertionIndex = tempSubtitleJSon.segments.length;
            }

            // Insert the new block into the tempSubtitleJSON.segments array at the determined index
            tempSubtitleJSon.segments.splice(insertionIndex, 0, newBlock);

            setSubtitleJSON(tempSubtitleJSon)
            // Update captions array with new block
            setCaptions([...captions, newBlock]);
        }
        toast.error("There is already subtitle there, find an empty place.")
    };

    const handleDragMove = (id, x) => {
        if (disabled) {
            toast.success("This feature is coming soon.",{
                id: "feature",
              })
            return
        }
        // console.log("handleDragMove")
        const caption = captions.find(c => c.id === id);
        // Adjust the block edges considering the padding
        const blockLeftEdge = x - padding;
        const blockRightEdge = x + (caption.end - caption.start) * scale + padding;
        const viewportRightEdge = scrollContainerRef.current.scrollLeft + scrollContainerRef.current.clientWidth;
        const scrollThreshold = 50; // pixels threshold from the edges

        // Trigger scrolling when the block right edge is near the right edge of the viewport
        if (blockRightEdge >= viewportRightEdge - scrollThreshold) {
            const newScrollPosition = scrollContainerRef.current.scrollLeft + 20;
            setScrollPosition(newScrollPosition);
            scrollContainerRef.current.scrollLeft = newScrollPosition;
        }

        // Trigger scrolling when the block left edge is near the left edge of the viewport
        // Taking into account the padding and the threshold
        if (blockLeftEdge <= scrollContainerRef.current.scrollLeft + scrollThreshold) {
            const newScrollPosition = scrollContainerRef.current.scrollLeft - 20;
            setScrollPosition(newScrollPosition);
            scrollContainerRef.current.scrollLeft = newScrollPosition;
        }
    };

    // Sync the scroll position with the range input
    const handleScrollSliderChange = useCallback((event) => {
        const newPosition = Number(event.target.value);
        setScrollPosition(newPosition);
        // console.log("newPosition is: ", newPosition)
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = newPosition;
            // console.log("inside if condition : ", scrollContainerRef.current.scrollLeft)
        }
    }, []);

    // Update state when the container is scrolled
    const handleScroll = useCallback(() => {
        if (scrollContainerRef.current) {
            // console.log("scrollContainerRef.current.scrollLeft is: ", scrollContainerRef.current.scrollLeft)
            setScrollPosition(scrollContainerRef.current.scrollLeft);
        }
    }, []);

    // Function to create draggable marker with triangle
    const createMarkerWithTriangle = (markerXPosition, setMarkerXPosition, padding, scale, containerWidth, containerHeight) => {
        // Drag bound function to restrict movement along the x-axis
        const dragBoundFunc = (pos) => {
            // Adjusted to take padding into account for the dragging boundaries
            const minX = padding;
            const maxX = (containerWidth - padding);
            const newX = Math.max(minX, Math.min(pos.x, maxX));
            return { x: newX, y: 0 }; // Lock y position
        };

        const markerPixelPosition = (markerXPosition * scale) + padding; // Adjust marker position for padding

        return (
            <Group
                draggable
                x={markerPixelPosition} // Set initial position in pixels, adjusted for padding
                dragBoundFunc={dragBoundFunc}
                onDragMove={(e) => {
                    // Adjusting for padding
                    const newMarkerPos = (e.target.x() - padding) / scale;
                    // Update the marker's x position on the scale
                    setMarkerXPosition(newMarkerPos);
                    // mediaPlayerRef.current.seekTo(newMarkerPos * 30)
                    // if (audioRef && audioRef.current) {
                    // mediaPlayerRef.current.seekTo(30*newMarkerPos) 
                    // }

                }}
            >
                <Line
                    points={[0, 10, 0, containerHeight]} // Line starts from the top of the triangle
                    stroke="#00A3FF"
                    strokeWidth={2}
                />
                <RegularPolygon
                    x={0}
                    y={0}
                    sides={3}
                    radius={10}
                    fill="#00A3FF"
                    stroke="#00A3FF"
                    strokeWidth={2}
                    rotation={180}
                />
            </Group>
        );
    };

    const handleTransformEnd = (id, start, end) => {
        // console.log("handleTransformEnd")
           if (disabled) {
            toast.success("This feature is coming soon.",{
                id: "feature",
              })
            return
        }
        setSubtitleEditable({ ...subtitleEditable, [videoFile?.video_id]: true })
        let tempSubtitleJSon = JSON.parse(JSON.stringify(subtitleJSON));

        let idxx = captions.findIndex(c => c.id === id)
        setCaptions(captions.map(c => c.id === id ? { ...c, start, end } : c));


        tempSubtitleJSon.segments[idxx].start = start;
        tempSubtitleJSon.segments[idxx].end = end;

        setSubtitleJSON(tempSubtitleJSon)
    }

    // Function to create draggable caption blocks with boundaries
    const createCaptionBlocks = () => {
        return captions.map((caption) => {
            const blockWidth = (caption.end - caption.start) * scale;
            const boundaryWidth = 5; // Width of the left/right boundary rectangles
            const boundaryHeight = 2; // Height of the top/bottom boundary rectangles
            const innerBoundaryOffset = 5; // Offset for boundaries inside the block
            const isSelected = caption.id === selectedBlockId;
            // const editIcon = '✏️'; // Edit icon (using a pencil emoji)
            return (
                // console.log("caption id click here is: ", caption.id),
                <Group
                    key={caption.id}
                    id={`caption-block-${caption.id}`} // Assign unique ID to each caption block
                    x={caption.start * scale + padding}
                    y={rulerHeight + spaceBetweenCaptionsAndScale}
                    draggable
                    onDragStart={() => setSelectedBlockId(caption.id)}
                    // onDragEnd={(e) => handleDragEnd(caption.id, e.target.x())}
                    onDragEnd={(e) => {
                        const rawXPosition = e.target.x();

                        // Call handleDragEnd with the raw x position
                        handleDragEnd(caption.id, rawXPosition);
                    }}
                    // dragBoundFunc={(pos) => checkBoundaries(pos, caption, captions, scale)}
                    dragBoundFunc={(pos) => checkBoundaries(pos, caption, captions, scale, padding, videoDuration)}
                    onDragMove={(e) => handleDragMove(caption.id, e.target.x())}  // Scroll the container when dragging
                    onClick={() => {
                        setSelectedBlockId(caption.id);
                        setBlockJustClicked(true);
                        setTimeout(() => setBlockJustClicked(false), 10);
                    }}
                    onTransformEnd={(e) => {
                        // console.log("onTransformEnd")
                        const scaleX = e.currentTarget.scaleX();
                        const scaleY = e.currentTarget.scaleY();
                        const width = e.currentTarget.width() * scaleX;
                        const endTime = caption.start + (width / scale); // Calculate end time
                        // Now you have access to the start and end times, you can pass them to the handleTransformEnd function
                        handleTransformEnd(caption.id, caption.start, endTime);
                    }}
                >
                    {/* Caption block */}
                    <Rect
                        width={blockWidth}
                        height={30}
                        fill='#E1F1FF'

                    />

                    {/* Caption text */}
                    <Text
                        text={caption.text}
                        fontSize={10}
                        fontFamily="Noto Sans"
                        fill="#003350"
                        align="center"
                        verticalAlign="middle"
                        width={blockWidth}
                        height={30}
                        ellipsis={true}
                        wrap="none"
                    />

                    {/* Top boundary (inside the block) */}
                    <Rect
                        x={0}
                        y={-boundaryHeight}
                        width={blockWidth}
                        height={boundaryHeight}
                        fill={isSelected ? '#003350' : '#00A3FF'}
                    />

                    {/* Bottom boundary (inside the block) */}
                    <Rect
                        x={0}
                        y={30}
                        width={blockWidth}
                        height={boundaryHeight}
                        fill={isSelected ? '#003350' : '#00A3FF'}
                    />

                    {/* Left boundary (inside the block) */}
                    <Rect
                        onMouseDown={(e) => {
                            handleLeftResizeMouseDown(e, caption.id);
                            setSelectedBlockId(caption.id); // Manually set selected block
                        }}
                        x={innerBoundaryOffset - innerBoundaryOffset}
                        width={boundaryWidth}
                        height={30}
                        fill={isSelected ? '#003350' : '#00A3FF'}
                    />

                    {/* Right boundary (inside the block) */}
                    <Rect
                        onMouseDown={(e) => {
                            handleRightResizeMouseDown(e, caption.id);
                            setSelectedBlockId(caption.id); // Manually set selected block
                        }}
                        x={blockWidth - innerBoundaryOffset}
                        width={boundaryWidth}
                        height={30}
                        fill={isSelected ? '#003350' : '#00A3FF'}
                    />
                </Group>
            );
        });
    };

    ////////////////////////// zoom control code below /////////////////////////////
    function ZoomControl({ scale, setScale }) {
        // Assuming zoomLevel is a state variable in the parent component controlling the zoom
        const minScale = 0.1; // minimum scale value
        const maxScale = 40; // maximum scale value

        const handleZoomOut = () => {
            let zoomOutRate;

            if (videoFile.duration > 2400) {
                zoomOutRate = 0.09;
            } else if (videoFile.duration > 1800) {
                zoomOutRate = 0.1;
            } else if (videoFile.duration > 1000) {
                zoomOutRate = 0.2;
            } else {
                zoomOutRate = 0.5; // Default zoom out rate for shorter durations
                // console.log("scale lenght is : ", scaleLength)
                // if (scaleLength <= 120) {
                //     setScaleLength(360);
                //     zoomOutRate = 0.5;
                // }
            }

            setScale(prevScale => Math.max(prevScale - zoomOutRate, minScale));
        };

        const handleZoomIn = () => {
            let zoomInRate;

            if (videoFile.duration > 2400) {
                zoomInRate = 0.09;
            } else if (videoFile.duration > 1800) {
                zoomInRate = 0.1;
            } else if (videoFile.duration > 1000) {
                zoomInRate = 0.2;
            }
            else {
                zoomInRate = 0.5; // Default zoom in rate for shorter durations
            }

            setScale(prevScale => Math.min(prevScale + zoomInRate, maxScale));
        };

        const handleSliderChange = (event) => {
            const sliderValue = Number(event.target.value);
            // Map the slider value back to the scale range
            const newScale = ((sliderValue - 1) / 2) * (maxScale - minScale) + minScale;
            setScale(newScale);
        };

        const calculateSliderValue = () => {
            // Map the scale range (0.5 to 40) to the slider range (1 to 3)
            return ((scale - minScale) / (maxScale - minScale)) * 2 + 1;
        };

        // const adjustTimeline = () => {
        //     const maxDuration = Math.max(maxVideoDuration, maxAudioDuration);

        //     console.log("maxDuration is: ", maxDuration)
        //     console.log("maxVideoDuration is: ", maxVideoDuration)
        //     console.log("maxAudioDuration is: ", maxAudioDuration)

        //     // Assuming you have a function similar to adjustScaleLength
        //     // that sets the scale based on the max duration
        //     adjustScaleLength(maxDuration);
        // };

        return (
            <div className="zoom-controls">
                <div onClick={handleZoomOut} className='zoom-icon'>
                    <ZoomOutIcon htmlColor="white"></ZoomOutIcon>
                </div>
                {/* <button className="zoom-button" onClick={handleZoomOut}>-</button> */}
                {!isXs ? <input
                    type="range"
                    min="1"
                    max="3"
                    step="0.1"
                    value={calculateSliderValue()}
                    onChange={handleSliderChange}
                    className="zoom-slider"
                /> : <div style={{ width: 2 }}></div>}
                <div onClick={handleZoomIn} className='zoom-icon'>
                    <ZoomInIcon htmlColor="white"></ZoomInIcon>
                </div>
                {/* <button className="zoom-button" onClick={handleZoomIn}>+</button> */}
            </div>
        );
    }

    ////////////////// video layer work below /////////////////////////////////
    // Function to handle video seek
    const seekVideo = (time) => {
        setVideoCurrentTime(time);
    };

    // Helper function to format duration in mm:ss
    const formatDuration = (duration) => {
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleVideoDurationChange = (duration) => {
        // setLongestDuration(duration);

        const scaleSettings = [
            { maxDuration: 120, scale: 14, scaleLength: 120 },
            { maxDuration: 180, scale: 10, scaleLength: 180 },
            { maxDuration: 360, scale: 4, scaleLength: 360 },
            { maxDuration: 600, scale: 2.7, scaleLength: 600 },
            { maxDuration: 900, scale: 1.8, scaleLength: 900 },
            { maxDuration: 1200, scale: 1.4, scaleLength: 1200 },
            { maxDuration: 1500, scale: 1.3, scaleLength: 1500 },
            { maxDuration: 1800, scale: 0.9, scaleLength: 1800 },
            { maxDuration: 2100, scale: 0.8, scaleLength: 2100 },
            { maxDuration: 2400, scale: 0.8, scaleLength: 2400 },
            { maxDuration: 2700, scale: 0.7, scaleLength: 2700 },
            { maxDuration: 3000, scale: 0.6, scaleLength: 3000 },
            { maxDuration: 3300, scale: 0.5, scaleLength: 3300 },
            { maxDuration: 3600, scale: 0.5, scaleLength: 3600 },
            { maxDuration: 3900, scale: 0.4, scaleLength: 3900 },
            { maxDuration: 4200, scale: 0.4, scaleLength: 4200 },
            { maxDuration: 4500, scale: 0.3, scaleLength: 4500 },
            { maxDuration: 4800, scale: 0.3, scaleLength: 4800 },
            { maxDuration: 5400, scale: 0.3, scaleLength: 5400 },
            { maxDuration: Infinity, scale: 18, scaleLength: 90 } // Default case
            // The last object in scaleSettings serves as the default case, applying if none of the other conditions are met.
        ];

        const setting = scaleSettings.find(s => duration <= s.maxDuration);
        if (setting) {
            setScale(setting.scale);
            setScaleLength(setting.scaleLength);
        }
    };

    const handleTimelineClick = (e) => {
        const stage = e.target.getStage();
        const clickX = stage.getPointerPosition().x;
        const timestamp = Math.max(0, (clickX - padding) / scale); // Convert click position to timestamp
        ////////// music work is below /////////////////////////////////
        if (e.target === e.target.getStage()) {
            // Deselect any selected music blocks
            setSelectedMusicBlockId(null);
            setSelectedVoiceOverBlockId(null); // Deselect any selected voiceOver blocks
        }
        //////////////////////////////////////// music work is above /////////////////////////////////    
        if (timestamp <= videoDuration) {
            seekVideo(timestamp); // Seek the video if within duration
        }
        mediaPlayerRef.current.seekTo(timestamp * videoFile.video_FPS)
        // setMarkerXPosition(timestamp); // Always move the marker to the clicked position
    };
    // Function to move marker with video in real time
    const handleVideoTimeUpdate = (currentTime) => {
        setMarkerXPosition(currentTime); // Update marker position based on current time of video
    };

    const handleVideoUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const videoUrl = URL.createObjectURL(file);
            setVideoSrc(videoUrl); // Set the video source state
            setVideoFileName(file.name); // Set the video file name state
            // Assuming you have a function to get the video duration
            const duration = await getVideoDuration(file);
            // setVideoDuration(duration);
            handleVideoDurationChange(duration); // Update the overall video duration state
        }
    };

    useEffect(() => {
        handleVideoDurationChange(videoFile.duration); // Update the overall video duration state

    }, [videoFile])


    const getVideoDuration = (file) => {
        return new Promise((resolve, reject) => {
            const videoElement = document.createElement("video");
            videoElement.src = URL.createObjectURL(file);

            videoElement.addEventListener('loadedmetadata', () => {
                resolve(videoElement.duration);
                URL.revokeObjectURL(videoElement.src); // Clean up memory
            });

            videoElement.addEventListener('error', () => {
                reject('Error loading video file');
            });
        });
    };

    // making video component
    const VideoLayer = ({ videoTrackHeight }) => {
        // console.log({ videoDuration })
        const videoPlayerHeight = videoTrackHeight - padding * 5; // Height of the video player
        const videoPlayerWidth = videoDuration * scale

        // Calculate position for duration text
        const durationTextX = videoPlayerWidth + padding - 60; // 50 is an arbitrary value for padding, adjust as needed
        // Format the duration in a readable format (e.g., "mm:ss")
        const formattedDuration = formatDuration(videoDuration);

        return (
            <Group>
                {/* Layer background with border */}
                <Rect
                    x={0}
                    y={0}
                    width={videoPlayerWidth + padding * 2}
                    height={videoTrackHeight}
                />
                {/* Video player with padding */}
                <Rect
                    x={padding}
                    y={padding}
                    width={videoPlayerWidth}
                    height={videoPlayerHeight}
                    fill="#FFC6C6"
                    stroke="#F1B9AC"
                    strokeWidth={4}
                    cornerRadius={5} // Optional: if you want rounded corners
                    shadowColor="orange"
                    shadowBlur={10}
                />
                {/* Additional elements representing the video frames can be added here */}
                {/* video file name below */}
                <Text
                    x={padding}
                    y={padding + 5} // Adjust as needed
                    width={videoPlayerWidth}
                    height={videoPlayerHeight}
                    text={`${videoFileName}`}
                    fontSize={12}
                    fontFamily="Calibri"
                    fill="#000"
                    align='center'
                />
                {/* Video duration */}
                <Text
                    x={durationTextX}
                    y={padding + 5}
                    width={50} // Adjust width based on the format of your duration
                    height={videoTrackHeight}
                    text={formattedDuration}
                    fontSize={12}
                    fontFamily="Calibri"
                    fill="#000"
                    align='right'
                />
            </Group>
        );
    };
    ///////////////////////////////// video layer work above /////////////////////////////////

    //////////////////////////////////////// music work is below /////////////////////////////////

    const createMusicBlock = (startTime, duration) => ({
        id: `track-${Date.now()}`,
        originalStartTime: startTime,
        originalDuration: duration,
        originalEndTime: startTime + duration,
        modifiedStartTime: startTime,
        modifiedDuration: duration,
        modifiedEndTime: startTime + duration,
        preTrimStartTime: startTime,
        preTrimEndTime: startTime + duration,
        musicTrimDuration: duration,
        trimDurationAmount_L: 0,
        trimDurationAmount_R: 0,
    });

    const addNewMusicBlockBasedOnMarker = (markerTimestamp, newBlockDuration, musicTracks) => {
        // Sort tracks by start time
        const sortedTracks = [...musicTracks].sort((a, b) => a.modifiedStartTime - b.modifiedStartTime);

        // Find index of the block where the marker is and the next block
        const currentBlockIndex = sortedTracks.findIndex(track =>
            markerTimestamp >= track.modifiedStartTime && markerTimestamp <= track.modifiedEndTime
        );

        // If the marker is on an existing block, add the new block after the last block
        if (currentBlockIndex !== -1) {
            const lastBlock = sortedTracks[sortedTracks.length - 1];
            return createMusicBlock(lastBlock.modifiedEndTime, newBlockDuration);
        }

        // If marker is not on an existing block, check available space between nearest blocks
        if (currentBlockIndex === -1) {
            const leftBlock = sortedTracks.reduce((acc, block) => block.modifiedEndTime <= markerTimestamp ? block : acc, null);
            const rightBlock = sortedTracks.find(block => block.modifiedStartTime > markerTimestamp);

            // If there's enough space between left and right blocks, add new block after left block
            if (leftBlock && rightBlock && rightBlock.modifiedStartTime - leftBlock.modifiedEndTime >= newBlockDuration) {
                return createMusicBlock(leftBlock.modifiedEndTime, newBlockDuration);
            }
        }

        // Add the new block after the last block or at the start
        const lastBlock = sortedTracks[sortedTracks.length - 1];
        const newBlockStartTime = lastBlock ? lastBlock.modifiedEndTime : 0;

        return createMusicBlock(newBlockStartTime, newBlockDuration);
    };

    const handleMusicUpload = async (event) => {
        const files = Array.from(event.target.files);
        const MAX_DURATION_ALLOWED = 5400;

        try {
            const newTracks = await Promise.all(files.map(async (file, index) => {
                const duration = await getMusicDuration(file); // This function gets the duration of the music file
                // console.log("duration is: ", duration)

                // Check if the music duration exceeds the maximum allowed duration
                if (duration > MAX_DURATION_ALLOWED) {
                    console.log(`Maximum allowed music duration is 90 minutes. The uploaded file exceeds this limit.`);
                    return null;
                }

                // Calculate total duration of all existing plus new music blocks
                const totalDurationWithNewBlock = musicTracks.reduce((total, track) => total + track.modifiedDuration, duration);

                if (totalDurationWithNewBlock > MAX_DURATION_ALLOWED) {
                    console.log("Exceeds maximum allowed duration when combined with existing blocks.");
                    return null; // Skip adding this block
                }

                if (totalDurationWithNewBlock > videoFile.duration) {
                    // Adjust the scale length based on the total duration
                    adjustScaleLength(totalDurationWithNewBlock);
                }

                const markerTimestamp = markerXPosition; // The current position of the marker on the scale
                const newBlock = addNewMusicBlockBasedOnMarker(markerTimestamp, duration, musicTracks);
                // console.log("longest video_duration is: ", videoFile.duration) // longest duration represents the duration of the video. 

                // console.log("newBlock is: ", newBlock)
                if (newBlock) {
                    return {
                        id: `track-${Date.now()}-${index}`,
                        file,
                        originalStartTime: newBlock.originalStartTime,
                        originalDuration: duration,
                        originalEndTime: newBlock.originalEndTime,
                        modifiedStartTime: newBlock.modifiedStartTime,
                        modifiedDuration: duration,
                        modifiedEndTime: newBlock.modifiedEndTime,
                        preTrimStartTime: newBlock.originalStartTime,
                        preTrimEndTime: newBlock.originalEndTime,
                        musicTrimDuration: duration,
                        trimDurationAmount_L: 0,
                        trimDurationAmount_R: 0,
                    };
                } else {
                    return null;
                }
            }));

            // Filter out any null values and add new tracks to the state
            const updatedTracks = [...musicTracks, ...newTracks.filter(track => track !== null)];
            // Sort the updated tracks array by start time
            updatedTracks.sort((a, b) => a.modifiedStartTime - b.modifiedStartTime);
            setMusicTracks(updatedTracks);

            ////// Calculate the longest end time among music, voice-over, and video
            const longestMusicEndTime = Math.max(...updatedTracks.map(track => track.modifiedEndTime));
            const longestVoiceOverEndTime = Math.max(...voiceOverTracks.map(track => track.modifiedEndTime));
            const calculatedLongestDuration = Math.max(videoDuration, longestMusicEndTime, longestVoiceOverEndTime);
            // Adjust the scale length if necessary
            if (calculatedLongestDuration > videoFile.duration) {
                adjustScaleLength(calculatedLongestDuration);
            }

        } catch (error) {
            console.error('An error occurred while uploading files:', error);
            // Handle the error appropriately in the UI
        }
    };

    const getMusicDuration = (file) => {
        return new Promise((resolve, reject) => {
            const audio = new Audio();
            audio.src = URL.createObjectURL(file);

            audio.addEventListener('loadedmetadata', () => {
                resolve(audio.duration);
                URL.revokeObjectURL(audio.src); // Clean up memory
            });

            audio.addEventListener('error', () => {
                reject('Error loading audio file');
            });
        });
    };


    const dragBoundFunc = (pos, trackId) => {
        const trackIndex = musicTracks.findIndex(track => track.id === trackId);
        const currentTrack = musicTracks[trackIndex];

        // Find the previous track's index
        const prevTrackIndex = trackIndex - 1 >= 0 ? trackIndex - 1 : null;
        const prevTrack = prevTrackIndex !== null ? musicTracks[prevTrackIndex] : null;

        // Set the maximum x position as the end of the container minus the width of the block and padding
        let maxPosX = containerWidthWithPadding - (currentTrack.modifiedDuration * scale) - padding;

        // Set the minimum x position to padding initially
        let minPosX = padding;

        // If there is a previous track, calculate the minPosX based on the previous track's end time
        if (prevTrack) {
            const prevTrackEndX = prevTrack.modifiedEndTime * scale + padding;
            // Ensure the current track's x position does not go below the prevTrackEndX
            minPosX = Math.max(minPosX, prevTrackEndX);
        }

        // If there is a next track, calculate the maxPosX based on the next track's start time
        const nextTrackIndex = trackIndex + 1 < musicTracks.length ? trackIndex + 1 : null;
        const nextTrack = nextTrackIndex !== null ? musicTracks[nextTrackIndex] : null;
        if (nextTrack) {
            const nextTrackStartX = nextTrack.modifiedStartTime * scale + padding;
            maxPosX = Math.min(maxPosX, nextTrackStartX - (currentTrack.modifiedDuration * scale));
        }

        // Constrain the new x position within the calculated boundaries
        const newX = Math.max(minPosX, Math.min(pos.x, maxPosX));

        return { x: newX, y: currentTrack.yPosition || 0 };
    };

    // This function is called when the drag action ends.... perfect working code...
    const onDragEnd = (e, trackId) => {
        // Calculate the new start time based on the final x position
        const node = e.target;
        // const scaleX = node.getStage().scaleX(); // or simply 1 if you're not scaling the stage
        const newStartSec = Math.max(0, (node.x() - padding) / scale);

        // Update the state with the new start time
        setMusicTracks(prevTracks => prevTracks.map(track => {
            // Check if the block is back to its original duration
            const isUntrimmed = Number(track.modifiedDuration.toFixed(2)) === Number(track.originalDuration.toFixed(2));
            if (track.id === trackId) {
                return {
                    ...track,
                    modifiedStartTime: newStartSec,
                    modifiedEndTime: newStartSec + track.modifiedDuration,
                    preTrimStartTime: newStartSec - track.trimDurationAmount_L, // subtracting the trimmed amount from the start time
                    preTrimEndTime: newStartSec + track.modifiedDuration + track.trimDurationAmount_R, // adding the trimmed amount to the end time
                    trimDurationAmount_L: isUntrimmed ? 0 : track.trimDurationAmount_L, // resetting the trimmed amount to 0
                    trimDurationAmount_R: isUntrimmed ? 0 : track.trimDurationAmount_R, // resetting the trimmed amount to 0
                };
            }
            return track;
        }));
    };

    const onDragMove = (e, trackId) => {
        const pos = { x: e.target.x(), y: e.target.y() };
        const confinedPos = dragBoundFunc(pos, trackId);
        // Update the drag position with the confined position
        // Note: This does not set the state, it only moves the node visually
        e.target.position(confinedPos);
    };

    // Function to handle music block resize or music block trim
    const handleMusicResizeMouseMove = (moveEvent, trackId, side, stage) => {
        const track = musicTracks.find(t => t.id === trackId);
        if (!track || !stage) return;

        const mousePos = stage.getPointerPosition();
        let newTime = (mousePos.x - padding) / scale;
        newTime = Math.max(0, newTime);
        const MINIMUM_TRACK_DURATION = 1;
        const MAX_SCALE_DURATION_ALLOWED = 5370; // allowed rescalling length
        setSelectedMusicBlockId(track.id); // Manually set the selected block

        // Find index of the current track
        const trackIndex = musicTracks.findIndex(t => t.id === trackId);
        // Get previous and next tracks
        const prevTrack = trackIndex > 0 ? musicTracks[trackIndex - 1] : null;
        const nextTrack = trackIndex < musicTracks.length - 1 ? musicTracks[trackIndex + 1] : null;

        if (side === 'left') {
            let newStart = Math.max(track.preTrimStartTime, newTime);

            // Ensure newStart does not overlap with the end of the previous track
            if (prevTrack) {
                newStart = Math.max(newStart, prevTrack.modifiedEndTime);
            }

            newStart = Math.min(newStart, track.modifiedEndTime - MINIMUM_TRACK_DURATION);
            const newDuration = track.modifiedEndTime - newStart;

            // console.log(`newStart: ${newStart}, newDuration: ${newDuration}, modifiedStartTime: ${track.modifiedStartTime}, "currentTimes.modifiedStartTime: ${currentTimes.modifiedStartTime}`);
            const trimmedAmount = Math.abs(track.preTrimStartTime - newStart);

            // Create a new array of updated tracks
            const updatedTracks = musicTracks.map(t =>
                t.id === trackId ? {
                    ...t,
                    modifiedStartTime: newStart,
                    modifiedDuration: newDuration,
                    trimDurationAmount_L: Number(trimmedAmount.toFixed(2))
                } : t
            );

            setMusicTracks(updatedTracks);

        }
        else if (side === 'right') {

            let newEnd = Math.min(track.preTrimEndTime, newTime); // Use preTrimEndTime for expanding limit

            if (nextTrack) {
                newEnd = Math.min(newEnd, nextTrack.modifiedStartTime);
            }

            newEnd = Math.max(newEnd, track.modifiedStartTime + MINIMUM_TRACK_DURATION); // Ensure it doesn't overlap or underflow
            // newEnd = Math.min(newEnd, maxEndPosition);  // Ensure it doesn't exceed scale limit, accounting for padding
            const newDuration = newEnd - track.modifiedStartTime;

            const trimmedAmount = Math.abs(newEnd - track.preTrimEndTime);

            // Create a new array of updated tracks
            const updatedTracks = musicTracks.map(t =>
                t.id === trackId ? {
                    ...t,
                    modifiedEndTime: newEnd,
                    modifiedDuration: newDuration,
                    trimDurationAmount_R: Number(trimmedAmount.toFixed(2)),
                    // Retain pre-trim values until a new drag begins
                } : t
            );
            setMusicTracks(updatedTracks);

            ////// Calculate the longest end time among music, voice-over, and video
            // musicLastEndTimeRef.current = Math.max(...updatedTracks.map(track => track.modifiedEndTime));
            // voiceOverLastEndTimeRef.current = Math.max(...voiceOverTracks.map(track => track.modifiedEndTime));
            // if (musicLastEndTimeRef.current > videoFile.duration && musicLastEndTimeRef.current < MAX_SCALE_DURATION_ALLOWED && musicLastEndTimeRef.current > voiceOverLastEndTimeRef.current) {
            //     needScaleAdjustmentRef.current = true;
            // }

            // test code
            musicLastEndTimeRef.current = Math.max(...updatedTracks.map(track => track.modifiedEndTime));
            voiceOverLastEndTimeRef.current = Math.max(...voiceOverTracks.map(track => track.modifiedEndTime));
            if (musicLastEndTimeRef.current > videoFile.duration && musicLastEndTimeRef.current < MAX_SCALE_DURATION_ALLOWED && musicLastEndTimeRef.current > voiceOverLastEndTimeRef.current) {
                needScaleAdjustmentRef.current = true;
            }

        }
    };

    // Call this function when the resize operation is completed
    const finalizeResize = () => {
        if (needScaleAdjustmentRef.current) {
            const lastEndTime = Math.max(videoFile.duration, musicLastEndTimeRef.current, voiceOverLastEndTimeRef.current); //test
            needScaleAdjustmentRef.current = false;
            // const lastEndTime = musicLastEndTimeRef.current;
            adjustScaleLength(lastEndTime);
            // needScaleAdjustmentRef.current = false;
        }
    };

    const handleMusicLeftResizeMouseDown = (e, trackId) => {
        e.evt.preventDefault();
        const stage = mainContainerRef.current.getStage();
        currentResizeHandler = (moveEvent) => handleMusicResizeMouseMove(moveEvent, trackId, 'left', stage);
        window.addEventListener('mousemove', currentResizeHandler);
        window.addEventListener('mouseup', handleMusicResizeMouseUp);
    };

    const handleMusicRightResizeMouseDown = (e, trackId) => {
        e.evt.preventDefault();
        const stage = mainContainerRef.current.getStage();
        currentResizeHandler = (moveEvent) => handleMusicResizeMouseMove(moveEvent, trackId, 'right', stage);
        window.addEventListener('mousemove', currentResizeHandler);
        window.addEventListener('mouseup', handleMusicResizeMouseUp);
    };

    const handleMusicResizeMouseUp = () => {
        if (currentResizeHandler) {
            window.removeEventListener('mousemove', currentResizeHandler);
            window.removeEventListener('mouseup', handleMusicResizeMouseUp);
            currentResizeHandler = null;

            // const MAX_SCALE_DURATION_ALLOWED = 5370; // allowed rescalling length
            // if (musicLastEndTimeRef.current > videoFile.duration && musicLastEndTimeRef.current < MAX_SCALE_DURATION_ALLOWED && musicLastEndTimeRef.current > voiceOverLastEndTimeRef.current) {
            //     finalizeResize(); /// test code
            // } 

            // if (musicLastEndTimeRef.current > videoFile.duration && musicLastEndTimeRef.current < MAX_SCALE_DURATION_ALLOWED && musicLastEndTimeRef.current > voiceOverLastEndTimeRef.current) {
            //     finalizeResize(); /// test code
            // } 
            finalizeResize();
        }
    };

    const deleteMusicBlock = () => {
        setMusicTracks(prevTracks => prevTracks.filter(track => track.id !== selectedMusicBlockId));
        setSelectedMusicBlockId(null); // Reset the selected block ID
    };

    // Define your MusicBlock component
    const MusicBlock = ({ track, onDragMove, onDragEnd }) => {
        const blockWidth = track.modifiedDuration * scale;
        const xPosition = track.modifiedStartTime * scale + padding;
        const resizeHandleWidth = 6; // Adjust as needed
        const fileName = track.file.name;

        const handleClick = () => {
            setSelectedMusicBlockId(track.id);
        };

        return (
            <Group
                draggable
                x={xPosition}
                y={0}
                dragBoundFunc={(pos) => dragBoundFunc(pos, track.id)}
                onDragMove={(e) => onDragMove(e, track.id)}
                onDragEnd={(e) => onDragEnd(e, track.id)}
                onClick={handleClick} // Select the block when clicked
            >
                {/* Main Music Block */}
                <Rect
                    width={blockWidth}
                    height={30}
                    fill={track.id === selectedMusicBlockId ? 'green' : '#EEEAFF'}
                    stroke="#2D0050"
                    strokeWidth={1}
                />
                {/* Music File Name */}
                <Text
                    text={fileName}
                    fontSize={12} // Adjust font size as needed
                    fill='#2D0050' // Text color
                    width={blockWidth}
                    height={30}
                    padding={5} // Padding inside the block
                    align='center' // Center the text inside the block
                    offsetY={-5} // Adjust the vertical position as needed
                />
                {/* End Time */}
                <Text
                    x={blockWidth - 33}
                    y={10}
                    text={formatDuration(track.modifiedEndTime)}
                    fontSize={12}
                    fill='#2D0050'
                    align='right'
                />
                {/* Left Resize Handle */}
                <Rect
                    width={resizeHandleWidth}
                    height={30}
                    fill="#EEEAFF"
                    onMouseDown={(e) => {
                        handleMusicLeftResizeMouseDown(e, track.id)
                    }}

                />
                {/* Right Resize Handle */}
                <Rect
                    x={blockWidth - resizeHandleWidth}
                    width={resizeHandleWidth}
                    height={30}
                    fill="orange"
                    onMouseDown={(e) => handleMusicRightResizeMouseDown(e, track.id)}
                />
            </Group>
        );
    };

    const MusicLayer = ({ tracks }) => {
        if (!tracks || tracks.length === 0) {
            return null;
        }

        return (
            <Group>
                {tracks.map(track => (
                    <MusicBlock
                        key={track.id}
                        track={track}
                        onDragMove={onDragMove}
                        onDragEnd={onDragEnd}
                    />
                ))}
            </Group>
        );
    };
    ///////////////////////////////// music work is above /////////////////////////////////////////

    ////////////////////////////// Shape layer work below /////////////////////////////////////////
    // Calculate the Y position for the Shape Layer
    const shapeLayerYPosition = videoLayerYPosition + videoTrackHeight + spaceBetweenLayers;
    // handleAddShape function
    const handleAddShape = useCallback(() => {
        let isMarkerOnBlock = false;
        let nextBlockStartTime = null;

        // Iterate through shapes to check if the marker is on any block
        shapes.forEach(shape => {
            if (markerXPosition >= shape.start && markerXPosition <= shape.end) {
                isMarkerOnBlock = true;
            } else if (shape.start > markerXPosition && (nextBlockStartTime === null || shape.start < nextBlockStartTime)) {
                nextBlockStartTime = shape.start;
            }
        });

        if (!isMarkerOnBlock) {
            const newBlockStart = Math.max(0, markerXPosition);
            let newBlockEnd = nextBlockStartTime !== null ? nextBlockStartTime : scaleLength;

            // If there's no block ahead, add a default duration to the new block
            if (nextBlockStartTime === null) {
                newBlockEnd = Math.min(newBlockStart + 5, scaleLength); // Default duration of 5 seconds
            }

            // Create a new block, ensuring it starts at the current marker position
            const newBlock = {
                id: new Date().getTime() + 1, // Assign a new unique id // Current timestamp as ID
                start: newBlockStart,
                end: newBlockEnd,
                text: '', // Empty string for new block
                x: 100,
                y: 70,
                width: 100,
                height: 100,
                fill: 'red',
                isImg: false,
                shapeImg: null,
                imgBlob: null,
            };

            // Update shapes array with new block
            setShapes([...shapes, newBlock]);
        }
    }, [shapes, markerXPosition, scaleLength]);

    // for uploading images as shape
    const handleAddImageAsShape = (imgBlobUrl) => {
        let isMarkerOnBlock = false;
        let nextBlockStartTime = null;

        shapes.forEach(shape => {
            if (markerXPosition >= shape.start && markerXPosition <= shape.end) {
                isMarkerOnBlock = true;
            } else if (shape.start > markerXPosition && (nextBlockStartTime === null || shape.start < nextBlockStartTime)) {
                nextBlockStartTime = shape.start;
            }
        });

        if (!isMarkerOnBlock) {
            const newBlockStart = Math.max(0, markerXPosition);
            let newBlockEnd = nextBlockStartTime !== null ? nextBlockStartTime : scaleLength;

            if (nextBlockStartTime === null) {
                newBlockEnd = Math.min(newBlockStart + 5, scaleLength);
            }

            const newImageShape = {
                id: new Date().getTime(), // Unique id
                start: newBlockStart,
                end: newBlockEnd,
                text: '', // Empty text for new image shape
                x: 100, // Initial position
                y: 70, // Initial position
                width: 100, // Initial width
                height: 100, // Initial height
                fill: "transparent", // Transparent fill
                isImg: true, // Flag to indicate that this is an image
                shapeImg: imgBlobUrl, // Blob URL of the uploaded image
                imgBlob: null // Blob URL of the downloaded image
            };
            setShapes([...shapes, newImageShape]);
        }
    };

    // this is linked with button that will upload image as shape.
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imgBlobUrl = URL.createObjectURL(file);
            handleAddImageAsShape(imgBlobUrl); // Add the image as a new shape
        }
        // Reset the value of the input after handling the file
        event.target.value = '';
    };

    ////////////////////////////// Shape layer work above /////////////////////////////////////////

    ////////////////////////////// VoiceOver layer work below /////////////////////////////////////////
    const voiceOverLayerYPosition = videoLayerYPosition + videoTrackHeight + spaceBetweenLayers + voiceOverLayerHeight;
    // const voiceOverLayerYPosition = rulerHeight + captionLayerHeight + spaceBetweenLayers + 5;


    const createVoiceOverBlock = (startTime, duration) => ({
        id: `track-${Date.now()}`,
        originalStartTime: startTime,
        originalDuration: duration,
        originalEndTime: startTime + duration,
        modifiedStartTime: startTime,
        modifiedDuration: duration,
        modifiedEndTime: startTime + duration,
        preTrimStartTime: startTime,
        preTrimEndTime: startTime + duration,
        voiceOverTrimDuration: duration,
        trimDurationAmount_L: 0,
        trimDurationAmount_R: 0,
    });

    const addNewVoiceOverBlockBasedOnMarker = (markerTimestamp, newBlockDuration, voiceOverTracks) => {
        // Sort tracks by start time
        const sortedTracks = [...voiceOverTracks].sort((a, b) => a.modifiedStartTime - b.modifiedStartTime);

        // Find index of the block where the marker is and the next block
        const currentBlockIndex = sortedTracks.findIndex(track =>
            markerTimestamp >= track.modifiedStartTime && markerTimestamp <= track.modifiedEndTime
        );

        // If the marker is on an existing block, add the new block after the last block
        if (currentBlockIndex !== -1) {
            const lastBlock = sortedTracks[sortedTracks.length - 1];
            return createVoiceOverBlock(lastBlock.modifiedEndTime, newBlockDuration);
        }

        // If marker is not on an existing block, check available space between nearest blocks
        if (currentBlockIndex === -1) {
            const leftBlock = sortedTracks.reduce((acc, block) => block.modifiedEndTime <= markerTimestamp ? block : acc, null);
            const rightBlock = sortedTracks.find(block => block.modifiedStartTime > markerTimestamp);

            // If there's enough space between left and right blocks, add new block after left block
            if (leftBlock && rightBlock && rightBlock.modifiedStartTime - leftBlock.modifiedEndTime >= newBlockDuration) {
                return createVoiceOverBlock(leftBlock.modifiedEndTime, newBlockDuration);
            }
        }

        // Add the new block after the last block or at the start
        const lastBlock = sortedTracks[sortedTracks.length - 1];
        const newBlockStartTime = lastBlock ? lastBlock.modifiedEndTime : 0;

        return createVoiceOverBlock(newBlockStartTime, newBlockDuration);
    };

    const handleVoiceOverUpload = async (event) => {
        const files = Array.from(event.target.files);
        const MAX_DURATION_ALLOWED = 5400;

        try {
            const newTracks = await Promise.all(files.map(async (file, index) => {
                const duration = await getVoiceOverDuration(file); // This function gets the duration of the voiceOver file

                // Check if the voiceOver duration exceeds the maximum allowed duration
                if (duration > MAX_DURATION_ALLOWED) {
                    console.log(`Maximum allowed voiceOver duration is 90 minutes. The uploaded file exceeds this limit.`);
                    return null;
                }

                // Calculate total duration of all existing plus new voiceOver blocks
                const totalDurationWithNewBlock = voiceOverTracks.reduce((total, track) => total + track.modifiedDuration, duration);

                if (totalDurationWithNewBlock > MAX_DURATION_ALLOWED) {
                    console.log("Exceeds maximum allowed duration when combined with existing blocks.");
                    return null; // Skip adding this block
                }

                if (totalDurationWithNewBlock > videoFile.duration) {
                    // Adjust the scale length based on the total duration
                    adjustScaleLength(totalDurationWithNewBlock);
                }

                const markerTimestamp = markerXPosition; // The current position of the marker on the scale
                const newBlock = addNewVoiceOverBlockBasedOnMarker(markerTimestamp, duration, voiceOverTracks);

                if (newBlock) { // Only proceed if there is space for the new block
                    return {
                        id: `track-${Date.now()}-${index}`,
                        file,
                        originalStartTime: newBlock.originalStartTime,
                        originalDuration: duration,
                        originalEndTime: newBlock.originalEndTime,
                        modifiedStartTime: newBlock.modifiedStartTime,
                        modifiedDuration: duration,
                        modifiedEndTime: newBlock.modifiedEndTime,
                        preTrimStartTime: newBlock.originalStartTime,
                        preTrimEndTime: newBlock.originalEndTime,
                        voiceOverTrimDuration: duration,
                        trimDurationAmount_L: 0,
                        trimDurationAmount_R: 0,
                    };
                } else {
                    return null;
                }
            }));

            // Filter out any null values and add new tracks to the state
            const updatedTracks = [...voiceOverTracks, ...newTracks.filter(track => track !== null)];

            // Sort the updated tracks array by start time
            updatedTracks.sort((a, b) => a.modifiedStartTime - b.modifiedStartTime);
            setVoiceOverTracks(updatedTracks);

            ////// Calculate the longest end time among music, voice-over, and video
            const longestMusicEndTime = Math.max(...musicTracks.map(track => track.modifiedEndTime));
            const longestVoiceOverEndTime = Math.max(...updatedTracks.map(track => track.modifiedEndTime));
            const calculatedLongestDuration = Math.max(videoDuration, longestMusicEndTime, longestVoiceOverEndTime);
            // Adjust the scale length if necessary
            if (calculatedLongestDuration > videoFile.duration) {
                adjustScaleLength(calculatedLongestDuration);
            }

        } catch (error) {
            console.error('An error occurred while uploading files:', error);
            // Handle the error appropriately in the UI
        }
    };

    const getVoiceOverDuration = (file) => {
        return new Promise((resolve, reject) => {
            const audio = new Audio();
            audio.src = URL.createObjectURL(file);

            audio.addEventListener('loadedmetadata', () => {
                resolve(audio.duration);
                URL.revokeObjectURL(audio.src); // Clean up memory
            });

            audio.addEventListener('error', () => {
                reject('Error loading audio file');
            });
        });
    };
    ////////////////////////////// VoiceOver layer work above /////////////////////////////////////////

    ////////////////////////////// Interactive layer work below /////////////////////////////////////////

    const togglePlayPause = () => {
        if (mediaPlayerRef && mediaPlayerRef.current) {
            // mediaPlayerRef.current.toggle()
            if (!mediaPlayerRef.current.isPlaying()) {
                mediaPlayerRef.current.play()
                // Pause both the video and the audio if they are playing
                // if (mediaPlayerRef.current.isPlaying() === false) mediaPlayerRef.current.play();
                // if (audioRef && audioRef.current && audioRef.current.paused === false) audioRef.current.pause();
                setIsPlaying(!mediaPlayerRef.current.isPlaying());
            } else {
                mediaPlayerRef.current.pause()

                // Play both the video and the audio if they are paused
                // if (mediaPlayerRef.current.isPlaying() === true) mediaPlayerRef.current.pause();
                // if (audioRef && audioRef.current && audioRef.current.paused === true) audioRef.current.play();
                // setIsPlaying(false);
                setIsPlaying(!mediaPlayerRef.current.isPlaying());

            }
        }
    };



    const [selectedShapeId, setSelectedShapeId] = useState(null);
    const shapeStageRef = useRef(null);

    const frames = [];
    const captureFrames = async () => {

        const node = document.getElementById('imagesShape');

        const frameRate = 900;
        const totalFrames = 1800;

        let framesCaptured = 0;

        const intervalId = setInterval(async () => {

            if (framesCaptured >= totalFrames) {
                clearInterval(intervalId);
                return;
            }

            const dataUrl = await htmlToImage.toPng(node);
            frames.push(dataUrl);

            framesCaptured++;

        }, 1000 / frameRate);

    };

    const countFrames = () => {
        console.log("images length is: ", frames.length)
    }

    // function to download the shapes as png file
    const downloadShapeAsImage = (shapeId) => {
        const group = shapeStageRef.current.findOne(`#group-${shapeId}`);
        // temporary turning off the visibility of the circles and lines for the sake of images. 
        const circles = group.find('Circle');
        const lines = group.find('Line');
        // turn their visibility off
        circles.forEach(circle => circle.visible(false));
        lines.forEach(line => line.visible(false));

        if (group) {
            group.toImage({
                width: group.getClientRect().width,
                height: group.getClientRect().height,
                callback: (img) => {
                    // Ensure img is not null and has src
                    if (img) {
                        const imgBlobUrl = img.src;
                        // Update state with the new blob URL
                        setShapes(prevShapes => prevShapes.map(shape => {
                            if (shape.id === shapeId) {
                                return { ...shape, imgBlob: imgBlobUrl };
                            }
                            return shape;
                        }));
                        const anchor = document.createElement('a');
                        anchor.href = img.src;
                        anchor.download = `${shapeId}.png`;
                        document.body.appendChild(anchor);
                        anchor.click();
                        document.body.removeChild(anchor);
                        // ...resetting the visibility logic
                    } else {
                        console.error("Image generation failed for shape", shapeId);
                    }
                }
            });
        }
        // resetting the visibility of the circles and lines back
        circles.forEach(circle => circle.visible(true));
        lines.forEach(line => line.visible(true));
    };

    // this function download shapes.. as png file
    const handleProceed = () => {
        shapes.forEach(shape => {
            downloadShapeAsImage(shape.id);
        });
    };

    // // This function makes the shape color transparent
    const makeShapeTransparent = () => {
        if (selectedShapeId != null) {
            setShapes(shapes.map(shape => {
                if (shape.id === selectedShapeId) {
                    return { ...shape, fill: 'transparent' };
                }
                return shape;
            }));
        }
    };
    ////////////////////////////// Interactive layer work above /////////////////////////////////////////

    return (
        <div className='container-timeline' style={{ border: "1px solid #DDD", position: 'fixed', bottom: 0, backgroundColor: "white", width: "100%", marginBottom: isXs ? '60px' : "0px" }}>
            <div>
                {/* <p style={{}}>{currentFrame}</p> */}
                {/* <div style={{ position: 'relative', }}> */}
                {/* video player component */}
                {/* <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
                        <VideoPlayer 
                                // src="./MarkCuban.mp4" 
                                src={videoSrc}
                                currentTime={videoCurrentTime} 
                                onTimeUpdate={handleVideoTimeUpdate} 
                                onDurationChange={handleVideoDurationChange}
                        />
                    </div> */}
                {/* <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 2 }}>
                        <InteractiveLayer width={640} height={360} shapes={shapes} 
                            setShapes={setShapes} selectedShapeId={selectedShapeId} setSelectedShapeId={setSelectedShapeId}
                            stageRef={shapeStageRef}
                            // videoSrc={videoSrc}
                        />
                    </div> */}
                {/* <div>
                        <VideoOverlay 
                            shapes={shapes}
                            setShapes={setShapes}
                            videoSrc={videoSrc}
                        />
                    </div> */}
                {/* </div> */}

                {/* <div style={{ position: "relative", margin: "50px" }}></div>
                <div>
                    <button onClick={captureFrames}>Start Capture</button>
                </div>
                <div>
                    <button onClick={countFrames}>Count frames</button>
                </div>
                <div>
                    <input
                        className="shape-text-input"
                        type="text"
                        value={selectedShapeId ? shapes.find(shape => shape.id === selectedShapeId)?.text || '' : ''}
                        onChange={(e) => {
                            const newText = e.target.value;
                            setShapes(shapes.map(shape => {
                                if (shape.id === selectedShapeId) {
                                    return { ...shape, text: newText };
                                }
                                return shape;
                            }));
                        }}
                        disabled={!selectedShapeId}
                    />
                    <button onClick={handleProceed}>Proceed</button>
                    <button
                        onClick={makeShapeTransparent}
                        disabled={selectedShapeId == null}
                    >
                        Transparent Shape
                    </button>
                    <button onClick={() => fileInputRef.current && fileInputRef.current.click()}>
                        Upload Image Sh
                    </button>
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleImageUpload}
                    />
                </div> */}
            </div>


            {/* <div style={{ position: 'relative', height: 30 }}></div> */}
            <div ref={mainContainerRef}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: 50, backgroundColor: 'white', flexWrap: '' }}>
                    {/* <button onClick={handleButtonClick} className='add-subtitle-button' style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
                        <AddIcon sx={{ marginRight: '5px' }} />
                        {!isXs && "Add subtitle"}
                    </button> */}
                    <div style={{}}>
                        {isXs && <ZoomControl scale={scale} setScale={setScale} />}

                    </div>
                    {/* Icons and Zoom Control */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {isPlaying ? (
                            <PauseCircleIcon style={{ fontSize: 40, color: '#495253', marginRight: 10 }} onClick={togglePlayPause} />
                        ) : (
                            <PlayCircleIcon style={{ fontSize: 40, color: '#495253', marginRight: 10 }} onClick={togglePlayPause} />
                        )}
                        {!isXs && <ZoomControl scale={scale} setScale={setScale} />}
                    </div>

                    {/* Apply Button */}
                    {subtitleEditable && subtitleEditable[videoFile.video_id] && !disabled  ?
                        <div onClick={updateCaption} className="apply-button" style={{ marginRight: 30 }}>
                            {isXs ? <SaveIcon htmlColor='white'></SaveIcon> : <p className='text'>Apply Changes</p>}
                        </div> : <div></div>}
                </div>



                <div className="scroll-container"
                    ref={scrollContainerRef}
                    style={{
                        overflowX: 'auto',
                        overflowY: 'auto',
                        width: '100%',
                        border: '1px solid #F0F0F0',
                        backgroundColor: "white",
                        minHeight: 100,
                    }}
                    onScroll={handleScroll}
                >
                    {/* <Stage width={containerWidth} height={(Object.keys(layers).length * (layerHeight + spaceBetweenLayers)) + captionLayerHeight} draggable={false}> */}
                    <Stage onClick={handleTimelineClick} ref={mainContainerRef} width={containerWidthWithPadding} height={calculateContainerHeight()} draggable={false}>
                        {/* Ruler and captions are independent of layers */}
                        {/* <Layer>
                    <Rect
                        x={0}
                        y={0}
                        width={containerWidthWithPadding}
                        height={rulerHeight}
                        fill="white"
                    />
                    {createRulerLines()}
                </Layer> */}
                        <Layer>
                            <Rect
                                x={0}
                                y={0}
                                width={containerWidthWithPadding}
                                height={rulerHeight}
                                fill="white"
                            />
                            {createRulerLines()}
                            {createCaptionBlocks()}
                            {createMarkerWithTriangle(markerXPosition, setMarkerXPosition, padding, scale, containerWidthWithPadding, containerHeight)}
                        </Layer>
                        {/* Video layer */}
                        <Layer y={videoLayerYPosition}>
                            <VideoLayer videoTrackHeight={videoTrackHeight} />
                        </Layer>
                        {/* Music layer */}
                        <Layer y={musicLayerYPosition}>
                            <MusicLayer tracks={musicTracks} />
                        </Layer>
                        {/* Shape and TTS layer */}
                        {/* <Layer y={shapeLayerYPosition}> */}
                        <Layer>
                            <TimelineShapeLayer
                                scale={scale}
                                scrollContainerRef={scrollContainerRef}
                                shapeLayerYPosition={shapeLayerYPosition}
                                videoDuration={videoDuration}
                            />
                            <VoiceOverLayer
                                scale={scale}
                                containerWidthWithPadding={containerWidthWithPadding} padding={padding}
                                mainContainerRef={mainContainerRef} voiceOverLastEndTimeRef={voiceOverLastEndTimeRef}
                                needScaleAdjustmentVoiceOverRef={needScaleAdjustmentVoiceOverRef} longestDuration={videoFile.duration}
                                adjustScaleLength={adjustScaleLength} currentVoiceOverResizeHandler={currentVoiceOverResizeHandler}
                                voiceOverTracks={voiceOverTracks} setVoiceOverTracks={setVoiceOverTracks}
                                selectedVoiceOverBlockId={selectedVoiceOverBlockId} setSelectedVoiceOverBlockId={setSelectedVoiceOverBlockId}
                                voiceOverLayerYPosition={voiceOverLayerYPosition} musicTracks={musicTracks} musicLastEndTimeRef={musicLastEndTimeRef}
                            />
                        </Layer>
                        {/* Marker layer */}
                        {/* <Layer>
                    {createMarkerWithTriangle(markerXPosition, setMarkerXPosition, padding, scale, containerWidthWithPadding, containerHeight)}
                </Layer> */}
                    </Stage>
                </div>

                {/* Slider input for controlling scroll position */}
                {/* <input
                    type="range"
                    min="0"
                    max={containerWidthWithPadding - window.innerWidth}
                    value={scrollPosition}
                    onChange={handleScrollSliderChange}
                    style={{ width: '100%', marginTop: '10px' }}
                /> */}

                {/* Editable Caption Display
            <div style={{ marginTop: '20px' }}>
            {captions.map((caption, index) => (
                <div key={index} style={{ marginBottom: '10px', border: '1px solid #ccc', padding: '10px' }}>
                <label>
                    Caption {index + 1}: 
                    <input 
                    type="text"
                    value={caption.text}
                    onChange={(e) => handleCaptionChange(caption.id, e.target.value)}
                    style={{ marginLeft: '10px' }}
                    />
                </label>
                </div>
            ))}
            </div> */}

                {/* <div>
               
                    <button>
                        <input type="file" accept=".mp3, .wav" multiple onChange={handleMusicUpload} />
                        Upload Music
                    </button>
                    <AudioPlayer src={"./aud_2.wav"} startTime={0} endTime={5} out_filename={"trimmed.mp3"}/>
                    <button>
                        <input type="file" accept="video/*" onChange={handleVideoUpload} />
                        Upload Video
                    </button>
                    <button onClick={handleAddShape} style={{ marginTop: '10px' }}>
                        Add New Shape
                    </button>
                    <button>
                        <input type="file" accept=".mp3, .wav" multiple onChange={handleVoiceOverUpload} />
                        Upload voiceOver
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default Timeline;


// Notes:
// layers.length * shapeLayerHeight is the total height of the Stage to accommodate all layers. 