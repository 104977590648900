import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { addVideoId } from "../../redux/slices/video.slice";

const Upload = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const uploadState = useAppSelector((state: RootState) => state.video.uploadState)

    console.log({uploadState})

    useEffect(() => {
        if (uploadState) {
            dispatch(addVideoId(uploadState.videoId))

            console.log(uploadState.platform == 'youtube' && uploadState.service == "transcribe")
            if (uploadState.platform == 'youtube' && uploadState.service == "aishorts") {
                navigate("/ai-shortsv2?upload=youtube")
            }
            else if (uploadState.platform == 'youtube' && uploadState.service == "transcribe") {
                navigate("/video?upload=youtube")
            }
            else if (uploadState.platform == 'youtube' && uploadState.service == "text_to_video") {
                navigate("/video?upload=youtube")
            }
        }
        // else {
        //     navigate("/")
        // }

    }, [uploadState])


    return (
        <div></div>
    )
}

export default Upload;