import React, { useRef, useState } from 'react';
import { useAppSelector } from '../hooks/redux';
import { RootState } from '../redux/store';

export interface DashboardContextType {
    videoRef: any,
    inputRef: any,
    dragActive: boolean,
    setDragActive: any,
    file: any,
    setFile: any,
    uploadTransModal: boolean,
    setUploadTransModal: any,
    language: string,
    setLanguage: any,
    modalType: string,
    setModalType: any,
    bannerModal: boolean,
    setBannerModal: any
    userId: string | null
    studioData: IStudio
    setStudioData: any
    activeButtons: any
    setActiveButtons: any
    alertModal: any
    setAlertModal: any
}

interface IStudio {
    preset: string,
    font: string,
    colors: string[],
    visuals: string,
    maxWords: number,
    fontSize: number,
    position: number

}

export const DashboardContext = React.createContext<DashboardContextType | undefined>(undefined);


const DashboardContextProvider = ({ children }: any) => {
    const videoRef = useRef<any>(null);
    const auth = useAppSelector((state: RootState) => state.auth)

    // drag state
    const [dragActive, setDragActive] = useState<boolean>(false);
    // ref
    const inputRef = useRef<HTMLInputElement>(null);

    const [file, setFile] = useState<Blob>()
    const [uploadTransModal, setUploadTransModal] = useState<boolean>(false)
    const [language, setLanguage] = useState<string>("en")
    const [modalType, setModalType] = useState<string>("language")
    const [bannerModal, setBannerModal] = useState<boolean>(false)
    const [activeButtons, setActiveButtons] = useState<any>({
        brolls_on: true,
        overlays_on: true,
        zoom_on: true,
        scene_transitions: true,
        enhanceAudio: true,
        video_filters: true,
        keyword_soundEffects_on: true,
        bg_music_on: true,
        laptop_overlay: true,
    });
    const [studioData, setStudioData] = useState({
        preset: 'transpifyspecial',
        font: 'The Bold Font',
        colors: [
            "#FFFFFF",
            "#FF0000",
            "#AA00FF",
            "#009922"
        ],
        visuals: 'emojis',
        maxWords: 3,
        fontSize: 30,
        position: 6
    })
    const [alertModal, setAlertModal] = useState<{ open: boolean, msg: string }>({
        open: false,
        msg: ""
    })


    return (
        <DashboardContext.Provider value={{ activeButtons, setActiveButtons, studioData, setStudioData, videoRef, dragActive, setDragActive, inputRef, file, setFile, uploadTransModal, setUploadTransModal, language, setLanguage, modalType, setModalType, bannerModal, setBannerModal, userId: auth.user?.id || auth.guestId, alertModal, setAlertModal }}>{children}</DashboardContext.Provider>
    )
}

export default DashboardContextProvider;