// exportSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';
import httpApi from '../../utils/httpApi2';
import { SEVER_URL2 } from '../../utils/url';
import axios from 'axios';
// import { login } from './auth.actions';

interface TittiesState {
  isLoading: boolean;
  error: string | null;
  voiceSteps: number,
  submittedVoice: number,
  phase: number,
  initials: boolean,
  title: string,
  voices: any,
  wav_output: string,
  available_voices: any,
  existing_voice_folders: any,
  total_characters: number
}

const initialState: TittiesState = {
  isLoading: false,
  error: null,
  voiceSteps: 0,
  submittedVoice: 0,
  phase: 1,
  initials: false,
  title: "",
  voices: [],
  wav_output: "",
  available_voices: [],
  existing_voice_folders: [],
  total_characters: 0
};


// me
export const uploadVoice = createAsyncThunk(
  'titties/uploadVoice',
  async (data: any) => {
    const response = await httpApi.post(`upload_audio_files`, data);
    return response.data;
  }
);


export const speechToText = createAsyncThunk(
  'titties/speechToText',
  async (data: any) => {
    const response = await httpApi.post(`convert_text_to_speech`, data);
    // const response = await axios.post(`${SEVER_URL2}convert_text_to_speech`, data, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data', // This is important for FormData
    //     // 'Content-Disposition': `form-data; name="files"; filename="voice.wav"`,
    //     // 'Content-Type': 'audio/wav;codecs=opus' // Though you mentioned WAV, your MIME type seems to be for webm with opus codec
    //   }
    // });
    return response.data;
  }
);

export const voiceData = createAsyncThunk(
  'titties/voiceData',
  async (data: any) => {
    const response = await httpApi.get(`get_voice_data/${data.user_id}`);
    return response.data;
  }
);

export const voiceDelete = createAsyncThunk(
  'titties/voiceDelete',
  async (data: any) => {
    const response = await httpApi.delete(`delete_voice_data?user_id=${data.user_id}&voice_id=${data.voice_id}`);
    return {
      data: response.data,
      voice_id: data.voice_id,
    }
  }
);

// Continuing in exportSlice.ts

const tittiesSlice = createSlice({
  name: 'titties',
  initialState,
  reducers: {
    updateVoiceSteps: (state, action: PayloadAction<any>) => {
      state.voiceSteps = action.payload
      if (action.payload == 15)
        state.phase = 2
    },
    updateSubmittedVoice: (state, action: PayloadAction<any>) => {
      if (action.payload)
        state.submittedVoice = state.submittedVoice + action.payload
      else state.submittedVoice = 0
    },
    updatePhase: (state, action: PayloadAction<any>) => {
      // state.initials = false;
      state.phase = action.payload
    },
    updatedTitties: (state) => {
      state.voiceSteps = state.submittedVoice
    },
    updateInitials: (state, action: PayloadAction<any>) => {
      state.initials = action.payload;
    },
    updateTitle: (state, action: PayloadAction<any>) => {
      state.title = action.payload
    },
    resetTitties: (state) => {
      state.voiceSteps = 0
      state.submittedVoice = 0
      state.phase = 1
      state.initials = false
      state.title = ""

    },
    contineProcessingTitties: (state, action: PayloadAction<any>) => {
      state.voiceSteps = action.payload.voiceSteps
      state.phase = action.payload.phase
      state.title = action.payload.title
      state.initials = action.payload.initials
      state.submittedVoice = action.payload.submittedVoice
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadVoice.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadVoice.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.submittedVoice = state.submittedVoice + 3
      })
      .addCase(uploadVoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(speechToText.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.wav_output = ""
      })
      .addCase(speechToText.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        if (action.payload.wav_output)
          state.wav_output = action.payload.wav_output
        else if (action.payload.wav_output == "") alert("It appears that one of your voice entries is incomplete. Please remove and re-add it to ensure proper voice training.")
      })
      .addCase(speechToText.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(voiceData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(voiceData.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.voices = action.payload.voice_ids
        state.available_voices = action.payload.available_voices
        state.existing_voice_folders = action.payload.existing_voice_folders
        state.total_characters = action.payload.total_characters
      })
      .addCase(voiceData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(voiceDelete.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(voiceDelete.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        let temp = [...state.voices]
        let ind = temp.findIndex((str: string) => str === action.payload.voice_id)
        temp.splice(ind, 1)
        state.voices = temp
      })
      .addCase(voiceDelete.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
  },
});

export const { updateVoiceSteps, updateSubmittedVoice, updatedTitties, updateInitials, updateTitle, updatePhase, resetTitties, contineProcessingTitties } = tittiesSlice.actions;
export default tittiesSlice.reducer;
