import './style.css'
import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Box, Typography, Button, Card, CardActionArea, CardMedia, CardContent, Grid, RadioGroup, FormControlLabel, Radio, Switch, useTheme, useMediaQuery } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

// ICON
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import InputField from '../TextField';
import InputTags from '../InputTags';
import LongInput from '../LongInput';
import CustomizedSelects from '../DropDown/CustomizedSelect';
import { fetchYoutubeVideoCategory } from '../../redux/slices/export.slice';
import CustomizedButtons from '../Button';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';
import { downloadVideo } from '../../utils/download';
import { logEvent, logPageView } from '../../utils/ga.utils';
import { BASE_URL } from '../../utils/url';
import ConfimationModal from '../ConfirmationModal';
import { discordWebhook } from '../../redux/slices/feedbacks.slice';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import CloseIcon from '@mui/icons-material/Close';



interface Errors {
    title?: boolean
    description?: boolean
    privacyStatus?: boolean
    tags?: boolean
}

type PrivacyStatus = 'Public' | 'Unlisted' | 'Private' | 'Friend' | 'Only Me'
type Platform = 'youtube' | 'tiktok' | 'vimeo' | 'reels' | 'device'

interface PrivacyStatusMap {
    [key: string]: PrivacyStatus[]
}

interface TabMap {
    [key: number]: Platform
}

const TabMain: TabMap = {
    0: 'youtube',
    1: 'tiktok',
    2: 'vimeo',
    3: 'reels',
    4: 'device',
}

const PrivacyStatusMain: PrivacyStatusMap = {
    youtube: ['Public', 'Unlisted', 'Private'],
    tiktok: ['Public', 'Friend', 'Private'],
    vimeo: ['Public', 'Friend', 'Only Me'],
    reels: ['Public', 'Friend'],
}


const platforms = [
    { name: 'YouTube', imagePath: '/youtube.png', isDisabled: false },
    { name: 'TikTok', imagePath: '/tiktok.png', isDisabled: true },
    { name: 'Reels', imagePath: '/reels.png', isDisabled: true },
    { name: 'Vimeo', imagePath: '/vimeo.png', isDisabled: true },
];

const VideoPublishModal: React.FC<any> = ({ currentExportUrl, DoCompression, open, handleClose, data }) => {
    const dispatch = useAppDispatch()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [privacyStatus, setPrivacyStatus] = useState('public')
    const [tags, setTags] = useState<string[]>([])
    const [categoryId, setCategoryId] = useState('')
    const [currentCaption, setCurrentCaption] = useState('')
    const [platform, setPlatform] = useState(0)
    const [accordian, setAccordian] = useState<string[]>([])
    const [errors, setErrors] = useState<Errors>({
        title: false,
        description: false,
        privacyStatus: false,
        tags: false,
    })
    const [loaderExport, setLoaderExport] = useState<boolean>(false)
    const [modalStatus, setModalStatus] = useState<boolean>(false)
    const [confirmModal, setConfirmModal] = useState<{ data: any | null, open: boolean }>({
        data: null,
        open: false
    })
    const [scheduleSwitch, setScheduleSwitch] = useState<boolean>(false)
    const [scheduleDT, setScheduleDT] = useState<Dayjs | null>(dayjs(new Date()));


    const user = useAppSelector((state: RootState) => state.auth.user)
    const isLoading = useAppSelector((state: RootState) => state.export.isLoading)
    const videoFile = useAppSelector((state: RootState) => state.video.videoFile)
    const recentProject = useAppSelector((state: RootState) => state.video.recentProject)
    const videoId = useAppSelector((state: RootState) => state.video.videoId)
    const youtubeVideoCategory = useAppSelector(
        (state: RootState) => state.export.youtubeVideoCategory,
    )

    const privacyType = useMemo(() => PrivacyStatusMain[TabMain[platform]], [
        platform,
    ])

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : "40%",
        maxWidth: '90%',
        maxHeight: '80vh', // Set maxHeight to a percentage of the viewport height
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: 'auto', // Enable vertical scrolling
    };

    const getFirstError = (): string | null => {
        for (let key in errors) {
            if (errors[key as keyof Errors]) {
                // Capitalize the first letter of the key and return the error message.
                const field = key.charAt(0).toUpperCase() + key.slice(1)
                return `${field} is required`
            }
        }
        return null
    }

    const extractHashtags = (input: string): string[] => {
        // Split the string by spaces to get individual hashtags
        const hashtags = input.split(' ');
      
        // Remove the '#' character and get only the first two hashtags
        const firstTwoHashtags = hashtags.map(tag => tag.replace('#', ''));
      
        return firstTwoHashtags;
      };

    console.log(data)
    useEffect(() => {
        if (data && data.title) {
            setTitle(data.title)
            setDescription(data.description)
            setTags(extractHashtags(data.hashtags))
        }
        else{
            setTitle("")
            setDescription("")
            setTags([])
        }
    }, [data])


    useEffect(() => {
        logPageView()
        if (!youtubeVideoCategory?.length) dispatch(fetchYoutubeVideoCategory())
    }, [])

    const ButtonDouble = ({ name, desc, onClick, disabled = false }: any) => {
        return (
            <div
                style={{
                    display: 'flex',
                    padding: 10,
                    margin: 5,
                    borderRadius: 6,
                    background: disabled ? "#CCCCCC" : "#F6F6F6", // Change background color when disabled
                    alignItems: 'center',
                    cursor: disabled ? "not-allowed" : "pointer", // Change cursor when disabled
                    pointerEvents: disabled ? "none" : "auto", // Disable pointer events when disabled
                    opacity: disabled ? 0.6 : 1 // Reduce opacity when disabled
                }}
                onClick={!disabled ? onClick : undefined} // Disable onClick handler when disabled
            >
                <p className='buttondouble-name'>{name}</p>
                <p className='buttondouble-desc'>{desc}</p>
            </div>
        );
    };




    function ExportVideoYoutube() {
        let videoURL = currentExportUrl

        let tempErrors: Errors = {}

        if (!title) tempErrors.title = true
        if (!description) tempErrors.description = true
        if (!privacyStatus) tempErrors.privacyStatus = true
        if (tags.length <= 0) tempErrors.tags = true

        setErrors(tempErrors)
        if (platform == 4) {
            setLoaderExport(true)
            downloadVideo(
                videoURL as string,
                recentProject?.find(({ video_id }: any) => video_id == videoId).name,
            )
                .then(() => {
                    setLoaderExport(false)
                })
                .catch(() => {
                    setLoaderExport(false)
                })
        }
        if (title && description && privacyStatus && tags.length > 0) {
            const now = new Date();
            now.setHours(now.getHours() + 1);
            const isoDate = now.toISOString();
            let publishAt = scheduleSwitch ? scheduleDT?.toISOString() : isoDate


            dispatch({
                type: 'export/UPDATE_EXPORT',
                payload: {
                    videoURL,
                    title,
                    description,
                    privacyStatus,
                    tags,
                    categoryId,
                    accordian,
                    ...(scheduleSwitch && { publishAt }),
                },
            })
            logEvent({
                category: 'Video',
                action: 'Export Click',
                label: TabMain[platform],
            })

            dispatch({
                type: "video/updateUploadState", payload: {
                    videoId: videoId,
                    service: "aishorts",
                    platform: TabMain[platform],
                    videoUrl: currentExportUrl
                }
            })

            setTimeout(() => {
                switch (platform) {
                    case 0:
                        window.location.replace(`${BASE_URL}/auth/google?id=${user?.id}`)
                        break
                    case 1:
                        window.location.replace(`${BASE_URL}/auth/tiktok?id=${user?.id}`)
                        break
                    case 2:
                        window.location.replace(`${BASE_URL}/auth/vimeo?id=${user?.id}`)
                        break
                    case 3:
                        window.location.replace(`${BASE_URL}/auth/instagram?id=${user?.id}`)
                        break
                    case 4:
                        setLoaderExport(true)
                        downloadVideo(
                            videoURL as string,
                            recentProject?.find(({ video_id }: any) => video_id == videoId)
                                .name,
                        )
                        setTimeout(() => {
                            setLoaderExport(false)
                        }, 3000)
                        break
                    default:
                        break
                }
            }, 1000)
        }
    }

    function formatVideoDetails(videoFile: any) {
        if (!videoFile.duration) return "3m 20s - 13 MB";

        // Convert duration from seconds to minutes and seconds
        const minutes = Math.floor(videoFile.duration / 60);
        const seconds = Math.floor(videoFile.duration % 60);

        // Check if size is defined and is a number
        if (typeof videoFile.size !== 'number' || isNaN(videoFile.size)) {
            console.error("Invalid size value:", videoFile.size);
            return `${minutes}m ${seconds}s - Size unknown`;
        }

        // Adjust the conversion if the size is already in MB
        const sizeInMB = Math.round(videoFile.size);

        // Format the string
        const formattedDuration = `${minutes}m ${seconds}s`;
        const formattedSize = `${sizeInMB} MB`;

        // Return the formatted string
        return `${formattedDuration} - ${formattedSize}`;
    }

    function clearInputFunc() {
        setTitle('');
        setDescription('');
        setPrivacyStatus('public');
        setTags([]);
        setCategoryId('');
        setCurrentCaption('');
        setAccordian([]);
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyle}>
                <div style={{ position: 'absolute', right: 20, top: 15 }}>
                    <CloseIcon className="close-icon" onClick={handleClose} />
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div>
                        <Grid container spacing={1} >
                            {/* <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <ArrowBackIcon onClick={() => setModalStatus(false)} />
                            </Grid> */}
                            <Grid item xs={11} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography variant="h6" className='title-platform'>
                                    Publish to {platforms[platform].name}
                                </Typography>
                            </Grid>
                            {platform != 4 && (
                                <>
                                    {' '}
                                    <Grid item xs={12} sm={3} sx={{ marginTop: '10px' }}>
                                        <Typography className="overlay-title">
                                            Video Title*
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <InputField
                                            fullWidth
                                            error={errors.title}
                                            value={title}
                                            onChange={(e: any) => setTitle(e.target.value)}
                                        />
                                    </Grid>
                                </>
                            )}
                            {platform != 4 && (
                                <>
                                    <Grid item xs={12} sm={3}>
                                        <Typography className="overlay-title">Tags*</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <InputTags
                                            error={errors.tags}
                                            setTags={setTags}
                                            tags={tags}
                                        />
                                    </Grid>
                                </>
                            )}
                            {platform != 4 && (
                                <>
                                    <Grid item xs={12} sm={3}>
                                        <Typography className="overlay-title">
                                            Description*
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <LongInput
                                            error={errors.description}
                                            fullWidth
                                            onChange={(e: any) => setDescription(e.target.value)}
                                            multiline
                                            value={description}
                                            maxRows={4}
                                            variant="standard"
                                        />
                                    </Grid>
                                </>
                            )}
                            {platform != 4 && (
                                <>
                                    <Grid item xs={12} sm={3}>
                                        <Typography className="overlay-title">
                                            Privacy*
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            {privacyType?.map((value: string) => (
                                                <FormControlLabel
                                                    value={value}
                                                    key={value}
                                                    control={
                                                        <Radio
                                                            checked={value == privacyStatus}
                                                            onChange={(e) =>
                                                                setPrivacyStatus(e.target.value)
                                                            }
                                                            sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 10,
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    sx={{
                                                        '& .MuiTypography-root': {
                                                            color: '#4B4B4B',
                                                            fontFamily: 'Poppins',
                                                            fontSize: 14,
                                                            fontStyle: 'normal',
                                                            fontWeight: 400 /* 316.667% */,
                                                            letterSpacing: -0.14,
                                                        },
                                                    }}
                                                    label={value}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </Grid>
                                </>
                            )}
                            {platform == 0 && (
                                <>
                                    <Grid item xs={12} sm={3}>
                                        <Typography className="overlay-title">
                                            Category
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <CustomizedSelects
                                            fullWidth
                                            defaultValue=""
                                            handleChange={(e: any) =>
                                                setCategoryId(e.target.value)
                                            }
                                            items={
                                                youtubeVideoCategory?.map(
                                                    ({ snippet }: { snippet: any }) => ({
                                                        name: snippet.title,
                                                        value: snippet.channelId,
                                                    }),
                                                ) || []
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography className="overlay-title">
                                            Schedule
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Switch onChange={() => setScheduleSwitch(!scheduleSwitch)} value={scheduleSwitch} />

                                        {scheduleSwitch && <DateTimePicker value={scheduleDT} onChange={(val) => {
                                            setScheduleDT(val)
                                        }} />}
                                    </Grid>
                                </>
                            )}
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'flex-end',
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                }}
                            >
                                <a onClick={clearInputFunc} className="clear-button">Clear</a>
                                <Typography
                                    sx={{ color: 'red', fontSize: 12, fontFamily: 'Inter' }}
                                >
                                    {platform !== 4 && getFirstError()}
                                </Typography>
                                <CustomizedButtons
                                    disabled={isLoading || loaderExport}
                                    loading={loaderExport}
                                    onClick={ExportVideoYoutube}
                                    name="Export"
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                        <ConfimationModal open={confirmModal.open} onClick={DoCompression} onClose={() => setConfirmModal({ data: null, open: false })} title="Confirmation" text="You want to Compress Video?" />
                    </div>
                </LocalizationProvider>
            </Box>
        </Modal>
    );
};

export default VideoPublishModal;
