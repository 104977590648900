import { useEffect } from 'react';
import { Modal, Box, Typography, useMediaQuery, useTheme, } from '@mui/material'

const BannerModal = ({ open, text, setOpen, duration }: any) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : 600,
        maxWidth: '90%',
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                setOpen(false)
            }, Number(duration));
        }
    }, [open])


    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)", // This is the CSS property that blurs the background
                }
            }}
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                    color: "#4A4A4A",
                    textAlign: "center",
                    fontFamily: "Nunito",
                    fontSize: "22px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                }}>
                    <p>  {text}</p>
                </Typography>
            </Box>
        </Modal>
    );
}

export default BannerModal;
