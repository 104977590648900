// authSlice.ts

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { discordWebhook } from './feedbacks.slice';
import { getProfile } from './profile.slice';
// import { login } from './auth.actions';

export interface IGooglePassport {
  id: string;
  sync: boolean;
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
}

interface ISubscriptionPlan {
  id: string,
  name: string,
  amount: number
}

export interface IUser {
  // isModified(arg0: string): unknown;
  id: string;
  username: string;
  fullName: string;
  email: string;
  verificationCode: string;
  isVerified: boolean;
  password: string;
  resetPasswordToken?: string;
  resetPasswordExpires?: Date;
  stripeCustomerId: string;
  stripePaymentMethod: string;
  referral: string;
  referredBy?: string;
  reward: number;
  google: IGooglePassport;
  vimeo: IGooglePassport;
  tiktok: IGooglePassport;
  role: string;
  active: boolean;
  pictureUrl: string;
  pictureBlob: string;
  lastLoginDate: Date;
  subscriptionStatus: string;
  subscriptionPlan: ISubscriptionPlan
  notification: {
    fcmPermission: string;
    firebaseMessageToken: string;
  };
  // messages: IUserMessages[];
  featureFlags?: {
    [key: string]: string;
  };
}

interface AuthState {
  token: string | null;
  email: string | null;
  isLoading: boolean;
  error: string | null;
  user: IUser | null;
  isAuthenticated: boolean;
  resetPassword: string | null;
  paymentMethod: any[] | null;
  guestId: string | null;
  t_token: string | null
}

const initialState: AuthState = {
  token: null,
  email: null,
  isLoading: false,
  error: null,
  user: null,
  isAuthenticated: false,
  resetPassword: null,
  paymentMethod: null,
  guestId: null,
  t_token: null
};

export interface SerializedError {
  name?: string
  message?: string
  stack?: string
  code?: string
}

export const thunkHandler = (asyncFn: { (userCreds: any): Promise<any>; (arg0: any, arg1: any): any; }) => async (arg: any, thunkAPI: { rejectWithValue: (arg0: any) => any; }) => {
  try {
    const response = await asyncFn(arg, thunkAPI);
    return response.data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
};

// export const login = createAsyncThunk(
//   'auth/login',
//   thunkHandler(async userCreds => api.post("/auth/login'", userCreds))
// )

export const getToken = createAsyncThunk(
  'auth/getToken',
  async (data: any) => {
    const response = await api.post('/auth/token', { userId: data });
    return response.data;
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (user, thunkApi) => {
    return api.post('https://api.transpify.com/api/v1/users/signIn', user)
      .then(response => response.data.results)
      .catch(error => thunkApi.rejectWithValue(error?.response?.data || error))
  }
)

// guestUser
export const guestUser = createAsyncThunk(
  'auth/guestUser',
  async (data: any, thunkApi: any) => {
    const response = await api.post('/guest/create', data);

    thunkApi.dispatch(getToken(response.data.guestId))
    thunkApi.dispatch(discordWebhook(`New Guest User ${response.data.guestId}`))

    return response.data;
  }
);

// Login
// export const login = createAsyncThunk(
//   'auth/login',
//   async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
//     try {
//       const result = await api.post('/auth/login', { email, password });
//       return result.data;
//     } catch (err: any) {
//       const customError = {
//         name: "Custom axios error",
//         message: err.response.statusText,
//         data: err.response.data // serializable
//       };
//       throw customError;
//     }
//   }
// )

// Signup
export const signup = createAsyncThunk(
  'auth/signup',
  async (data: { email: string; password: string; fullname: string, }, thunkApi: any) => {
    const response = await api.post('/auth/signup', data);
    thunkApi.dispatch(discordWebhook("Signup"))
    return response.data;
  }
);

// Email Verification
export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (data: { email: string, code: string }) => {
    const response = await api.post(`/auth/verify-email`, data);
    return response.data;
  }
);

// me
export const me = createAsyncThunk(
  'auth/me',
  async () => {
    const response = await api.get(`/auth/me`);
    return response.data;
  }
);

// Forgot Password
export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email: string, thunkApi: any) => {
    const response = await api.post('/auth/forgot-password', { email });
    thunkApi.dispatch(discordWebhook("Forgot Password"))

    return response.data;
  }
);

// PaymentIntent
export const PaymentIntent = createAsyncThunk(
  'auth/PaymentIntent',
  async (customerId: string) => {
    const response = await api.get('/pay/payment-intent', { params: { customerId } });
    return response.data;
  }
);

// PaymentIntent
export const RemovePaymentMethod = createAsyncThunk(
  'auth/RemovePaymentMethod',
  async (data: any, thunkApi: any) => {
    const response = await api.post('/pay/remove-payment-method', data);
    thunkApi.dispatch(PaymentMethod(data.customerId))

    return response.data;
  }
);

// PaymentIntent
export const RetrieveCard = createAsyncThunk(
  'auth/RetrieveCard',
  async (customerId: string) => {
    const response = await api.get('/pay/retrieve-card', { params: { customerId } });
    return response.data;
  }
);


// PaymentMethod
export const PaymentMethod = createAsyncThunk(
  'auth/PaymentMethod',
  async (customerId: string) => {
    const response = await api.get('/pay/payment-method', { params: { customerId } });
    return response.data;
  }
);

// AttachPaymentMethod
export const AttachPaymentMethod = createAsyncThunk(
  'auth/AttachPaymentMethod',
  async (data: any, thunkApi: any) => {
    const response = await api.post('/pay/attach-payment-method', data);
    return response.data;
  }
);

// PaymentMethod
export const AddPaymentMethod = createAsyncThunk(
  'auth/PaymentMethod',
  async (data: any, thunkApi: any) => {
    const response = await api.post('/pay/payment-method', data);
    thunkApi.dispatch(getProfile(data.userId))
    thunkApi.dispatch(PaymentMethod(data.customerId))
    return response.data;
  }
);


// Continuing in authSlice.ts

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogout: (state, action: PayloadAction<string>) => {
      localStorage.removeItem("token")
      state.token = null;
      state.email = null;
      state.user = null;
      state.error = null;
      state.isAuthenticated = false
    },
    resetAuthError: (state) => {
      state.error = null;
      state.resetPassword = null
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    Login_Pending: (state, action: PayloadAction<any | null>) => {

      state.isLoading = true;
      state.error = null;
      state.isAuthenticated = false
    },
    Login_Success: (state, action: PayloadAction<any | null>) => {
      console.log("login success", action.payload)
      localStorage.setItem('token', action.payload.token)
      state.isLoading = false;
      state.isAuthenticated = true
      state.user = action.payload?.data?.user
      state.email = action.payload?.data?.user?.email;
    },
    Login_Error: (state, action: PayloadAction<{ err: any }>) => {
      state.isLoading = false;
      state.error = action.payload?.err?.message || 'Something went wrong!';
      state.isAuthenticated = false
    },
    Signup_Pending: (state, action: PayloadAction<any | null>) => {
      state.isLoading = true;
      state.error = null;
      state.isAuthenticated = false
    },
    //654250688cff38da840b138b
    Signup_Success: (state, action: PayloadAction<any | null>) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.token = action.payload?.token;
      state.user = action.payload?.data?.user
      state.email = action.payload?.data?.user?.email;
    },
    Signup_Error: (state, action: PayloadAction<{ err: any }>) => {
      state.isLoading = false;
      state.error = action.payload?.err?.message || 'Something went wrong!';
      state.isAuthenticated = false
    },

    Recover_Password_Pending: (state, action: PayloadAction<any | null>) => {
      state.isLoading = true;
      state.error = null;
    },
    Recover_Password_Success: (state, action: PayloadAction<any | null>) => {
      state.isLoading = false;
      state.resetPassword = 'pending'
    },
    Recover_Password_Error: (state, action: PayloadAction<{ err: any }>) => {
      state.isLoading = false;
      state.error = action.payload?.err?.message || 'Something went wrong!';
    },
    Reset_Password_Pending: (state, action: PayloadAction<any | null>) => {
      state.isLoading = true;
      state.error = null;
    },
    Reset_Password_Success: (state, action: PayloadAction<any | null>) => {
      state.isLoading = false;
      state.resetPassword = 'success'
    },
    Reset_Password_Error: (state, action: PayloadAction<{ err: any }>) => {
      state.isLoading = false;
      state.error = action.payload?.err?.message || 'Something went wrong!';
    },
    Change_Password_Pending: (state, action: PayloadAction<any | null>) => {
      state.isLoading = true;
      state.error = null;
    },
    Change_Password_Success: (state, action: PayloadAction<any | null>) => {
      state.isLoading = false;
    },
    Change_Password_Error: (state, action: PayloadAction<{ err: any }>) => {
      state.isLoading = false;
      state.error = action.payload?.err?.message || 'Something went wrong!';
    },
    updateUser: (state, action: any) => {
      state.user = action.payload
    },
    userUpdate: (state, action: any) => {
      state.user = { ...state.user, ...action.payload }
    },
    removeToken: (state, action: any) => {
      state.token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidXNlciIsImlhdCI6MTcxNDU5NDc5NSwiZXhwIjoxNzE0NTk4Mzk1LCJhdXRob3JpemVkIjp0cnVlfQ.YEUVRn_YMQu8NNf57NmzYOjuINLXoVX8JQqD3HUQEuw'
    }
  },
  extraReducers: (builder) => {
    builder
      // .addCase(login.pending, (state) => {

      //   state.isLoading = true;
      //   state.error = null;
      //   state.isAuthenticated = false
      // })
      // .addCase(login.fulfilled, (state, action: PayloadAction<{ token: string, data: any }>) => {
      //   console.log({ action })

      //   localStorage.setItem('token', action.payload.token)

      //   state.isLoading = false;
      //   state.token = action.payload.token;
      //   state.user = action.payload.data.user
      //   state.email = action.payload.data.user.email;
      //   state.isAuthenticated = true
      // })
      // .addCase(login.rejected, (state, action) => {
      //   console.log({ action })
      //   state.isLoading = false;
      //   state.error = action.error.message || 'Failed to login';
      //   state.isAuthenticated = false
      // })
      // .addCase(signup.pending, (state) => {
      //   state.isLoading = true;
      //   state.error = null;
      //   state.isAuthenticated = false
      // })
      // .addCase(signup.fulfilled, (state, action: PayloadAction<{ token: string, data: any }>) => {
      //   state.isLoading = false;
      //   state.token = action.payload.token;
      //   state.user = action.payload.data.user
      //   state.email = action.payload.data.user.email;
      //   state.isAuthenticated = false
      // })
      // .addCase(signup.rejected, (state, action) => {
      //   console.log({ action })
      //   state.isLoading = false;
      //   state.error = action.error.message || 'Failed to signup';
      //   state.isAuthenticated = false
      // })
      .addCase(guestUser.pending, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(guestUser.fulfilled, (state, action: PayloadAction<{ guestId: string }>) => {
        state.isLoading = false;
        state.guestId = action.payload.guestId
        // state.isAuthenticated = true
      })
      .addCase(guestUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to verifyEmail';
        // state.isAuthenticated = false
      })
      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isAuthenticated = false
      })
      .addCase(verifyEmail.fulfilled, (state, action: PayloadAction<{ token: string, data: any }>) => {
        state.isLoading = false;
        state.user = action.payload.data.user
        state.isAuthenticated = true
        state.guestId = null;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to verifyEmail';
        state.isAuthenticated = false
      })
      .addCase(me.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(me.fulfilled, (state, action: PayloadAction<{ token: string, data: any }>) => {
        state.isLoading = false;
        if (action.payload.data.user == null) {
          state.isAuthenticated = false
          state.email = null;
          state.user = null;
          state.error = null;
        }
        else {
          state.user = action.payload.data.user
          state.email = action.payload.data.user.email;
          state.isAuthenticated = true
        }
      })
      .addCase(me.rejected, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false
        state.email = null;
        state.user = null;
        state.error = null;
        state.t_token = null;
      })
      .addCase(PaymentMethod.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(PaymentMethod.fulfilled, (state, action: PayloadAction<{ data: any }>) => {
        state.isLoading = false;
        state.paymentMethod = action.payload.data

      })
      .addCase(PaymentMethod.rejected, (state, action) => {
        state.isLoading = false;
        state.paymentMethod = null
      })
      .addCase(AttachPaymentMethod.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(AttachPaymentMethod.fulfilled, (state, action: PayloadAction<{ data: any }>) => {
        state.isLoading = false;

      })
      .addCase(AttachPaymentMethod.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getToken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getToken.fulfilled, (state, action: PayloadAction<{ token: any }>) => {
        state.isLoading = false;
        state.t_token = action.payload.token;
      })
      .addCase(getToken.rejected, (state, action: any) => {
          state.isLoading = false;
          state.isAuthenticated = false
          state.email = null;
          state.user = null;
          state.error = null;
          state.isAuthenticated = false
          state.t_token = null
      })
      .addCase(RemovePaymentMethod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(RemovePaymentMethod.fulfilled, (state, action: PayloadAction<{ token: any }>) => {
        state.isLoading = false;

      })
      .addCase(RemovePaymentMethod.rejected, (state, action) => {
        state.isLoading = false;
      })
    //   ... You can handle other actions similarly: signup, verifyEmail, forgotPassword
  },
});

export const { resetAuthError } = authSlice.actions;
export default authSlice.reducer;
