import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import './style.css'
import { Container, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio, RadioGroup, TextField, ToggleButton, ToggleButtonGroup, Tooltip, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import Header from '../Header';

import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';
import { getVideoFiles } from '../../redux/slices/video.slice';
import { logEvent, logPageView } from '../../utils/ga.utils';
import ShortsSlider from '../../components/ShortsSlider';
import GradientButton from '../../components/Button/gradient';
import { downloadVideo } from '../../utils/download';
import VideoPublishModal from '../../components/VideoPublishModal/modal';
import useExport from '../../hooks/useExport';
import { uploadReelVideo, uploadTiktokVideo, uploadVimeoVideo } from '../../redux/slices/export.slice';
import LanguageModal from '../../components/LanguageModal';
import VideoProgress from '../../components/ProgressVideo';
import { VideoContext } from '../../context/video.context';
import axios from 'axios';

type JsonShortType = {
    [key: number]: any;
};

type VideoFileType = {
    urls: {
        edited_json_uris: string[];
        output_rendered_videos: string[];
    };
};

const AIShorts = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    const [searchParams] = useSearchParams();
    const { videoIndex } = useParams()

    const { youtubeExportUpload } = useExport()
    const contextValue = useContext(VideoContext);

    if (!contextValue) {
        throw new Error("VideoContext value is not provided");
    }

    const { jsonAIStudio, setJsonAIStudio } = contextValue;

    const aishortsIndex = useAppSelector((state: RootState) => state.video.aishortsIndex);
    const userId = useAppSelector((state: RootState) => state.auth.user?.id)
    const videoFile = useAppSelector((state: RootState) => state.video.videoFile)
    const isLoading = useAppSelector((state: RootState) => state.video.isLoading)
    const videoId = useAppSelector((state: RootState) => state.video.videoId)
    const exportField = useAppSelector((state: RootState) => state.export.exportField)

    const currentService: any = useMemo(() => videoFile && videoFile.active_service?.length > 0 && videoFile.active_service[0], [videoFile])
    const aishortsIndexValue = useMemo(() => {
        let i = aishortsIndex?.[videoFile?.video_id_old || 0]
        return i
    }, [videoFile, aishortsIndex])


    const [open, setOpen] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState(" Go Back");
    const [captions, setCaptions] = useState<{ text: string } | null>(null)
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [jsonShort, setJsonShort] = useState<JsonShortType>({})
    const [selectedPlatform, setSelectedPlatform] = useState(-1)
    const [publishModal, setPublishModal] = useState(false)
    const editedVideoUrl = useMemo(() => videoFile?.urls?.output_rendered_videos?.[videoIndex || videoId?.split("-")?.[1] || 0], [videoIndex, videoFile])


    const [videoKey, setVideoKey] = useState(0);

    // Update the key whenever the video URL changes to force re-render
    useEffect(() => {
        setVideoKey(prevKey => prevKey + 1);
    }, [editedVideoUrl]);


    const derivedShort = useMemo(
        () => videoFile?.urls?.output_rendered_videos?.[activeIndex],
        [activeIndex, videoFile]
    );

    useEffect(() => {
        if (videoId == null) navigate("/")
        if (videoFile == null)
            dispatch(getVideoFiles({ userId: userId, videoId }))

    }, [videoId, videoFile])

    useEffect(() => {
        dispatch(getVideoFiles({ userId: userId, videoId }))
        logPageView()
    }, [])


    const returnIcon = (txt: string) => {
        switch (txt) {
            case 'Go Back':
                return <ReplyIcon />
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (videoFile?.urls?.edited_json_uris[videoIndex || aishortsIndexValue]) {
                    const { data } = await axios.get(videoFile?.urls?.edited_json_uris[videoIndex || aishortsIndexValue]);
                    setJsonAIStudio(data);
                    console.log("21312", videoFile?.urls?.edited_json_uris[videoIndex || aishortsIndexValue])
                }
            } catch (e) { console.log(e) }
        }
        fetchData()
    }, [videoIndex, aishortsIndexValue, videoFile])


    const platforms = [
        { name: 'YT Shorts', imagePath: '/ytShort.png', isDisabled: false },
        { name: 'TikTok', imagePath: '/tiktok.png', isDisabled: true },
        { name: 'Reels', imagePath: '/reels.png', isDisabled: true },
        { name: 'Vimeo', imagePath: '/vimeo.png', isDisabled: true },
    ];

    useEffect(() => {
        let obj = {
            videoURL: exportField?.videoURL,
            title: exportField?.title,
            description: exportField?.description,
            privacyStatus: exportField?.privacyStatus,
            tags: exportField?.tags,
            categoryId: exportField?.categoryId,
            platform: exportField?.platform,
            lang: 'en',
            vttURL: exportField?.publishAt,
            publishAt: exportField?.publishAt
        }

        logEvent({
            category: 'Video',
            action: `Upload AIShorts ${searchParams.get('upload')}`,
            label: exportField?.videoURL,
        })

        // dispatch({
        //     type: "video/updateUploadState", payload: null
        // })

        switch (searchParams.get('upload')) {
            case 'youtube':
                youtubeExportUpload(obj)
                // dispatch(uploadYoutubeVideo(obj))
                break
            case 'vimeo':
                dispatch(uploadVimeoVideo(obj))
                break
            case 'tiktok':
                dispatch(uploadTiktokVideo(obj))
                break
            case 'reel':
                dispatch(uploadReelVideo(obj))
                break
            default:
                break
        }

    })

    const copyToClipboard = (text: string): void => {
        if (!navigator.clipboard) {
            console.error("Clipboard API not supported");
            return;
        }

        navigator.clipboard.writeText(text).then(
            () => {
                console.log("Text copied to clipboard successfully!");
            },
            (err) => {
                console.error("Failed to copy text to clipboard", err);
            }
        );
    };

    const returnURLRender = () => {
        if (currentService === "aistudio") {
            return "aistudio_rendering_progress";
        } else if (currentService === "text_to_video") {
            return "ttv_rendering_progress";
        } else if (currentService === "transcribe") {
            if (videoFile?.caption_render_processing) {
                return "video_rendering_progress";
            } else if (videoFile?.compression) {
                return 'compression_progress';
            }
        }
        if (currentService == "aishorts") {
            return "aishorts_rendering_progress"
        }
    };


    const returnVideoURL = (): string | undefined => { // Specify return type as string | undefined
        if (currentService == "aistudio") {
            return videoFile?.urls?.output_rendered_video;
        }
        if (currentService == "text_to_video") {
            return videoFile?.urls?.text_to_video_rendered_uri
        }
        if (currentService == "transcribe") {
            return videoFile?.urls?.rendered_captioned_video;
        }
        if (currentService == "compression")
            return videoFile?.urls?.compressed_updated_video_file
        if (currentService == "aishorts") {
            let index = videoId?.split("-")?.[1] || 0
            return videoFile?.urls?.output_rendered_videos?.[videoIndex || index]
        }
    };

    return (
        <div style={{ marginBottom: isXs ? '10vh' : '0' }}>
            <Fragment>
                {!isXs && <Header />}
                <Container sx={{ height: '100vh', backgroundColor: 'white' }} maxWidth={false}>
                    <Grid container spacing={2} sx={{ width: '100%', height: '100%' }}>
                        <Grid item xs={12} md={1} sx={{ border: "1px solid #DDD" }}>
                            <div className="drawer-container">
                                <Divider />
                                <List sx={{ marginTop: 2 }}>
                                    {['Go Back'].map((text, index) => (
                                        <ListItem key={text + index} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 68,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                    flexDirection: open ? 'row' : 'column',
                                                    backgroundColor: '#FFF',
                                                }}
                                                onClick={() => {
                                                    if (text === "Go Back") navigate("/ai-shortsv2");
                                                    setCurrentTab(text);
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                        marginRight: open ? "25px" : "0px",
                                                        color: currentTab === text ? "#0075E1" : 'black',
                                                    }}
                                                >
                                                    {returnIcon(text)}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={text}
                                                    sx={{
                                                        opacity: open ? 1 : 1,
                                                        color: currentTab === text ? "#0075E1" : 'black',
                                                        textAlign: 'center'
                                                    }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={11} sx={{ padding: 3 }}>
                            <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.12)', paddingBottom: 4 }} md={12}>
                                <p className='text-main' style={{ margin: 0 }}>ViralMe AI Shorts</p>
                                <InfoIcon></InfoIcon>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginTop: 2, paddingLeft: { xs: 1, md: 5 }, paddingRight: { xs: 1, md: 1 } }}>
                                <Grid item container spacing={2} xs={12} md={6}>
                                    <Grid item sx={{}} md={12} xs={12} sm={12}>
                                        <Grid>
                                            <div className='title-copy-div'>
                                                <div className='title-div'>
                                                    <p>Title</p>
                                                </div>
                                                <Tooltip title="Copy">
                                                    <IconButton>
                                                        <ContentCopyIcon onClick={() => copyToClipboard(jsonAIStudio?.video_meta_data?.title)}></ContentCopyIcon>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        <Grid>
                                            <p className='title-text'>
                                                {jsonAIStudio?.video_meta_data?.title || "write title here.."}
                                            </p>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={{}} md={12} xs={12} sm={12}>
                                        <Grid>
                                            <div className='title-copy-div'>
                                                <div className='title-div'>
                                                    <p>
                                                        Description
                                                    </p>
                                                </div>
                                                <Tooltip title="Copy">
                                                    <IconButton>
                                                        <ContentCopyIcon onClick={() => copyToClipboard(jsonAIStudio?.video_meta_data?.description)}></ContentCopyIcon>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        <Grid>
                                            <p className='desc-text'>
                                                {jsonAIStudio?.video_meta_data?.description || "write description here.."}
                                            </p>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={{}} md={12} xs={12} sm={12}>
                                        <Grid>
                                            <div className='title-copy-div'>
                                                <div className='title-div'>
                                                    <p>
                                                        Tags
                                                    </p>
                                                </div>
                                                <Tooltip title="Copy">
                                                    <IconButton>
                                                        <ContentCopyIcon onClick={() => copyToClipboard(jsonAIStudio?.video_meta_data?.hashtags)}></ContentCopyIcon>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        <Grid>
                                            <p className='desc-text'>
                                                {jsonAIStudio?.video_meta_data?.hashtags || "write tags here.."}
                                            </p>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item sx={{}} md={12} xs={12} sm={12}>
                                        <Grid>
                                            <div className='title-copy-div'>
                                                <div className='title-div'>
                                                    <p>Subtitle</p>
                                                </div>
                                                <ContentCopyIcon></ContentCopyIcon>
                                            </div>
                                        </Grid>
                                        <Grid>
                                            <p style={{ marginBottom: 10 }} className='desc-text'>
                                                {jsonShort[activeIndex] ? jsonShort[activeIndex]?.segments.map(({ text }: any) => text) : "Loading..."}
                                            </p>
                                        </Grid>
                                    </Grid> */}
                                    <Grid container spacing={2} sx={{ marginBottom: 5 }} display="flex" alignContent='flex-end' justifyContent="flex-end">
                                        {platforms.map(({ name, imagePath, isDisabled }, index) => (
                                            <Grid item xs={6} sm={3} md={2} key={index} display="flex" flexDirection="column" alignItems="center">
                                                <div
                                                    onClick={() => setPublishModal(true)}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: '12px',
                                                        backgroundColor: isDisabled ? '#E0E0E0' : '#F0F0F0',
                                                        padding: '16px',
                                                        width: '100px',
                                                        height: '100px',
                                                        opacity: isDisabled ? 0.5 : 1,
                                                        cursor: isDisabled ? 'not-allowed' : 'pointer'
                                                    }}
                                                >
                                                    <img src={imagePath} alt={name} style={{ width: '60%' }} />
                                                </div>
                                                <p
                                                    className='platform-name-text'
                                                    style={{
                                                        marginTop: '8px',
                                                        fontSize: '14px',
                                                        color: isDisabled ? '#A0A0A0' : '#000',
                                                    }}
                                                >
                                                    {name}
                                                </p>
                                            </Grid>
                                        ))}
                                        <Grid item xs={6} sm={3} md={4} display="flex" justifyContent="center" alignItems="flex-end">
                                            <GradientButton onClick={() => downloadVideo(editedVideoUrl || "")}>
                                                <p>Download Video</p>
                                            </GradientButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div style={{ width: '100%', maxWidth: '640px', marginBottom: '20px', position: 'relative' }}>

                                        <video
                                            key={videoKey}
                                            playsInline
                                            style={{
                                                borderRadius: '8px',

                                                width: 1080 / 3,
                                                height: 1920 / 3
                                            }}
                                            controls
                                        >
                                            <source src={editedVideoUrl} type='video/mp4' />
                                        </video>
                                    </div>
                                    <div style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        {captions !== null && (
                                            <button onClick={() => navigate("/publish")} style={{ marginLeft: 10 }} className="done-button box-shadoww">
                                                Publish
                                            </button>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <LanguageModal
                    open={isLoading}
                    check="false"
                    type={'loading'}
                />
                <VideoPublishModal data={jsonAIStudio?.video_meta_data} open={publishModal} currentExportUrl={editedVideoUrl} handleClose={() => setPublishModal(false)} />
                {((currentService == "transcribe" || currentService == "aistudio" || currentService == "aishorts") && (videoFile?.caption_render_processing || videoFile?.compression || videoFile?.video_in_queue || videoFile?.aistudio_rendering_ongoing || videoFile?.in_queue_waiting_for_rendering || videoFile?.aistudio_rendering_ongoing || videoFile?.in_queue_waiting_for_rendering || videoFile?.aishorts_rendering_ongoing)) &&
                    <VideoProgress renderurl={returnURLRender()} userId={videoFile?.user_id} videoId={videoFile?.video_id.split("-")?.[0]} close={false} checkForQueue={{ video_in_queue: videoFile?.video_in_queue || videoFile?.in_queue_waiting_for_rendering }}></VideoProgress>}
            </Fragment>
        </div >
    )
}

export default AIShorts;