import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import httpApi from '../../utils/httpApi2';

interface TextToVideoState {
    isLoading: boolean;
    error: string | null;
    videoId: string | null;
}

const initialState: TextToVideoState = {
    isLoading: false,
    error: null,
    videoId: null
};

export const postTextToVideo = createAsyncThunk(
    'texttovideo/postTextToVideo',
    async (data: any) => {
        const response = await httpApi.post(`text_to_video_instant`, data);
        return response.data;
    }
);

// Continuing in exportSlice.ts

const TextToVideoSlice = createSlice({
    name: 'texttovideo',
    initialState,
    reducers: {
        upadateVideoId: (state, action: any) => {
            state.videoId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postTextToVideo.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(postTextToVideo.fulfilled, (state, action: PayloadAction<any>) => {
                state.isLoading = false;
                // state.TextToVideo = action.payload;
            })
            .addCase(postTextToVideo.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
    },
});

export const { } = TextToVideoSlice.actions;
export default TextToVideoSlice.reducer;
