// exportSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';
// import { login } from './auth.actions';

interface ExportField {
  title: string;
  description: string;
  tags: string[];
  privacyStatus: string;
  categoryId: string;
  accordian: string[];
  platform: number;
  publishAt: Date;
  videoURL: string;
}

interface ExportState {
  isLoading: boolean;
  error: string | null;
  youtubeVideoCategory: any[] | null;
  exportField: ExportField | null;
  success: boolean;
  message: string | null;

}

const initialState: ExportState = {
  isLoading: false,
  error: null,
  youtubeVideoCategory: null,
  exportField: null,
  success: false,
  message: null
};

function removeQueryParams() {
  const { protocol, host, pathname } = window.location;
  const cleanURL = `${protocol}//${host}${pathname}`;
  window.history.pushState({}, '', cleanURL);
}


// me
export const fetchYoutubeVideoCategory = createAsyncThunk(
  'export/youtubeVideoCategory',
  async () => {
    const response = await api.get(`/upload/youtube-video-category`);
    return response.data;
  }
);

// upload video on youtube
export const uploadYoutubeVideo = createAsyncThunk(
  'export/uploadYoutubeVideo',
  async (data: object, thunkApi) => {
    const response = await api.post(`/upload/youtube`, data);
    return response.data;
  }
);

// upload video on vimeo
export const uploadVimeoVideo = createAsyncThunk(
  'export/uploadVimeoVideo',
  async (data: object, thunkApi) => {
    const response = await api.post(`/upload/vimeo`, data);
    return response.data;
  }
);

// upload video on tiktok
export const uploadTiktokVideo = createAsyncThunk(
  'export/uploadTikokVideo',
  async (data: object, thunkApi) => {
    const response = await api.post(`/upload/tikok`, data);
    return response.data;
  }
);

// upload video on tiktok
export const uploadReelVideo = createAsyncThunk(
  'export/uploadReelVideo',
  async (data: object, thunkApi) => {
    const response = await api.post(`/upload/instagram`, data);
    return response.data;
  }
);

// Continuing in exportSlice.ts

const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    UPDATE_EXPORT: (state, action: PayloadAction<ExportField | null>) => {
      state.exportField = action.payload;
    },
    resetExportError: state => {
      state.error = null;
      state.message = null;
      state.success = false;
    },
    Youtube_Pending: state => {
      state.isLoading = true;
      state.error = null;
    },
    Youtube_Success: state => {
      removeQueryParams()
      state.exportField = null
      state.isLoading = false;
      state.success = true;
      state.message = "Youtube Video Upload Successfully"
    },
    Youtube_Error: (state, action: PayloadAction<{ error: any }>) => {
      state.isLoading = false;
      state.success = true;
      state.message = action.payload?.error?.errors?.[0]?.message || 'Failed to upload';
      state.error = action.payload.error || 'Failed to upload';
    },
    setToast: (state, action: any) => {
      state.success = action.payload.success;
      state.message = action.payload.message;
      state.error = action.payload.error;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchYoutubeVideoCategory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchYoutubeVideoCategory.fulfilled, (state, action: PayloadAction<any>) => {
        state.youtubeVideoCategory = action.payload.data.items;
        state.isLoading = false;
      })
      .addCase(fetchYoutubeVideoCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(uploadYoutubeVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadYoutubeVideo.fulfilled, (state, action: PayloadAction<any>) => {
        removeQueryParams()
        state.exportField = null
        state.isLoading = false;
        state.success = true;
        state.message = "Youtube Video Upload Successfully"
      })
      .addCase(uploadYoutubeVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to upload';
      })
      .addCase(uploadVimeoVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadVimeoVideo.fulfilled, (state, action: PayloadAction<any>) => {
        removeQueryParams()
        state.exportField = null
        state.isLoading = false;
        state.success = true;
        state.message = "Vimeo Video Upload Successfully"
      })
      .addCase(uploadVimeoVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to upload';
      })
      .addCase(uploadTiktokVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadTiktokVideo.fulfilled, (state, action: PayloadAction<any>) => {
        removeQueryParams()
        state.exportField = null
        state.isLoading = false;
        state.success = true;
        state.message = "Tiktok Video Upload Successfully"
      })
      .addCase(uploadTiktokVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to upload';
      })
      .addCase(uploadReelVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadReelVideo.fulfilled, (state, action: PayloadAction<any>) => {
        removeQueryParams()
        state.exportField = null
        state.isLoading = false;
        state.success = true;
        state.message = "Tiktok Video Upload Successfully"
      })
      .addCase(uploadReelVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to upload';
      })

    //   ... You can handle other actions similarly: signup, verifyEmail, forgotPassword
  },
});

export const { resetExportError } = exportSlice.actions;
export default exportSlice.reducer;
