import React, { useEffect, useState } from 'react';
import './style.css'
import { Modal, Box, Typography, Button, useMediaQuery, useTheme, Grid, Alert } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { Link } from "react-router-dom";
import CustomizedButtons from '../Button';
import Phase1 from '../../utils/corpus_phase1.json'
import Phase2 from '../../utils/corpus_phase2.json'
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';
// import { MediaOutlet, MediaPlayer } from '@vidstack/react';
import { resetTitties, updateInitials, updatePhase, updateSubmittedVoice, updateTitle, updateVoiceSteps, updatedTitties, uploadVoice } from '../../redux/slices/titties.slice';
import InputWithIcon from '../InputBox';
import { logEvent } from '../../utils/ga.utils';

const TittiesModal = ({ onSubmit, open, setOpenModal }: any) => {
    const [step, setStep] = useState<number>(0)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useAppDispatch()

    const [audioBlob, setAudioBlob] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [multipleAudio, setMultipleAudio] = useState([])
    const [congratulations, setCongratulations] = useState(false)
    const [error, setError] = useState(false)
    const [localTitle, setLocalTitle] = useState("")
    const user = useAppSelector((state: RootState) => state.auth.user)
    const { voiceSteps, phase, initials, title, voices, available_voices, existing_voice_folders, } = useAppSelector((state: RootState) => state.titties)

    const recorderControls = useAudioRecorder();

    useEffect(() => {
        if (!recorderControls.recordingBlob) return;
        addAudioElement(recorderControls.recordingBlob);
        // recorderControls.recordingBlob will be present at this point after 'stopRecording' has been called
    }, [recorderControls.recordingBlob])

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : "60%",
        height: isMobile ? '80vh' : "auto",  // Set height for mobile views
        overflowY: isMobile ? 'scroll' : "visible",  // Add overflowY for scrolling on mobile
        maxWidth: '90%',
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };
    const addAudioElement = (blob: any) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement("audio");
        audio.src = url;
        audio.controls = true;
        audio.id = "myAudioElement";
        audio.style.display = "none";
        document.body.appendChild(audio);
    };

    const getCurrentPhase = () => {
        return phase == 1 ? Object.values(Phase1) : phase == 2 ? Object.values(Phase2) : []
    }

    // Handler for recording completion
    const handleAudioComplete = (blob: any) => {
        setAudioBlob(blob);
    };

    useEffect(() => {
        if (voiceSteps == 0 && phase == 1) {
            dispatch(updateTitle(""))
        }
    }, [])


    const submitWorkAndContinue = () => {
        if (user) {
            let temp = [...multipleAudio]
            if (audioBlob) {
                temp.push(audioBlob)
                setMultipleAudio(temp)
                setAudioBlob(null)
                let newVal = voiceSteps + 1
                dispatch(updateVoiceSteps(newVal))
                if ((temp.length % 1) == 0) {
                    let formData = new FormData()
                    temp?.map(async (blob) => {
                        const wavBlob = new Blob([blob], { type: 'audio/wav' });
                        // const blobb = await convertToWav(blob)
                        formData.append('files', wavBlob, 'voice.wav');
                    })
                    formData.append("user_id", user.id)
                    formData.append("lang", "en")
                    formData.append("voice_id", title.trim())
                    dispatch(uploadVoice(formData))
                    logEvent({ category: "Titties", action: "Custome Voice Upload", label: `uploaded ${phase}`, value: voiceSteps })

                    dispatch(updateSubmittedVoice(3))
                    setMultipleAudio([])
                    removeAudioElement()
                    if (newVal >= 9 && phase == 1) {
                        dispatch(updatePhase(2))
                        dispatch(updateVoiceSteps(0))
                    }
                    else if (newVal >= 9 && phase == 2) {
                        setCongratulations(true)
                        dispatch(updatePhase(1))
                        dispatch(updateVoiceSteps(0))
                        dispatch(updateSubmittedVoice(null))
                        dispatch(updateTitle(""))
                        setTimeout(() => {
                            setOpenModal(false)
                            setCongratulations(false)
                        }, 1000)
                    }
                }
            }
        }
    }

    const removeAudioElement = () => {
        const audio = document.getElementById("myAudioElement");
        if (audio) {
            document.body.removeChild(audio);
        }
    };

    const checkNameUnique = () => {

        if (localTitle !== "") {
            let uni = voices?.findIndex(({ name }: any) => name.toLowerCase() == localTitle.toLowerCase())
            let uni2 = [...existing_voice_folders, ...available_voices]?.findIndex((str: string) => str.toLowerCase() == localTitle.toLowerCase())
            if (uni == -1 && uni2 == -1) {
                dispatch(updateInitials(true))
                dispatch(updateTitle(localTitle))
            }
            else {
                setError(true)
                return
            }
        }

    }


    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    backdropFilter: "blur(8px)", // This is the CSS property that blurs the background
                }
            }}
        >
            <Box sx={style}>
                <Grid item sx={{ marginTop: 2, marginBottom: 2, alignSelf: "start" }} xs={12}>
                    <div onClick={() => {
                        dispatch(resetTitties())
                        setOpenModal(false)
                    }} style={{ display: 'flex' }}><KeyboardBackspaceIcon htmlColor='rgba(0, 0, 0, 0.54)' />
                    </div>
                </Grid>
                {congratulations ?
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                            color: "#000",
                            textAlign: "center",
                            fontFamily: "Nunito",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                        }}>
                            Congratulations, we have recorded all your 30 voices.
                        </Typography>
                        <p className='speech-text'>We are training our AI engine to learn your voice, it can take some time.</p>
                        <img style={{ width: 100, marginTop: 10 }} src="./Loading.gif" alt="Loading" />

                    </>
                    : step == 0 ? <>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                            color: "#000",
                            textAlign: "center",
                            fontFamily: "Nunito",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                        }}>
                            To train our model perfectly on your voice we will record your 30 samples.
                        </Typography>
                        <Typography id="modal-modal-title" variant="h6" component="h6" sx={{
                            color: "#757575",
                            textAlign: "center",
                            fontFamily: "Nunito",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                        }}>
                            This may take upto 15mins.
                        </Typography>
                        <img style={{ width: 250, marginTop: 10 }} src="./sound_wave.gif" alt="Loading" />
                        <div style={{ display: 'flex', marginTop: "10px" }}>
                            <CustomizedButtons onClick={() => setOpenModal(false)} color="#7E0000" name="Do it later" />
                            <div style={{ marginLeft: "10px", marginRight: "10px" }}></div>
                            <CustomizedButtons onClick={() => setStep(1)} color="#0085FF" name="I'm ready" />
                        </div>
                    </> : ((step == 1 && !initials && phase == 1 && voiceSteps == 0) || title == "") ?
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                                color: "#000",
                                textAlign: "center",
                                fontFamily: "Nunito",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "normal",
                            }}>
                                Titties!
                            </Typography>
                            <p className='speech-text'>Enter the title for your voice and save.</p>
                            <InputWithIcon error={error} name="name" onChange={(e: any) => setLocalTitle(e.target.value)} />
                            {error && <Alert sx={{ marginTop: 3 }} severity="error">This voice is already in use, please choose any other unique name.</Alert>}
                            <div style={{ paddingTop: 20 }}></div>
                            <CustomizedButtons onClick={checkNameUnique} name='Save' size='small' />
                        </>
                        : (step == 1 && (phase == 1 || phase == 2) && voiceSteps >= 0) ?
                            <>
                                <p className='stepper'>{voiceSteps + 1}/10</p>
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                                    color: "#000",
                                    textAlign: "center",
                                    fontFamily: "Nunito",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                }}>

                                    Say naturally, like telling a story.
                                </Typography>
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                                    color: "#000",
                                    textAlign: "center",
                                    fontFamily: "Nunito",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "normal",
                                }}>
                                    Phase {phase}:
                                </Typography>
                                <p className='speech-text'>{getCurrentPhase()[voiceSteps]}</p>
                                <AudioRecorder
                                    onRecordingComplete={handleAudioComplete}
                                    audioTrackConstraints={{
                                        noiseSuppression: true,
                                        echoCancellation: true,
                                    }}
                                    downloadOnSavePress={false}
                                    downloadFileExtension="wav"
                                    recorderControls={recorderControls}

                                />
                                {/* {audioBlob && (
                                    <>
                                        <MediaPlayer style={{ marginTop: "10px" }} src={[{ src: URL.createObjectURL(audioBlob), type: "audio/wav" }]} controls>
                                            <MediaOutlet />
                                        </MediaPlayer>
                                        <div style={{ display: 'flex', marginTop: "10px" }}>
                                            <CustomizedButtons onClick={() => setAudioBlob(null)} color="#7E0000" name="Retry" />
                                            <div style={{ marginLeft: "10px", marginRight: "10px" }}></div>
                                            <CustomizedButtons onClick={submitWorkAndContinue} color="#0085FF" name="Next" />
                                        </div>
                                    </>
                                )} */}

                            </>
                            : step == 2 ?
                                <>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                                        color: "#000",
                                        textAlign: "center",
                                        fontFamily: "Nunito",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: 700,
                                        lineHeight: "normal",
                                    }}>
                                        First, we will show you 15 corpuses
                                    </Typography>
                                </>
                                : <></>}
            </Box>

        </Modal>
    );
}

export default TittiesModal;
