import './style.css'
import { Fragment, useState, useEffect } from "react";
import { Container, Grid, Card, Typography, Alert, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Avatar, useTheme, IconButton, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Header from "../Header";
import CustomizedButtons from '../../components/Button';
import AddIcon from '@mui/icons-material/Add';
import CustomizedSelects from '../../components/DropDown/CustomizedSelect';
// import { MediaOutlet, MediaPlayer } from '@vidstack/react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TittiesModal from '../../components/TittiesModal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { RootState } from '../../redux/store';
import { contineProcessingTitties, speechToText, updatedTitties, voiceData, voiceDelete } from '../../redux/slices/titties.slice';
import LanguageModal from '../../components/LanguageModal';
import { downloadFile } from '../../utils/download';
import GroupDropDown from '../../components/GroupDropDown';
import packageData from '../../utils/packageData';
import PlansData from '../../utils/plans_data.json';
import { ILimit } from '../../interface/user';
import { useTour } from '@reactour/tour';
import { logEvent, logPageView } from '../../utils/ga.utils';
import languageJSON from '../../utils/language.json'
import AutoComplete from '../../components/AutoComplete';

// ICON
import ReplyIcon from '@mui/icons-material/Reply';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Drawer, DrawerHeader } from '../../components/Drawer';

import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

function TittiesParent() {
    const dispatch = useAppDispatch()

    const { setIsOpen, setCurrentStep, isOpen } = useTour()

    const [openModal, setOpenModal] = useState<boolean>(false)
    const [speech, setSpeech] = useState<boolean>(false)
    const [text, setText] = useState("")
    const [selectedYourVoice, setSelectedYourVoice] = useState("")
    const [manageVoice, setManageVoice] = useState(false)

    const user = useAppSelector((state: RootState) => state.auth.user)
    const voices = useAppSelector((state: RootState) => state.titties.voices)
    const wav_output = useAppSelector((state: RootState) => state.titties.wav_output)
    const isLoading = useAppSelector((state: RootState) => state.titties.isLoading)
    const available_voices = useAppSelector((state: RootState) => state.titties.available_voices)
    const total_characters = useAppSelector((state: RootState) => state.titties.total_characters)

    const [exportLoader, setExportLoader] = useState(false)
    const [limitation, setLimitation] = useState<ILimit>({
        "Name": "Free",
        "Transcription_Minutes": 30,
        "Characters_Audio": 2000,
        "Secure_Cloud_Storage": 1024
    },)
    const [errors, setErrors] = useState({
        voice_id: false,
        text: false
    })

    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(!open);
    };


    useEffect(() => {
        if (user?.subscriptionStatus == "succeeded") {
            // find name of subscription product
            let mySubsPkg = packageData?.find(({ cycle }) => cycle?.find(({ subscriptionId }) => subscriptionId == user.subscriptionPlan?.id))
            if (mySubsPkg) {
                let PlansLimit = PlansData?.find(({ Name }) => Name == mySubsPkg?.name)
                if (PlansLimit)
                    setLimitation(PlansLimit as ILimit)
            }
        }
    }, [user])

    useEffect(() => {
        logPageView()
        const tour = localStorage.getItem('tour-titties');
        if (!tour || tour == 'false') {
            setIsOpen(true)
            localStorage.setItem('tour-titties', 'true'); // Set the key to prevent displaying the banner in future visits
        }
    }, [])

    const GenerateSpeech = () => {
        if (!user) return
        if (text == "") setErrors({ ...errors, text: true })
        if (selectedYourVoice == "") setErrors({ ...errors, voice_id: true })
        if (text !== "" && selectedYourVoice !== "") {
            setErrors({
                voice_id: false,
                text: false
            })

            let formData = new FormData()
            formData.append("text", text.trim())
            formData.append("user_id", user.id)
            formData.append("lang", "en")
            formData.append("voice_id", selectedYourVoice)
            formData.append("quality", "fast")
            logEvent({ category: "Titties", action: "Speech to Text" })

            dispatch(speechToText(formData))
        }
    }

    useEffect(() => {
        // if (user && openModal == false)
        // dispatch(voiceData({ user_id: user.id }))
    }, [openModal])

    const downloadFiles = async () => {
        setExportLoader(true)
        await downloadFile(wav_output as string, `transpify.wav`, "audio/wav")
        logEvent({ category: "Titties", action: "download voice" })
        setExportLoader(false)
    }

    const getErrorMessage = () => {
        if (errors.text) return "Please enter a text.";
        if (errors.voice_id) return "Please select the voice.";
        return "";
    };

    const errorMessage = getErrorMessage();

    const handleDeleteVoice = (id: string) => {
        if (user) {
            dispatch(voiceDelete({ user_id: user.id, voice_id: id }))
            logEvent({ category: "Titties", action: "delete voice" })

        }
    }

    const continueProcessing = (name: string, num: number) => {
        let temp = {
            voiceSteps: 0,
            phase: 1,
            title: name,
            initials: true,
            submittedVoice: num
        }
        if (num > 10 && num < 20) {
            temp["voiceSteps"] = num - 10
            temp["phase"] = 2
        }
        else if (num < 10) {
            temp["voiceSteps"] = num
        }
        dispatch(contineProcessingTitties(temp))
        setOpenModal(true)
    }

    return (
        <Fragment>
            <Header></Header>
            <Container maxWidth="xl">
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {['Back', 'Youtube'].map((text, index) => (
                            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 68,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        flexDirection: open ? 'row' : 'column'
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            marginRight: "0px"
                                        }}
                                    >
                                        {index % 2 === 0 ? <ReplyIcon /> : <YouTubeIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 1 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <DrawerHeader />
                <Grid className='first-step' container spacing={2}>

                    {/* <Grid sx={{ backgroundColor: "white", justifyContent: 'center', padding: 5, height: '100%' }} md={1.5}>
                        <div className='side-card'>
                            <ReplyIcon className='side-icon' />
                            <p className='side-text'>Go Back</p>
                        </div>
                        <div style={{ marginTop: 10 }} className='side-card'>
                            <YouTubeIcon className='side-icon' />
                            <p className='side-text'>Embed to Video</p>
                        </div>
                    </Grid> */}
                    <Grid sx={{}} md={9}>
                        <Grid sx={{ backgroundColor: 'white', padding: 3, display: 'flex' }} md={12}>
                            <Grid md={6}>
                                <p className='text-main'>Voice-over</p>
                            </Grid>
                            <Grid sx={{ display: 'flex' }} md={3}>
                                <AutoComplete className="sixth-step" selected={(languageJSON as any)["en"]}
                                    handleChange={(e: any, value: string) => {
                                        // const key = getKeyByValue(languageJSON, value) || "en"
                                        // if (language == key) return
                                        // updateLanguage(key)
                                        // if (key == "ko")
                                        //     updateFont("NotoSansKR")
                                        // else if (key == "ar") {
                                        //     updateFont("NotoSansArabic")
                                        // }
                                    }}
                                    options={
                                        Object.entries(languageJSON).map(([value, name]) => ({
                                            value,
                                            label: name
                                        }))
                                    }
                                />
                                <div style={{ width: 10 }}></div>
                            </Grid>

                            <Grid md={3}>
                                <CustomizedButtons
                                    // onClick={ExportVideoYoutube}
                                    name="Import Scripts"
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                        <Grid md={12}>
                            <div style={{ display: "flex", backgroundColor: 'white', margin: 25, borderRadius: 15, padding: 10, alignItems: 'center' }}>
                                <Avatar />
                                <p style={{ marginLeft: 15, marginRight: 15 }} className='side-text'>Hello this is Aron from Transpify, we are here to introduce you the world’s largest and easiest  platform that empowers you to go viral with your content. Hello this is Aron from Transpify, we are her</p>
                                <PlayArrowIcon htmlColor='#00A3FF' />
                        </div>
                        </Grid>
                    </Grid>
                    <Grid sx={{ backgroundColor: "white", padding: 3, }} md={3}>
                        <Grid md={12}>
                            <p className='voice-title'>Voice</p>
                            <p className='voice-desc'>Pick the right persona for your voice segment</p>
                        </Grid>
                        <Grid sx={{
                            backgroundColor: "rgba(237, 248, 255, 0.84)"
                        }} md={12}>
                            <Grid sx={{ display: 'flex', flexWrap: 'wrap', padding: 2, marginTop: 3 }} md={12}>
                                <Grid md={3.5} className='clone-div' item>
                                    <Avatar></Avatar>
                                    <p className='clone-title'>Katherine</p>
                                    <p className='clone-desc'>Cloned Voice</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid sx={{ marginTop: 2 }} md={12}>
                            <p className='voice-title'>Playback</p>
                            <p className='voice-desc'>Select the playback speed for the voice-over</p>
                            <Grid md={12}></Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <LanguageModal open={isLoading} setOpenModal={setOpenModal} type={"loading"} msg={"Hold tight baby, magic will take sometime 🪄"} /> */}
                <TittiesModal open={openModal} setOpenModal={setOpenModal} />
            </Container>
        </Fragment>
    )
}

export default TittiesParent