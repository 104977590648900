/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */

import { useState, useEffect } from "react";
import {
  useCurrentFrame,
  delayRender,
  continueRender,
} from "remotion";
import { Sequence, useVideoConfig, AbsoluteFill } from "remotion";
import {
  getTextStyle,
  getCurrencyCaptionStyle,
  getMagnifyingGlassStyle,
  getFearCaptionStyle,
  getCaptionsStyle,
  getThrillerCaptionStyle,
  getCurrencyCaptionStyle2,
  getSadCaptionStyle,
  getNumberCaptionStyle,
  getDisappointedCaptionStyle,
  getConfusedCaptionStyle,
  getPlainTextStyle,
  getHormoziStyle,
  getRandomWordHighlightStyle,
  getTranspifySpecialStyle,
  getViralTremblingStyle,
  getImanGadhziStyle,
  getWordStyle,
  getHormoziRobuckStyle,
  getNeonGlowStyle,
  getKaraokeStyle,
  getLuminousEchoStyle,
  getMrBeastStyle,
  getGradientHighlightStyle,
  getTrutagemStyle,
  getRevealStyle,
  getWaveformStyle,
} from "./CaptionsStyles";
import "./utilities/fonts.css";
import { Emoji } from "./Emoji";
import { Animated, Move, Fade } from "remotion-animated";
import { MotionbgGIF } from "./MotionBG_GIFS";

const renderEmojiSegment = (
  segment: any,
  index: any,
  currentTime: any,
  fps: any,
  width: any,
  height: any,
  config: any
) => {
  if (!segment.emoji_uri) return null; // Skip rendering if no emoji_uri
  const isActive = currentTime >= segment.start && currentTime <= segment.end;
  if (!isActive) return null;

  const startFrame = Math.round(segment.start * fps);
  const endFrame = Math.round(segment.end * fps);
  const totalFrames = endFrame - startFrame + 0.001;

  let captionY = 0.5;
  const pre = config.position;
  if (pre == 1) {
    captionY = height * 0.005; // Y position of the caption
  } else if (pre == 2) {
    captionY = height * 0.1;
  } else if (pre == 3) {
    captionY = height * 0.2;
  } else if (pre == 4) {
    captionY = height * 0.3; // Y position of the caption
  } else if (pre == 5) {
    captionY = height * 0.4; // Y position of the caption
  } else if (pre == 6) {
    captionY = height * 0.5; // Y position of the caption
  } else if (pre == 7) {
    captionY = height * 0.6; // Y position of the caption
  } else if (pre == 8) {
    captionY = height * 0.7; // Y position of the caption
  } else if (pre == 9) {
    captionY = height * 0.8; // Y position of the caption
  } else {
    captionY = height * 0.5; // Y position of the caption
  }

  // Determine the direction based on the index
  const isLeftToRight = index % 2 === 0;
  // const emojiInitialX = isLeftToRight ? width * 0.1 : width * 0.3;
  const emojiInitialX = isLeftToRight ? width * 0.15 : width * 0.3;
  const emojiFinalX = isLeftToRight ? width * 0.65 : width * 0.2;
  const emojiPositionY = isLeftToRight
    ? captionY - height * 0.013
    : captionY + height * 0.09; // working v2


  return (
    <Sequence key={index} from={startFrame} durationInFrames={totalFrames}>
      <Animated
        animations={[
          // Move({ initialX: emojiInitialX, x: emojiFinalX - emojiInitialX, initialY: 0, y: 0, duration: totalFrames }), // Move from right to left
          Move({
            initialX: emojiInitialX,
            x: emojiFinalX - emojiInitialX,
            initialY: 0,
            y: 0,
            duration: totalFrames,
          }), // Move based on direction
          Fade({ to: 0, start: totalFrames / 4, duration: totalFrames / 2 }), // Fade out at the end
        ]}
        absolute
      >
        <div
          style={{
            position: "absolute",
            top: emojiPositionY,
            left: emojiInitialX,
            zIndex: 100,
          }}
        >
          <Emoji src={segment.emoji_uri} />
        </div>
      </Animated>
    </Sequence>
  );
};

const WordSequence = ({
  width,
  height,
  wordData,
  globalStart,
  globalEnd,
  fps,
  config,
}: any) => {
  const frame = useCurrentFrame();
  const currentTime = frame / fps;
  const isActive = currentTime >= wordData.start && currentTime <= wordData.end;
  console.log(config);
  // Debugging log to check active status and timing for each word
  if (!isActive) return null;

  const startFrame = Math.floor(wordData.start * fps);
  const durationInFrames = Math.floor((wordData.end - wordData.start) * fps);

  const style = getWordStyle(width, height, wordData, frame, fps, config);

  return (
    <Sequence
      from={startFrame}
      durationInFrames={durationInFrames + 0.01}
      layout="none"
    >
      <div style={style}>{wordData.word}</div>
    </Sequence>
  );
};


const randomStyles = ["thriller", "sad", "fear", "wonder", "disappointed", "confused", null];




export const CaptionsOverlay = ({
  jsonData,
  jsonCaptions,
  template,
  config = {},
  emojis_on = 0,
  gifs_on = 0,

}: {
  jsonData: any;
  jsonCaptions: any;
  template: string;
  config?: any;
  emojis_on: any;
  gifs_on: any;

}) => {
  const { width, height, fps } = useVideoConfig();
  const frame = useCurrentFrame();
  const currentTime = frame / fps; // Convert current frame to time
  const [loaded, setLoaded] = useState(false); // State to track if all emojis are loaded
  const [handle] = useState(() => delayRender("Loading emojis"));
  const emojisegments = jsonData.segments;


  useEffect(() => {
    const imageLoads = emojisegments.map((segment: any) => {
      return new Promise((resolve) => {
        if (!segment.emoji_uri) return resolve(true); // Resolve immediately if no emoji_uri
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false); // continue even if there's an error
        img.src = segment.emoji_uri;
      });
    });

    Promise.all(imageLoads).then(() => {
      setLoaded(true);
      continueRender(handle);
    });
  }, [emojisegments, handle]);

  // Ensure rendering waits for all emojis to be loaded
  if (!loaded) {
    return null; // You can return a loading screen here if you prefer
  }



  return (
    <AbsoluteFill>
      {/* Conditionally render emojis */}
      {emojis_on === 1 && (
        <AbsoluteFill style={{ padding: 0 }}>
          {emojisegments.map((segment: any, index: any) =>
            renderEmojiSegment(
              segment,
              index,
              currentTime,
              fps,
              width,
              height,
              config
            )
          )}
        </AbsoluteFill>
      )}

      {(gifs_on === 1 || gifs_on === true || gifs_on === "1") &&
        jsonData.segments.map((segment: any, index: any) => {
          if (segment.gif_on) {
            const startFrame = Math.floor(segment.gif_start_time * fps);
            const endFrame = Math.floor(segment.gif_end_time * fps);
            const durationInFrames = endFrame - startFrame;

            // Ensure there is a minimum duration to display the GIF
            if (durationInFrames < 5) {
              return null;
            }

            return segment.gif_uri.map((gifUri: any, gifIndex: any) => (
              <Sequence
                key={`${index}-${gifIndex}`}
                from={startFrame}
                durationInFrames={durationInFrames}
                layout="none"
              >
                <MotionbgGIF src={gifUri} />
              </Sequence>
            ));
          }
          return null;
        })}

      {/* Render captions */}
      {jsonCaptions.segments.map((segment: any, index: any) => {
        const startFrame = Math.floor(segment.start * fps);
        const endFrame = Math.floor(segment.end * fps);
        const durationInFrames = endFrame - startFrame + 0.001;

        const isActive = frame >= startFrame && frame <= endFrame;
        // Determine the style index based on the current segment index
        const styleIndex = Math.floor(index / 4) % randomStyles.length;
        const randomStyle = randomStyles[styleIndex];


        if (durationInFrames < 5) {
          return null;
        }
        if (isActive) {
          let style;
          if (template === "ViralMe_AI_Studio_Captions") {
            if (
              randomStyle === "angry"
            ) {
              style = getThrillerCaptionStyle(
                width,
                height,
                frame - startFrame,
                config
              );
            } else if (randomStyle === "sad") {
              style = getSadCaptionStyle(
                width,
                height,
                frame - startFrame,
                config
              );
            } else if (/[$£€₹¥]/.test(segment.text)) {
              style = getCurrencyCaptionStyle(
                width,
                height,
                frame - startFrame,
                segment.text,
                config
              );
            } else if (
              /\b(dollars|usd|pound|euros|yen|rupees|won)\b/i.test(segment.text)
            ) {
              style = getCurrencyCaptionStyle2(
                width,
                height,
                frame - startFrame,
                segment.text,
                config
              );
            } else if (/\d{2,}/.test(segment.text)) {
              style = getNumberCaptionStyle(
                width,
                height,
                frame - startFrame,
                segment.text,
                config
              );
            } else if (
              randomStyle === "fear"
            ) {
              style = getFearCaptionStyle(
                width,
                height,
                frame - startFrame,
                config
              );
            } else if (
              randomStyle === "wonder"
            ) {
              style = getMagnifyingGlassStyle(
                width,
                height,
                frame - startFrame,
                config
              );
            } else if (randomStyle === "disappointed") {
              style = getDisappointedCaptionStyle(
                width,
                height,
                frame - startFrame,
                config
              );
            } else if (randomStyle === "confused") {
              style = getConfusedCaptionStyle(
                width,
                height,
                frame - startFrame,
                config
              );
            } else {
              // Use counter to alternate between the two functions
              // Determine style based on segment index and start time
              const styleType = (index + Math.round(segment.start)) % 3; // Uses the segment index and start time to vary style
              switch (styleType) {
                case 0:
                  style = getTextStyle(width, height, config);
                  break;
                default:
                  style = getCaptionsStyle(width, height, config); // Fallback to a default style
              }
            }
          } else if (template === "plaintext") {
            style = getPlainTextStyle(width, height, config);
          } else if (template === "hormozi") {
            const {
              style: hormoziStyle,
              firstHalf,
              secondHalf,
              color2,
            } = getHormoziStyle(
              width,
              height,
              segment.text,
              frame,
              fps,
              config
            );
            return (
              <Sequence
                key={index}
                from={startFrame}
                durationInFrames={durationInFrames}
              >
                <div style={hormoziStyle}>
                  {firstHalf}{" "}
                  <span
                    style={{ color: color2, textShadow: `2px 2px 4px #000` }}
                  >
                    {secondHalf}
                  </span>
                </div>
              </Sequence>
            );
          } else if (template === "randomword") {
            const { style: randomWordStyle, highlightedText } =
              getRandomWordHighlightStyle(
                width,
                height,
                segment.text,
                segment.start,
                config
              );
            return (
              <Sequence
                key={index}
                from={startFrame}
                durationInFrames={durationInFrames}
              >
                <div
                  style={randomWordStyle}
                  dangerouslySetInnerHTML={{ __html: highlightedText }}
                />
              </Sequence>
            );
          } else if (template === "transpifyspecial") {
            const { style: transpifySpecialStyle, highlightedText } =
              getTranspifySpecialStyle(
                width,
                height,
                segment.text,
                durationInFrames,
                frame - startFrame,
                fps,
                config,
                `${segment.start}-${segment.end}`
              );
            return (
              <Sequence
                key={index}
                from={startFrame}
                durationInFrames={durationInFrames}
              >
                <div
                  style={transpifySpecialStyle}
                  dangerouslySetInnerHTML={{ __html: highlightedText }}
                />
              </Sequence>
            );
          } else if (template === "viral_trembling") {
            const viralTremblingStyle = getViralTremblingStyle(
              width,
              height,
              frame,
              config,
              `${segment.start}-${segment.end}`,
              fps
            );
            return (
              <Sequence
                key={index}
                from={startFrame}
                durationInFrames={durationInFrames}
              >
                <div style={viralTremblingStyle}>{segment.text}</div>
              </Sequence>
            );
          } else if (template === "oneword") {
            return segment.words.map((word: any, wordIndex: any) => {
              console.log(`Rendering word: ${word.word}, Index: ${wordIndex}`); // Debug output
              return (
                <WordSequence
                  key={`${index}-${wordIndex}`}
                  width={width}
                  height={height}
                  wordData={word}
                  globalStart={segment.start}
                  globalEnd={segment.end}
                  fps={fps}
                  config={config}
                />
              );
            });
          } else if (template === "hormozirobuck") {

            const startFrame = Math.floor(segment.start * fps);
            const durationInFrames = Math.floor((segment.end - segment.start) * fps);


            const hormoziRobuckStyle = getHormoziRobuckStyle(
              width,
              height,
              config,
              fps,
              durationInFrames,
              startFrame,
              frame
            );
            return (
              <Sequence
                key={index}
                from={startFrame}
                durationInFrames={durationInFrames}
              >
                <div style={hormoziRobuckStyle}>{segment.text}</div>
              </Sequence>
            );
          } else if (template === "karaoke") {
            const startFrame = Math.floor(segment.start * fps);
            const durationInFrames = Math.floor((segment.end - segment.start) * fps);

            return (
              <Sequence key={segment.id} from={startFrame} durationInFrames={durationInFrames}>
                <div style={getKaraokeStyle(width, height, config, frame, startFrame, durationInFrames, fps)}>
                  {segment.text}
                </div>
              </Sequence>
            );
          } else if (template === "luminous_echo") {
            const startFrame = Math.floor(segment.start * fps);
            const durationInFrames = Math.floor((segment.end - segment.start) * fps);

            const wordStyles = getLuminousEchoStyle(width, height, segment, config, frame, fps);

            return (
              <Sequence key={segment.id} from={startFrame} durationInFrames={durationInFrames}>
                <div style={{ position: 'absolute', top: `${config.position * 9.5}%` || "50%", left: '50%', transform: 'translate(-50%, -50%)', textAlign: "center", width: '90%' }}>
                  {wordStyles.map(({ word, style }: any, index: any) => (
                    <span key={index} style={style}>{word}</span>
                  ))}
                </div>
              </Sequence>
            );
          } else if (template === "reveal") {
            const startFrame = Math.floor(segment.start * fps);
            const durationInFrames = Math.floor((segment.end - segment.start) * fps);

            const wordStyles = getRevealStyle(width, height, segment, config, frame, fps);

            return (
              <Sequence key={segment.id} from={startFrame} durationInFrames={durationInFrames}>
                <div style={{ position: 'absolute', top: `${config.position * 9.5}%` || "50%", left: '50%', transform: 'translate(-50%, -50%)', textAlign: "center", width: '90%' }}>
                  {wordStyles.map(({ word, style }: any, index: any) => (
                    <span key={index} style={style}>{word}</span>
                  ))}
                </div>
              </Sequence>
            );
          } else if (template === "mrbeast") {
            // Calculate start frame and duration in frames based on the segment timings
            const startFrame = Math.floor(segment.start * fps);
            const durationInFrames = Math.floor((segment.end - segment.start) * fps);

            // Fetch the style settings from the getMrBeastStyle function
            const mrBeastStyle = getMrBeastStyle(width, height, segment, config, frame, fps, index, startFrame);

            // Render the sequence with the calculated style
            return (
              <Sequence key={segment.id} from={startFrame} durationInFrames={durationInFrames}>
                <div style={mrBeastStyle}>
                  {segment.text}
                </div>
              </Sequence>
            );
          }else if (template === "trutagem") {
            // Calculate start frame and duration in frames based on the segment timings
            const startFrame = Math.floor(segment.start * fps);
            const durationInFrames = Math.floor((segment.end - segment.start) * fps);

            // Fetch the style settings from the getTrutagemStyle function
            const trutagemStyle = getTrutagemStyle(width, height, segment, config, frame, fps, index, startFrame);

            // Render the sequence with the calculated style
            return (
              <Sequence key={segment.id} from={startFrame} durationInFrames={durationInFrames}>
                <div style={trutagemStyle}>
                  {segment.text}
                </div>
              </Sequence>
            );
          }else if (template === "waveform") {
            const startFrame = Math.floor(segment.start * fps);
            const durationInFrames = Math.floor((segment.end - segment.start) * fps);

            const { position, characterStyles } = getWaveformStyle(width, height, segment, config, frame, fps);

            return (
              <Sequence key={segment.id} from={startFrame} durationInFrames={durationInFrames}>
                <div style={position}>
                  {characterStyles.map(({ char, style }: any, index: any) => (
                    <span key={index} style={style}>{char}</span>
                  ))}
                </div>
              </Sequence>
            );
          }else if (template === "gradient_highlight") {
            const startFrame = Math.floor(segment.start * fps);
            const durationInFrames = Math.floor((segment.end - segment.start) * fps);

            const { wordStyles, activeText, activeTextStyle, inactiveTextStyle } = getGradientHighlightStyle(width, height, segment, config, frame, fps);

            return (
              <Sequence key={segment.id} from={startFrame} durationInFrames={durationInFrames}>
                <div style={{ position: 'absolute', top: `${config.position * 9.5}%` || "50%", left: '50%', transform: 'translate(-50%, -50%)', textAlign: "center", width: '90%', lineHeight: "1.2" }}>
                  <span style={activeTextStyle}>{activeText}</span> {/* Active text with gradient */}
                  {wordStyles.map(({ word, isActive }: any, index: any) => (
                    !isActive && <span key={index} style={inactiveTextStyle}>{word + " "}</span> // Inactive words styled differently
                  ))}
                </div>
              </Sequence>
            );
          } else if (template === "imangadhzi") {
            // Determine the current frame and calculate if the first line should be highlighted
            const startFrame = Math.floor(segment.start * fps);
            const durationInFrames = Math.floor((segment.end - segment.start) * fps);

            const { baseStyle, secondLineStyle, firstLine, secondLine, hasMultipleLines } =
              getImanGadhziStyle(width, height, segment, config, frame, fps, index, startFrame, durationInFrames);

            return (
              <Sequence key={segment.id} from={startFrame} durationInFrames={durationInFrames}>
                <div style={baseStyle}>{firstLine}</div>
                {hasMultipleLines && <div style={secondLineStyle}>{secondLine}</div>}
              </Sequence>
            );
          } else if (template === "neon") {
            // Default neon color if  not specified
            const startFrame = Math.floor(segment.start * fps);
            const durationInFrames = Math.floor((segment.end - segment.start) * fps);

            return (
              <Sequence key={segment.id} from={startFrame} durationInFrames={durationInFrames}>
                <div style={getNeonGlowStyle(width, height, config, frame, startFrame, durationInFrames, fps)}>
                  {segment.text}
                </div>
              </Sequence>
            );
          }
          return (
            <Sequence
              key={index}
              from={startFrame}
              durationInFrames={durationInFrames}
            >
              <div style={style}>{segment.text}</div>
            </Sequence>
          );
        }
        return null;
      })}



    </AbsoluteFill>
  );
};