import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import httpApi from '../../utils/httpApi';
import presets from '../../utils/presetsnew.json'


// Define a type for the slice state
export type iPosition = "top" | "center" | "bottom";

export interface IVideoEffect {
  broll: boolean,
  overlays: boolean,
  motionbg_on?: boolean,
  keyword_soundEffects_on: boolean,
  zoom_on: boolean,
  filters_on: boolean,
  scene_transitions_on: boolean,
  bg_music_on: boolean
  laptop_overlay_on?: boolean
  billboard_overlay_on?: boolean
  lightleaks_on?: boolean

}

export interface IMediaPlayer {
  font: string | undefined,
  variant: string | undefined,
  captionPosition: number,
  customCaption: boolean,
  captionColor: string[],
  fontSize: number,
  preset: string,
  videoToggle: boolean,
  language?: string
  italic: boolean,
  bold: boolean,
  underline: boolean,
  maxWords: number,
  visuals: string,
  effects?: IVideoEffect
}

type VideoStore = {
  [videoId: string]: IMediaPlayer;
};

const defaultVideo: IMediaPlayer = {
  font: "The Bold Font",
  variant: "",
  captionPosition: 6,
  customCaption: true,
  captionColor: ["#FFFFFF"],
  fontSize: 50,
  preset: "transpifyspecial",
  videoToggle: false,
  language: 'en',
  italic: false,
  bold: false,
  underline: false,
  maxWords: 4,
  visuals: 'none',
  effects: {
    broll: true,
    overlays: true,
    motionbg_on: true,
    keyword_soundEffects_on: true,
    zoom_on: true,
    filters_on: true,
    scene_transitions_on: true,
    bg_music_on: false,
    laptop_overlay_on: false,
    billboard_overlay_on: false,
    lightleaks_on: false,
  }
}


// font: "",
//   variant: "",
//   captionPosition: 100,
//   customCaption:false,
//   captionColor: {
//     primary: {
//       r: 255, g: 255, b: 255, a: 1
//     },
//     secondary: {
//       r: 228, g: 219, b: 0, a: 1
//     }
//   },
//   fontSize: 12,
//   preset: "",

// Define the initial state using that type
const initialState: VideoStore = {}



export const counterSlice = createSlice({
  name: 'mediaPlayer',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDefault: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload !== undefined)
        if (!(action.payload in state)) {
          state[action.payload] = defaultVideo
        }
    },
    DefaultChecker: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload !== undefined) {
        if (Object.keys(state).findIndex((nam) => nam == action.payload) == -1) {
          state[action.payload] = defaultVideo
        }
        if (action?.payload !== undefined) {
          let p = presets.find(({ name }) => name == state[action.payload as string].preset)
          if (p) {
            state[action.payload].captionColor = p.colors
          }
          else {
            state[action.payload].captionColor = ["#FFF"]

          }
        }
      }
    },
    setFont: (state, action: PayloadAction<{ videoId: string, font: string }>) => {
      const { videoId, font } = action.payload;

      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, font };
      } else {
        state[videoId].font = font;
      }
    },
    setVariant: (state, action: PayloadAction<{ videoId: string, variant: string }>) => {
      const { variant, videoId } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, variant };
      } else {
        state[videoId].variant = variant;
      }
    },
    setCaptionColor: (state: any, action: PayloadAction<{ videoId: string; captionColor: any }>) => {
      const { videoId, captionColor } = action.payload;
      if (!state[videoId]) {
        state[videoId] = { captionColor: captionColor };
      } else {
        state[videoId].captionColor = captionColor
      }
    },
    setCaptionPosition: (state, action: PayloadAction<{ videoId: string, captionPosition: number }>) => {
      const { videoId, captionPosition } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, captionPosition };
      } else {
        state[videoId].captionPosition = captionPosition;
      }
    },
    setCustomCaption: (state, action: PayloadAction<{ videoId: string, customCaption: boolean }>) => {
      const { videoId, customCaption } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, customCaption };
      } else {
        state[videoId].customCaption = customCaption;
      }
    },
    clearCaption: (state, action: PayloadAction<{ videoId: string }>) => {
      const { videoId } = action.payload
      state[videoId] = { ...defaultVideo, customCaption: state[videoId].customCaption }
    },
    setPresets: (state, action: PayloadAction<IMediaPlayer & { videoId: string }>) => {
      const { font, variant, captionColor, fontSize, preset, videoId, maxWords } = action.payload;
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, font, variant, captionColor, fontSize, preset };
      } else {
        state[videoId].captionColor = captionColor;
        state[videoId].font = font;
        state[videoId].variant = variant;
        state[videoId].fontSize = fontSize;
        state[videoId].preset = preset;
        state[videoId].maxWords = maxWords
      }
    },

    setFontSize: (state, action: PayloadAction<{ videoId: string, fontSize: number }>) => {
      const { videoId, fontSize } = action.payload
      console.log("fontSize", fontSize, videoId)

      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, fontSize };
      } else {
        state[videoId].fontSize = fontSize;
      }
    },
    setVideoToggle: (state, action: PayloadAction<{ videoId: string, videoToggle: boolean }>) => {
      const { videoId, videoToggle } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, videoToggle };
      } else {
        state[videoId].videoToggle = videoToggle;
      }
    },

    setLanguage: (state, action: PayloadAction<{ videoId: string, language: string }>) => {
      const { videoId, language } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, language };
      } else {
        state[videoId].language = language;
      }
    },

    setItalic: (state, action: PayloadAction<{ videoId: string, italic: boolean }>) => {
      const { videoId, italic } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, italic };
      } else {
        state[videoId].italic = italic;
      }
    },

    setBold: (state, action: PayloadAction<{ videoId: string, bold: boolean }>) => {
      const { videoId, bold } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, bold };
      } else {
        state[videoId].bold = bold;
      }
    },

    setUnderLine: (state, action: PayloadAction<{ videoId: string, underline: boolean }>) => {
      const { videoId, underline } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, underline };
      } else {
        state[videoId].underline = underline;
      }
    },

    setPresetsCustom: (state, action: PayloadAction<{ videoId: string }>) => {
      const { videoId } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, preset: "default" };
      } else {
        state[videoId].preset = "default";
      }
    },

    setMaxWords: (state, action: PayloadAction<{ videoId: string, maxWords: number }>) => {
      const { videoId, maxWords } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, maxWords };
      } else {
        state[videoId].maxWords = maxWords;
      }
    },

    setVisuals: (state, action: PayloadAction<{ videoId: string, visuals: string }>) => {
      const { videoId, visuals } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, visuals };

      } else {
        state[videoId].visuals = visuals;
        state[videoId].maxWords = 3;
        // state[videoId].captionPosition = 9;
      }
    },

    setEffects: (state, action: PayloadAction<{ videoId: string, effects: IVideoEffect }>) => {
      const { videoId, effects } = action.payload
      if (!state[videoId]) {
        state[videoId] = { ...defaultVideo, effects };

      } else {
        state[videoId].effects = effects;
        state[videoId].maxWords = 3;
        // state[videoId].captionPosition = 9;
      }
    },
  },
})

export const mediaPlayerAction = counterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.media

export default counterSlice.reducer