import { IconButton } from "@mui/material";
import toast from "react-hot-toast";

const NumberButton = ({ num, onChange, PlatformDisabledVideo, maxWords }: any) => {
    return (
        <div>
            {Array.from({ length: num }, (_, i) => i + 1).map((val, i) => (
                <IconButton
                    key={i}
                    className={(val == 1 || val == 2) ? "" : 'box-shadoww'}
                    // disabled={ }
                    sx={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '3px',
                        backgroundColor: val == maxWords ? "#C7E2F1" : '#FFF',
                        border: '0.9px solid #D9D9D9',
                        marginRight: "4px"
                    }}
                    onClick={() => (val == 1 || val == 2) ? toast("1 word template is under development. This preset only allow 3 words.") : PlatformDisabledVideo && onChange(val)}
                    aria-label="bold"
                >
                    <p className='number-button'>{val}</p>
                </IconButton>
            ))
            }
        </div >
    );
}

export default NumberButton;