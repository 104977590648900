const steps = [
    {
        selector: '.first-step',
        content: 'Welcome: Get started with our Text to Speech platform offering lifelike audio and voice cloning.',
    },
    {
        selector: '.second-step',
        content: 'Text Input: Type your desired text here for instant audio conversion.',
    },
    {
        selector: '.third-step',
        content: 'Voice Selection: Choose a voice from the dropdown to match your desired tone and style.',

    },
    {
        selector: '.fifth-step',
        content: 'Quota Info: Keep track of your available character quota right here.',

    },
    {
        selector: '.sixth-step',
        content: "Generate Speech Click 'Generate Speech' to turn your text into realistic audio.",
        disableActions: false

    }
]

export { steps }