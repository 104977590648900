import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

function SearchInput({ onChange, value }: any) {
    return (
        <TextField
            label="Search"
            variant="outlined"
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    );
}

export default SearchInput;
