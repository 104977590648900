import React from 'react';
import {
  Sequence,
  OffthreadVideo,
  useVideoConfig,
  staticFile,
} from 'remotion';



const sortSegmentedVideoUris = (uris: string[]) => {
  return uris.map(uri => {
    // Extract the segment number from the filename in the URI
    const match = uri.match(/segmented_video_(\d+)/);
    const segmentNumber = match ? parseInt(match[1], 10) : 0;
    return { uri, segmentNumber };
  })
  .sort((a, b) => a.segmentNumber - b.segmentNumber) // Sort by segment number
  .map(item => item.uri); // Return sorted URIs
};


export const SegmentedVideosMotionBg = ({ segments,zoomStyle,segmented_video_uris }:any) => {
    const { fps } = useVideoConfig();

    const sortedUris = sortSegmentedVideoUris(segmented_video_uris);
  
  // Group segments by motionbg_key and use the corresponding URL from segmented_video_uris
  const groups = segments.reduce((acc: any, segment: any) => {
    if (segment.motionbg) {
      const key = segment.motionbg_key;
      if (!acc[key]) {
        acc[key] = {
          uri: segmented_video_uris[key - 1], // Fetch the URI using 1-based index from motionbg_key
          start: segment.start,
          end: segment.end,
        };
      } else {
        acc[key].start = Math.min(acc[key].start, segment.start);
        acc[key].end = Math.max(acc[key].end, segment.end);
      }
    }
    return acc;
  }, {});
  
    // Render each group as a video sequence
    return (
      <React.Fragment>
        {Object.values(groups).map((group:any, index:any) => (
          <Sequence
            key={index}
            from={Math.floor(group.start * fps)}
            durationInFrames={Math.ceil((group.end - group.start) * fps)}
            style={{ zIndex: 5 }} // Set zIndex as needed
          >
            <OffthreadVideo
              src={group.uri}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                ...zoomStyle,
              }}
              transparent
              volume={0}
            />
          </Sequence>
        ))}
      </React.Fragment>
    );
  };

