import React, { useMemo, useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Avatar, useMediaQuery, Container, Tooltip } from '@mui/material';

//css
import "./style.css";
import GradientButton from '../../components/Button/gradient';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { discordWebhook } from '../../redux/slices/feedbacks.slice';
import packageData from '../../utils/packageData';
import ExperimentalModal from '../../components/ExpermantalModal';

const icons: any = {
  "Starter Plan": {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="25" viewBox="0 0 17 25" fill="none">
        <path d="M13.5241 0.106934C13.6753 0.195583 13.7924 0.333468 13.8561 0.498064C13.9198 0.662661 13.9264 0.844238 13.8749 1.01313L11.0916 10.1564H16.227C16.3779 10.1563 16.5255 10.2009 16.6517 10.2847C16.7778 10.3685 16.8769 10.4878 16.9367 10.6279C16.9965 10.768 17.0145 10.9227 16.9883 11.073C16.9622 11.2232 16.893 11.3625 16.7895 11.4735L4.42633 24.754C4.3067 24.8826 4.14752 24.9664 3.97472 24.9919C3.80193 25.0173 3.6257 24.9828 3.47478 24.894C3.32386 24.8052 3.20713 24.6673 3.14363 24.5029C3.08013 24.3384 3.0736 24.1571 3.1251 23.9884L5.90837 14.8436H0.773007C0.622091 14.8437 0.474461 14.799 0.348341 14.7152C0.222221 14.6314 0.123132 14.5121 0.0633065 14.3721C0.00348114 14.232 -0.0144617 14.0773 0.0116927 13.927C0.0378472 13.7767 0.106954 13.6375 0.210482 13.5265L12.5737 0.245989C12.6931 0.117557 12.8521 0.0337642 13.0246 0.00823436C13.1972 -0.0172955 13.3732 0.0169391 13.5241 0.105372V0.106934Z" fill="#A2A2A2" />
      </svg>
    ), color: "#32A6F9"
  },
  "Creator Plan": {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M18 0L16.74 2.75L14 4L16.74 5.26L18 8L19.25 5.26L22 4L19.25 2.75M8 3L5.5 8.5L0 11L5.5 13.5L8 19L10.5 13.5L16 11L10.5 8.5M18 14L16.74 16.74L14 18L16.74 19.25L18 22L19.25 19.25L22 18L19.25 16.74" fill="white" />
      </svg>
    ), color: '#0038FF'
  },
  "Influencer Plan": {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
        <path d="M3.38553 1.83524e-06H5.34311L3.37991 5.6988H0L2.54897 0.524292C2.62676 0.366725 2.7462 0.234235 2.89394 0.141643C3.04169 0.0490503 3.21189 7.74934e-06 3.38553 1.83524e-06ZM0.0673737 7.5984L5.70805 16.5018L3.32938 7.5984H0.0673737ZM5.26825 7.5984L8.13163 18.3216C8.18237 18.5161 8.29505 18.6881 8.45212 18.8108C8.60918 18.9335 8.80181 19 9 19C9.19819 19 9.39082 18.9335 9.54788 18.8108C9.70495 18.6881 9.81763 18.5161 9.86837 18.3216L12.7392 7.5984H5.26825ZM14.6781 7.5984L12.2938 16.498L17.9326 7.5984H14.6781ZM18 5.6988H14.6257L12.6606 1.83524e-06H14.6145C14.7884 -0.000345199 14.959 0.0485309 15.1071 0.141143C15.2552 0.233755 15.375 0.366437 15.4529 0.524292L18 5.6988ZM12.6438 5.6988H5.36369L7.32502 1.83524e-06H10.675L12.6438 5.6988Z" fill="#A2A2A2" />
      </svg>
    ), color: "#4E32F9"
  }
};

const Header: React.FC = () => {
  const navigation = useNavigate()
  const dispatch = useAppDispatch()
  const user = useSelector((state: RootState) => state.auth.user)
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isButton = useMediaQuery('(max-width: 850px)');
  const [expModal,setExpModal] = useState(false)
 
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const PKG = useMemo(() => {
    if (user?.subscriptionPlan == null) return null
    return packageData.find(data => {
      if (data.name == "Free") return false
      return data.cycle.find(cy => cy.subscriptionId == user?.subscriptionPlan.id);
    }) // filters out undefined or null values
  }, [user?.subscriptionPlan]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch({
      type: "auth/setLogout", payload: {}
    })
    dispatch(discordWebhook("logout"))

    setAnchorElUser(null)
    setAnchorEl(null)
    navigation("/signin")

  }

  const settings = ['Pricing',];

  return (
    <>
      {isMobile &&
        <AppBar position="relative" sx={{ boxShadow: 'none', backgroundColor: '#FFFFFF' }}>
          <Toolbar>
            {/* Logo */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'flex-start' }}>
              <a href="/" className="-m-1.5 p-1.5 flex items-center">
                <span className="sr-only">Transpify</span>
                <img
                  className="h-8 w-auto"
                  src="/logo.png"
                  alt=""
                  style={{ height: isMobile ? "30px" : "100%" }}
                />
              </a>
            </div>

            {/* User Profile Menu */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
              <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user?.fullName} src={user?.pictureUrl || "/avatar.png"} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key={"profile"} onClick={() => navigation("/profile")}>
                    <Typography textAlign="center">Profile</Typography>
                  </MenuItem>
                  <MenuItem key={"pricing"} onClick={() => navigation("/pricing")}>
                    <Typography textAlign="center">Pricing</Typography>
                  </MenuItem>
                  {isAuthenticated ? <MenuItem key={"logout"} onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem> :
                    <MenuItem key={"login"} onClick={() => navigation("/signin")}>
                      <Typography textAlign="center">Login</Typography>
                    </MenuItem>
                  }

                </Menu>
              </div>
            </div>

            {!isMobile && (
              <div style={{ marginLeft: isMobile ? 0 : 16 }}>
                <Typography className="profile-title">{user?.fullName}</Typography>
                <Typography className="profile-username">{user?.username}</Typography>
              </div>
            )}

            {/* Main App Menu */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {isAuthenticated ? <MenuItem onClick={handleLogout}>Logout</MenuItem> :
                <MenuItem onClick={() => navigation("/signin")}>Login</MenuItem>}
            </Menu>
          </Toolbar>
        </AppBar>
      }
      {!isMobile &&
        <AppBar position="relative" sx={{ boxShadow: 'none', backgroundColor: '#FFFFFF', zIndex: 1, border: '1px solid #DDD;' }}>
          <Container maxWidth={false} sx={{ width: '100%', maxWidth: '100%', backgroundColor: 'white' }}>
            <Toolbar sx={{ backgroundColor: "white" }}>
              {/* Logo */}
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                <a href="/" className="-m-2.5 p-1.5 flex items-center">
                  <span className="sr-only">ViralMe</span>
                  <img
                    className="h-8 w-auto"
                    src="/logo.png"
                    alt=""
                    style={{ height: isMobile ? "22px" : "50px" }}
                  />
                </a>

                <div onClick={()=>setExpModal(true)} className='expermantal-div'>
                  <p>
                    Experimental Launch
                  </p>
                </div>
              </div>

              {/* User Profile */}
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
                {!user?.subscriptionStatus && !isButton && (
                  <div style={{ marginRight: 20 }}>
                    <GradientButton onClick={() => navigation("/pricing")}>
                      Upgrade to Flexible
                    </GradientButton>
                  </div>
                )}

                {PKG && PKG?.name &&
                  <div style={{ color: icons[PKG?.name].color }} className="plan-button-active">
                    {icons[PKG?.name].icon}
                    <p>{PKG?.name}</p>
                  </div>
                }
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt={user?.fullName} src={user?.pictureUrl || "/avatar.png"} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem key={"profile"} onClick={() => navigation("/profile")}>
                      <Typography textAlign="center">{"Profile"}</Typography>
                    </MenuItem>
                    {isAuthenticated ? <MenuItem onClick={handleLogout}>Logout</MenuItem> :
                      <MenuItem onClick={() => navigation("/signin")}>Login</MenuItem>}
                  </Menu>
                </div>
              </div>

              {!isMobile && (
                <div className="name-email-profile" style={{ marginLeft: isMobile ? 0 : 16 }}>
                  <Typography className="profile-title">{user?.fullName || "-"}</Typography>
                  <Typography className="profile-username">{user?.email}</Typography>
                </div>
              )}
            </Toolbar>
          </Container>
        </AppBar >
      }
      <ExperimentalModal setOpen={setExpModal} open={expModal}></ExperimentalModal>
    </>
  );
};

export default Header;
