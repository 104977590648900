import React from 'react';
import './PlanCard.css';

const icons: any = {
  "Starter Plan": (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="27" viewBox="0 0 17 27" fill="none">
      <path d="M13.5241 0.298711C13.6753 0.390906 13.7924 0.534307 13.8561 0.705487C13.9198 0.876667 13.9264 1.06551 13.8749 1.24116L11.0916 10.7501H16.227C16.3779 10.7501 16.5255 10.7965 16.6517 10.8836C16.7778 10.9708 16.8769 11.0948 16.9367 11.2405C16.9965 11.3862 17.0145 11.5471 16.9883 11.7034C16.9622 11.8597 16.893 12.0045 16.7895 12.1199L4.42633 25.9316C4.3067 26.0654 4.14752 26.1526 3.97472 26.179C3.80193 26.2055 3.6257 26.1696 3.47478 26.0772C3.32386 25.9849 3.20713 25.8415 3.14363 25.6705C3.08013 25.4995 3.0736 25.3109 3.1251 25.1354L5.90837 15.6248H0.773007C0.622091 15.6249 0.474461 15.5785 0.348341 15.4914C0.222221 15.4042 0.123132 15.2801 0.0633065 15.1345C0.00348114 14.9888 -0.0144617 14.8279 0.0116927 14.6716C0.0378472 14.5153 0.106954 14.3705 0.210482 14.255L12.5737 0.443328C12.6931 0.309759 12.8521 0.222615 13.0246 0.196064C13.1972 0.169513 13.3732 0.205117 13.5241 0.297087V0.298711Z" fill="white" />
    </svg>
  ),
  "Creator Plan": (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M18 0L16.74 2.75L14 4L16.74 5.26L18 8L19.25 5.26L22 4L19.25 2.75M8 3L5.5 8.5L0 11L5.5 13.5L8 19L10.5 13.5L16 11L10.5 8.5M18 14L16.74 16.74L14 18L16.74 19.25L18 22L19.25 19.25L22 18L19.25 16.74" fill="white" />
    </svg>
  ),
  "Influencer Plan": (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
      <path d="M3.94978 2.12502e-06H6.23362L3.94323 6.59861H0L2.9738 0.607074C3.06455 0.424629 3.20391 0.27122 3.37627 0.164007C3.54863 0.056795 3.74721 8.97292e-06 3.94978 2.12502e-06ZM0.0786026 8.79815L6.65939 19.1074L3.88428 8.79815H0.0786026ZM6.14629 8.79815L9.4869 21.2145C9.5461 21.4397 9.67756 21.6388 9.8608 21.7809C10.044 21.923 10.2688 22 10.5 22C10.7312 22 10.956 21.923 11.1392 21.7809C11.3224 21.6388 11.4539 21.4397 11.5131 21.2145L14.8624 8.79815H6.14629ZM17.1245 8.79815L14.3428 19.103L20.9214 8.79815H17.1245ZM21 6.59861H17.0633L14.7707 2.12502e-06H17.0502C17.2532 -0.000399704 17.4522 0.0561936 17.625 0.163429C17.7978 0.270664 17.9375 0.424295 18.0284 0.607074L21 6.59861ZM14.7511 6.59861H6.25764L8.54585 2.12502e-06H12.4541L14.7511 6.59861Z" fill="white" />
    </svg>
  )
};

const backgrounds: any = {
  "Free": '/FreeBadge.png',
  "Starter Plan": '/StarterBadge.png',
  "Creator Plan": '/CreatorBadge.png',
  "Influencer Plan": '/InfluencerBadge.png',
  "Reward": '/rewards.png'
};

const PlanCard = ({ plan, onClick, type }: any) => {
  return (<>
    {plan === 'Reward' ?
      <div className="plan-card-reward" onClick={onClick} style={{ backgroundImage: `url(${backgrounds[plan]})`, cursor: "pointer" }}>
      </div>
      :
      plan == 'Manage Billing' ?
        <div className="plan-card" onClick={onClick} style={{ backgroundImage: `url(${backgrounds[plan] ? backgrounds[plan] : '/ManageBilling.png'})`, cursor: "pointer" }}>
          <div className="plan-card-content-manage" style={{ justifyContent: 'center' }}>
            {icons[plan] && <div className="plan-icon">{icons[plan]}</div>}
            <div className="plan-text">
              {plan === 'Reward' ? "" : (plan === 'Manage Billing' ? `${plan}` : `${plan}`)}
            </div>
            {plan !== 'Reward' && plan !== 'Manage Billing' && <div className="update-plan"><p className="update-plan-text">Update Plan</p></div>}
          </div>
        </div> : plan == "Influencer Plan" ?
          <div className="plan-card" onClick={onClick} style={{ backgroundImage: `url(${backgrounds[plan] ? backgrounds[plan] : '/ManageBilling.png'})`, cursor: "pointer" }}>
            <div className="plan-card-content-manage" style={{ justifyContent: 'center' }}>
              {icons[plan] && <div className="plan-icon">{icons[plan]}</div>}
              <div className="plan-text">
                {plan === 'Reward' ? "" : (plan === 'Manage Billing' ? `${plan}` : `${plan}`)}
              </div>
            </div>
          </div> :
          <div className="plan-card" onClick={onClick} style={{ backgroundImage: `url(${backgrounds[plan] ? backgrounds[plan] : '/ManageBilling.png'})`, cursor: "pointer" }}>
            <div className="plan-card-content">
              {icons[plan] && <div className="plan-icon">{icons[plan]}</div>}
              {plan !== 'Reward' && plan !== 'Manage Billing' && <div style={{ color: 'transparent' }}>a</div>}
              <div className="plan-text">
                {plan === 'Reward' ? "" : (plan === 'Manage Billing' ? `${plan}` : `${plan}`)}
              </div>
              {plan !== 'Reward' && plan !== 'Manage Billing' && plan !== "Influencer Plan" && <div className="update-plan"><p className="update-plan-text">Update Plan</p></div>}
            </div>
          </div>
    }
  </>
  );
};

export default PlanCard;
