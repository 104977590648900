import './style.css'
import React, { useMemo, useState, useEffect } from 'react';
import { Modal, Box, Typography, useMediaQuery, useTheme, Button, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import SearchInput from './SearchInput';
import MusicLibrary from '../../utils/music-library.json'
import { PlayArrow, Pause } from '@mui/icons-material';
import { extractGenres } from '../../utils/functions';



const MusicModal = ({ open, selectMusic }: any) => {
    const navigate = useNavigate()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [currentPlayingURL, setCurrentPlayingURL] = React.useState<string | null>(null);
    const [musicIndex, setMusicIndex] = React.useState<string | null>(null)
    const audioRef = React.useRef(new Audio());
    const [search, setSearch] = useState('')
    const musics = useMemo(() => {
        if (!search) return MusicLibrary; // if no search term, return the whole library

        return MusicLibrary.filter(music =>
            music.music_name.toLowerCase().includes(search.toLowerCase()) ||
            music.artist_name.toLowerCase().includes(search.toLowerCase()) ||
            music.genre.toLowerCase().includes(search.toLowerCase())
        );
    }, [search]);
    const genres = useMemo(() => extractGenres(MusicLibrary as any), [])

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '90%' : 600,
        maxWidth: '90%',
        bgcolor: 'background.paper',
        border: '2px solid white',
        borderRadius: 10,
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "70%"
    };

    const handlePlayPauseMusic = (url: string) => {
        if (currentPlayingURL === url) {
            if (audioRef.current.paused) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        } else {
            if (!audioRef.current.paused) {
                audioRef.current.pause();
            }
            audioRef.current = new Audio(url);
            audioRef.current.play();
            setCurrentPlayingURL(url);
        }
    };


    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    backdropFilter: "blur(10px)", // This is the CSS property that blurs the background
                }
            }}
        >
            <Box sx={style}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}> <Button onClick={() => {
                    if (!audioRef?.current?.paused) {
                        audioRef.current.pause();
                    }
                    selectMusic(musicIndex)
                }}>Done</Button></div>
                <SearchInput onChange={setSearch} value={search} />
                {/* <div style={{ display: 'flex', }}>
                    {genres.map((name) =>
                        <div><p>{name}</p></div>)}
                </div> */}
                <div style={{ width: "100%", overflowY: "auto" }}>
                    {musics.map(({ thumbnail_url, music_name, artist_name, audio_url }) =>
                        <div key={audio_url} onClick={() => setMusicIndex(audio_url)} className='cardmusic' style={{ display: 'flex', padding: 10, marginTop: 10, position: 'relative', backgroundColor: audio_url === musicIndex ? '#F0F0F0' : "white", cursor: 'pointer' }}>
                            <div style={{ width: 50, position: 'relative' }}>
                                <img style={{ width: 50, height: 50, borderRadius: 50 }} src={thumbnail_url} />
                                <IconButton
                                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                    onClick={() => handlePlayPauseMusic(audio_url)}
                                    color="primary"
                                >
                                    {currentPlayingURL === audio_url && !audioRef.current.paused ? <Pause htmlColor='white' /> : <PlayArrow htmlColor='white' />}
                                </IconButton>
                            </div>
                            <div style={{ marginLeft: 10, }}>
                                <p className='title'>{music_name}</p>
                                <p className='subtitle'>{artist_name}</p>
                            </div>
                        </div>
                    )}
                </div>
            </Box>
        </Modal>
    );
}

export default MusicModal;
