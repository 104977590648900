// httpApi.ts
import { store } from '../redux/store'; // Import your Redux store
import axios from 'axios';
import { SEVER_URL } from './url';

const httpApi = axios.create({
  baseURL: SEVER_URL,
});

// Request Interceptor to set Authorization Header
httpApi.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.t_token // Get token from localStorage

    if (token && token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Access-Control-Allow-Origin'] = '*';

   
    return config;
  },
  (error) => Promise.reject(error)
);

export default httpApi;

// Response Interceptor to handle Token Expiry and Refresh
// httpApi.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     // Assuming 401 is returned when token is expired. Adjust as per your API's contract.
//     if (error.response.status === 401 && !originalRequest._retry) {
//     const token = localStorage.getItem('token'); // Get token from localStorage

//       originalRequest._retry = true;

//     if (token && token !== null) return httpApi(originalRequest);

//       // Assuming you have an API endpoint to refresh tokens
//       const refreshTokenResponse = await httpApi.post('/refresh-token-endpoint');

//       // Extract new token, save it in your store and also in localStorage
//       const newToken = refreshTokenResponse.data.token;
//       localStorage.setItem('token', newToken); // Save token to localStorage
//       store.dispatch({ type: 'UPDATE_TOKEN', payload: newToken }); // Dispatch an action to save this token in your store

//       originalRequest.headers.Authorization = `Bearer ${newToken}`;
//       return httpApi(originalRequest);
//     }

//     return Promise.reject(error);
//   }
// );