import React, { useEffect, useState, useContext, useCallback, useRef, useMemo } from 'react';
import './style.css';
import useVideo from '../../hooks/useVideo';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getGoogleFonts, modifySubtitle } from '../../redux/slices/video.slice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { logEvent } from '../../utils/ga.utils';
import axios from 'axios';
import { BASE_URL } from '../../utils/url';
import MusicLibrary from '../../utils/music-library.json';
import { VideoContext } from '../../context/video.context';
import InteractiveLayer from '../SubtitleEbith/InteractiveLayer';
import { getVideoOrientationHeight } from '../../utils/functions';
import { MyRemotionPlayer } from '../RemotionPlayer/MyRemotionPlayer';
import { AIStudioRemotionPlayer } from '../AiStudioRemotion/AIStudioRemotionPlayer';
import AIShortsRemotionPlayer from '../AiShortsRemotion/RemotionPlayer';
import Slider, { Settings } from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface MyType {
    [key: string]: string;
}

function VideoPlayer({ isOpen, setIsOpen, setCurrentStep, isXs, videoTab }: any) {
    const dispatch = useAppDispatch();
    const contextValue = useContext(VideoContext);
    let sliderRef = useRef<Slider>(null);


    if (!contextValue) {
        throw new Error("VideoContext value is not provided");
    }

    const { subtitleJSON, mediaPlayerRef, canvasRef, audioRef, setCurrentTime, jsonAIStudio, setJsonAIStudio } = contextValue;
    const { font, videoId, user, updateLanguage, updateFont } = useVideo();

    const fileUrls = useSelector((state: RootState) => state.video.videoFile);
    const aishortsIndex = useSelector((state: RootState) => state.video.aishortsIndex);
    const musicId = useSelector((state: RootState) => state.video.musicId);
    const fonts = useSelector((state: RootState) => state.video.fonts);
    const isLoadingVideo = useAppSelector((state: RootState) => state.video.isLoading);

    const [updatedSubtitles, setUpdatedSubtitles] = useState<MyType | {}>({});
    const [videoLoading, setVideoLoading] = useState(false);
    const [isIPhone, setIsIPhone] = useState(false);
    const [videoSize, setVideoSize] = useState<number[]>([1280, 720]);
    const [activeIndex, setActiveIndex] = useState(0);
    const aishortsIndexValue = useMemo(() => {
        let i = aishortsIndex?.[fileUrls?.video_id_old || 0]
        sliderRef?.current?.slickGoTo(i)
        return i
    }, [fileUrls, aishortsIndex])
    const [setting, setSetting] = useState({
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: 'ondemand' as 'ondemand' | 'progressive', // Ensure correct type
        beforeChange: (current: any, next: any) => changeVideoIndex(next),
        initialSlide: aishortsIndex?.[videoId] || 0
    })


    // const updateCanvasSize = useCallback(() => {
    //     const video = mediaPlayerRef.current;
    //     const canvas = canvasRef.current;

    //     if (!video || !canvas) return;

    //     const ctx = canvas.getContext('2d');
    //     if (!ctx) return;

    //     const videoWidth = 1280;
    //     const videoHeight = 720;
    //     const aspectRatio = videoHeight / videoWidth;

    //     canvas.width = videoWidth;
    //     canvas.height = videoWidth * aspectRatio;

    //     if (!video.paused && !video.ended) {
    //         ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    //     }
    // }, [mediaPlayerRef, canvasRef]);

    // useEffect(() => {
    //     const video = mediaPlayerRef.current;
    //     const canvas = canvasRef.current;

    //     if (!video || !canvas) return;

    //     updateCanvasSize();

    //     const handleResize = () => {
    //         requestAnimationFrame(updateCanvasSize);
    //     };

    //     // window.addEventListener('resize', handleResize);

    //     return () => {
    //         // window.removeEventListener('resize', handleResize);
    //     };
    // }, [updateCanvasSize, mediaPlayerRef, canvasRef]);

    useEffect(() => {
        if (!fonts?.length) {
            dispatch(getGoogleFonts());
        }
    }, [fonts, dispatch]);

    // useEffect(() => {
    //     let instance: any = null;

    //     async function OctopusOnVideo() {
    //         if (fileUrls?.urls && fileUrls?.urls.ass_original_file && mediaPlayerRef.current && fonts && fonts.length > 0) {
    //             const selectedFont = fonts?.find(({ family }: any) => family === font);
    //             const options = {
    //                 video: mediaPlayerRef.current,
    //                 subUrl: fileUrls.urls.ass_original_file,
    //                 fonts: ['/fonts/The-Bold-Font.ttf', selectedFont?.files?.regular || ''],
    //                 workerUrl: '/js/subtitles-octopus-worker.js',
    //                 legacyWorkerUrl: '/js/subtitles-octopus-worker-legacy.js'
    //             };
    //             try {
    //                 if (instance) {
    //                     instance.dispose();
    //                     instance = null;
    //                 }

    //                 instance = new (window as any).SubtitlesOctopus(options);
    //                 instance.setTrackByUrl(fileUrls?.urls.ass_original_file);
    //             } catch (e) {
    //                 console.log(e);
    //             }
    //         }
    //     }
    //     OctopusOnVideo();

    //     return () => {
    //         if (instance) {
    //             instance.dispose();
    //         }
    //     };
    // }, [fileUrls?.urls.ass_original_file, isLoadingVideo]);

    useEffect(() => {
        if (fileUrls && fileUrls.hasOwnProperty("language")) {
            updateLanguage(fileUrls?.language || "en");
            if (fileUrls?.language == "ko") updateFont("NotoSansKR");
            else if (fileUrls?.language == "ar") updateFont("NotoSansArabic");
        }
    }, [fileUrls, fileUrls?.language]);

    const updateModification = () => {
        if (videoId && user?.id)
            if (fileUrls && fileUrls?.language) {
                let temp = JSON.parse(JSON.stringify(subtitleJSON));
                temp.ori_dict.segments = temp.segments;

                let data = new FormData();
                data.append('video_id', videoId);
                data.append('user_id', user?.id);
                data.append('lang', fileUrls?.language);
                data.append('modified_json', JSON.stringify(temp));
                logEvent({ category: "Video", action: 'Update Subtitle', label: videoId });

                dispatch(modifySubtitle({ form: data, videoId: videoId }));
                setUpdatedSubtitles({});
            }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (fileUrls?.urls?.edited_json_uris[aishortsIndexValue]) {
                    const { data } = await axios.get(fileUrls?.urls?.edited_json_uris[aishortsIndexValue]);
                    setJsonAIStudio(data);
                }
            } catch (e) { console.log(e) }
        }
        fetchData()
    }, [aishortsIndexValue])

    useEffect(() => {
        setIsIPhone(/iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream);
    }, []);

    const getZipFileUrlForIPhone = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/download-zip`, {
                params: {
                    files: [
                        fileUrls?.urls?.ass_original_file,
                        fileUrls?.urls?.vtt_original_file,
                        fileUrls?.urls?.srt_original_file
                    ]
                },
                responseType: 'blob',
            });
            return URL.createObjectURL(new Blob([response.data]));
        } catch (error) {
            console.error('Error downloading zip file:', error);
        }
    };

    useEffect(() => {
        if (!musicId) return;
        const videoElement = mediaPlayerRef.current;
        const audioElement = audioRef.current;

        if (!videoElement || !audioElement) return;

        const handlePlay = () => {
            audioElement.play();
        };

        const handlePause = () => {
            audioElement.pause();
        };

        const handleSeeked = () => {
            audioElement.currentTime = videoElement.currentTime;
        };

        videoElement.addEventListener('play', handlePlay);
        videoElement.addEventListener('pause', handlePause);
        videoElement.addEventListener('seeked', handleSeeked);

        return () => {
            videoElement.removeEventListener('play', handlePlay);
            videoElement.removeEventListener('pause', handlePause);
            videoElement.removeEventListener('seeked', handleSeeked);
        };
    }, [musicId]);

    useEffect(() => {
        function handleResize() {
            if (isXs) {
                let ss = getVideoOrientationHeight(fileUrls?.resolution);
                ss[1] = 520;
                setVideoSize(ss);
            } else {
                setVideoSize(getVideoOrientationHeight(fileUrls?.resolution));
            }
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [fileUrls?.resolution]);
    useEffect(() => {
        sliderRef?.current?.slickGoTo(aishortsIndex?.[fileUrls?.video_id_old || 0])
    }, [sliderRef])


    const changeVideoIndex = (next: number) => {

        dispatch({ type: "video/updateIndexVideoId", payload: { newVideoId: `${fileUrls?.video_id_old}-${next}`, next } })

    }

    const formatTime = (seconds: number): string => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        let formattedTime = '';

        if (hours > 0) {
            formattedTime += `${hours} hr`;
        }

        if (minutes > 0) {
            if (hours > 0) {
                formattedTime += ' ';
            }
            formattedTime += `${minutes} min`;
        }

        return formattedTime || '0 min'; // Return "0 min" if no hours or minutes
    };

    // const videoIdFirstPart = videoId?.split("-")?.[0];
    // const aishortsIndexValue = aishortsIndex?.[videoIdFirstPart];

    console.log('videoId:', videoId);
    console.log('jsonAIStudio:', jsonAIStudio);
    console.log('aishortsIndex:', aishortsIndex);
    console.log('aishortsIndexValue:', aishortsIndexValue);

    return (
        <div style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'center', height: isXs ? 250 : getVideoOrientationHeight(fileUrls?.resolution)[1] + 50 }}>
            <div className='disable-fullscreen' style={{ position: 'relative', width: isXs ? '100%' : '70%' }}>
                <Slider {...setting} ref={sliderRef}>
                    {fileUrls?.urls?.trimmed_videos_uris.map((uri: string, i: number) => (
                        <div style={{ height: '1920px' }} key={i}>
                            {i === (aishortsIndexValue || 0) ? <AIShortsRemotionPlayer uri={uri} index={i} /> : null}
                        </div>
                    ))}
                </Slider>
                {!isXs && <div className="slider-info">
                    <p>{`${Number(videoId.split("-")?.[1]) + 1} of ${fileUrls?.urls?.trimmed_videos_uris?.length} Viral Shorts from ${formatTime(fileUrls?.video_duration || 40)} Video`}</p>
                </div>}
                <div className="vtitle"><p><span> TITLE:</span> {jsonAIStudio?.video_meta_data?.title}</p></div>
            </div>
        </div>
    )
}

export default VideoPlayer;
