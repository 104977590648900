

import React from 'react';
import { AbsoluteFill, OffthreadVideo, Sequence, useCurrentFrame, useVideoConfig } from 'remotion';

export const VerticalSplitLeftRight = ({
    videoLink,
    totalVideoWidth,
    leftFace,
    rightFace,
    overlayDelay
}: {
    videoLink: string;
    totalVideoWidth: number;
    leftFace: { x: number, y: number, width: number, height: number };
    rightFace: { x: number, y: number, width: number, height: number };
    overlayDelay: any;  
}) => {
    const { width, height } = useVideoConfig(); // This should be 1080x1920 for portrait
    const frame = useCurrentFrame();

    if (leftFace.x > rightFace.x) {
        // Swap the faces if the left face is on the right
        const temp = leftFace;
        leftFace = rightFace;
        rightFace = temp;
    }

    const leftCenterX = (leftFace.x + leftFace.width) / 2;
    const rightCenterX = (rightFace.x + rightFace.width) / 2;

    const objectFitPositionLeft = (leftCenterX / totalVideoWidth) * 100;
    const objectFitPositionRight = (rightCenterX / totalVideoWidth) * 100;

    return (
        <AbsoluteFill>
            <Sequence layout="none">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* Left Half */}
                    <AbsoluteFill
                        style={{
                            width: width ,
                            height: height,
                            overflow: 'hidden',
                            position: 'relative',

                            // maskImage: 'linear-gradient(to right, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%)',
                            // WebkitMaskImage: 'linear-gradient(to right, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%)',
                        }}
                    >
                        <OffthreadVideo
                            src={videoLink}
                            style={{
                                width: 1080,
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: `${objectFitPositionLeft}% `,
                            }}
                            volume={frame >= overlayDelay ? 1 : 0}
                        />
                    </AbsoluteFill>

                    {/* Right Half */}
                    <AbsoluteFill
                        style={{
                            width: width,
                            height: height,
                            overflow: 'hidden',
                            position: 'absolute',
                            maskImage: 'linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 45%, rgba(0,0,0,1) 55%)',
                            WebkitMaskImage: 'linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 45%, rgba(0,0,0,1) 55%)',

                        }}
                    >
                        <OffthreadVideo
                            src={videoLink}
                            style={{
                                width: 1080,
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: `${objectFitPositionRight}%`,
                            }}
                            volume={0}
                        />
                    </AbsoluteFill>
                </div>
            </Sequence>
        </AbsoluteFill>
    );
};

export default VerticalSplitLeftRight;