import React, { FC, SyntheticEvent, useCallback, useContext, useMemo, useRef } from 'react';
import styled from 'styled-components';
import withButton from '../withButton';
import Block, { BlocksArray } from '../Block';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import getRandomId from '../../utils/functions';
import { VideoContext } from '../../context/video.context';
import CustomizedButtons from '../Button';
import { TitleHead } from '../TitleHead';
import { Grid, TextField } from '@mui/material';
import SketchExample from '../PIckerColor';
import GoogleFontsDropdown from '../GoogleFontSelector';
import FontSizeChanger from '../FontSizeChanger';
import SketchColor from '../PIckerColor/SketchColor';
import Fonts from '../../utils/fonts';

interface ContainerProps {
    active: boolean;
}

interface SceneBlocksProps extends ContainerProps {
    // onItemClick: (e: SyntheticEvent<HTMLDivElement>, itemType: string, type: string) => void;
}

const Container = styled.div<ContainerProps>`
    width: 100%;
    // height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    flex-wrap: wrap;
    overflow-y: auto;
    // position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: inherit;
    margin: 5px;
    background-color: #D0D0D0;
    visibility: ${({ active }) => active ? 'visible' : 'hidden'};
`

const ButtonBlock = withButton(Block);

const StyledBlock = styled(ButtonBlock)`
margin: 5px;
`


const SceneBlocks: FC<{}> = ({ }) => {
    const dispatch = useAppDispatch();
    const inputRef = useRef<HTMLInputElement>(null);

    const contextValue = useContext(VideoContext);

    if (!contextValue) {
        // handle the case where the context value is not provided
        throw new Error("VideoContext value is not provided");
    }

    const { setShapes, shapes, markerXPosition, setMarkerXPosition, scaleLength, selectedBlockId
    } = contextValue;

    let shape = useMemo(() => selectedBlockId && shapes.find((sh: any) => selectedBlockId == sh.id), [selectedBlockId, shapes])


    const duration = useAppSelector(state => state.video.videoFile?.duration || 0);
    const timelineLayer = useAppSelector(state => state.video.timelineLayer);

    const handleItemClick = (e: SyntheticEvent<HTMLDivElement>, itemType: string, type: string, image?: string) => {
        e.preventDefault();
        e.stopPropagation();


        // const shapes = timelineLayer.filter((shape: any) => shape.layerId === 2);
        let start = 0;
        let end = 5;
        if (shapes.length !== 0) {
            start = shapes[shapes.length - 1].end;
            end = start + 5;
        }

        // if (timelineRef) {
        const endTime = 134 * duration / 100;

        // if (trackList.length !== 0) {
        const textOptions = {
            textAlign: 'center',
            fontSize: '20px',
            justifyContent: 'center',
            fontFamily: 'Lato',
            text: 'TEXT',
            textColor: '#000',
            textPosition: null
        }
        const newItem = {
            type: type,
            itemType: itemType,
            width: 134,
            height: 100,
            xPosition: 0,
            id: getRandomId(),
            text: '',
            selector: getRandomId(),
            layerId: 2,
            color: '#fff',
            start: start,
            end: end,
            x: 100,
            y: 70,
            fill: 'transparent',
            textOptions: type === 'drawtext' ? textOptions : null,
            imageSrc: image ? image : null,
            keepRatio: true
        }
        setShapes([...shapes, newItem])
        //         const tracksWithoutItems = trackList.filter(item => item.item === null);
        // dispatch({ type: 'video/addShapeLayer', payload: newItem })

        //         if (tracksWithoutItems.length !== 0) {
        //             dispatch({
        //                 type: types.ADD_ITEM_TO_TRACK,
        //                 payload: {
        //                     item: newItem,
        //                     name: tracksWithoutItems[0].name
        //                 }
        //             });
        //         }
        //     }
        // }
    }


    const handleAddShape = useCallback((e: SyntheticEvent<HTMLDivElement>, itemType: string, type: string, image?: string) => {
        let isMarkerOnBlock = false;
        let nextBlockStartTime: any = null;

        // Iterate through shapes to check if the marker is on any block
        shapes.forEach((shape: any) => {
            if (markerXPosition >= shape.start && markerXPosition <= shape.end) {
                isMarkerOnBlock = true;
            } else if (shape.start > markerXPosition && (nextBlockStartTime === null || shape.start < nextBlockStartTime)) {
                nextBlockStartTime = shape.start;
            }
        });

        if (!isMarkerOnBlock) {
            const newBlockStart = Math.max(0, markerXPosition);
            let newBlockEnd = nextBlockStartTime !== null ? nextBlockStartTime : scaleLength;

            // If there's no block ahead, add a default duration to the new block
            if (nextBlockStartTime === null) {
                newBlockEnd = Math.min(newBlockStart + 5, scaleLength); // Default duration of 5 seconds
            }

            // Create a new block, ensuring it starts at the current marker position
            const newBlock = {
                id: new Date().getTime() + 1, // Assign a new unique id // Current timestamp as ID
                start: newBlockStart,
                end: newBlockEnd,
                text: '', // Empty string for new block
                x: 100,
                y: 70,
                width: 100,
                height: 100,
                fill: 'transparent',
                isImg: false,
                shapeImg: null,
                imgBlob: null,
            };

            // Update shapes array with new block
            setShapes([...shapes, newBlock]);
        }
    }, [shapes, markerXPosition, scaleLength]);

    const handleAddImageAsShape = (imgBlobUrl: string) => {
        let isMarkerOnBlock = false;
        let nextBlockStartTime = null as any

        shapes.forEach((shape: any) => {
            if (markerXPosition >= shape.start && markerXPosition <= shape.end) {
                isMarkerOnBlock = true;
            } else if (shape.start > markerXPosition && (nextBlockStartTime === null || shape.start < nextBlockStartTime)) {
                nextBlockStartTime = shape.start;
            }
        });

        if (!isMarkerOnBlock) {
            const newBlockStart = Math.max(0, markerXPosition);
            let newBlockEnd = nextBlockStartTime !== null ? nextBlockStartTime : scaleLength;

            if (nextBlockStartTime === null) {
                newBlockEnd = Math.min(newBlockStart + 5, scaleLength);
            }

            const newImageShape = {
                id: new Date().getTime(), // Unique id
                start: newBlockStart,
                end: newBlockEnd,
                text: '', // Empty text for new image shape
                x: 100, // Initial position
                y: 70, // Initial position
                width: 100, // Initial width
                height: 100, // Initial height
                fill: "transparent", // Transparent fill
                isImg: true, // Flag to indicate that this is an image
                shapeImg: imgBlobUrl, // Blob URL of the uploaded image
                imgBlob: null // Blob URL of the downloaded image
            };
            setShapes([...shapes, newImageShape]);
        }
    };


    const handleChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imgBlobUrl = URL.createObjectURL(file);
            handleAddImageAsShape(imgBlobUrl); // Add the image as a new shape
        }
        // Reset the value of the input after handling the file
        event.target.value = '';
    };

    const handleImageUpload = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const onChangeShapeTextPreference = (key: string, val: string) => {
        let tempShapes = shapes.map((shape: any) => {
            // Check if this is the shape we want to update
            if (selectedBlockId === shape.id) {
                // If so, return a new object with the updated value
                return { ...shape, [key]: val };
            }
            // Otherwise, return the shape as is
            return shape;
        });
        // Finally, update the state with the new shapes array
        setShapes(tempShapes);
    }

    return (
        <div style={{ padding: 10 }}>
            <TitleHead name={"Add Media"} />

            <Container active={true}>
                {/* <StyledBlock onClick={(e: SyntheticEvent<HTMLDivElement>) => handleItemClick(e, 'drawtext', 'drawtext')} type="text" textAlign="center" fontSize="24px" border justifyContent="center" text="TEXT" /> */}
                <StyledBlock onClick={handleImageUpload} type="text" textAlign="center" fontSize="24px" border justifyContent="center" text="Upload Image" />
                <input
                    ref={inputRef}
                    type="file"
                    id="input-file-upload"
                    multiple={true}
                    onChange={handleChange}
                    style={{ display: 'none' }} />
                {
                    BlocksArray.map((item, key) => {
                        return <StyledBlock key={key} onClick={(e: SyntheticEvent<HTMLDivElement>) => handleAddShape(e, item, 'drawtext')} type={item} />
                    })
                }
            </Container>
            {selectedBlockId && <Grid md={12} sx={{ padding: 3, backgroundColor: 'white' }}>
                <p className='title-header mb-3'>Text Preferences:</p>
                <Grid container md={12}>
                    <Grid sx={{}} sm={6} xs={6} md={4}>
                        <p className='title-name mb-2'>Text</p>
                        <TextField onChange={(val) => { onChangeShapeTextPreference('text', val.target.value) }} id="outlined-basic" variant="outlined" />
                    </Grid>
                    <Grid sx={{}} sm={6} xs={6} md={3}>
                        <p className='title-name mb-2'>Font Colors</p>
                        <Grid item sm={5} xs={5} md={2}>
                            <SketchColor
                                name={'primary'}
                                color={shape.color}
                                onChange={(val: any) => onChangeShapeTextPreference("color", val)}
                            />
                        </Grid>
                    </Grid>
                    <Grid sx={{}} sm={6} xs={6} md={3}>
                        <p className='title-name mb-2'>Background Colors</p>

                        <Grid item sm={5} xs={5} md={2}>
                            <SketchColor
                                name={'secondary'}
                                color={shape.fill}
                                onChange={(val: any) => onChangeShapeTextPreference("fill", val)}
                            />
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={12} sx={{
                        overflow: "auto",
                        whiteSpace: { xs: 'normal', sm: 'nowrap' },
                        width: '100%',
                        marginTop: 2
                    }}>
                        {Fonts.map(({ family }: any) => {
                            const isSelected = shape.font_family === family;
                            return (
                                <div
                                    onClick={() => onChangeShapeTextPreference("font_family", family)}
                                    className={`font-circle cursor-pointer box-shadoww  ${isSelected ? 'font-circle-selected' : ''}`}
                                    style={{
                                        display: 'inline-flex', // Use inline-flex for items to align them in a line
                                        marginRight: '10px', // Add some space between the font choices
                                    }}
                                >
                                    <p
                                        style={{
                                            fontFamily: family,
                                            color: isSelected ? '#0075E1' : '#A1A1A1',
                                            fontSize: 20
                                        }}
                                    >Aa</p>
                                </div>
                            )
                        })}
                    </Grid>

                    <Grid sx={{ paddingRight: "20px" }} sm={6} xs={6} md={4}>
                        <p className='title-name mb-2'>Font size</p>
                        <FontSizeChanger
                            onChange={(val: any) => onChangeShapeTextPreference("font_size", val)}
                        />
                    </Grid>
                </Grid>
            </Grid>}

        </div>

    )
}

export default SceneBlocks;