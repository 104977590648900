import React, { Fragment } from 'react';
import './style.css'
import { Container, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import {  useTheme } from '@mui/material/styles'
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import InfoIcon from '@mui/icons-material/Info';
import CustomizedButtons from '../../components/Button';

const DenoiseAudio = () => {
    const navigate = useNavigate()
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState("Go Back");

    const handleDrawerClose = () => {
        setOpen(!open);
    };

    const returnIcon = (txt: string) => {
        switch (txt) {
            case 'Go Back':
                return <ReplyIcon />
        }
    };


    return (
        <div style={{ marginBottom: isXs ? '10vh' : '0' }}> {/* Adjust padding to make room for the video player */}
            <Fragment>
                {!isXs && <Header></Header>}
                <Container sx={{ height: '100vh', backgroundColor: 'white' }} maxWidth={false}>
                    <Grid sx={{ width: '100%', height: '100%' }} container spacing={2}>

                        <Grid sx={{ border: "1px solid #DDD;" }} md={1}>
                            <div style={{}} className="drawer-container">
                                <Divider />
                                <List sx={{ marginTop: 2 }}>
                                    {['Go Back'].map((text, index) => (
                                        <ListItem key={text + index} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 68,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                    flexDirection: open ? 'row' : 'column',
                                                    backgroundColor: '#FFF',
                                                }}
                                                onClick={() => {
                                                    if (text == "Go Back") navigate("/")
                                                    setCurrentTab(text)
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                        marginRight: open ? "25px" : "0px",
                                                        color: currentTab == text ? "#0075E1" : 'black',
                                                    }}
                                                >
                                                    {returnIcon(text)}
                                                </ListItemIcon>
                                                <ListItemText primary={text} sx={{
                                                    opacity: open ? 1 : 1,
                                                    color: currentTab == text ? "#0075E1" : 'black',
                                                    textAlign: 'center'
                                                }} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </Grid>
                        <Grid sx={{ padding: 3, }} md={11}>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.12)', paddingBottom: 10 }} md={12}>
                                <p className='text-main' style={{ margin: 0 }}>Denoise Video</p>
                                <InfoIcon></InfoIcon>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} md={12}>
                                <div style={{ width: "184px", height: "25px", borderRadius: '3.4px', backgroundColor: '#989898', marginLeft: "20px", color: 'white', display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: 10 }}>
                                    <p>Denoise Video</p>
                                    <div className='new-div'><p> New</p></div>
                                </div>

                                <div style={{ width: '100%', maxWidth: '640px', marginBottom: '20px', position: 'relative' }}>
                                    <video style={{ width: '100%', borderRadius: '8px', height: '500px', }} controls>
                                        <source src='https://transpifyblob.blob.core.windows.net/transpifyvideos/64f4f8ab9a3444f4a2a073b1/664412/original/664412.mp4?se=2200-06-07T17%3A21%3A11Z&sp=r&sv=2023-11-03&sr=b&sig=XlmmVn0ia1exjo2KmWK62/wDatLtn%2BMEpjsLxx/36/I%3D' type='video/mp4' />
                                    </video>
                                </div>
                                <CustomizedButtons onClick={() => { }} background='#00A3FF' color='white' name="Publish"></CustomizedButtons>

                                <div style={{ textAlign: 'center', display: 'flex', marginTop: '20px' }}>
                                    <p className="text-write">Do you want to edit video?</p>
                                    <div style={{ width: "88px", height: "25px", borderRadius: '3.4px', backgroundColor: '#989898', marginLeft: "20px", color: 'white' }}>AI Studio &gt;</div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Fragment>
        </div>
    )
}

export default DenoiseAudio;