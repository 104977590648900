const loadFont = (font: string | undefined, variant?: string | undefined) => {
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${font}&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
}

function buildFontUrl(fontData: any) {
    const fontName = fontData.family.replace(/\s/g, '+'); // Replace spaces with '+'
    let fontUrl = `https://fonts.googleapis.com/css2?family=${fontName}`;

    // Handle variants
    const variants = Object.keys(fontData.files);
    if (variants.length > 0) {
        fontUrl += ':wght@';
        const variantUrls = variants.map(variant => {
            if (variant === 'regular') {
                return '400';
            } else if (variant === 'italic') {
                return '400italic';
            }
            // Add more conditions if there are other variants
        }).join(';');
        fontUrl += variantUrls;
    }

    fontUrl += '&display=swap';
    return fontUrl;
}


export { loadFont, buildFontUrl }