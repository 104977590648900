import { FFmpeg } from '@ffmpeg/ffmpeg';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { get, set } from 'idb-keyval';

import { VideoTransform } from '../components/TrimVideo/types';
import { useRef } from 'react';
import { toBlobURL } from '@ffmpeg/util';

// Define interfaces for better type checking
interface FfmpegStoreInterface {
    loaded: boolean;
    loadProgress: number;
    // Other properties...
    load: () => Promise<void>;
    exec: (file: File, args: string[]) => Promise<File>;
    // Other methods...
}

interface MainStoreInterface {
    file: File | undefined;
    fileLoading: boolean;
    transform: VideoTransform;
    ffmpeg: FfmpegStoreInterface;
    // Other properties...
    loadVideo: (file: File) => Promise<void>;
    reset: () => void;
    // Other methods...
}

// const canUseMT =
//     import.meta.env.VITE_ENABLE_MT === '1' && 'SharedArrayBuffer' in window;
// const ffmpegVersion = '0.12.3';
// const ffmpegName = canUseMT ? 'core-mt' : 'core';
// const ffmpegWorker = canUseMT ? 'ffmpeg-core.worker.js' : undefined;
const ffmpegBaseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd'

async function retrieveBlob(
    url: string,
    type: string,
    onProgress?: (progress: number) => void,
) {
    let buffer = await get(url);
    if (!buffer) {
        const response = await fetch(url);
        const reader = response.body?.getReader();
        if (!reader) {
            throw new Error(`Unable to fetch: ${url}`);
        }

        const contentLength = +response.headers.get('Content-Length')!;
        let receivedLength = 0;
        const chunks = [];

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const { done, value } = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
            receivedLength += value.length;
            onProgress?.(receivedLength / contentLength);
        }

        buffer = await new Blob(chunks).arrayBuffer();

        try {
            set(url, buffer);
            console.log(`Saved to IndexedDB: ${url}`);
        } catch {
            //
        }
    } else {
        console.log(`Loaded from IndexedDB: ${url}`);
    }

    const blob = new Blob([buffer], { type });
    return URL.createObjectURL(blob);
}


class FfmpegStore implements FfmpegStoreInterface {
    loaded = false;
    loadProgress = 0;
    ffmpeg: FFmpeg;
    running = false;
    execProgress = 0;
    outputUrl: string | undefined = undefined;
    output: string = '';
    log: string = '';
    onLoadCallback: (() => void) | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
        this.ffmpeg = new FFmpeg();

        this.load()

        this.ffmpeg.on('log', e => {
            runInAction(() => {
                this.output = e.message;
                this.log += `${e.message}\n`;
            });
        });

        this.ffmpeg.on('progress', e => {
            runInAction(() => {
                this.execProgress = e.progress;
            });
        });
    }

    async load() {
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
        await this.ffmpeg.load({
            coreURL: `${baseURL}/ffmpeg-core.js`,
            wasmURL: `${baseURL}/ffmpeg-core.wasm`,
        }).then(() => {
            runInAction(() => {
                this.loaded = true
            })
        }).catch((err) => console.error("error on laod ffmped", err))
    }

    async exec(file: File, args: string[]): Promise<File | any> {
        this.running = true;
        this.execProgress = 0;
        this.output = '';

        try {
            await this.load();

            // Write the input file to ffmpeg's virtual filesystem
            await this.ffmpeg.writeFile('input', new Uint8Array(await file.arrayBuffer()));

            // Execute the ffmpeg command
            await this.ffmpeg.exec(['-i', 'input', ...args, 'convertOutput.mp4']);

            // Read the output file from ffmpeg's virtual filesystem
            const data = await this.ffmpeg.readFile('convertOutput.mp4');

            return new File([data], 'output.mp4', { type: 'video/mp4' });
        } catch (error) {
            console.error('Error during ffmpeg execution:', error);
        } finally {
            // Clean up the virtual filesystem
            try {
                await this.ffmpeg.deleteFile('input');
            } catch (error) {
                console.error('Error deleting input file:', error);
            }
            try {
                await this.ffmpeg.deleteFile('output.mp4');
            } catch (error) {
                console.error('Error deleting output file:', error);
            }

            runInAction(() => {
                this.running = false;
            });
        }
    }

    cancel() {
        this.ffmpeg.terminate();
        this.load();
    }
}


class MainStore implements MainStoreInterface {
    file: File | undefined = undefined;
    fileLoading = false;
    transform: VideoTransform = {};

    ffmpeg = new FfmpegStore();

    step = 0;
    video: HTMLVideoElement | undefined = undefined;
    thumbnail: string | undefined;
    resolution: any;

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => [this.step],
            () => this.video?.pause(),
        );
    }

    reset() {
        this.transform = {};

        if (this.video) {
            this.video.pause();
            this.video.currentTime = 0.1;
        }
    }

    async captureThumbnail(file: File) {

        return new Promise((resolve, reject) => {

            const video = document.createElement('video');

            // Create a Blob URL from the file
            const blobURL = URL.createObjectURL(file);
            video.src = blobURL;

            video.addEventListener('loadeddata', () => {
                try {
                    video.currentTime = 1; // skip to 1 second to capture a non-blank frame
                } catch (e) {
                    reject(e);
                }
            });

            video.addEventListener('seeked', () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');

                if (ctx === null) {
                    reject(new Error('Could not get canvas context'));
                    return;
                }
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                canvas.toBlob((blob) => {
                    if (blob === null) {
                        reject(new Error('Could not create blob from canvas'));
                        return;
                    }

                    resolve({ thumbnailURL: URL.createObjectURL(blob), resolution: { width: canvas.width, height: canvas.height } });
                }, 'image/jpeg');
            });

            video.addEventListener('error', (e) => {
                reject(e);
            });

            // Load the video
            video.load();

            // Clean up the Blob URL when done
            video.addEventListener('ended', () => URL.revokeObjectURL(blobURL));
            video.addEventListener('error', () => URL.revokeObjectURL(blobURL));
        });
    };

    setFile(file: File) {
        this.file = file;
        this.thumbnail = '/addmusic.png'
    }


    async loadVideo(file: File) {
        this.video?.pause();
        this.video = undefined;
        this.file = file;
        this.fileLoading = true;
        this.ffmpeg.onLoadCallback = undefined;
        this.reset();

        const video = document.createElement('video');

        // Check if the video can be played directly
        if (!video.canPlayType(file.type)) {
            const remux = async () => {
                try {
                    const newFile = await this.ffmpeg.exec(file, [
                        '-c:v', 'copy',
                        '-c:a', 'copy'
                    ]);
                    if (newFile) {
                        this.loadVideo(newFile);
                    } else {
                        throw new Error('Failed to remux the video');
                    }
                } catch (error) {
                    console.error('Error remuxing the video:', error);
                    runInAction(() => {
                        this.fileLoading = false;
                    });
                }
            };
            setTimeout(async () => {
                if (this.ffmpeg.loaded) {
                    await remux();
                } else {
                    this.ffmpeg.onLoadCallback = remux;
                }
            }, 1000);
            return;
        }

        // Capture thumbnail and set video attributes
        try {
            this.captureThumbnail(file).then(({ thumbnailURL, resolution }: any) => {
                runInAction(() => {
                    this.thumbnail = thumbnailURL as string;
                    this.resolution = resolution
                })
            })
        } catch (error) {
            console.error('Error capturing thumbnail:', error);
        }

        video.setAttribute('playsinline', '');
        video.preload = 'metadata';
        video.autoplay = false;
        video.crossOrigin = 'anonymous'; // Required for iOS Safari when using a Service Worker

        // Video event listeners
        video.addEventListener('loadedmetadata', () => {
            runInAction(() => {
                video.currentTime = 0.01;
                this.video = video;
            });
        });

        video.addEventListener('canplay', () => {
            runInAction(() => {
                if (this.fileLoading) {
                    this.fileLoading = false;
                    this.step = 1;
                }
            });
        });

        video.addEventListener('ended', () => {
            const start = this.transform.time?.[0] || 0;
            video.currentTime = start;
        });

        video.addEventListener('timeupdate', () => {
            const start = this.transform.time?.[0] || 0;
            const end = this.transform.time?.[1] || video.duration;

            if (video.currentTime > end) {
                video.currentTime = start;
            } else if (video.currentTime < start - 1) {
                video.currentTime = start;
            }
        });

        // Set video source
        video.src = URL.createObjectURL(file);
    }

}

// load image
const loadImage = async (file: File) => {
    const url = URL.createObjectURL(file);
    const image = await loadImageAsync(url);
    URL.revokeObjectURL(url);
    // this.file = image;
};

const loadImageAsync = (url: string) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (e) => reject(e));
        image.src = url;
    });
};

export default MainStore
