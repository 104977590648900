import './style.css'
import '../../screens/Main/style.css'
import { Typography, Card, Grid, TextField, Chip } from '@mui/material';
import {
  IconButton,
  Box,
} from '@mui/material';
import { SEVER_URL } from '../../utils/url';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';
import CloseIcon from '@mui/icons-material/Close';
// import addNotification from 'react-push-notification';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { addVideoId, deleteVideo, fetchRecentProjects, getVideoFileAndExtract, getVideoFiles } from '../../redux/slices/video.slice';
import { RootState } from '../../redux/store';
import ConfimationModal from '../ConfirmationModal';
import { captureThumbnail, generateRandomName } from '../../utils/functions';
import VideoTabs from '../VideoTabs';
import { discordWebhook } from '../../redux/slices/feedbacks.slice';
import { mediaPlayerAction } from '../../redux/slices/mediaPlayer';
import LanguageModal from '../LanguageModal';

type VideoThumbnails = {
  [key: string]: string;
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={30} variant="determinate" {...props} />
      <Box
        sx={{
          top: -0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ color: 'white' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

interface RecentProjectProps {
  loadingPerform: any; // Adjust the type as per your requirement
  openProgressLoader: (data: any) => void; // Function type
  progressVId?: any; // Optional property
}

function RecentProject({ loadingPerform, openProgressLoader, progressVId }: RecentProjectProps): JSX.Element {

  const navigation = useNavigate()
  const dispatch = useAppDispatch()

  const id = useAppSelector((state: RootState) => state.auth.user?.id)
  const user = useAppSelector((state: RootState) => state.auth.user)
  const tab = useAppSelector((state: RootState) => state.profile.mainTab)

  const guestId = useAppSelector((state: RootState) => state.auth.guestId)
  const isLoading = useAppSelector((state: RootState) => state.video.isLoading)
  const recentProject = useAppSelector((state: RootState) => state.video.recentProject)
  // const videoThumbnails = useAppSelector((state: RootState) => state.video.videoThumbnails)
  const [videoThumbnails, setVideoThumbnails] = useState<any>({})
  const [confirmModal, setConfirmModal] = useState<{ data: any | null, open: boolean }>({
    data: null,
    open: false
  })
  const [tabValue, setTabValue] = useState(tab)
  const [messageMod, setMessageMod] = useState({ open: false, msg: '' })
  const [loader, setLoader] = useState(false)
  // const searchedProjects = useMemo(() => {
  //   if (!searchProject) return recentProject;
  //   return recentProject.filter(({ name }: any) => name.toLowerCase().includes(searchProject.toLowerCase()))
  // }, [searchProject, recentProject])

  // Defining tab values inside the hook
  const tabDesc: any = {
    "All": ["transcribe", "aistudio", "compression", "text_to_video", "aishorts"],
    "AI Studio": ["aistudio"],
    "AI Captions": ["transcribe"],
    "AI Shorts": ["aishorts"],
    "Text to Video": ["text_to_video"],
    "Compressed": ["compression"],
  };

  const filterProjects = useMemo(() => {

    let currentTab = Object.keys(tabDesc)[tabValue]

    // Filter the projects based on the selected tab and its corresponding services
    return recentProject.filter(({ active_service }: any) => {
      // Assuming `active_service` is an array and `currentTab` is the state that holds the current tab
      if (!active_service || active_service.length === 0) return false;

      // Get the services for the current tab
      const servicesForCurrentTab = tabDesc[currentTab] || [];

      // Check if any of the active services match the services for the current tab
      return active_service.some((service: any) => servicesForCurrentTab.includes(service));
    });
  }, [recentProject, tabValue]);


  useEffect(() => {

    if (id || guestId)
      dispatch(fetchRecentProjects(id || guestId as string))
  }, [])

  useEffect(() => {
    setTabValue(tab)
  }, [tab])


  const notifyMsg = (service: string) => {
    const messages: any = {
      aistudio: "Your video has been rendered. Dive in and check out the awesome colors we put in your video",
      aishorts: "Your video has been rendered. Buckle up, because you are about to be amazed!",
      text_to_video: "Woohoo! your video has been created. Check out the love we poured into it.",
      compression: "Your video is successfully compressed.",
      transcribe: "Your video has been transcribed successfully."
    };

    const msg = messages[service] || "Your video is ready.";

    toast((t) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>🎉 {msg}</span>
        <button
          onClick={() => toast.dismiss(t.id)}
          style={{
            background: 'none',
            border: 'none',
            marginLeft: 'auto',
            cursor: 'pointer',
          }}
        >
          <CloseIcon style={{ width: 20, height: 20, color: 'black' }} />
        </button>
      </div>
    ), {
      position: 'top-right',
      duration: Infinity,
      // Styling
      style: {
        backgroundColor: "#ffa500",
        color: "#fff"
      },
      className: '',
      // Change colors of success/error/loading icon
      iconTheme: {
        primary: '#000',
        secondary: '#fff',
      },
      // Aria
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    });
  };


  const sendNotification = () => {
    // addNotification({
    //   title: 'Warning',
    //   subtitle: 'This is a subtitle',
    //   message: 'This is a very long message',
    //   native: true, // when using native, your OS will handle theming.
    //   icon: '/logo192.png'
    // })
    notifyMsg("text_to_video")
  }

  const confirmationModal = (data: any) => {
    setConfirmModal({ data, open: true })
  }


  const notify = () => toast.success('Your video transcribe successfully.');

  const [progresses, setProgresses] = useState(filterProjects && filterProjects.length > 0 && filterProjects?.map(() => 0));

  // Initialize a cache object to store progress data
  const progressCache: any = {}

  useEffect(() => {

    let foundQueue = false
    let success = false
    let service = null
    filterProjects && filterProjects.length > 0 && progresses.length > 0 && filterProjects?.forEach(({ transcription_processing = true, video_in_queue, video_id, user_id, active_service, rendering_inprocess_TTV, rendering_inprocess, podcast_editing_completed, in_queue_waiting, podcast_editing_ongoing, compressed, aistudio_editing_completed, aistudio_editing_ongoing, in_queue_waiting_aistudio_edit, aishorts_editing_progress }: any, index: number) => {
      if (!active_service) return
      if (podcast_editing_completed) return
      if (compressed) return
      if (aistudio_editing_completed) return
      if (podcast_editing_completed) return
      if ((!transcription_processing && !video_in_queue) || progressVId == video_id) {

        // Set progress to -1 if transcription processing is complete and video is not in queue
        setProgresses((currentProgresses: any) => {
          const newProgresses = [...currentProgresses];
          newProgresses[index] = -1;
          return newProgresses;
        });
      } else if (video_in_queue || in_queue_waiting || in_queue_waiting_aistudio_edit) {

        // Set progress to 5 if video is in queue
        setProgresses((currentProgresses: any) => {
          const newProgresses = [...currentProgresses];
          newProgresses[index] = 5;
          return newProgresses;
        });

        foundQueue = true

        // Schedule a fetch of recent projects after 200 seconds

      } else if (transcription_processing || podcast_editing_ongoing || aistudio_editing_ongoing || podcast_editing_ongoing) {
        // Check if progress data is already cached
        if (progressCache[video_id]) {
          setProgresses((currentProgresses: any) => {
            const newProgresses = [...currentProgresses];
            newProgresses[index] = progressCache[video_id].progress == 100 ? -1 : progressCache[video_id].progress;
            return newProgresses;
          });
        }
        else {
          let pag = "transcribe" as string
          // Fetch progress data from the server if not cached
          let url = `${SEVER_URL}transcription_progress/${user_id}/${video_id}`;
          if (active_service[0] == "aistudio") { url = `${SEVER_URL}${aistudio_editing_ongoing ? "aistudio_editing_progress" : "aistudio_rendering_progress"}/${user_id}/${video_id}`; pag = "aistudio" }
          if (active_service[0] == "aishorts") { url = `${SEVER_URL}${podcast_editing_ongoing ? 'aishorts_editing_progress' : 'aishorts_rendering_progress'}/${user_id}/${video_id}`; pag = "aishorts" }
          if (active_service[0] == "text_to_video") { url = `${SEVER_URL}ttv_rendering_progress/${user_id}/${video_id}`; pag = "text_to_video" }
          if (active_service[0] == "compression") { url = `${SEVER_URL}compression_progress/${user_id}/${video_id}`; pag = "compression" }
          if ((pag == "aistudio" && !rendering_inprocess) || (pag == "text_to_video" && !rendering_inprocess_TTV)) return

          const eventSource = new EventSource(url);

          eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setProgresses((currentProgresses: any) => {
              const newProgresses = [...currentProgresses];
              if (data.progress >= 90 && data.progress <= 100) {
                // addNotification({
                //   title: 'ViralMe',
                //   subtitle: transcribeName[pag],
                //   message: '🎉 Your video is live! Dive in and share the excitement!',
                //   native: true, // when using native, your OS will handle theming.
                //   icon: '/logo192.png'
                // });

                success = true
                service = active_service[0]

                dispatch({ type: "mediaPlayer/setDefault", payload: video_id })
              }
              newProgresses[index] = data.progress == 100 ? -1 : data.progress;
              return newProgresses;
            });
            // Cache the progress data
            progressCache[video_id] = data;
          };

          eventSource.onerror = (error) => {
            console.error("EventSource failed:", error);
            eventSource.close();
          };

          return () => {
            eventSource.close();
          };
        }
      }
    });
    if (success && service) notifyMsg(service);
    // if (id || guestId)
    //   setTimeout(() => {
    //     dispatch(fetchRecentProjects(id || guestId as string))
    //   }, 60000);
  }, [filterProjects, tabValue]); // You might need to adjust dependencies based on your setup




  const dispatchDeleteVideo = () => {
    if (confirmModal?.data && (id || guestId)) {
      const { videoId, size } = confirmModal.data
      let user_id = id || guestId || ""
      const data = new FormData()
      data.append("video_id", videoId)
      data.append("user_id", user_id)
      dispatch(deleteVideo({ data, videoId, size: formatMBSize(size), user_id }))
      setConfirmModal({ data: null, open: false })
    }
  }

  function formatMBSize(bytes: number) {
    if (!bytes) return 13
    const mbSize = bytes / (1024 * 1024);
    return `${bytes.toFixed(2)}`; // This will give you the size in MB with 2 decimal places.
  }

  function shortenFileName(file: string, maxLength = 25, service: string, id: string) {
    if (file == null && service == "text_to_video") return "Text to Video"
    if (file == null) {
      return id
    }
    // Check if the file has the expected extension
    let fileName = file?.toLowerCase(); // Convert to lowercase for a case-insensitive check

    let extension = "";  // Store the file extension

    if (fileName.endsWith('.webm')) {
      extension = '.webm';
    } else if (fileName.endsWith('.mkv')) {
      extension = '.mkv';
    } else if (fileName.endsWith('.mov')) {
      extension = '.mov';
    } else if (fileName.endsWith('.wmv')) {
      extension = '.wmv';
    } else if (fileName.endsWith('.mp4')) {
      extension = '.mp4';
    } else if (fileName.endsWith('.avi')) {
      extension = '.avi';
    } else {
      return "Invalid file type";  // Handle unexpected file extension
    }

    // Remove the extension for shortening
    let baseName = file.slice(0, file.length - extension.length);

    // If the baseName is shorter than or equal to the maxLength, return the original
    if (baseName.length <= maxLength) {
      return file;
    }

    // Shorten and add "..." to indicate truncation, and then append the original extension
    let shortened = baseName.slice(0, maxLength - 3) + '...' + extension;

    return shortened;
  }

  const returnUrl = (active_service: any, urls: any) => {
    let currentService = active_service?.length > 0 && active_service[0]
    let vUrl = urls?.video_original_file
    if (currentService == "aistudio") vUrl = urls?.aistudio_video_original_file
    if (currentService == "text_to_video") vUrl = urls?.text_to_video_rendered_uri

    return vUrl;
  }

  // useEffect(() => {
  //   recentProject.forEach(({ urls, video_id, active_service }: any) => {
  //     // Check if thumbnail already exists for this video ID
  //     if (videoThumbnails && videoThumbnails.hasOwnProperty(video_id)) return;

  //     // Call funcCreateThumbnail if thumbnail doesn't exist
  //     let currentService = returnUrl(active_service, urls);
  //     funcCreateThumbnail(currentService, video_id);
  //   });
  // }, [recentProject, videoThumbnails]); // Add videoThumbnails to the dependency array

  function funcCreateThumbnail(recentProject: string, video_id: string) {
    captureThumbnail(recentProject)
      .then((thumbnailUrl: any) => {
        // Update videoThumbnails state only if thumbnail is successfully captured
        let temp = { ...videoThumbnails };
        temp[video_id] = thumbnailUrl;
        setVideoThumbnails(temp)
        // dispatch({ type: "video/addVideoThumbnails", payload: temp });
      })
      .catch(error => {
        console.error('Error capturing thumbnail:', error);
      });
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    // sendNotification()
    dispatch({ type: "profile/changeMainTab", payload: newValue })
  };

  const formatDateTime = (createdAt: string) => {
    const date = new Date(createdAt);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Use 12-hour time format
    };

    return date.toLocaleString('en-US', options as any);
  };

  return (
    <Grid sx={{ marginTop: 2, backgroundColor: 'white', minHeight: 400, padding: 2, paddinTop: 6, borderRadius: '10px', width: "100%" }} md={12}>
      <Typography className="typo-recent-project">Recent Projects</Typography>
      <VideoTabs value={tabValue} onChange={handleTabChange} data={Object.keys(tabDesc).map((val) => ({ label: val }))} />

      <Grid sx={{ marginTop: 1 }} className='transcibe-grid-2' md={12}>
        {filterProjects && filterProjects.length > 0 && filterProjects?.map(({ name, size, video_id, id, urls, active_service, video_in_queue, user_id, UpdatedCompressedFileSize, createdAt, aistudio_editing_progress = false }: any, i: number) =>
          <Grid key={video_id} className='transcribe-card-2' style={{ position: 'relative' }} item xs={6} md={3}>
            <IconButton
              className='cursor-pointer'
              style={{ position: 'absolute', right: 20, top: 5, backgroundColor: '#D9D9D9', borderRadius: '50px', zIndex: 1 }}
              onClick={() => confirmationModal({ videoId: video_id, size: size, id: id })}
              color="secondary">
              <DeleteOutlineOutlinedIcon
                onClick={() => confirmationModal({ video_id, id, size })}
                sx={{ color: 'black' }}
                fontSize='small' />
            </IconButton>

            {/* Container for image and progress */}
            <div className='recent-image box-shadoww' style={{ position: 'relative', width: "100%", borderRadius: 10 }}>
              <img
                className='cursor-pointer'
                onClick={() => {
                  setLoader(true)
                  dispatch({ type: "mediaPlayer/setDefault", payload: video_id })
                  dispatch(addVideoId(video_id))
                  if (active_service && active_service.length > 0) {
                    if (active_service[0] == 'transcribe') {
                      dispatch(discordWebhook(`Click on transcribe Video ${video_id}`))

                      navigation(`/video`)
                    }
                    if (active_service[0] == 'denoise') {
                      dispatch(discordWebhook(`Click on denoise Video ${video_id}`))

                      navigation(`/denoise`)
                    }
                    if (active_service[0] == "aistudio") {
                      dispatch(discordWebhook(`Click on aistudio Video ${video_id}`))

                      if (video_id !== '' && (user?.id || guestId)) {
                        dispatch(getVideoFiles({ userId: user?.id || guestId, videoId: video_id }))
                        setTimeout(() => {
                          navigation('/ai-studio')
                        }, 2000);
                      }
                    }
                    if (active_service[0] == "aishorts") {
                      dispatch(discordWebhook(`Click on aishorts Video ${video_id}`))

                      if (video_id !== '' && (user?.id)) {
                        dispatch(getVideoFiles({ userId: user?.id, videoId: video_id }))
                        setTimeout(() => {
                          navigation('/ai-shortsv2')
                        }, 2000);
                      }
                    }
                    if (active_service[0] == "compression") {
                      dispatch(discordWebhook(`Click on compression Video ${video_id}`))

                      if (video_id !== '' && (user?.id || guestId)) {
                        dispatch(getVideoFiles({ userId: user?.id || guestId, videoId: video_id }))
                        setTimeout(() => {
                          navigation('/publish')
                        }, 1000);
                      }
                    }
                    if (active_service[0] == "text_to_video") {
                      dispatch(discordWebhook(`Click on text_to_video Video ${video_id}`))

                      dispatch({ type: "texttovideo/upadateVideoId", payload: video_id })
                      navigation(`/text-video`)

                    }
                  }
                  else {
                    dispatch(discordWebhook(`Click on Video ${video_id}`))
                    navigation(`/video`)
                  }
                }}
                onError={(err) => { }
                  // funcCreateThumbnail(returnUrl(active_service, urls), video_id)
                }
                style={{ width: "100%", height: "100%", objectFit: 'cover', borderRadius: 10 }}
                // src={videoThumbnails && videoThumbnails.hasOwnProperty(video_id) ? videoThumbnails[video_id] : ''}
                src="/thumbnail.png"
              >

              </img>

              {/* Progress overlay */}
              {progresses && progresses.length > 0 && progresses?.[i] > 0 && progresses[i] < 100 && (
                <div
                  onClick={(e) => {
                    openProgressLoader({ video_id, user_id, active_service: active_service, aistudio_editing_progress: aistudio_editing_progress })
                    setMessageMod({ open: true, msg: "Please wait a while video is rendering" })
                    return
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '140px',
                    backgroundColor: 'rgba(128, 128, 128, 0.5)', // Semi-transparent gray
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 10, // Match the border radius of the image
                  }}>
                  <CircularProgressWithLabel size={50} sx={{ color: 'white' }} value={progresses[i]} />
                </div>
              )}
            </div>

            <Typography sx={{ marginTop: 1 }} className='recent-name'>{shortenFileName(name, 15, active_service[0], video_id)}</Typography>
            <div style={{ display: "flex", justifyContent: 'space-between' }}>
              <Typography className='recent-name recent-size'>{formatMBSize(UpdatedCompressedFileSize || size)}MB</Typography>
              <Typography className='recent-name recent-size'>{formatDateTime(createdAt)}</Typography>
            </div>
          </Grid>
        )}
      </Grid>
      <LanguageModal
        open={loader}
        check="false"
        type={'loading'}
      />
      <ConfimationModal open={confirmModal.open} onClick={dispatchDeleteVideo} onClose={() => setConfirmModal({ data: null, open: false })} title="Confirmation" text="Are you sure?" />
    </Grid >
  )


}

export default RecentProject;