// exportSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

interface ProfileState {
  isLoading: boolean;
  error: string | null;
  rewardUserList: any[] | null;
  mainTab: number
}

const initialState: ProfileState = {
  isLoading: false,
  error: null,
  rewardUserList: null,
  mainTab: 0,
};

export const rewardList = createAsyncThunk(
  'profile/rewardList',
  async ({ referral }: any) => {
    const response = await api.get(`/user/referral-user`, {
      params: { referral }
    });
    return response.data
  }
)

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (data: any, ThunkApi) => {
    const response = await api.put(`/user/${data.id}`, data);
    ThunkApi.dispatch({ type: 'auth/updateUser', payload: response.data })
    return response.data
  }
)


export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (id: string, ThunkApi) => {
    const response = await api.get(`/user/${id}`);
    ThunkApi.dispatch({ type: 'auth/updateUser', payload: response.data })
    return response.data
  }
)


export const uploadProfilePic = createAsyncThunk(
  'profile/uploadProfilePic',
  async (data: any, ThunkApi) => {
    const response = await api.post(`/uploadfile/profile`, data);
    console.log("uploadProfilePic", response)
    ThunkApi.dispatch({ type: 'auth/userUpdate', payload: response.data })
    return response.data
  }
)

// Continuing in exportSlice.ts
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    changeMainTab: (state, action: PayloadAction<any>) => {
      state.mainTab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(rewardList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(rewardList.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.rewardUserList = action.payload.data
      })
      .addCase(rewardList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(getProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getProfile.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
      .addCase(uploadProfilePic.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadProfilePic.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(uploadProfilePic.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch';
      })
  }
});

export default profileSlice.reducer;
