// CheckoutForm.tsx
import React, { useState, useEffect, useRef, useMemo } from "react";
import './style.css'

import style from "./style.module.scss";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Button, TextField, Container, Typography, Grid } from "@mui/material";

import api from '../../utils/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import packageData from "../../utils/packageData";
import { useAppDispatch } from "../../hooks/redux";
import { PaymentMethod, me } from "../../redux/slices/auth.slice";
import { FaLess } from "react-icons/fa";
import CustomizedButtons from "../Button";
import LanguageModal from "../LanguageModal";

type LocationOption = {
    label: string;
    value: string;
};

type SelectedLocation = {
    country: LocationOption;
    city: LocationOption;
    state: LocationOption;
};

const discountReferal = [{
    code: "d6ddcd8c",
    discount: "30%",
    couponCode: "ReferralDiscount"
}]

function CheckoutForm({ subscriptionId }: { subscriptionId: string }) {

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const id = useSelector((state: RootState) => state.auth.user?.id)
    const stripeCustomerId = useSelector((state: RootState) => state.auth.user?.stripeCustomerId)
    const user = useSelector((state: RootState) => state.auth.user)
    const paymentMethod = useSelector((state: RootState) => state.auth.paymentMethod)

    const card = useRef();

    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [coupon, setCoupon] = useState("");
    const [discount, setDiscount] = useState("0%");  // Amount or percentage saved
    const [couponMessage, setCouponMessage] = useState("");  // Success or error message
    const [cardInfo, setCardInfo] = useState({
        name: "",
        expiry: "",
        number: "",
        address: {
            line: "",
            postalCode: "",
            country: "",
            state: "",
            city: "",
            line1: "",
        },
    });
    const [locations, setLocations] = useState<{ countries?: any[], states?: any[], cities?: any[] }>({});
    const [selectedLocation, setSelectedLocation] = useState<SelectedLocation>({
        country: { label: '', value: '' },
        city: { label: '', value: '' },
        state: { label: '', value: '' },
    });

    const PKG = useMemo(() => {
        return packageData.map(data => {
            return data.cycle.find(cy => cy.subscriptionId == subscriptionId);
        }).filter(Boolean)[0] || null // filters out undefined or null values
    }, [subscriptionId]);
    const currentPaymentMethod = useMemo(() => paymentMethod?.find(({ id }) => id == user?.stripePaymentMethod), [paymentMethod, user])


    useEffect(() => {

        if (user) dispatch(PaymentMethod(user?.stripeCustomerId))
        if (user && user?.referredBy) {

            let discount = discountReferal.find(({ code }) => code == user?.referredBy)

            if (discount)
                setCoupon(discount.couponCode)
        }
    }, [user])


    function handleChangeAddressLine(e: any) {
        const { value } = e.target;
        setCardInfo((prev) => {
            return { ...prev, address: { ...prev.address, line: value } };
        });
    }

    function handleChangePostalCode(e: any) {
        const { value } = e.target;
        setCardInfo((prev) => {
            return { ...prev, address: { ...prev.address, postalCode: value } };
        });
    }

    function handleChangeName(e: any) {
        const { value } = e.target;
        setCardInfo((prev) => {
            return { ...prev, name: value };
        });
    }

    function parseForSelect(arr: any) {

        return arr.map((item: any) => ({
            label: item.name,
            value: item.isoCode ? item.isoCode : item.name,
            key: item.name
        }));
    }

    function handleSelectCountry(country: any) {
        const states = State.getStatesOfCountry(country.value);
        setSelectedLocation((prev) => {
            return { ...prev, country };
        });

        setLocations((prev) => ({ ...prev, states: parseForSelect(states) }));
    }

    function handleSelectState(state: any) {
        const cities = City.getCitiesOfState(selectedLocation.country.value, state.value);
        setSelectedLocation((prev) => {
            return { ...prev, state };
        });

        setLocations((prev) => ({ ...prev, cities: parseForSelect(cities) }));
    }

    function handleSelectCity(city: any) {
        setSelectedLocation((prev) => {
            return { ...prev, city };
        });
    }

    useEffect(() => {
        const allCountry = Country.getAllCountries();

        setLocations((prev) => {
            return { ...prev, countries: parseForSelect(allCountry) };
        });

    }, []);

    const handleCoupon = async (event: React.FormEvent) => {
        event.preventDefault();
        if (discountReferal.find(({ couponCode }) => coupon == couponCode)) {
            setCouponMessage("You can't meet requirement to use this coupon.");
            setDiscount("0%")
            return
        }
        if (coupon)
            try {
                const response = await api.post("/pay/validate-coupon", { coupon });
                if (response.data.success) {
                    setDiscount(response.data.discount);
                    setCouponMessage("Success! Discount applied.");
                } else {
                    setCouponMessage("Invalid coupon code.");
                    setDiscount("0%")
                }
            } catch (error) {
                setCouponMessage("Error validating coupon.");
            }
        else
            setDiscount("0%")
    }



    // main function
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe) {
            return;
        }
        setIsLoading(true)
        const address = cardInfo.address
        const billingDetails = {
            name: cardInfo.name,
            address: {
                country: address?.country,
                state: address?.state,
                city: address?.city,
                line1: address?.line,
            },
        };

        // const cardElement = elements.getElement(CardElement);

        // if (cardElement) {


        // call the backend to create subscription
        const { data } = await api.post("/pay/subscription", {
            stripeCustomerId: stripeCustomerId,
            paymentMethod: user?.stripePaymentMethod,
            id,
            email,
            subscriptionId,
            coupon: discount !== "0%" ? coupon : null
        })

        const confirmPayment = await stripe?.confirmCardPayment(
            data.data.clientSecret.payment_intent.client_secret
        );
        setIsLoading(false)

        if (confirmPayment?.error) {
            alert(confirmPayment.error.message);
        } else {

            alert("Success! Check your email for the invoice.");
            dispatch(me())
            navigate("/")
        }
        // }
        // else {
        //     setIsLoading(false)

        // }
    };

    function calculateDiscountedPrice(originalPrice: number, discountString: string) {
        // Extract the numerical value from the discount string
        const discountPercentage = parseFloat(discountString);

        // Ensure the provided discount is a valid percentage
        if (isNaN(discountPercentage) || discountPercentage < 0 || discountPercentage > 100) {
            throw new Error("Invalid discount percentage. It should be between 0 and 100.");
        }

        // Calculate the discount amount
        const discountAmount = (originalPrice * discountPercentage) / 100;

        // Return the discounted price
        return originalPrice - discountAmount;
    }

    return (
        <Container>
            <form>
                <div className={style.wrapper}>
                    <div className={style.innerWrapper}>
                        <div className={style.title}>Select Payment Method</div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CustomizedButtons onClick={() => navigate("/profile?tab=payment_method")} color="black" name="Add New" />
                            <div style={{ width: "20px" }}></div>
                            <CustomizedButtons onClick={() => navigate("/profile?tab=billing")} color="#00A3FF" name="Change" />
                        </div>
                        <div >
                            {/* <CardElement /> */}
                            <Grid container>
                                {currentPaymentMethod &&
                                    <div style={{ flex: 1 }} className="display-flex margin-vertical">

                                        <Grid md={2} item><img style={{ width: "40px" }} src={`/card/${currentPaymentMethod.card.brand}.png`}></img></Grid>
                                        <Grid md={6} item>
                                            <div>
                                                <Typography className="cell-typo">**** **** **** {currentPaymentMethod.card.last4}</Typography>
                                                <Typography className="cell-typo color-light">Expires {currentPaymentMethod.card.exp_month}-{currentPaymentMethod.card.exp_year}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid md={4} item></Grid>
                                        {/* <Grid md={2} item>
                                            {user?.stripePaymentMethod !== id && <CustomizedButtons onClick={() => { }} color="#00A3FF" name="Default" />}
                                        </Grid>
                                        <Grid md={2} item>
                                            <CustomizedButtons onClick={() => { }} color="#7E0000" name="Remove" />
                                        </Grid> */}
                                    </div>
                                }
                            </Grid>
                        </div>

                        <div className={style.addressWrapper}>
                            {/* <div className={style.row}>
                                <label>Address</label>
                                <input
                                    onChange={handleChangeAddressLine}
                                    type="text"
                                    name="address"
                                    placeholder="Enter Full Address"
                                />
                            </div>
                            <div className={style.rowSelect}>
                                <div>
                                    <label>Country</label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        name="country"
                                        value={selectedLocation.country || ""}
                                        options={locations.countries}
                                        onChange={handleSelectCountry}
                                    />
                                </div>

                                <div>
                                    <label>State</label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        name="state"
                                        value={selectedLocation.state || ""}
                                        options={locations.states}
                                        onChange={handleSelectState}
                                    />
                                </div>
                            </div>
                            <div className={style.rowSelect}>
                                <div>
                                    <label>City</label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        name="city"
                                        value={selectedLocation.city || ""}
                                        options={locations.cities}
                                        onChange={handleSelectCity}
                                    />
                                </div>

                                <div>
                                    <label>Postal Code</label>
                                    <input onChange={handleChangePostalCode} type="text" placeholder="Enter Zip Code" />
                                </div>
                            </div> */}

                            <div className={style.couponContainer}>
                                <input
                                    type="text"
                                    placeholder="Enter Coupon Code"
                                    value={coupon}
                                    onChange={(e) => setCoupon(e.target.value)}
                                />
                                <button onClick={handleCoupon}>Apply</button>
                            </div>
                            <div>
                                <Typography color={couponMessage.includes("Success") ? "green" : "red"}>
                                    {couponMessage}
                                </Typography>
                            </div>


                            <div className={style.btnContainer}>
                                <div>
                                    <Typography>
                                        ${PKG && calculateDiscountedPrice(PKG?.price, discount)}/{PKG?.type == "monthly" ? "month" : "year"}
                                    </Typography>
                                </div>
                                <button disabled={isLoading || !currentPaymentMethod} onClick={handleSubmit}>Pay Now</button>
                            </div>
                            <LanguageModal open={isLoading} type={"loading"} />
                        </div>
                    </div>
                </div>
            </form>
        </Container>
    );
}

export default CheckoutForm;