import React, {  useEffect  } from 'react';
import { Rect, Text, Group} from 'react-konva';

const VoiceOverLayer = ({ 
                        scale, containerWidthWithPadding, 
                        padding, mainContainerRef, voiceOverLastEndTimeRef, 
                        needScaleAdjustmentVoiceOverRef, longestDuration, adjustScaleLength, 
                        currentVoiceOverResizeHandler, voiceOverTracks, setVoiceOverTracks, 
                        selectedVoiceOverBlockId, setSelectedVoiceOverBlockId,
                        voiceOverLayerYPosition, musicTracks, musicLastEndTimeRef
                    }) => {

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.key === 'Delete' || event.key === 'Backspace') && selectedVoiceOverBlockId) {
                deleteVoiceOverBlock();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedVoiceOverBlockId]);

    const dragBoundFunc = (pos, trackId) => {
        const trackIndex = voiceOverTracks.findIndex(track => track.id === trackId);
        const currentTrack = voiceOverTracks[trackIndex];

        // Find the previous track's index
        const prevTrackIndex = trackIndex - 1 >= 0 ? trackIndex - 1 : null;
        const prevTrack = prevTrackIndex !== null ? voiceOverTracks[prevTrackIndex] : null;

        // Set the maximum x position as the end of the container minus the width of the block and padding
        let maxPosX = containerWidthWithPadding - (currentTrack.modifiedDuration * scale) - padding;
        
        // Set the minimum x position to padding initially
        let minPosX = padding;
        
        // If there is a previous track, calculate the minPosX based on the previous track's end time
        if (prevTrack) {
            const prevTrackEndX = prevTrack.modifiedEndTime * scale + padding;
            // Ensure the current track's x position does not go below the prevTrackEndX
            minPosX = Math.max(minPosX, prevTrackEndX);
        }

        // If there is a next track, calculate the maxPosX based on the next track's start time
        const nextTrackIndex = trackIndex + 1 < voiceOverTracks.length ? trackIndex + 1 : null;
        const nextTrack = nextTrackIndex !== null ? voiceOverTracks[nextTrackIndex] : null;
        if (nextTrack) {
            const nextTrackStartX = nextTrack.modifiedStartTime * scale + padding;
            maxPosX = Math.min(maxPosX, nextTrackStartX - (currentTrack.modifiedDuration * scale));
        }

        // Constrain the new x position within the calculated boundaries
        const newX = Math.max(minPosX, Math.min(pos.x, maxPosX));
        
        // return { x: newX, y: currentTrack.yPosition || 0 };
        return { x: newX, y: voiceOverLayerYPosition || 0 };
    };

    // This function is called when the drag action ends.... perfect working code...
    const onDragEnd = (e, trackId) => {
        // Calculate the new start time based on the final x position
        const node = e.target;
        // const scaleX = node.getStage().scaleX(); // or simply 1 if you're not scaling the stage
        const newStartSec = Math.max(0, (node.x() - padding) / scale);
        
        // Update the state with the new start time
        setVoiceOverTracks(prevTracks => prevTracks.map(track => {
            // Check if the block is back to its original duration
            const isUntrimmed = Number(track.modifiedDuration.toFixed(2)) === Number(track.originalDuration.toFixed(2));
            if (track.id === trackId) {
                return {
                    ...track, 
                    modifiedStartTime: newStartSec,
                    modifiedEndTime: newStartSec + track.modifiedDuration, 
                    preTrimStartTime: newStartSec - track.trimDurationAmount_L, // subtracting the trimmed amount from the start time
                    preTrimEndTime: newStartSec + track.modifiedDuration + track.trimDurationAmount_R, // adding the trimmed amount to the end time
                    trimDurationAmount_L: isUntrimmed ? 0 : track.trimDurationAmount_L, // resetting the trimmed amount to 0
                    trimDurationAmount_R: isUntrimmed ? 0 : track.trimDurationAmount_R, // resetting the trimmed amount to 0
                };
            }
            return track;
        }));
    };

    const onDragMove = (e, trackId) => {
        const pos = { x: e.target.x(), y: e.target.y() };
        const confinedPos = dragBoundFunc(pos, trackId);
        // Update the drag position with the confined position
        // Note: This does not set the state, it only moves the node visually
        e.target.position(confinedPos);
    };

    // Function to handle voiceOver block resize or voiceOver block trim
    const handleVoiceOverResizeMouseMove = (moveEvent, trackId, side, stage) => {
        const track = voiceOverTracks.find(t => t.id === trackId);
        if (!track || !stage) return;

        const mousePos = stage.getPointerPosition();
        let newTime = (mousePos.x - padding) / scale;
        newTime = Math.max(0, newTime);
        const MINIMUM_TRACK_DURATION = 1;
        const MAX_SCALE_DURATION_ALLOWED = 5370; // allowed rescalling length
        setSelectedVoiceOverBlockId(track.id); // Manually set the selected block

        // Find index of the current track
        const trackIndex = voiceOverTracks.findIndex(t => t.id === trackId);
        // Get previous and next tracks
        const prevTrack = trackIndex > 0 ? voiceOverTracks[trackIndex - 1] : null;
        const nextTrack = trackIndex < voiceOverTracks.length - 1 ? voiceOverTracks[trackIndex + 1] : null;

        if (side === 'left') {
            let newStart = Math.max(track.preTrimStartTime, newTime); 

            // Ensure newStart does not overlap with the end of the previous track
            if (prevTrack) {
                newStart = Math.max(newStart, prevTrack.modifiedEndTime);
            }

            newStart = Math.min(newStart, track.modifiedEndTime - MINIMUM_TRACK_DURATION);
            const newDuration = track.modifiedEndTime - newStart;

            // console.log(`newStart: ${newStart}, newDuration: ${newDuration}, modifiedStartTime: ${track.modifiedStartTime}, "currentTimes.modifiedStartTime: ${currentTimes.modifiedStartTime}`);
            const trimmedAmount = Math.abs(track.preTrimStartTime - newStart);

            // Create a new array of updated tracks
            const updatedTracks = voiceOverTracks.map(t =>
                t.id === trackId ? {
                    ...t,
                    modifiedStartTime: newStart,
                    modifiedDuration: newDuration,
                    trimDurationAmount_L: Number(trimmedAmount.toFixed(2))
                } : t
            );

            setVoiceOverTracks(updatedTracks);

        }
        else if (side === 'right') {

            let newEnd = Math.min(track.preTrimEndTime, newTime); // Use preTrimEndTime for expanding limit
            
            if (nextTrack) {
                newEnd = Math.min(newEnd, nextTrack.modifiedStartTime);
            }

            newEnd = Math.max(newEnd, track.modifiedStartTime + MINIMUM_TRACK_DURATION); // Ensure it doesn't overlap or underflow
            // newEnd = Math.min(newEnd, maxEndPosition);  // Ensure it doesn't exceed scale limit, accounting for padding
            const newDuration = newEnd - track.modifiedStartTime;
            
            const trimmedAmount = Math.abs(newEnd - track.preTrimEndTime);

            // Create a new array of updated tracks
            const updatedTracks = voiceOverTracks.map(t =>
                t.id === trackId ? {
                    ...t,
                    modifiedEndTime: newEnd,
                    modifiedDuration: newDuration,
                    trimDurationAmount_R: Number(trimmedAmount.toFixed(2)),
                    // Retain pre-trim values until a new drag begins
                } : t
            );
            setVoiceOverTracks(updatedTracks);
            
            ////// Calculate the longest end time among music, voice-over, and video
            musicLastEndTimeRef.current = Math.max(...musicTracks.map(track => track.modifiedEndTime));
            voiceOverLastEndTimeRef.current = Math.max(...updatedTracks.map(track => track.modifiedEndTime));
            if (voiceOverLastEndTimeRef.current > longestDuration && voiceOverLastEndTimeRef.current < MAX_SCALE_DURATION_ALLOWED && voiceOverLastEndTimeRef.current > musicLastEndTimeRef.current) {
                needScaleAdjustmentVoiceOverRef.current = true;
            }
        }
    };

    // Call this function when the resize operation is completed
    const finalizeResize = () => {
        if (needScaleAdjustmentVoiceOverRef.current) {
            const lastEndTime = voiceOverLastEndTimeRef.current;
            adjustScaleLength(lastEndTime);
            needScaleAdjustmentVoiceOverRef.current = false;
        }
    };

    const handleVoiceOverLeftResizeMouseDown = (e, trackId) => {
        e.evt.preventDefault();
        const stage = mainContainerRef.current.getStage();
        currentVoiceOverResizeHandler = (moveEvent) => handleVoiceOverResizeMouseMove(moveEvent, trackId, 'left', stage);
        window.addEventListener('mousemove', currentVoiceOverResizeHandler);
        window.addEventListener('mouseup', handleVoiceOverResizeMouseUp);
    };

    const handleVoiceOverRightResizeMouseDown = (e, trackId) => {
        e.evt.preventDefault();
        const stage = mainContainerRef.current.getStage();
        currentVoiceOverResizeHandler = (moveEvent) => handleVoiceOverResizeMouseMove(moveEvent, trackId, 'right', stage);
        window.addEventListener('mousemove', currentVoiceOverResizeHandler);
        window.addEventListener('mouseup', handleVoiceOverResizeMouseUp);
    };

    const handleVoiceOverResizeMouseUp = () => {
        if (currentVoiceOverResizeHandler) {
            window.removeEventListener('mousemove', currentVoiceOverResizeHandler);
            window.removeEventListener('mouseup', handleVoiceOverResizeMouseUp);
            currentVoiceOverResizeHandler = null;

            const MAX_SCALE_DURATION_ALLOWED = 5370; // allowed rescalling length
            if (voiceOverLastEndTimeRef.current > longestDuration && voiceOverLastEndTimeRef.current < MAX_SCALE_DURATION_ALLOWED && voiceOverLastEndTimeRef.current > musicLastEndTimeRef.current) {
                finalizeResize(); 
            } else if (longestDuration > musicLastEndTimeRef.current) {
                const lastEndTime = longestDuration;
                adjustScaleLength(lastEndTime);
                needScaleAdjustmentVoiceOverRef.current = false;
            } else if (musicLastEndTimeRef.current > longestDuration) {
                const lastEndTime = musicLastEndTimeRef.current;
                adjustScaleLength(lastEndTime);
                needScaleAdjustmentVoiceOverRef.current = false;
            }
        }
    };

    const deleteVoiceOverBlock = () => {
        setVoiceOverTracks(prevTracks => prevTracks.filter(track => track.id !== selectedVoiceOverBlockId));
        setSelectedVoiceOverBlockId(null); // Reset the selected block ID
    };

    // Helper function to format duration in mm:ss
    const formatDuration = (duration) => {
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    // Define your VoiceOverBlock component
    const VoiceOverBlock = ({ track, onDragMove, onDragEnd }) => {
        const blockWidth = track.modifiedDuration * scale;
        const xPosition = track.modifiedStartTime * scale + padding;
        const resizeHandleWidth = 6; // Adjust as needed
        const fileName = track.file.name;

        const handleClick = () => {
            setSelectedVoiceOverBlockId(track.id);
        };
    
        return (
            <Group
                draggable
                x={xPosition}
                y={voiceOverLayerYPosition}
                dragBoundFunc={(pos) => dragBoundFunc(pos, track.id)}
                onDragMove={(e) => onDragMove(e, track.id)}
                onDragEnd={(e) => onDragEnd(e, track.id)}
                onClick={handleClick} // Select the block when clicked
            >
                {/* Main voiceOver Block */}
                <Rect
                    width={blockWidth}
                    height={30}
                    fill={track.id === selectedVoiceOverBlockId ? 'green' : '#B7EAF7'}
                    stroke="black"
                    strokeWidth={1}
                />
                {/* voiceOver File Name */}
                <Text
                    text={fileName}
                    fontSize={12} // Adjust font size as needed
                    fill='black' // Text color
                    width={blockWidth}
                    height={30}
                    padding={5} // Padding inside the block
                    align='center' // Center the text inside the block
                    offsetY={-5} // Adjust the vertical position as needed
                />
                {/* voiceOver end Time */}
                <Text
                    x={blockWidth - 33}
                    y={10}
                    text={formatDuration(track.modifiedEndTime)}
                    fontSize={12}
                    fill='black'
                    align='right'
                />
                {/* Left Resize Handle */}
                <Rect
                    width={resizeHandleWidth}
                    height={30}
                    fill="orange"
                    onMouseDown={(e) => {
                        handleVoiceOverLeftResizeMouseDown(e, track.id)
                    }}
                
                />
                {/* Right Resize Handle */}
                <Rect
                    x={blockWidth - resizeHandleWidth}
                    width={resizeHandleWidth}
                    height={30}
                    fill="orange"
                    onMouseDown={(e) => handleVoiceOverRightResizeMouseDown(e, track.id)}
                />
            </Group>
        );
    };

    const CreateVoiceOverLayer = ({ tracks }) => {
        if (!tracks || tracks.length === 0) {
            return null;
        }

        return (
            <Group>
                {tracks.map(track => (
                    <VoiceOverBlock
                        key={track.id}
                        track={track}
                        onDragMove={onDragMove}
                        onDragEnd={onDragEnd}
                    />
                ))}
            </Group>
        );
    };


    return (
            <CreateVoiceOverLayer tracks={voiceOverTracks} />      
        );

};

export default VoiceOverLayer;