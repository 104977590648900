import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';
import { CircularProgress } from '@mui/material';

const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: 12,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    // backgroundColor: '#0063cc',
    color: '#000',
    boxShadow: '0px 2px 9px -4px rgba(0,0,0,0.64)',
    borderColor: '#000',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    // '&:hover': {
    //     backgroundColor: '#0069d9',
    //     borderColor: '#0062cc',
    //     boxShadow: 'none',
    // },
    // '&:active': {
    //     boxShadow: 'none',
    //     backgroundColor: '#0062cc',
    //     borderColor: '#005cbf',
    // },
    // '&:focus': {
    //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    // },
});

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
        backgroundColor: purple[700],
    },
}));

interface CustomizedButtonsProps {
    name?: string;
    size?: string;
    onClick?: any;
    disabled?: boolean;
    icon?: React.ReactNode;  // <-- Add this for the optional icon
    loading?: boolean
    className?: any
    color?: string
    background?: string
    id?: string,
    type?: "button" | "submit" | "reset"
}

export default function CustomizedButtons({ name, size, onClick, disabled = false, icon, loading = false, className, color = '#000', background = '', id, type = "button" }: CustomizedButtonsProps) {
    return (
        <BootstrapButton id={id} className={className} type={type} disabled={disabled} onClick={onClick} startIcon={icon} variant="outlined" sx={{
            borderColor: background,
            color: color,
            background: background
        }} size="small">
            {loading ?
                <CircularProgress size={12} /> : name || ""}
        </BootstrapButton >
    );
}