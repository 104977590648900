const filters = {
  "Blob_URL": [
    "https://viralmefast.azureedge.net/aistudio/Filters/scratchleak05.mp4?sp=r&st=2024-07-15T19:55:24Z&se=2224-07-16T03:55:24Z&spr=https&sv=2022-11-02&sr=b&sig=cMss%2Fk52yS7vcvuzel%2FLNcAu6v1FG3zqMm0z%2Bi03PfM%3D",
    "https://viralmefast.azureedge.net/aistudio/Filters/scratchleak06.mp4?sp=r&st=2024-07-15T19:55:49Z&se=2224-07-16T03:55:49Z&spr=https&sv=2022-11-02&sr=b&sig=0e2PXls5%2BNBNJEkPzGXc4h32z7a025eF7JgfmgjfIY0%3D",
    "https://viralmefast.azureedge.net/aistudio/Filters/scratchleak07.mp4?sp=r&st=2024-07-15T19:56:18Z&se=2224-07-16T03:56:18Z&spr=https&sv=2022-11-02&sr=b&sig=eFynxBVndpRGvvjf0SbU3n6FifITipSmH%2FGI%2F9a2Hnc%3D",
    "https://viralmefast.azureedge.net/aistudio/Filters/scratchleak08.mp4?sp=r&st=2024-07-15T19:56:40Z&se=2224-07-16T03:56:40Z&spr=https&sv=2022-11-02&sr=b&sig=Vojb8ET5I4ouibYnwETfl45l7AgBWZV9SxQful4r17c%3D",
    "https://viralmefast.azureedge.net/aistudio/Filters/scratchleak01.mp4?sp=r&st=2024-07-15T19:53:23Z&se=2224-07-16T03:53:23Z&spr=https&sv=2022-11-02&sr=b&sig=pTj6cL7izdA%2FHDggyO%2BivdcIz%2FVj0ohEA7YNVPL3c8w%3D",
    "https://viralmefast.azureedge.net/aistudio/Filters/scratchleak02.mp4?sp=r&st=2024-07-15T19:54:04Z&se=2224-07-16T03:54:04Z&spr=https&sv=2022-11-02&sr=b&sig=7K%2BdOb58vb4USqUXAdbvUpSzkNN7tPPcH9armJxeSdQ%3D",
    "https://viralmefast.azureedge.net/aistudio/Filters/scratchleak03.mp4?sp=r&st=2024-07-15T19:54:35Z&se=2224-07-16T03:54:35Z&spr=https&sv=2022-11-02&sr=b&sig=ph2bW8puhmIHbc2fhap9ofuD%2FB%2Bc7EbhWOkqvrOcopE%3D",
    "https://viralmefast.azureedge.net/aistudio/Filters/scratchleak04.mp4?sp=r&st=2024-07-15T19:54:57Z&se=2224-07-16T03:54:57Z&spr=https&sv=2022-11-02&sr=b&sig=9Vu4FFhxV4wqQiVo3P7Lx4WMmeY9vl7qcG1jeU8eeCM%3D",
  ],
};

export default filters;