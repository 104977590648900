/* eslint-disable prefer-destructuring */
/* eslint-disable no-negated-condition */
/* eslint-disable no-else-return */
/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
// /* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @remotion/from-0 */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable capitalized-comments */
/* eslint-disable prefer-const */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */

import React, { useEffect, useRef, useState } from "react";
import {
  useCurrentFrame,
  useVideoConfig,
  interpolate,
  OffthreadVideo,
  Sequence,
  AbsoluteFill,
  delayRender,
  continueRender,
  cancelRender,
  Loop,
} from "remotion";
import { getVideoMetadata } from "@remotion/media-utils";
import filtersOverlay from "./Filters";
import VerticalOneHeadView from "./Composition_VerticalOneHeadView";
import BlurBackgroundTemplate from "./Composition_BlurBackgroundTemplate";
import LaptopOverlayTemplate from "./Composition_LaptopOverlay";
import CompositionPodcast_H from "./Composition_Podcast_Split_H";
import VerticalSplitLeftRight from "./Composition_Podcast_Split_V";
import BillBoardOverlayTemplate from "./Composition_BillboardOverlay";


export const ZoomEffectVideoWithFiltersPodcast = ({
  videoLink,
  jsonData,
  video_width,
  zoom_on,
  filters_on,
  render_lightleaks,
  laptop_overlay,
  billboard_overlay,
}: {
  videoLink: string;
  jsonData: any;
  video_width: number;
  zoom_on: any;
  filters_on: any;
  render_lightleaks: any;
  laptop_overlay: any;
  billboard_overlay: any;
}) => {
  const frame = useCurrentFrame();
  const { fps, width, height, durationInFrames } = useVideoConfig() as any;
  const scaleRef = useRef(1);

  const renderZoom = zoom_on === 1 || zoom_on === "1" || zoom_on === "true";
  const renderFilters =
    filters_on === 1 || filters_on === "1" || filters_on === "true";
  // Convert seconds to frames
  const secondsToFrames = (seconds: number) => Math.round(seconds * fps);
  const renderLaptopOverlay =
    laptop_overlay === 1 || laptop_overlay === "1" || laptop_overlay === "true";
  const laptoptemplate_video =
    "https://viralmefast.azureedge.net/aistudio/Template_Assets/MacBookTemplate.mp4?sp=r&st=2024-06-05T13:59:15Z&se=2224-06-05T21:59:15Z&spr=https&sv=2022-11-02&sr=b&sig=yFMfAkW4JhPWH3XVTPFLUV%2BudEGfBiAAKRDXiwdB2r4%3D";

  const overlayDelay = renderLaptopOverlay ? Math.round(0.1 * fps) : 0;  // Calculate 0.1 seconds delay in frames

  const billboard_overlay_template = "https://viralmefast.azureedge.net/aistudio/Template_Assets/NYCS.png?sp=r&st=2024-07-10T14:37:36Z&se=2224-07-10T22:37:36Z&spr=https&sv=2022-11-02&sr=b&sig=iKCbYl0j4Fgl3kPjyUqKBu3xhGExh%2F%2FLXkK2TjxU5WM%3D"

  const getnoisefilterIndex = (segments: any[]) => {
    const lightleakSegment = segments.find(segment => segment.lightleak === 'filter');
    return lightleakSegment ? segments.length % filtersOverlay.Blob_URL.length : 0;
  };

  const noisefilterIndex = getnoisefilterIndex(jsonData.segments);
  const filteroverlayUrl = filtersOverlay.Blob_URL[noisefilterIndex];




  const LoopedVideoOverlay = ({ src }: any) => {
    const [videoDuration, setVideoDuration] = useState<null | number>(null);
    const handle = useState(() => delayRender())[0];

    useEffect(() => {
      getVideoMetadata(src)
        .then(({ durationInSeconds }) => {
          if (durationInSeconds === Infinity) {
            // Handle videos with undetermined duration
            console.error("Video duration is infinite, using fallback duration.");
            console.log("Duration in seconds INF ", durationInSeconds)
            setVideoDuration(Math.floor(40 * fps)); // Example fallback: 30 seconds
          } else {
            console.log("Duration in seconds ", durationInSeconds)
            setVideoDuration(Math.floor(durationInSeconds * fps));
          }
          continueRender(handle);
        })
        .catch((error: any) => {
          console.log("Error fetching video metadata:", error);
          setVideoDuration(Math.floor(40 * fps));
        });
    }, [src, fps, handle]);

    if (!videoDuration) {
      return null; // Optionally return a placeholder or loader
    }

    return (
      <Loop durationInFrames={videoDuration}>
        <OffthreadVideo

          src={src}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            opacity: 0.15, // Using confidence score for opacity
            zIndex: 1001,
          }}

        />
      </Loop>
    );
  };


  ///// Here we will apply filer code
  // Function to create overlay styles
  const getOverlayStyle = (filterId: string) => {
    if (filterId === "studioLight") {
      return {
        width,
        height,
        position: "absolute",
        top: 0,
        left: 0,
        background:
          "radial-gradient(circle at center, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.65) 100%)",
        boxShadow: "inset 0 0 100px 50px rgba(0, 0, 0, 0.5)",
        mixBlendMode: "multiply",
      } as any;
    } else if (filterId === "orange_shade") {
      return {
        width,
        height,
        position: "absolute",
        top: 0,
        left: 0,
        background:
          "linear-gradient(to right, rgba(156, 75, 0, 0) 0%, rgba(255, 122, 0, 0.36) 63.2707%, #FFBE90 100%)",
        mixBlendMode: "multiply",
        opacity: 0.7,
      } as any;
    } else if (filterId === "halftone_grey") {
      const baseWidth = 608; // DONT REMOVE THIS. This is used for halftone_grey filter
      const baseHeight = 1080; // DONT REMOVE THIS. This is used for halftone_grey filter
      const baseSizeWidth = 2; // px
      const baseSizeHeight = 3.5; // px
      // Calculate dynamic backgroundSize based on current resolution
      const backgroundSizeWidth = baseSizeWidth * (width / baseWidth);
      const backgroundSizeHeight = baseSizeHeight * (height / baseHeight);

      return {
        width,
        height,
        position: "absolute",
        top: 0,
        left: 0,
        backgroundImage: `
            radial-gradient(circle, black 45%, transparent 25%),
            radial-gradient(circle, black 20%, transparent 35%)`,
        backgroundPosition: "0 0, 25px 25px",
        backgroundSize: `${backgroundSizeWidth}px ${backgroundSizeHeight}px`,
        opacity: 0.75, // Adjust based on desired effect
        pointerEvents: "none",
        zIndex: 2,
      } as any;
    }

    return {} as any;
  };

  ////////  Zoom main logic below /////
  const applyZoomEffect = (segment: any, frame: number) => {
    const startFrame = secondsToFrames(segment.zoom_start);
    const endFrame = secondsToFrames(segment.zoom_end);

    // Easing functions
    const easeInOutQuad = (t: number) =>
      t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    const easeInOutCubic = (t: number) =>
      t < 0.5 ? 4 * t : 1 - Math.pow(-2 * t + 2, 9) / 2;
    const easeInOutSine = (t: number) => -(Math.cos(Math.PI * t) - 1) / 2;

    if (frame >= startFrame && frame <= endFrame) {
      const progress = (frame - startFrame) / (endFrame - startFrame);


      let scale = 1;
      let blur = "none";

      switch (segment.zoom_type) {
        case "verySlowZoomIn":
          const verySlowZoomInProgress =
            (frame - startFrame) / (endFrame - startFrame);
          scale = interpolate(verySlowZoomInProgress, [0, 1], [1, 1.15]);
          break;
        case "verySlowZoomOut":
          const verySlowZoomOutProgress =
            (frame - startFrame) / (endFrame - startFrame); // progress
          if (segment.zoom_end - segment.zoom_start <= 0.6) {
            scale = interpolate(
              verySlowZoomOutProgress,
              [startFrame, endFrame],
              [scaleRef.current, 1]
            );
            break;
          } else {
            scale = interpolate(
              verySlowZoomOutProgress,
              [0, 1],
              [scaleRef.current, 1],
              {
                easing: easeInOutCubic,
              }
            );
            break;
          }
        case "instantSharpZoomIn":
          scale = 1.5;
          break;
        case "instantZoomIn1.25": // test
          scale = 1.25;
          break;
        case "instantZoomIn1.5": // test
          scale = 1.5;
          break;
        case "instantSharpZoomOut":
          const zoomOutDuration = 0.25 * fps;
          const zoomOutStartFrame = Math.max(startFrame, frame - zoomOutDuration);
          const zoomOutProgress = (frame - zoomOutStartFrame) / zoomOutDuration;
          const easedZoomOutProgress = easeInOutQuad(Math.min(zoomOutProgress, 1));
          scale = interpolate(easedZoomOutProgress, [0, 1], [1.5, 1]);
          break;
        case "instantZoomBlur":
          const effectProgress = frame - startFrame;
          const blurEffectDuration1 = Math.min(0.8 * fps, endFrame - startFrame);
          const blurAmount = interpolate(
            effectProgress,
            [0, blurEffectDuration1],
            [20, 0],
            {
              extrapolateLeft: "clamp",
              extrapolateRight: "clamp",
            }
          );
          blur = `blur(${blurAmount}px)`;
          scale = 1;
          break;
        case "defaultZoomIn":
          scale = interpolate(frame, [startFrame, endFrame], [1, 1.3]);
          break;
        case "defaultZoomOut":
          const defaultZoomOutProgressCubic = progress;
          if (segment.zoom_end - segment.zoom_start <= 1.0) {
            scale = interpolate(
              defaultZoomOutProgressCubic,
              [0, 1],
              [scaleRef.current, 1]
            );
          } else {
            scale = interpolate(
              defaultZoomOutProgressCubic,
              [0, 1],
              [1.8, 1],
              { easing: easeInOutSine }
            );
          }
          break;
        case "instantZoomOutBlur":
          const effectDuration = endFrame - startFrame;
          const blurEffectDuration = Math.min(effectDuration, fps / 2);
          if (frame <= startFrame + blurEffectDuration) {
            const blurAmount = interpolate(
              frame,
              [startFrame, startFrame + blurEffectDuration],
              [30, 0],
              {
                extrapolateLeft: "clamp",
                extrapolateRight: "clamp",
              }
            );
            blur = `blur(${blurAmount}px)`;
          }
          scale = interpolate(frame, [startFrame, endFrame], [1.5, 1]);
          break;
      }

      // const translateX = (width / 2) * (1 - 1 / scale);
      // const translateY = (height / 2) * (1 - 1 / scale);
      const translateX = (width / 9) * (1 - 1 / scale);
      const translateY = (height / 8) * (1 - 1 / scale);

      scaleRef.current = scale;

      return {
        transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
        filter: blur !== "none" ? blur : undefined,
      };
    }

    return {};
  };

  const zoomStyle = jsonData.segments.reduce((style: any, segment: any) => {
    if (segment.zoom_on && renderZoom) {
      const zoomEffect = applyZoomEffect(segment, frame);
      return { ...style, ...zoomEffect };
    }
    return style;
  }, {});

  ////// Filter logic here /////
  // Extract filter value from the first segment
  // const firstSegment = jsonData.segments[0];
  const firstSegment = renderFilters ? jsonData.segments[0] : null;
  const filterId = firstSegment ? firstSegment.filter : null;




  console.log("overlayUrl", filteroverlayUrl);


  const getCurrentScene = (frame: any) => {
    const sceneChangeTimes = Object.values(jsonData.scene_change_timings);
    const currentSceneIndex = sceneChangeTimes.findIndex(
      (time: any, index: any) => {
        const nextTime = (sceneChangeTimes[index + 1] || Infinity) as any;
        return frame / fps >= time && frame / fps < nextTime;
      }
    );
    return jsonData.scene_change_person_coordinates[currentSceneIndex];
  };

  const renderView = () => {
    const currentScene = getCurrentScene(frame);
    if (!currentScene) return null;

    const talkinghead = currentScene.detections[0].bbox;
    const { transition_type } = currentScene;

    const vertical_modified_Props = {
      videoLink,
      totalVideoWidth: video_width, // Assume a default value or derive from metadata
      talkinghead,
      zoomStyle, // Pass the zoom style to the view
      renderLaptopOverlay,
      overlayDelay: overlayDelay,
    };

    // If currentScene has more than 1 detections, then we need to pass the second detection as well
    // Only if two so we will pass to both topFace and bottomFace
    let bottomFace;
    const topFace = currentScene.detections[0].bbox;
    if (currentScene.detections.length < 2) { bottomFace = topFace; }
    else {
      bottomFace = currentScene.detections[1].bbox;
    }
    // console.log("top face",topFace,"  bottomFace", bottomFace, );

    const up_down_Props = {
      videoLink,
      topFace,  // Assume a default value or derive from metadata
      bottomFace,  // Assume a default value or derive from metadata,
      totalVideoWidth: video_width, // Assume a default value or derive from metadata
      overlayDelay: overlayDelay,
    };

    const left_right_Props = {
      videoLink,
      totalVideoWidth: video_width, // Assume a default value or derive from metadata
      leftFace: topFace, // Assume a default value or derive from metadata
      rightFace: bottomFace, // Assume a default value or derive from metadata
      overlayDelay: overlayDelay,
    };

    const blurBackgroundProps = {
      videoLink,
      overlayDelay: overlayDelay,
    };

    switch (transition_type) {
      case "vertical_modified":
        return <VerticalOneHeadView {...vertical_modified_Props} />;
      case "landscape_original":
        return <BlurBackgroundTemplate {...blurBackgroundProps} />;
      case "up_down":
        return <CompositionPodcast_H {...up_down_Props} />;
      case "left_right":
        return <VerticalSplitLeftRight {...left_right_Props} />;

      default:
        const combinedFilter = `${zoomStyle.filter || ""} ${filterId === "halftone_grey"
          ? "grayscale(100%)"
          : filterId
            ? `url(#${filterId})`
            : ""
          }`.trim();

        return (
          <Sequence from={0} durationInFrames={durationInFrames}>
            <OffthreadVideo
              src={videoLink}
              style={{
                width,
                height,
                ...zoomStyle,
                position: "absolute",
                zIndex: -1,
                // filter: `${zoomStyle.blur || ""} ${
                //   filterId === "halftone_grey"
                //     ? "grayscale(100%)"
                //     : filterId
                //     ? `url(#${filterId})`
                //     : ""
                // }`,
                filter: combinedFilter,

              }}
              volume={frame >= overlayDelay ? 75 : 0}
            />
          </Sequence>
        );
    }
  };

  return (
    <AbsoluteFill>
      <svg style={{ height: 0 }}>
        <defs>
          <filter id="studioLight">
            <feColorMatrix
              type="matrix"
              values=" 1.920  0.000  0.000  0.000 -0.008 
                      0.000  1.920  0.000  0.000 -0.008 
                      0.000  0.000  1.920  0.000 -0.008 
                      0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="orange_shade">
            <feColorMatrix
              type="matrix"
              values=" 1.720  0.000  0.000  0.000 -0.035 
                      0.000  1.720  0.000  0.000 -0.035 
                      0.000  0.000  1.720  0.000 -0.035 
                      0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="colorgrading">
            <feColorMatrix
              type="matrix"
              values="1.500  0.000  0.000  0.000 -0.035 
                    0.000  1.500  0.000  0.000 -0.035 
                    0.000  0.000  1.500  0.000 -0.035 
                    0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="grey">
            <feColorMatrix
              type="matrix"
              values="0.213  0.715  0.072  0.000  0.000 
                    0.213  0.715  0.072  0.000  0.000 
                    0.213  0.715  0.072  0.000  0.000 
                    0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="sepia">
            <feColorMatrix
              type="matrix"
              values="0.393  0.768  0.188  0.000  0.000 
                    0.349  0.685  0.167  0.000  0.000 
                    0.272  0.533  0.130  0.000  0.000 
                    0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="nightlife">
            <feColorMatrix
              type="matrix"
              values=" 2.360 -0.327 -0.033  0.000  0.005 
            -0.097  2.130 -0.033  0.000  0.005 
            -0.097 -0.327  2.430  0.000  0.005 
             0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="high_exposure">
            <feColorMatrix
              type="matrix"
              values=" 2.000  0.000  0.000  0.000 -0.006 
                      0.000  2.000  0.000  0.000 -0.006 
                      0.000  0.000  2.000  0.000 -0.006 
                      0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="hue_excite_funky">
            <feColorMatrix
              type="matrix"
              values=" 0.040 -0.035  0.995  0.000  0.000 
                    0.345  0.869 -0.214  0.000  0.000 
                  -0.584  1.390  0.191  0.000  0.000 
                    0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
          <filter id="hue_vintage">
            <feColorMatrix
              type="matrix"
              values="-0.577  1.420  0.159  0.000  0.000 
                      0.428  0.432  0.139  0.000  0.000 
                      0.413  1.440 -0.855  0.000  0.000 
                      0.000  0.000  0.000  1.000  0.000"
            />
          </filter>
        </defs>
      </svg>

      {/* Render Laptop Overlay if it's enabled for only first 3 seconds*fps*/}
      {renderView()}

      {/* Render Laptop Overlay if it's enabled for only first 3 seconds*fps*/}
      {renderLaptopOverlay && (
        <Sequence from={0} durationInFrames={3 * fps}>
          <LaptopOverlayTemplate
            backgroundVideoLink={laptoptemplate_video}
            overlayVideoLink={videoLink}
            topLeft={{ x: 127, y: 465 }}
            topRight={{ x: 1000, y: 461 }}
            bottomLeft={{ x: 155, y: 1010 }}
            bottomRight={{ x: 964, y: 1040 }}
          />
        </Sequence>
      )}

      {/* Render Billboard Overlay if it's enabled for only first 3 seconds*fps*/}
      {billboard_overlay && (
        <Sequence from={0} durationInFrames={3 * fps}>
          <BillBoardOverlayTemplate
            backgroundImageLink={billboard_overlay_template}
            overlayVideoLink={videoLink}
          />
        </Sequence>
      )}

      {filterId &&
        ["studioLight", "orange_shade", "halftone_grey"].includes(filterId) && (
          <div style={getOverlayStyle(filterId)} />
        )}


      {filteroverlayUrl && render_lightleaks && (
        <Sequence key={filteroverlayUrl}
          from={0} durationInFrames={durationInFrames}>
          <LoopedVideoOverlay src={filteroverlayUrl}
          />
        </Sequence>
      )}


    </AbsoluteFill>
  );
};
