/* eslint-disable @remotion/deterministic-randomness */
/* eslint-disable object-shorthand */
/* eslint-disable capitalized-comments */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// styles.ts
/** @jsxImportSource @emotion/react */
// styles.ts
/** @jsxImportSource @emotion/react */
// import { css, keyframes } from '@emotion/react';
import './utilities/fonts.css';
import { random, interpolate } from 'remotion';

export const getTextStyle = (width: number, height: number, config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 10}%` : '70%'; // Scales position from 1 to 9 to percentages

    const style = {
        position: 'absolute',
        zIndex: 10,
        top: topPosition,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#333',  // Text color should be grey
        fontSize: responsiveFontSize,
        fontFamily: 'RocherColorGX, Arial, sans-serif',
        padding: '10px',
        borderRadius: '5px',
        textAlign: 'center',
        width: 'calc(100% - 10%)',
        filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 255, 0, 0.2))',
        // textShadow: '1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue', 
        // textShadow: '1px 1px 2px black, 0 0 15px blue, 0 0 5px darkblue',  // Text shadow  
        textShadow: '1px 1px 2px black, 0 0 8px blue, 0 0 5px darkblue',
    };
    return style as any;
};


export const getStyleBySegmentId = (segment: any, width: number, height: number,config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 10}%` : '50%'; // Example positioning scaling

    // Combine index and start time for a unique but consistent identifier
    const uniqueSeed = Math.floor(segment.start * 1000) + segment.index;
    const styleType = uniqueSeed % 3 + 1;  // Cycles through 1, 2, 3 based on a unique seed

    switch (styleType) {
        case 1:
            return {
                position: 'absolute',
                zIndex: 10,
                top: topPosition,
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: '#333',  // Text color should be grey
                fontSize: responsiveFontSize,
                fontFamily: 'RocherColorGX, Rye, sans-serif',
                padding: '10px',
                borderRadius: '5px',
                textAlign: 'center',
                width: 'calc(100% - 10%)',
                // filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 245, 235, 1.0)) blur(0.05rem)',  // transparent color
                filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 255, 0, 0.2))',
                // textShadow: '1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue',  // Text shadow    
                // textShadow: '1px 1px 2px black, 0 0 15px blue, 0 0 5px darkblue',  // Text shadow  
                textShadow: '1px 1px 2px black, 0 0 8px blue, 0 0 5px darkblue',
            };
        case 2:
            return {
                fontFamily: 'The Bold Font, Arial, sans-serif',
                fontSize: responsiveFontSize,
                color: 'white',
                textAlign: 'center',
                width: 'calc(100% - 20%)',
                // background: 'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)',
                // WebkitBackgroundClip: 'text',
                // WebkitTextFillColor: 'transparent',
            };
        case 3:
            return {
                position: 'absolute',
                zIndex: 10,
                top: topPosition,
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: '#333',  // Text color should be grey
                fontSize: responsiveFontSize,
                fontFamily: 'RocherColorGX, Rye, sans-serif',
                padding: '10px',
                borderRadius: '5px',
                textAlign: 'center',
                width: 'calc(100% - 10%)',
                // filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 245, 235, 1.0)) blur(0.05rem)',  // transparent color
                // filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 255, 0, 0.6))',
                filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 255, 0, 0.2))',
                // textShadow: '1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue',  // Text shadow    
                // textShadow: '1px 1px 2px black, 0 0 15px blue, 0 0 5px darkblue',  // Text shadow  
                textShadow: '1px 1px 2px black, 0 0 8px blue, 0 0 5px darkblue',
            };
        default:
            return {
                fontFamily: 'Arial, sans-serif',
                fontSize: responsiveFontSize,
                color: '#ccc',
                textAlign: 'center',
            };
    }
};


export const getCaptionsStyle = (width: number, height: number, config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 10}%` : '75%'; // Scales position from 1 to 9 to percentages

    const style ={
        position: 'absolute',
        zIndex: 10,
        top: topPosition,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: responsiveFontSize,
        fontFamily: 'The Bold Font, sans-serif',
        padding: '10px',
        borderRadius: '5px',
        fontWeight: 'bold',
        textAlign: 'center',
        width: 'calc(100% - 10%)',
        textShadow: '2px 2px 2px pink, 0 0 8px blue, 0 0 5px orange',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
    };
    return style as any;
};


// Define the style for the thriller caption with the animation
// Define the style for the thriller caption with the animation
export const getThrillerCaptionStyle = (width: number, height: number, frame: number,config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 10}%` : '75%';
    const maxFrames = 25; // Duration of the animation in frames
    const shadowIntensity = Math.min(frame, maxFrames); // Limit the shadow intensity to the animation duration

    const textShadow = `0px ${shadowIntensity}px ${shadowIntensity}px rgba(255, 0, 0, ${0.75 + shadowIntensity * 0.01})`;

    const style = {
        position: 'absolute',
        zIndex: 10,
        top: topPosition,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontFamily: '"PlasmaDrip"',
        textStroke: '2px black',
        color: 'white',

        width: '75%',
        fontSize: responsiveFontSize,
        textAlign: 'center',
        textShadow: textShadow,
        //   animation: `${thrillerAnimation} 0.7s ease-in-out forwards`,
    };
    return style as any;
};

// Define the style for the sad caption with the animation
export const getSadCaptionStyle = (width: number, height: number, frame: number, config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPositionPercentage = config.position ? config.position * 10 : 75; // Adjust top position based on config
    const maxFrames = 100;
    const droopIntensity = Math.min(frame * 0.1, 10);

    const fadeDuration = 10; // Duration for fade in and out in frames
    const fadeIn = Math.min(frame / fadeDuration, 1); // Fade in from 0 to 1
    const fadeOut = Math.max(1 - (frame - (maxFrames - fadeDuration)) / fadeDuration, 0); // Fade out from 1 to 0
    const opacity = frame <= fadeDuration ? fadeIn : frame >= (maxFrames - fadeDuration) ? fadeOut : 1;

    // Brightness effect: increase brightness during the middle part of the animation
    const middleFrame = maxFrames / 2;
    const brightnessFactor = Math.max(1, 1 + (Math.sin((frame - middleFrame) / middleFrame * Math.PI) * 0.5));

    return {
        position: 'absolute',
        zIndex: 10,
        top: `${topPositionPercentage + droopIntensity}%`, // Using dynamic top position based on config and animation
        left: '50%',
        transform: `translate(-50%, -50%)`,
        fontFamily: '"WondersRaisers"',
        fontWeight: 'bold',
        color: `rgba(224, 224, 224, ${opacity})`,

        width: '75%',
        fontSize: responsiveFontSize,
        textAlign: 'center',
        textShadow: `4px 4px 20px rgba(0, 0, 0, 0.5),4px 4px 14px rgba(0, 0, 0, 0.7),4px 4px 8px rgba(0, 0, 0, 0.9)`,
        filter: `brightness(${brightnessFactor}) blur(${Math.min(frame * 0.05, 2)}px)`,
        opacity: opacity,
    };
};



// Define the style for currency caption with the quick zoom-in animation
export const getCurrencyCaptionStyle = (width: number, height: number, frame: number, text: string, config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 10}%` : '75%'; // Scales position from 1 to 9 to percentages

    const zoomInDuration = 5; // Duration for zoom-in in frames

    const zoomIn = Math.min(frame / zoomInDuration, 1); // Zoom-in from 0 to 1
    const scale = 1 + zoomIn * 0.25; // Scale factor for zoom-in effect

    // Check if the text contains currency symbols or words
    const currencySymbols = ['$', '£', '€', '₹', '¥'];
    const currencyWords = ['dollars', 'usd', 'pound', 'euros', 'yen', 'rupees'];
    const isCurrencyText = currencySymbols.some(symbol => text.includes(symbol)) ||
        currencyWords.some(word => text.toLowerCase().includes(word));

    const color = isCurrencyText ? 'rgba(112, 255, 0, 0.90)' : 'rgba(255, 255, 255, 0.99)';
    const textStrokeColor = isCurrencyText ? '#006400' : '#000'; // Dark green stroke for currency text, black for others
    const textShadow = `
        0 0 8px ${color},
        0 0 16px ${color},
        0 0 24px ${color},
        0 0 32px ${color}

    `;

    return {
        position: 'absolute',
        zIndex: 10,
        top: topPosition,
        left: '50%',
        transform: `translate(-50%, -50%) scale(${scale})`,
        fontFamily: '"CashCurrency"',
        fontWeight: 'bold',
        color: color,
        width: '75%',
        fontSize: responsiveFontSize,
        textAlign: 'center',
        textTransform: 'uppercase',
        textShadow: textShadow,
        WebkitTextStrokeWidth: '1px',
        WebkitTextStrokeColor: textStrokeColor,
    };
};


// Define the style for currency caption with the quick zoom-in animation
export const getCurrencyCaptionStyle2 = (width: number, height: number, frame: number, text: string,config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 10}%` : '75%'; // Scales position from 1 to 9 to percentages

    const zoomInDuration = 5; // Duration for zoom-in in frames

    const zoomIn = Math.min(frame / zoomInDuration, 1); // Zoom-in from 0 to 1
    const scale = 1 + zoomIn * 0.25; // Scale factor for zoom-in effect

    // Check if the text contains currency symbols or words
    const currencyWords = ['dollars', 'usd', 'pound', 'euros', 'yen', 'rupees'];
    const isCurrencyText = currencyWords.some(word => text.toLowerCase().includes(word));

    const color = isCurrencyText ? '#4CAC00' : 'rgba(255, 255, 255, 0.99)';
    const textStrokeColor = isCurrencyText ? '#D9FFBB' : '#000'; // Dark green stroke for currency text, black for others
    const textShadow = `
        0 0 8px ${color},
        0 0 16px ${color},
        0 0 24px ${color}

    `;

    return {
        position: 'absolute',
        zIndex: 10,
        top: topPosition,
        left: '50%',
        transform: `translate(-50%, -50%) scale(${scale})`,
        fontFamily: '"CashCurrency"',
        fontWeight: 'bold',
        color: color,

        width: '75%',
        fontSize: responsiveFontSize,
        textAlign: 'center',
        textTransform: 'uppercase',
        textShadow: textShadow,
        WebkitTextStrokeWidth: '1px',
        WebkitTextStrokeColor: textStrokeColor,
    };
};


// Define the style for number caption with the quick reveal animation
export const getNumberCaptionStyle = (width: number, height: number, frame: number, text: string, config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 10}%` : '75%'; // Scales position from 1 to 9 to percentages

    const revealDuration = 5; // Duration for reveal in frames

    const reveal = Math.min(frame / revealDuration, 1); // Reveal from 0 to 1
    const scale = 1 + reveal * 0.25; // Scale factor for reveal effect

    const colors = ['rgba(0, 163, 255, 0.90)']; // Updated Blue and Yellow
    const color = colors[Math.floor(Math.random() * colors.length)];
    const textStrokeColor = '#f0f0f0'; // Brightened stroke color
    const textShadow = `
        0 0 8px ${color},
        0 0 16px ${color},
        0 0 24px ${color}

    `;


    return {
        position: 'absolute',
        zIndex: 10,
        top: topPosition,
        left: '50%',
        transform: `translate(-50%, -50%) scale(${scale})`,
        fontFamily: '"EB Garamond", serif',
        fontWeight: 'bold',
        color: "#fefefe",

        width: '75%',
        fontSize: responsiveFontSize,
        textAlign: 'center',
        textTransform: 'uppercase',
        textShadow: textShadow,
        WebkitTextStrokeWidth: '1px',
        WebkitTextStrokeColor: textStrokeColor,
    };
};


// Define the style for the fear caption with enhanced readability and a more haunted look
export const getFearCaptionStyle = (width: number, height: number, frame: number,config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 10}%` : '75%'; // Scales position from 1 to 9 to percentages


    // Calculate flicker effect
    const flickerFrames = [5, 10, 15, 20, 25, 30, 35, 40];
    const isFlickering = flickerFrames.includes(frame % 50);
    const color = isFlickering ? '#8B0000' : '#B22222'; // Flicker between dark red and firebrick
    const opacity = isFlickering ? 0.7 : 1; // Flicker opacity

    // Jitter effect
    const jitterX = Math.sin(frame / 3) * 2;
    const jitterY = Math.cos(frame / 3) * 2;

    return {
        position: 'absolute',
        zIndex: 10,
        top: topPosition,
        left: '50%',
        transform: `translate(-50%, -50%) translate(${jitterX}px, ${jitterY}px)`,
        fontFamily: 'NoMercy',
        fontWeight: 'bold',
        color: color,

        width: '75%',
        fontSize: responsiveFontSize,
        textAlign: 'center',
        textShadow: `
            0 0 15px rgba(255, 255, 255, 0.8),
            0 0 20px rgba(255, 255, 255, 0.6),
            0 0 25px rgba(139, 0, 0, 0.8),
            0 0 30px rgba(139, 0, 0, 0.6)
        `, // Enhanced white and dark red shadow
        letterSpacing: '0.07em', // Added letter spacing
        opacity: opacity,
    };
};



// Define the style for the magnifying glass caption
export const getMagnifyingGlassStyle = (width: number, height: number, frame: number,config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 10}%` : '75%'; // Scales position from 1 to 9 to percentages


    // Magnification effect
    const transitionDuration = 75; // Transition duration in frames (e.g., 2 seconds if fps is 30)
    const scale = 1 + 0.2 * (Math.sin(frame / transitionDuration * Math.PI * 2)); // Gradual magnification scale

    return {
        position: 'absolute',
        zIndex: 10,
        top: topPosition,
        left: '50%',
        transform: `translate(-50%, -50%) scale(${scale})`,
        fontFamily: 'Georgia, serif',
        fontWeight: 'bold',
        color: '#00008B', // Navy blue color

        width: '75%',
        fontSize: responsiveFontSize,
        textAlign: 'center',
        textShadow: '0 0 20px rgba(255, 255, 255, 0.75)', // Soft white glow
    };
};

// Define the magnifying glass overlay style
export const getMagnifyingGlassOverlayStyle = (width: number, height: number, frame: number, config: any = {}) => {
    const size = 150; // Size of the magnifying glass
    const x = (width / 2) + (Math.sin(frame / 20) * 100); // X position of the magnifying glass
    const y = (height / 2) + (Math.cos(frame / 20) * 50); // Y position of the magnifying glass

    return {
        position: 'absolute',
        zIndex: 12,
        top: `${y}px`,
        left: `${x}px`,
        width: `${size}px`,
        height: `${size}px`,
        background: 'rgba(255, 255, 255, 0.35)',
        borderRadius: '50%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        pointerEvents: 'none', // Prevent interaction with the magnifying glass
        overflow: 'hidden', // Clip the overflowing content
    } as any;
};

// Define the style for the text within the magnifying glass
export const getMagnifiedTextStyle = () => {
    return {
        transform: 'scale(0.5)', // Normal size inside the magnifying glass
        color: '#00008B', // Match the text color with the rest of the caption
        textShadow: '0 0 10px rgba(255, 255, 255, 0.5)', // Soft white glow
        position: 'relative',
        top: '50%',
        left: '50%',
        transformOrigin: 'center center',
    } as any;
};


// Define the style for the disappointed caption with trembling and static effect
export const getDisappointedCaptionStyle = (width: number, height: number, frame: number, config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 10}%` : '75%';

    // Trembling effect
    const trembleIntensity = 3;
    const trembleX = Math.sin(frame / 5) * trembleIntensity;
    const trembleY = Math.cos(frame / 5) * trembleIntensity;

    // Static noise effect
    const staticEffect = Math.random() * 0.5;

    return {
        position: 'absolute',
        zIndex: 13,
        top: topPosition,
        left: '50%',
        transform: `translate(-50%, -50%) translate(${trembleX + staticEffect}px, ${trembleY + staticEffect}px)`,
        fontFamily: 'TheGlobe',
        fontWeight: 'bold',
        color: `rgba(${Math.floor(48 + staticEffect * 100)}, ${Math.floor(48 + staticEffect * 100)}, ${Math.floor(48 + staticEffect * 100)}, 1)`, // Dark grey color with static effect

        width: '75%',
        fontSize: responsiveFontSize,
        textAlign: 'center',
        letterSpacing: '0.1em', // Increased letter spacing
        textShadow: `
            0 0 5px rgba(255, 255, 255, 0.9),
            0 0 10px rgba(255, 255, 255, 0.7),
            0 0 15px rgba(255, 255, 255, 0.5),
            0 0 20px rgba(255, 255, 255, 0.3)
        `, // Heavier text shadow with light grey to white tone
        filter: `blur(${0.5 + staticEffect}px)`, // Slight blur effect with static
    };
};

// Define the style for the confused caption
export const getConfusedCaptionStyle = (width: number, height: number, frame: number,config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    const topPosition = config.position ? `${config.position * 9}%` : '75%';

    // Fade downwards effect
    const fadeDuration = 30; // Duration of the fade effect in frames
    const opacity = Math.max(0, 1 - (frame / fadeDuration)); // Fade out over the duration
    const translateY = frame < fadeDuration ? frame : fadeDuration; // Translate downwards

    return {
        position: 'absolute',
        zIndex: 13,
        top: topPosition,
        left: '50%',
        transform: `translate(-50%, ${translateY / 3}px)`,
        fontFamily: 'OceanOasis',
        fontWeight: 'bold',
        color: '#005EFF', // Darker blue color for better visibility
        margin: '-30px auto',
        width: '75%',
        fontSize: responsiveFontSize,
        textAlign: 'center',
        letterSpacing: '0.1em', // Increased letter spacing
        textShadow: `
            0 0 5px rgba(255, 255, 255, 0.7),
            0 0 10px rgba(255, 255, 255, 0.5),
            0 0 15px rgba(255, 255, 255, 0.3),
            0 0 20px rgba(255, 255, 255, 0.1)
        `, // Subtle text shadow with lighter tone
        opacity: opacity, // Apply fade effect
    };
};

// Define the style for the confused eyes GIF
export const getConfusedEyesStyle = (width: number, height: number, frame: number) => {
    const size = Math.min(width, height) * 0.15; // Size relative to the screen dimensions
    const fadeDuration = 30; // Duration of the fade effect in frames
    const opacity = Math.min(1, frame / fadeDuration); // Fade in over the duration
    const translateY = frame < fadeDuration ? -frame : -fadeDuration; // Translate upwards

    return {
        position: 'absolute',
        zIndex: 12,
        top: `calc(50% + ${translateY / 3}px)`,
        left: '50%',
        transform: `translate(-50%, -50%)`,
        width: `${size}px`,
        height: 'auto',
        opacity: opacity, // Apply fade effect
        filter: 'drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 245, 235, 1.0)) blur(0.05rem)', // Add drop shadow to the GIF
    } as any;
};


// Define the plain Netflix-style caption style
export const getPlainTextStyle = (width: number, height: number, config: any = {}) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;

    const colors = config.colors || ['#FFFFFF'];
    const position = config.position || 5;

    return {
        position: 'absolute',
        zIndex: 10,
        top: `${position * 10}%`,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: responsiveFontSize,
        fontFamily: config.font_name || 'The Bold Font',
        color: colors[0],
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        textAlign: 'center',
        width: 'calc(100% - 10%)',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    };
};


export const getHormoziStyle = (width: number, height: number, text: string, frame: number, fps: number, config: any = {}) => {
    const words = text.split(' ');
    const midPoint = Math.ceil(words.length / 2);
    const firstHalf = words.slice(0, midPoint).join(' ');
    const secondHalf = words.slice(midPoint).join(' ');
  
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
  
    const color1 = config.colors?.[0] || '#FFFFFF'; // Color for the first half
    const color2 = config.colors?.[1] || '#FFFF00'; // Color for the second half
    const position = config.position ? `${config.position * 10}%` : '50%';
    const fontFamily = config.font_name || 'The Bold Font, sans-serif';
  
    // Calculate fade-in animation
    const fadeInDuration = 0.1 * fps;
    const opacity = Math.min(frame / fadeInDuration, 1);
  
    const style = {
      position: 'absolute',
      zIndex: 10,
      top: position,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: responsiveFontSize,
      fontFamily: fontFamily,
      color: color1,
      textAlign: 'center',
      width: '90%',
      padding: '10px 20px',
      borderRadius: '5px',
      textShadow: `2px 2px 4px #000`,
      opacity: opacity, // Apply fade-in animation
    };
  
    return { style, firstHalf, secondHalf, color1, color2 } as any;
  };
  
  
  export const getRandomWordHighlightStyle = (width: number, height: number, text: string, seed: string, config: any = {}) => {
    const words = text.split(' ');
    const majorWords = words.filter(word => word.length >= 4);
    const randomMajorWord = majorWords.length > 0 ? majorWords[Math.floor(random(seed) * majorWords.length)] : null;
  
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;

    const fontSizeMultiplierHighlight = config.font_size_highlight ? (0.003 + ((config.font_size_highlight - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSizeHighlight = `${minDimension * fontSizeMultiplierHighlight}em`;

    const color1 = config.colors?.[0] || '#FFFFFF'; // Main text color
    const highlightColors = config.colors?.slice(1) || ['#00FFEE', '#FF00FF', '#FFFF00']; // Highlight colors
    const highlightColor = highlightColors[Math.floor(random(seed) * highlightColors.length)];
    const position = config.position ? `${config.position * 10}%` : '50%';
    const fontFamily = config.font_name || 'Roboto, sans-serif';
  
    const style = {
      position: 'absolute',
      zIndex: 10,
      top: position,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: responsiveFontSize,
      fontFamily: fontFamily,
      color: color1,
      textAlign: 'center',
      width: '90%',
      padding: '10px 20px',
      borderRadius: '5px',
      textShadow: `2px 2px 4px #000`,
    };
  
    const highlightedText = words.map(word => (
      word === randomMajorWord
        ? `<span style="color: ${highlightColor}; font-size: ${parseFloat(responsiveFontSize)*0.5}em;">${word}</span>`
        : word
    )).join(' ');
  
    return { style, highlightedText } as any;
  };



export const getTranspifySpecialStyle = (
    width: number,
    height: number,
    text: string,
    segmentDuration: number, // Segment duration to reset the animation for each segment
    frame: number,
    fps: number,
    config: any = {},
    seed: string = '' // Add seed as an optional argument
  ) => {
    console.log(`Frame: ${frame}, Text: ${text}`);
  
    const words = text.split(' ').filter(word => word.trim() !== ''); // Filter out empty strings
    const minDimension = Math.min(width, height);
    const baseFontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const baseFontSize = minDimension * baseFontSizeMultiplier;
    const responsiveFontSize = `${baseFontSize}em`;
    const position = config.position ? `${config.position * 10}%` : '50%';
    const fontFamily = config.font_name || 'Poppins, sans-serif';
  
    const color1 = config.colors?.[0] || '#FFFFFF'; // Main text color
    const color2 = config.colors?.[1] || '#00FFEE';
    const color3 = config.colors?.[2] || '#FF00FF';
    const color4 = config.colors?.[3] || '#FFFF00';
  
    // Check if seed is provided for random selection
    const selectedWord = seed && words.length > 0 ? words[Math.floor(seededRandom(seed) * words.length)] : null;
  

  
    // Randomly choose between zoom, fade, stroke, and tremble effects for each segment
    const animationType = seededRandom(seed) > 0.75 ? 'zoom' : (seededRandom(seed) > 0.5 ? 'fade' : (seededRandom(seed) > 0.25 ? 'stroke' : 'tremble'));
  
    let appliedEffect = '';
    let opacity = 1;
    let multiplier = 0.3;
    if (selectedWord !== null) {
        let multiplier = 0.35;
        if (selectedWord.length < 6) {
          multiplier = 0.5;
        } else if (selectedWord.length < 9) {
          multiplier = 0.25;
        } else {
          multiplier = 0.25;
        }

    }
  
    if (animationType === 'zoom') {
      // Progressive font size increase in em units, reset for each segment
      const initialFontSize = baseFontSize; // Starting font size in em
      const maxIncreaseFactor = 0.035; // Adjust intensity as needed
      const zoomDuration = 30; // Duration for zoom-in effect in frames
  
      // Interpolating the font size increase
      const scaleFactor = interpolate(
        Math.min(frame % segmentDuration, zoomDuration), // Reset frame count for each segment
        [0, zoomDuration],
        [1, 1 + maxIncreaseFactor]
      );
      const fontSizeIncrease = initialFontSize * scaleFactor * multiplier;
      appliedEffect = `font-size: ${fontSizeIncrease}em; color: ${color2};`;
    } else if (animationType === 'fade') {
      // Interpolating the opacity for fade effect
      const fadeDuration = 15; // Duration for fade-in effect in frames
      opacity = interpolate(
        Math.min(frame % segmentDuration, fadeDuration), // Reset frame count for each segment
        [0, fadeDuration],
        [0.4, 1]
      );
      appliedEffect = `color: ${color3}; opacity: ${opacity}; text-shadow: 0 0 20px ${color1};`;
    } else if (animationType === 'stroke') {
      // Interpolating the stroke opacity for stroke effect
      const strokeDuration = 15; // Duration for stroke effect in frames
      const strokeOpacity = interpolate(
        Math.min(frame % segmentDuration, strokeDuration), // Reset frame count for each segment
        [0, strokeDuration],
        [0.7, 1]
      );
      const textOpacity = interpolate(
        Math.min(frame % segmentDuration, strokeDuration), // Reset frame count for each segment
        [0, strokeDuration],
        [0.3, 0]
      );
      const colorWithOpacity = color1 + Math.round(textOpacity * 255).toString(16).padStart(2, '0'); // Adjust text color opacity
      appliedEffect = `color: ${colorWithOpacity}; -webkit-text-stroke-width: 1.5px; -webkit-text-stroke-color: rgba(255, 255, 255, ${strokeOpacity}); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);`;
    } else if (animationType === 'tremble') {
      // Trembling effect
      const trembleIntensity = 2; // Tremble intensity in pixels
      const trembleX = Math.sin(frame * 0.3) * trembleIntensity;
      const trembleY = Math.cos(frame * 0.3) * trembleIntensity;
  
      appliedEffect = `transform: translate(${trembleX}px, ${trembleY}px); color: ${color4}; text-shadow: 0px 0px 24px rgba(255, 255, 255, 0.75);`;
    }
  
    console.log(`Applied Effect: ${appliedEffect}`);
  
    const style = {
      position: 'absolute',
      zIndex: 10,
      top: position,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: responsiveFontSize,
      fontFamily: fontFamily,
      fontWeight: 'bold',
      color: color1,
      textAlign: 'center',
      width: '90%',
      padding: '10px 20px',
      borderRadius: '5px',
      textShadow: `2px 2px 4px #000`,
      opacity: 1, // Main text opacity remains constant
    };
  
    console.log(`Final Style:`, style);
  
    // Ensure the selected word is not null
    const highlightedText = words.map(word => (
      word === selectedWord
        ? `<span style="${appliedEffect}">${word}</span>`
        : word
    )).join(' ');
  
    return { style, highlightedText } as any;
  };
  
  // Add a seeded random function to handle the random selection of words
  const seededRandom = (seed: string) => {
    const x = Math.sin(parseInt(seed, 36)) * 10000;
    return x - Math.floor(x);
  };
  
   


  export const getViralTremblingStyle = (width: number, height: number, frame: number, config: any, seed: string, fps: any) => {
    const minDimension = Math.min(width, height);
    const fontSizeMultiplier = config.font_size ? (0.003 + ((config.font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;
    
    // Trembling effect
    const trembleIntensity = fps/40; // Tremble intensity in pixels
    const trembleX = Math.sin(frame * 0.4) * trembleIntensity;
    const trembleY = Math.cos(frame * 0.4) * trembleIntensity;
    
    // Generate random color once per segment
    const seededRandomValue = seededRandom(seed);
    const colors = [config.colors?.[0] || '#FFFFFF', config.colors?.[1] || '#00FFEE', config.colors?.[2] || '#FF00FF'];
    const color = colors[Math.floor(seededRandomValue * colors.length)];
    
    const position = config.position ? `${config.position * 10}%` : '50%';
    const fontFamily = config.font_name || 'Poppins, sans-serif';
    
    return {
      position: 'absolute',
      zIndex: 10,
      top: position,
      left: '50%',
      transform: `translate(-50%, -50%) translate(${trembleX}px, ${trembleY}px)`,
      fontSize: responsiveFontSize,
      fontFamily: fontFamily,
      color: color,
      textAlign: 'center',
      width: '90%',
      padding: '10px 20px',
      borderRadius: '5px',
      textShadow: `
        0 0 5px rgba(225, 225, 0, 0.9),
        0 0 10px rgba(225, 225, 0, 0.8),
        0 0 15px rgba(215, 215, 215, 0.5),
        0 0 20px rgba(205, 205, 205, 0.3)
      `, // Heavier text shadow with light grey to white tone
    } as any;
  };
  

  export const getWordStyle = (width:any, height:any, wordData: any, frame: any, fps: any, config: any) => {
    console.log(`Word: ${wordData.word}, Frame: ${frame}`, config);
    const minDimension = Math.min(width, height);
    const font_size = config.font_size || 25;
    const fontSizeMultiplier = config.font_size ? (0.003 + ((font_size - 10) / 30 * 0.0015)) : 0.0045;
    const responsiveFontSize = `${minDimension * fontSizeMultiplier}em`;  // Adjust the size based on opacity

    const colors = config.colors || ['#FFFFFF', '#000000']; // Default colors if none are provided
    const position = config.position || 5;
    console.log(responsiveFontSize);
    console.log(width, height);
    // console.log(`Word: ${wordData.word}, Frame: ${frame}, FontSize: ${responsiveFontSize}`);

    return {
        position: 'absolute',
        zIndex: 10,
        top: `${position * 10}%`,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: responsiveFontSize,
        fontFamily: config.font_name || 'The Bold Font',  // Use custom or default font
        color: colors[0],  // Use the first color by default
        fontWeight: 'bold',
        lineHeight: '150%',
        textAlign: 'center',
        width: 'calc(100% - 10%)',
        textShadow: `2px 2px 4px ${colors.length > 1 ? colors[1] : 'rgba(0, 0, 0, 0.5)'}`,  // Use second color for shadow if available
    } as any;
};



  

  
  